import React, { useState } from "react";
import rightArrow from "../assets/images/landing-page/expand_right.svg";
import logo from "../assets/images/logo_two.svg";
import { Link } from "react-router-dom";
import UserLayout from "../components/layout/user_layout/UserLayout";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useDispatch } from "react-redux";
import { reportIssue } from "../redux/Action/UserActions";
import { useFormik } from "formik";

const Home = () => {
  const [index, setIndex] = useState(0);
  const [laod, setLoad] = useState(false);
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required*";
    }
    if (!values.comment) {
      errors.comment = "Required*";
    }
    return errors;
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      comment: "",
    },
    validate,
    onSubmit: async (values) => {
      debugger;
      const data = {
        title: values.title,
        comment: values.comment,
      };
      try {
        dispatch(reportIssue(data));
        formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <UserLayout>
      {/* <!-- banner  start --> */}
      <section className="banner-img registration_banner_img new_banner_img">
        <div className="container-fluid container_inner_width">
          <div className="banner-content">
            <h1 className="mb-0">
              Find a Tradesperson
              <br />
              the smart choice way
            </h1>
            <h2 className="mt-3">Get a free quotation in minutes</h2>
            <Link to="/find-trade-person" className="btn white_btn ">
              Find a local Tradesperson
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- banner  end --> */}

      {/* <!-- common banner bottom part start --> */}
      <section className="trust_wrapper position-relative home_btm_ques">
        <div className="banner_btm">
          <div className="btm_ques">
            <a href="services" className="book_dentist_link">
              Need help fast? See our available services{" "}
              <img src={rightArrow} alt="right-arrow" />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- common banner bottom part end --> */}

      {/* <!-- Problems Section start --> */}
      <section className="common-padding tradeperson_prblms">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center mb-5">
                IN A HURRY? Explore our services, instead.
              </h2>
              <div className="problems-content">
                <div className="row">
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/Tiliing.jpg")}
                        className="img-fluid"
                        alt="Tiling"
                      />
                      <h3 className="position-absolute absolute-txt">Tiling</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/landing-page/service-img-2.png")}
                        className="img-fluid"
                        alt="Broken Appliance"
                      />
                      <h3 className="position-absolute absolute-txt">
                        Broken Appliance
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/service-img-7.jpg")}
                        className="img-fluid"
                        alt="Painting & Decorating"
                      />
                      <h3 className="position-absolute absolute-txt">
                        Painting & Decorating
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/landing-page/plumber.jpg")}
                        className="img-fluid"
                        alt="plumbing"
                      />
                      <h3 className="position-absolute absolute-txt">
                        plumbing
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/service-img-5.jpg")}
                        className="img-fluid"
                        alt="Construction"
                      />
                      <h3 className="position-absolute absolute-txt">
                        Construction
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/service-img-6.jpg")}
                        className="img-fluid"
                        alt="Toilet & Bathroom"
                      />
                      <h3 className="position-absolute absolute-txt">
                        Toilet & Bathroom
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/Plastering.jpg")}
                        className="img-fluid"
                        alt="Plaster"
                      />
                      <h3 className="position-absolute absolute-txt">
                        plastering
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/new-images/service-img-8.jpg")}
                        className="img-fluid"
                        alt="Garden"
                      />
                      <h3 className="position-absolute absolute-txt">Garden</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 inner_prblm_detail">
                    <div className="position-relative img_text">
                      <img
                        src={require("../assets/images/landing-page/service-img-9.png")}
                        className="img-fluid"
                        alt="Electrical Problems"
                      />
                      <h3 className="position-absolute absolute-txt">
                        Electric Problems
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Problems Section end --> */}

      {/* <!-- About-us start --> */}
      <section className="about-us common-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 justify-content-center align-items-center d-flex logo_none logo_width ">
              <img
                src={require("../assets/images/landing-page/text_logo.png")}
                alt="black-logo"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="about-us-title d-flex justify-content-between align-items-center mb-3">
                <h2>TRADESPEOPLE, ON DEMAND.</h2>
                {/* <!-- <div className="side_logo">
                                <img src="images/logo-short.png" alt="logo" className="img-fluid"> 
                            </div>                          --> */}
              </div>
              <p>
                We’re anything but ordinary – just ask our customers. It’s all
                about connecting homeowners with local tradespeople, without the
                need for the personal recommendation bit in-between. Wherever
                you are, whatever you need, and whenever you need them, we’ve
                got a skilled labourer for that.{" "}
              </p>

              <div className="d-flex justify-content-end learn_more_btn">
                <a href="about-us" className="btn btn-primary white-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About-us end --> */}

      {/* <!-- Video-Section start --> */}
      {/* <section className="video_data position-relative">
        <div className="videoCoverImage">
          <div onclick="thevid=document.getElementById('thevideo'); thevid.style.display='block'; this.style.display='none'">
            <img
              className="thumb"
              style={{ cursor: "pointer" }}
              src={require("../assets/images/landing-page/video-thumb.png")}
            />
          </div>
          <div id="thevideo" style={{ display: "none" }}>
            <iframe
              width="100%"
              height="640"
              src="https://www.youtube.com/embed/fm208EgQUoU"
              frameborder="0"
              allowfullscreen=""
              include=""
              control=""
            ></iframe>
          </div>
        </div>
      </section> */}
      {/* <!-- Video-Section end --> */}

      <section className="blogs-sect discover common-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blogs-title">
                <h2 className="text-center mb-5">Blogs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 table-d">
              <Link
                to="/outsmarting-rogue-traders"
                className="blogs-sect-inner"
              >
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>
                    Outsmarting Rogue Traders: Your Guide to Dodging Disaster in
                    UK Construction
                  </h4>
                </div>
                <p>
                  Picture this: You're excited about your upcoming construction
                  project, envisioning your dream space taking shape. But wait!
                  Cue the ominous music because lurking in the shadows are rogue
                  traders, ready to turn your dreams into nightmares. Fear not,
                  brave homeowner or investor! In this blog post, we'll arm you
                  with knowledge and a dash of British wit to help you spot and
                  avoid rogue traders in the UK construction scene.
                </p>
              </Link>
            </div>
            <div className="col-lg-4 table-d">
              <Link to="/budget-friendly-planning" className="blogs-sect-inner">
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>Budget-Friendly Planning </h4>
                </div>
                <p>
                  Dreaming of a home makeover without breaking the bank? You're
                  in the right place! In this guide, we'll show you how to plan
                  your budget-friendly renovation while optimising your content
                  for search engines. Let's dive into the world of savvy
                  budgeting and SEO success.
                </p>
              </Link>
            </div>
            <div className="col-lg-4 table-d">
              <Link
                to="/becoming-eco-friendly-builder"
                className="blogs-sect-inner"
              >
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>
                    Becoming an Eco-Friendly Builder: A Path to Sustainable
                    Construction
                  </h4>
                </div>
                <p>
                  The construction industry plays a significant role in shaping
                  our world, and as an aspiring builder, you have the
                  opportunity to make a positive impact on the environment. In
                  this blog post, we’ll explore the steps to becoming an
                  eco-friendly builder, from embracing sustainable practices and
                  materials to navigating certifications. Let’s embark on the
                  journey to becoming an eco-friendly builder!
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Discover-Section start --> */}
      {/* <section className="discover common-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center mb-5">Discover</h2>
              <div className="discover-content">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-2 col-4 features">
                    <img
                      src={require("../assets/images/landing-page/industory_placeholder.png")}
                      className="img-fluid"
                      alt="video-consultation"
                    />
                    <h3>*****</h3>
                  </div>
                  <div className="col-md-2 col-4 features">
                    <img
                      src={require("../assets/images/landing-page/industory_placeholder.png")}
                      className="img-fluid"
                      alt="online-prescription"
                    />
                    <h3>*****</h3>
                  </div>
                  <div className="col-md-2 col-4 features">
                    <img
                      src={require("../assets/images/landing-page/industory_placeholder.png")}
                      className="img-fluid"
                      alt="industry-updates"
                    />
                    <h3>*****</h3>
                  </div>
                  <div className="col-md-2 col-4 features">
                    <img
                      src={require("../assets/images/landing-page/industory_placeholder.png")}
                      className="img-fluid"
                      alt="expert-guidance"
                    />
                    <h3>*****</h3>
                  </div>
                  <div className="col-md-2 col-4 features">
                    <img
                      src={require("../assets/images/landing-page/industory_placeholder.png")}
                      className="img-fluid"
                      alt="treatments-advice"
                    />
                    <h3>*****</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Discover-Section end --> */}

      {/* <!-- Logos-Section start --> */}
      {/* <section className="logos common-padding">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="logo-img">
                <img
                  src={require("../assets/images/landing-page/bbc_logo.png")}
                  alt="bbc-logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="logo-img">
                <img
                  src={require("../assets/images/landing-page/daily_logo.png")}
                  alt="daily-mail"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="logo-img">
                <img
                  src={require("../assets/images/landing-page/guardian_logo.png")}
                  alt="guardian-logo"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="logo-img">
                <img
                  src={require("../assets/images/landing-page/cosmopolitan_logo.png")}
                  alt="cosmopolitan"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--  Logos-Section end --> */}

      {/* <!-- Testimonial start --> */}
      <div className="testimonial-Section">
        <Container>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    {/* <div className="review-img-left-s">
          <img src={require("../assets/images/testimonial/1-lg.png")} className="img-fluid" alt="testimonial-img" />
          </div> */}
                    <div className="testimonial-review-text-right">
                      <p>
                        “Had a fantastic experience with this company, I will
                        definitely be using this again! “
                      </p>
                      <h3>Margaret Beckles </h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>

            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    <div className="testimonial-review-text-right">
                      <p>
                        {" "}
                        “I love the fact that tradespeople can upload quotes, I
                        felt reassured from start to finish.“
                      </p>
                      <h3>Peter Townsend</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>

            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    <div className="testimonial-review-text-right">
                      <p>
                        {" "}
                        “It was good to be able to enter my job and have
                        reliable tradesmen respond rather than me calling round
                        people.“
                      </p>
                      <h3>Vicky Jessops</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>

            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    <div className="testimonial-review-text-right">
                      <p>
                        {" "}
                        “I like how Smart Choice Traders does a thorough check
                        on all businesses before putting them on the site. It's
                        also useful to see photographs of work.“
                      </p>
                      <h3>Meera Bakshi</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>

            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    <div className="testimonial-review-text-right">
                      <p>
                        “The company were very professional from getting the
                        quote to completion.
                        <br />
                        The works were carried out in a very professional
                        manner, the lads working were very polite and extremely
                        helpful explaining each step of the works.“
                      </p>
                      <h3>Brajan Bajorek</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>

            <Carousel.Item>
              <Row>
                <Col lg={8} className="mx-auto">
                  <div className="bg-card-review">
                    <div className="testimonial-review-text-right">
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          </Carousel>
        </Container>
      </div>
      {/* <!-- Testimonial start / end--> */}
      <div className="Report-bug-sec">
        <div className="container">
          <section className="login_page">
            <div className="container-fluid">
              <div className="container_data">
                <div className="row ">
                  <div className="col-lg-12">
                    <h2 className="text-center mb-5">Report an issue</h2>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 d-flex justify-content-center">
                    <div className="sign_in report w-100 h-100">
                      <form onSubmit={formik.handleSubmit} className="w-100">
                        <div className="card">
                          <div className="card-body">
                            <div className="title_signin">
                              <div className="form-group mb-3 mt-4">
                                <label for="">Subject</label>
                                <input
                                  type="text"
                                  className="form-control login_form_control"
                                  placeholder="Add Title"
                                  name="title"
                                  id="Title"
                                  onChange={formik.handleChange}
                                  value={formik.values.title}
                                />
                                {formik.errors.title && (
                                  <p className="formik-error">
                                    {formik.errors.title}
                                  </p>
                                )}
                              </div>
                              <div className="form-group mb-3">
                                <label for="">Leave A comment</label>
                                <div className="input-group">
                                  <textarea
                                    rows={2}
                                    className="form-control br-none login_form_control"
                                    id="Comment"
                                    placeholder="comment*"
                                    name="comment"
                                    onChange={formik.handleChange}
                                    value={formik.values.comment}
                                  />
                                </div>
                                {formik.errors.comment && (
                                  <p className="formik-error">
                                    {formik.errors.comment}
                                  </p>
                                )}
                              </div>

                              <div className="btn_submit mt-4 d-flex">
                                <button
                                  htmlType="submit"
                                  className="btn btn-primary btn-custom btn-lg w-100"
                                >
                                  {laod ? "...Loading" : "Submit"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </UserLayout>
  );
};

export default Home;
