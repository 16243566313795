import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../redux/Action/tradeLoginAction";
import TradeHeader from "../layout/TradeHeader";

const TradeResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required*";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8 || values.password.length > 10) {
      errors.password = "Invalid Password";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords doesn't match";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      const dataVal = {
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: token,
      };
      dispatch(resetPassword(dataVal, navigate));
    },
  });
  return (
    <>
      {/* <!-- header start --> */}
      
      {/* <TradeHeader /> */}
      {/* <!-- header end --> */}

      {/* <!-- Login Page Start --> */}
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div className="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p className="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in ">
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h3 className="mb-4">Reset Password</h3>
                          <div className="form-group mb-3">
                            <label for="">Email</label>

                            <input
                              type="email"
                              className="form-control login_form_control"
                              placeholder="Email"
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.email && (
                              <p className="formik-error">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label for="">New Password</label>
                            <div className="input-group">
                              <input
                                type={showPass ? "text" : "password"}
                                className="form-control br-none login_form_control"
                                id=""
                                placeholder="*****"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                              />
                              <div className="input-group-prepend">
                                <span
                                  onClick={() => setShowPass((pre) => !pre)}
                                  className="input-group-text bl-none "
                                  id="basic-addon1"
                                >
                                  <i
                                    className={
                                      showPass ? "fa fa-eye-slash" : "fa fa-eye"
                                    }
                                    id="eye"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            {formik.errors.password && (
                              <p className="formik-error">
                                {formik.errors.password}
                              </p>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label for="">Confirm Password</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control br-none login_form_control"
                                id=""
                                placeholder="*****"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                              />
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text bl-none "
                                  id="basic-addon1"
                                >
                                  <i className="fa fa-eye" id="eye"></i>
                                </span>
                              </div>
                            </div>
                            {formik.errors.confirmPassword && (
                              <p className="formik-error">
                                {formik.errors.confirmPassword}
                              </p>
                            )}
                          </div>
                          <div className="btn_submit mt-5 d-flex">
                            <button type="submit" className="btn btn-primary btn-custom btn-lg w-100">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Login Page End --> */}
    </>
  );
};

export default TradeResetPassword;
