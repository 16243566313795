import React, { useEffect, useState } from "react";
import TradeAccLayout from "../../components/trade_acc_home/layout/TradeAccLayout";
import { getTradeLocations, getTradePersonProfile } from "../../redux/Action/tradeActions";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import ReviewModal from "../../components/user_acc_home/tradepersonRewiew/ReviewModal";
const TradeDashboard = () => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [reviewData, setReviewData] = useState()
  const dispatch = useDispatch();
  const tradeProfileDetail = useSelector(
    (state) => state?.tradeAction?.tradePerson_detail
  );
  const businessLocation = useSelector(
    (state) => state?.tradeAction?.traderLoctions
  );
  useEffect(() => {
    dispatch(getTradePersonProfile());
    if (sessionStorage.getItem("trade_id")) {
      dispatch(getTradeLocations(sessionStorage.getItem("trade_id"))); 
    }
  }, []);
  const viewFullReviews = (val) => {
    if (val) {
      setShow(true);
      setReviewData(val)
    }
  }
 
  return (
    <TradeAccLayout>
      <ReviewModal show={show} setShow={setShow} reviewData={reviewData} />
      <section className="recent_requests common_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="trade_enquiry_left mb-3">
                <div className="trade_enquiry_profile box_shadow_new mb-4">
                  {tradeProfileDetail?.business_image && tradeProfileDetail?.business_image !="undefined" ? (
                    <img
                      src={
                        BACKEND_URL +
                        "business_image/" +
                        tradeProfileDetail?.business_image
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={require("../../assets/images/placeholder.png")}
                      alt=""
                    />
                  )}
                  {/* <img src={require("../../assets/images/tradesperson/review-profile.png")} alt="" /> */}
                  <h3 className="border_grey">
                    {tradeProfileDetail?.company_name}
                  </h3>
                  <p>{tradeProfileDetail?.company_name}</p>
                </div>
                <div className="account box_shadow_new">
                  <h3 className="border_grey">Account</h3>
                  <ul>
                    <li>
                      <div className="account_detail">
                        <img src="images/enquiries/check_circle.svg" alt="" />
                        <h4>Standard Member</h4>
                      </div>
                      <a href="#">Go Pro</a>
                    </li>
                    <li className="mb-0">
                      <div className="account_detail">
                        <img src={"verified"} alt="" />
                        <h4>Verified </h4>
                      </div>
                    </li>
                  </ul>
                  <h3 className="border_grey"></h3>
                  <ul>
                    <li>
                      <div className="account_detail">
                        <img
                          src="images/enquiries/small_grey_star.svg"
                          alt=""
                        />
                        <h4>
                          {tradeProfileDetail?.overall_rating
                            ? tradeProfileDetail?.overall_rating
                            : "0"}{" "}
                          Star Rating
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="trade_enquiry_middle box_shadow_new  mb-4">
                <div className="enquiry_features services_info">
                  <h3 className="border_grey">
                    My Services {tradeProfileDetail?.multi_services?.length}
                  </h3>
                  <ul className="dashboard_listing dashboard_listing_height">
                    {tradeProfileDetail?.multi_services?.length>0?
                   ( tradeProfileDetail?.multi_services?.map((item) => {
                      return <li>{item?.label}</li>;
                    })):<p className="no-enquiry-img">No Services yet</p>}
                  </ul>
                  {/* <p className="more_options">See all</p> */}
                </div>
                <div className="enquiry_features location_info">
                  <h3 className="border_grey">My Locations</h3>
                  <ul className="dashboard_listing dashboard_location_listing">
                    {businessLocation?.map((item, i) => {
                      return <li>{item?.address}</li>;
                    })}
                  </ul>
                </div>
                <div className="enquiry_features notification_info">
                  <h3 className="border_grey">Notifications</h3>
                  <p className="notes">
                    New requests will be sent to this email address:
                  </p>
                  <ul className="dashboard_listing">
                    <li>Notifications</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="trade_enquiry_right box_shadow_new  mb-4">
                <div className="enquiry_features recent_enquiry-info">
                  <h3 className="border_grey">
                    Recent Enquiries: {tradeProfileDetail?.recentEnquiries}
                  </h3>
                  <ul className="dashboard_listing recent_enquiry_listing">
                    {
                    tradeProfileDetail?.enquiries?.length>0 ? 
                    tradeProfileDetail?.enquiries
                      ?.slice(0, 3)
                      ?.map((enquiry) => {
                        return (
                          <li>
                            {enquiry?.userId?.firstName.concat(
                              " ",
                              enquiry?.userId?.lastName
                            )}
                          </li>
                        );
                      }) :
                      <p className="no-enquiry-img">
                    No Enquiries yet
                  </p>
                      }
                  </ul>
                  {/* <p className="more_options">See all</p> */}
                </div>
                <div className="enquiry_features recent_enquiry-info">
                  <h3 className="border_grey">Recent Reviews</h3>
                  <div className="recent_review_scroll"> 

                  {
                  tradeProfileDetail?.recentReviews?.length>0 ? 
                  tradeProfileDetail?.recentReviews?.map((item, i) => {
                    return (
                      <div className="recent_reviews box_shadow_new">
                        <div className="review_detail">
                          <div className="review_detail_left">
                            <img
                              src={
                                item?.senderId?.profile_image && item?.senderId?.profile_image != "undefined"
                                  ? BACKEND_URL +
                                  "profile_image/" +
                                  item?.senderId?.profile_image
                                  : require("../../assets/images/tradesperson/review-profile.png")
                              }
                              alt=""
                            />
                          </div>
                          <div className="review_detail_right">
                            <h3 className="revewier_name">
                              {item?.senderId?.firstName +
                                " " +
                                item?.senderId?.lastName}
                            </h3>
                            <div className="review_sect mt-2 mb-2">
                              <div className="review_ratings_box review_stars lineHeight_stars">
                                {item?.overall_rating ? (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    isHalf={true}
                                    value={item?.overall_rating}
                                    size={30}
                                    activeColor="#ffd700"
                                  />
                                ) : (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    isHalf={true}
                                    size={30}
                                    activeColor="#ffd700"
                                  />
                                )}
                              </div>
                            </div>

                            <p className="comment_text">
                              {item?.comments
                                ? item?.comments
                                : "Not gives any types of comments"}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-4 justify-content-between">
                          <p>{moment(item?.createdAt).format("DD MMM YYYY")}</p>
                          <a className="" onClick={() => viewFullReviews(item)}>
                            More
                            <img
                              src={
                                require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                  .default
                              }
                              alt=""
                              className="ms-1"
                            />
                          </a>
                        </div>
                      </div>
                    );
                  }) :
                  <p className="no-enquiry-img">
                    No Reviews yet
                  </p>
                }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Recent Requests / End--> */}
    </TradeAccLayout>
  );
};

export default TradeDashboard;
