import React, { useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import lightLogo from "../../../assets/images/camera_placeholder.png";
import { getReviewTraderDetail } from "../../../redux/Action/tradeActions";
const SeeReviewTrader = ({ setSeeDetail, reviewId }) => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  console.log(reviewId, "reviewId");
  const dispatch = useDispatch();
  const reviewDetail = useSelector(
    (state) => state?.tradeAction?.review_detail_trader
  );
  useEffect(() => {
    dispatch(getReviewTraderDetail(reviewId));
  }, []);
  return (
    <div className="col-lg-8 ">
      <div className="review_expanded_left box_shadow_new ratings_main_page">
        <div className="back_btn">
          <Button onClick={() => setSeeDetail(false)}>
            <ChevronLeftIcon />
          </Button>
        </div>
        <div>
        <div className="d-flex justify-content-between align-items-center mt-4"> 
       <h2 className="">Overall rating</h2>
        <p className=" mb-0">Click to rate</p>
       </div>
          <div className="review_sect mt-3 mb-3 overall_ratings_score">
            <div className="review_ratings_box">
              {reviewDetail && (
                <ReactStars
                  count={5}
                  value={reviewDetail?.overall_rating}
                  edit={false}
                  isHalf={true}
                  size={50}
                  className={"starBig"}
                  activeColor="#ffd700"
                />
              )}
            </div>
          </div>
          
        </div>
        <div className="comment mb-4 mt-5">
          <form className="form-group ">
            <label className="">Comment</label>
            <textarea
              className="form-control box_shadow_new"
              value={reviewDetail?.comments}
              disabled
            ></textarea>
          </form>
        </div>

        <div className="ratings_edit">
          <ul className="ratings_view">
            <li>
              <h6>Quote Accuracy</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
              <div className="review_ratings_box ">
                {reviewDetail && (
                  <ReactStars
                    count={5}
                    value={reviewDetail?.quoteAccuracy}
                    isHalf={true}
                     size={40}
                  className={"starBig"}
                    activeColor="#ffd700"
                    edit={false}
                  />
                )}
              </div>
            </li>
            <li>
              <h6>Punctuality</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
              <div className="review_ratings_box">
                {reviewDetail && (
                  <ReactStars
                    count={5}
                    value={reviewDetail?.punctuality}
                     size={40}
                  className={"starBig"}
                    isHalf={true}
                    activeColor="#ffd700"
                    edit={false}
                  />
                )}
              </div>
            </li>
            <li>
              <h6>Quality of Work</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
              <div className="review_ratings_box">
                {reviewDetail && (
                  <ReactStars
                    count={5}
                    value={reviewDetail?.quality_of_Work}
                     size={40}
                  className={"starBig"}
                    isHalf={true}
                    activeColor="#ffd700"
                    edit={false}
                  />
                )}
              </div>
            </li>
            <li>
              <h6>Tidiness</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
              <div className="review_ratings_box">
                {reviewDetail && (
                  <ReactStars
                    count={5}
                    value={reviewDetail?.tidiness}
                     size={40}
                  className={"starBig"}
                    edit={false}
                    isHalf={true}
                    activeColor="#ffd700"
                  />
                )}
              </div>
            </li>
            <li>
              <h6>Friendliness</h6>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
              <div className="review_ratings_box">
                {reviewDetail && (
                  <ReactStars
                    count={5}
                    value={reviewDetail?.friendliness}
                     size={40}
                  className={"starBig"}
                    isHalf={true}
                    activeColor="#ffd700"
                    edit={false}
                  />
                )}
              </div>
            </li>
          </ul>
          <div className="work_evidence">
            <h6>Evidence of work</h6>
            <p>Add some before and after photographs.</p>
            <div className="d-flex">
              <div className="">
                <div className="upload_preview_image ">
                  <img
                    className="dashed_box"
                    src={
                      reviewDetail?.before_Img
                        ? BACKEND_URL +
                          reviewDetail?.before_Img
                        : lightLogo
                    }
                  />
                </div>
                <span className="text-center">Before</span>
              </div>
              <div className="ms-3">
                <div className="upload_preview_image ">
                  <img
                    className="dashed_box"
                    src={
                      reviewDetail?.after_Img
                        ? BACKEND_URL +
                          reviewDetail?.after_Img
                        : lightLogo
                    }
                  />
                </div>
                <span className="text-center">After</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeReviewTrader;
