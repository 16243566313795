import React, { useEffect } from "react";
import TradeAccLayout from "../../components/trade_acc_home/layout/TradeAccLayout";
import { getAllAppointments } from "../../redux/Action/tradeActions";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const TradeAppointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const traderAppointment = useSelector(
    (state) => state.tradeAction.trader_appointment
  );
  useEffect(() => {
    dispatch(getAllAppointments());
  }, []);

  const events = [];

  traderAppointment?.map((item, i) => {
    events?.push({
      title: `${item?.userId?.firstName} ${item?.userId?.lastName}`,
      date: `${item?.date_appointment}T${item?.time_appointment}`,
      extendedProps: { _id: item?.enquiryId?._id },
    });
  });

  const handleEventClick = (arg) => {
    const enquiry_id = arg.event._def.extendedProps._id;
    navigate(`/trade-enquiries#${enquiry_id}`);
  };

  return (
    <TradeAccLayout>
      {/* <section className="enquiry_sect_main">
          
            
          <p className='under_development_class'>
            Under Development
          </p>
    
</section> */}

      <section className="enquiry_sect">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 p-0 col-md-4 col-sm-12 col-12  ">
              <div class="sidebar-enquiries">
                <div class="enquiries-title d-flex justify-content-between">
                  <h4 class="blck_color">
                    Showing {traderAppointment?.length} Appointments
                  </h4>
                </div>
                <ul class="sidebar_lisiting">
                  {traderAppointment?.length > 0 ? (
                    traderAppointment?.map((enquiry) => {
                      var duration = moment.duration(
                        moment().diff(enquiry?.createdAt)
                      );
                      var days = duration.asDays();
                      var hours = duration.asHours();
                      var minutes = duration.asMinutes();
                      return (
                        <HashLink
                          to={`/trade-enquiries#${enquiry?.enquiryId?._id}`}
                        >
                          <li class="box_shadow_border mb-4">
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="dot"></span>
                              <span class="time">
                                {" "}
                                {hours > 24
                                  ? `${Math.floor(days)} days ago`
                                  : minutes > 60
                                  ? `${Math.floor(hours)} hours ago`
                                  : Math.floor(minutes) == 0
                                  ? "Just now"
                                  : `${Math.floor(minutes)} minutes ago`}
                              </span>
                            </div>
                            <div class="ps-4 enquiry_content">
                              <h3>
                                {enquiry?.userId?.firstName +
                                  " " +
                                  enquiry?.userId?.lastName}
                              </h3>
                              <p>{enquiry?.enquiryId?.service}</p>
                              <p class="mb-1">
                                {enquiry?.enquiryId?.enquiry_address?.address}
                              </p>
                              {/* <p>Hey I can sort this for you....</p> */}
                              <h4 class="text-end">
                                {moment(enquiry.date_appointment).format(
                                  "dddd Do MMMM"
                                )}
                              </h4>
                            </div>
                          </li>
                        </HashLink>
                      );
                    })
                  ) : (
                    <div className="no-enquiry-img ">
                      <img
                        src={require("../../assets/images/clock.svg").default}
                      />
                      <p>No Appointments yet</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div class="col-lg-9">
              <div class="calendar-container">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    // right: "dayGridMonth,timeGridWeek,timeGridDay",
                    right: "",
                  }}
                  initialView="dayGridMonth"
                  events={events}
                  eventClick={handleEventClick}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </TradeAccLayout>
  );
};

export default TradeAppointments;
