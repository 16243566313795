import React, { useEffect } from "react";
import UserAccLayout from "../../components/user_acc_home/layout/UserAccLayout";
import { Link, useNavigate } from "react-router-dom";
import { getAllEnquiry } from "../../redux/Action/UserActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const enquiry = useSelector((state) => state?.useAction?.enquiry);

  useEffect(() => {
    dispatch(getAllEnquiry());
  }, []);
  return (
    <UserAccLayout>
      <section className="recent_requests common_padding ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub_head">
                <h3>Recent Requests</h3>
              </div>
              {enquiry?.length > 0 ? (
                <div className="request_visible mt-4">
                  <div className="row">
                    {enquiry?.map((item, i) => {
                      return (
                        <>
                        <div className="col-md-6">
                          <HashLink to={`/my-enquiries#${item?._id}`}>
                              <div className="card card-new mb-4">
                                <div className="card-body card-body-new">
                                  <div>
                                    <h3 className="mb-3">{item?.company_name}</h3>
                                    <p>{item?.service}</p>
                                    <p className="recent_request_address">
                                      {item?.enquiry_address?.address}
                                    </p>
                                    <p>Verified, Upcoming Talent</p>
                                    <p className="line"></p>
                                    <div className="contact_info">
                                      <p>
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/phone.svg")
                                              .default
                                          }
                                          alt="phone"
                                        />
                                        +44 {item?.tradeId?.telephone}
                                      </p>
                                      <p>
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/message.svg")
                                              .default
                                          }
                                          alt="message"
                                        />
                                        {item?.tradeId?.email}
                                      </p>
                                    </div>
                                    <h4 className="text-end mt-2">Visit Enquiry</h4>
                                  </div>
                                </div>
                              </div>
                          </HashLink>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="inner_content">
                  <h4>Your requests and responses will show here</h4>
                  <Link to="/find-a-tradeperson" className="white_btn">
                    Find a Tradesperson
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Recent Requests / End--> */}

      {/* <!-- Popular Categories --> */}
      <section className="recent_requests common_padding pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub_head">
                <h4>Popular Categories</h4>
              </div>
              <div className="d-flex justify-content-between service_boxes">
                <div className="service" onClick={()=>navigate("/tradespersonList/Boiler%20Services/Boiler%20Services%20-%20Gas%20boiler%20breakdown/London,%20UK/51.5072178/-0.1275862/lat/long")}>
                <img src={ require("../../assets/images/dashboard/boiler.png")} alt="boiler" />
                  <h4>Gas Boiler Breakdown</h4>
                </div>
                <div className="service"  onClick={()=>navigate("/tradespersonList/Bathrooms/Bathrooms%20-%20Supply%20and%20fit/London,%20UK/51.5072178/-0.1275862/lat/long")}>
                <img src={ require("../../assets/images/dashboard/bathroom.png")} alt="bathroom" />
                  <h4>Supply & Fit Bathrooms</h4>
                </div>
                <div className="service" onClick={()=>navigate("/tradespersonList/Gardener/Gardener%20-%20One%20off%20Gardening%20Job%20-%20Garden%20clearance/London,%20UK/51.5072178/-0.1275862/lat/long")}>
                  <img src={ require("../../assets/images/dashboard/image-five.png")} alt="Garden Clearance" />
                  <h4>Garden clearance</h4>
                </div>
                <div className="service" onClick={()=>navigate("/tradespersonList/Driveways/Driveways%20-%20Patios%20or%20paving/London,%20UK/51.5072178/-0.1275862/lat/long")}>
                  <img src={ require("../../assets/images/dashboard/patio.png")} alt="patio" />
                  <h4>Patios & paving</h4>
                </div>
                <div className="service" onClick={()=>navigate("/tradespersonList/Handyman%20Services/Handyman%20Services%20-%20Furniture%20assembly/London,%20UK/51.5072178/-0.1275862/lat/long")}>
                  <img src={ require("../../assets/images/dashboard/furniture.png")} alt="furniture" />
                  <h4>Furniture Assembly</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Popular Categories / End--> */}
    </UserAccLayout>
  );
};

export default Dashboard;
