import React, { useEffect, useRef, useState } from "react";
import EdditNamePicture from "../../components/my_account/EdditNamePicture";
import Address from "../../components/my_account/Address";
import Reviews from "../../components/my_account/Reviews";
import Contact from "../../components/my_account/Contact";
import UpdatePassword from "../../components/my_account/UpdatePassword";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../redux/Action/UserActions";
import UserAccLayout from "../../components/user_acc_home/layout/UserAccLayout";
 
var data = [
  "Edit Name & Profile Picture",
  " My Address & Contact Details",
  "My Reviews",
  "Contact Preferences",
  "Update Password",
];
const MyAccount = () => {
  let prevRef = useRef();
  const [tabClick, setTab] = useState("Edit Name & Profile Picture");
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.useAction?.profile_detail);
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);
 
  return (
    <UserAccLayout>
      {/* <!-- header start --> */}
      <section className="account_settings common_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main_head">
                <h3 className="mb-4">My Account & Settings</h3>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="setting_tabs">
                    <ul>
                      {data.map((item) => {
                        return (
                          <li
                            className={
                              tabClick == item
                                ? "blue_active_tab mb-2"
                                : "white_tab mb-2"
                            }
                          >
                            <a onClick={() => {setTab(item)}}>{item}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {tabClick == "Edit Name & Profile Picture" && (
                  <EdditNamePicture userDetail={userDetail} ref={prevRef} />
                )}
                {tabClick == " My Address & Contact Details" && (
                  <Address userDetail={userDetail} ref={prevRef} />
                )}
                {tabClick == "My Reviews" && <Reviews  ref={prevRef} />}
                {tabClick == "Contact Preferences" && <Contact ref={prevRef} />}
                {tabClick == "Update Password" && <UpdatePassword ref={prevRef} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserAccLayout>
  );
};

export default MyAccount;
