import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function SlaveryPolicy() {
  return (
    <UserLayout>
      <div>
        <div className="container">
          <div className="terms_conditions">
            <h2 className="text-center pb-4 ">MODERN-DAY SLAVERY POLICY</h2>

            <div className="t_content">
              <h3>1. INTRODUCTION </h3>
              <p>
                At Smart Choice Traders, we are committed to promoting ethical
                standards in our business and supply chain. We recognise that
                modern slavery, which includes forced labour, human trafficking,
                debt bondage, child labour, and exploitative practices, is a
                significant violation of human rights. This policy outlines our
                commitment to combatting modern slavery and our expectations for
                our tradespeople, suppliers and staff.
              </p>
            </div>
            <div className="t_content">
              <h3>2. Purpose</h3>
              <p>
                This policy aims to prevent modern slavery within our
                organisation and throughout our supply chain. We strive to
                ensure that all individuals engaged in our services are treated
                with dignity and respect.
              </p>
            </div>
            <div className="t_content">
              <h3>3. Scope</h3>
              <p>
                This policy applies to:
                <ul>
                  <li>All employees of Smart Choice Traders</li>
                  <li>All tradespeople listed on our platform </li>
                  <li>
                    All suppliers and third-party service providers associated
                    with our business
                  </li>
                </ul>
              </p>
            </div>
            <div className="t_content">
              <h3>4. Policy Statement</h3>
              <p>
                Smart Choice Traders is dedicated to:
                <ul>
                  <li>
                    Preventing all forms of modern slavery in our business
                    operations and supply chains.
                  </li>
                  <li>
                    Ensuring ethical labor practices across all partners and
                    suppliers{" "}
                  </li>
                  <li>
                    Encouraging collaboration and accountability in combating
                    modern slavery
                  </li>
                </ul>
              </p>
            </div>
            <div className="t_content">
              <h3>5. Expectations for Tradespeople</h3>
              <p>
                Smart Choice Traders expects all tradespeople listed on our
                platform to:
                <ul>
                  <li>
                    Adhere to minimum legal standards concerning labour
                    practices and employee rights.
                  </li>
                  <li>
                    Ensure their workforce is employed voluntarily and free from
                    coercion or exploitation.
                  </li>
                  <li>
                    Conduct regular training for their employees about their
                    rights and identify potential risks of modern slavery.
                  </li>
                  <li>
                    Maintain clear and transparent hiring processes, with proper
                    documentation and records for all workers.
                  </li>
                  <li>
                    Report any known incidents or suspicions of modern slavery
                    within their operations or supply chains to Smart Choice
                    Traders.
                  </li>
                </ul>
              </p>
            </div>
            <div className="t_content">
              <h3>6. Expectations for Suppliers</h3>
              <p>
                Suppliers must commit to:
                <ul>
                  <li>
                    Comply with all relevant local and international labor laws
                    pertaining to human rights and labor practices
                  </li>
                  <li>
                    Provide written assurances confirming that they do not
                    engage in or support any form of modern slavery
                  </li>
                  <li>
                    Implement fair hiring practices and ensure that their
                    suppliers and subcontractors adhere to the same standards
                  </li>
                  <li>
                    Facilitate audits and inspections by Smart Choice Traders to
                    ensure compliance with this policy and immediately address
                    any identified issues.
                  </li>
                  <li>
                    Report any known incidents or suspicions of modern slavery
                    in their operations or those of their suppliers.
                  </li>
                </ul>
              </p>
            </div>
            <div className="t_content">
              <h3>a. Responsibilities of Staff</h3>
              <p>
                All employees of Smart Choice Traders are required to:
                <ul>
                  <li>
                    Understand and comply with this Modern Day Slavery Policy
                    and participate in relevant training.
                  </li>
                  <li>
                    Be aware of the signs of modern slavery and report any
                    suspicions to management or through our dedicated reporting
                    line.
                  </li>
                  <li>
                    Promote a culture of integrity, respect, and accountability
                    within their teams and throughout the organisation.
                  </li>
                  <li>
                    Engage in ongoing discussions about ethical practices with
                    tradespeople and suppliers to promote awareness and prevent
                    exploitation.
                  </li>
                </ul>
              </p>
            </div>
            <div className="t_content">
              <h3>7. Due Diligence Processes</h3>
              <p>
                To identify and mitigate the risk of modern slavery, Smart
                Choice Traders will: <br />
                <br />
                Monitor compliance with this policy and address any instances of
                noncompliance immediately, which may result in tradespeople
                having their accounts with Smart Choice Traders suspended or
                permanently closed.
                <br />
                <br />
                Review supply chain practices regularly and adapt policies as
                necessary based on findings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
