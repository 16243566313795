import { toast } from "react-toastify";
import API from "../../service/Api";
import {
  ALL_SERVICES,
  ALL_TRADES,
  ENVOICE_DETAIL,
  ENVOICE_DETAIL1,
  ESTIMATE_DETAIL,
  GET_ALL_ENQUIRY,
  GET_USER_CHAT,
  QUOTE_DETAIL,
  REVIEW_DETAIL,
  REVIEW_DETAIL_NEW,
  SERVICE_FETCH_SUCCESSFULLY,
  START_LOADING,
  STOP_LOADING,
  TRADE_DETAIL,
  TRADE_SERVICE_FETCH_SUCCESSFULLY,
  UPDATE_CHAT,
  UPDATE_PROFILE_DETAIL,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_PASSWORD,
  USER_APPOINTMENT,
  USER_CONTACT_PREFERENCES,
  USER_PROFILE_DETAIL,
  USER_REVIEWS,
  VALUE_SELECTED,
} from "../Constant/constant";

export const getUserProfile = (data) => async (dispatch) => {
  try {
    const res = await API.get("/getUserDetails", data);
    if (res?.data.success) {
      dispatch({
        type: USER_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateUserProfile = (data, setShowModal) => async (dispatch) => {
  try {
    const res = await API.put("/updateNameAndProfilePic", data);
    if (res?.data.success) {
      dispatch(getUserProfile());
      dispatch({
        type: UPDATE_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateUserAddress = (data, setShowModal) => async (dispatch) => {
  try {
    const res = await API.put("/updateAddress", data);
    if (res?.data.success) {
      dispatch({
        type: UPDATE_USER_ADDRESS,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateUserPassword = (data, setShowModal) => async (dispatch) => {
  try {
    const res = await API.put("/updatePassword", data);
    if (res?.data.success) {
      dispatch({
        type: UPDATE_USER_PASSWORD,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const fetchServiceCategory1 = (data) => async (dispatch) => {
  try {
    const res = await API.get("/fetchServiceCategory", data);
    if (res?.data.success) {
      dispatch({
        type: SERVICE_FETCH_SUCCESSFULLY,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const getDataVal = (data) => async (dispatch) => {
  dispatch({
    type: VALUE_SELECTED,
    payload: data,
  });
};
export const searchTrade = (val, location, lat, long, search_lat, search_long, emptyLocation) => async (dispatch) => {
  try {
    const res = await API.post(`/searchTrade`, {
      search: val,
      location: location ? location : emptyLocation,
      lat: lat, long: long, search_lat: search_lat, search_long: search_long
    });
    if (res?.data?.success) {
      dispatch({
        type: ALL_TRADES,
        payload: res.data.data,
      });
    }
    return res?.data
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getprofileDetail = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getTradeDetailsById/${id}`);
    if (res?.data?.success) {
      dispatch({
        type: TRADE_DETAIL,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const enquirySubmit = (data, setShow) => async (dispatch) => {
  try {
    const res = await API.post("/registerEnquiry", data);
    if (res.status == 200) {
      setShow(true);
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getAllEnquiry = () => async (dispatch) => {
  try {

    const res = await API.get(`/getEnquiryByUserId`);
    if (res?.data?.success) {
      console.log(res.data.data, "res.data.data")
      dispatch({
        type: GET_ALL_ENQUIRY,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const updateContactPrefsUser = (data, setShowModal) => async (dispatch) => {
  try {
    const res = await API.put("/updateContactPrefs", data);
    if (res.status == 200) {
      dispatch({
        type: USER_CONTACT_PREFERENCES,
        payload: res?.data,
      });
      dispatch(getUserProfile());
      setShowModal(true)
      // toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const userGetChat = (enquiry_id) => async (dispatch) => {
  try {
    const response = await API.get(`/userGetChat?enquiry_id=${enquiry_id}`);
    if (response?.data?.success) {
      dispatch({
        type: GET_USER_CHAT,
        payload: response?.data?.data,
      });
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getAllUserAppointments = () => async (dispatch) => {
  try {
    const { data } = await API.get("/getAllAppointments");
    if (data?.success) {
      dispatch({
        type: USER_APPOINTMENT,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const updateChat = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_CHAT,
    payload: payload,
  });
};
// reviews
export const getAllReviews = () => async (dispatch) => {
  try {
    const { data } = await API.get("/traderReviews");
    if (data?.success) {
      dispatch({
        type: USER_REVIEWS,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const reviewSubmit = (data, setShowReview, setShowModal) => async (dispatch) => {
  try {
    const res = await API.post("/addReviews", data);
    if (res.status == 200) {
      dispatch({
        type: USER_APPOINTMENT,
        payload: data?.data,
      });
      // setShowReview(false);
      setShowModal(true)
      // toast.success(res.data.message)
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getReviewDetail = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/reviewsById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: REVIEW_DETAIL,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getReview_Detail = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/tradeReviewsById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: REVIEW_DETAIL_NEW,
        payload: res.data.data,
      });
      return res.data.data;
    }
    return res?.data
  } catch (error) {
    toast.warning(error.message);
  }

};
export const updateMsgCount = (data) => async (dispatch) => {
  try {
    const res = await API.put("/updateEnquiryUnreadMsg", data);
    if (res.status == 200) {
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getEstimateDetailUser = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getEnquiryDetailById/?id=${id}`);
    console.log(res, "res");
    if (res?.data?.success) {
      dispatch({
        type: ESTIMATE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getInvoiceDetailUser = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getInvoicesById/?id=${id}`);
    console.log(res, "res9999");
    if (res?.data?.success) {
      dispatch({
        type: ENVOICE_DETAIL1,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getInvoiceDetailUser1 = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getEstimateById/?id=${id}`);
    console.log(res, "res000000");
    if (res?.data?.success) {
      dispatch({
        type: ENVOICE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getInvoiceDetailUserPay = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getEstimateById/?id=${id}`);
    console.log(res, "res");
    if (res?.data?.success) {
      dispatch({
        type: ENVOICE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const createStripePayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await API.post("/createPayment", data);
    if (res.status == 200) {

      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const paymentSuccess = (session_id, invoiceId) => async (dispatch) => {
  try {
    const response = await API.get(`/paymentSuccess?id=${session_id}&invoiceId=${invoiceId}`);
    if (response?.data?.success) {

      return response?.data?.data
      // dispatch({
      //   type: GET_USER_CHAT,
      //   payload: response?.data?.data,
      // });
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const createSearches = (userId, serviceId, location, trades) => async (dispatch) => {
  console.log(trades, 'trades');
  try {
    const res = await API.post(`/createAllSearches`, {
      userId: userId,
      serviceName: serviceId,
      location: location,
      no_of_tradeperson: trades ? trades : 0
    });
    console.log(res, 'res');
    // if (res?.data?.success) {
    //   dispatch({
    //     type: ALL_TRADES,
    //     payload: res.data.data,
    //   });
    // }
    return res?.data
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getAllServices = () => async (dispatch) => {
  try {
    const response = await API.get(`/getAllAlphabetServices`);
    console.log(response, "response");
    if (response?.data?.success) {
      dispatch({
        type: ALL_SERVICES,
        payload: response.data.data,
      });
      return response.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getQuoteDetailUser = (id) => async (dispatch) => {
  try {
    const res = await API.get(`/getQuoteById/?id=${id}`);
    console.log(res, "res111");
    if (res?.data?.success) {
      dispatch({
        type: QUOTE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const saveImages = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await API.post("/saveImages", data);
    if (res.status == 200) {
      dispatch({ type: STOP_LOADING, payload: false })
      return res?.data
    } else {
      toast.error(res.data.message);
      dispatch({ type: STOP_LOADING, payload: false })
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const contactUs = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await API.post("/add_contactus", data);
    if (res.status == 200) {
      toast.success(res.data.message)
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
  finally {
    dispatch({ type: STOP_LOADING, payload: false })
  }
};
export const add_complaint = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await API.post("/addcustomer_service", data);
    if (res.status == 200) {
      toast.success(res.data.message)
      navigate('/')
      // return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
  finally {
    dispatch({ type: STOP_LOADING, payload: false })
  }
};
export const reportIssue = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await API.post("/addReportissue", data);
    if (res.status == 200) {
      // dispatch({ type: STOP_LOADING, payload: false })
      toast.success(res.data.message)
      return res?.data
    } else {
      toast.error(res.data.message);
      // dispatch({ type: STOP_LOADING, payload: false })
    }
  } catch (error) {
    toast.warning(error.message);
  }
  finally {
    dispatch({ type: STOP_LOADING, payload: false })
  }
};