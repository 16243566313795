import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const UserFooter = () => {
  return (
    <>
      {/* <!-- Call-to-action start --> */}
      <section className="cta-section common-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <h2>BECOME A MEMBER, It’s free.</h2>
                <p>
                  Sign up today for access to online consultations, treatments
                  and more.
                </p>
                <div className="row">
                  <div className="col-lg-6 d-flex justify-content-end">
                    {/* <input
                    type="email"
                    name="email"
                    placeholder="Type your email here"
                  />
                  <input
                    type="submit"
                    name="submit"
                    value="Submit"
                    className="email-submit-btn"
                  /> */}
                    <Link
                      to="/tradepeople-register"
                      className="btn btn-primary white-btn w-70 mt-3"
                    >
                      Register as a Tradesperson
                    </Link>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-start">
                    <Link
                      to="/sign-up"
                      className="btn btn-primary white-btn w-70 mt-3"
                    >
                      Register as a Customer
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Call-to-action end --> */}

      {/* <!-- Footer Start --> */}
      <footer className="white_footer">
        <div className="footer_upper common-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="footer_innner-content">
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <div className="footer-content">
                        <h3>Help</h3>
                        <Link to="/ContactUs">
                          <p>Contact Us</p>
                        </Link>
                        <a href="/Faqs">
                          <p>FAQs</p>
                        </a>
                        <Link to="/CustomerServices">
                          <p>Complaints</p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="footer-content">
                        <h3>Responsibilty</h3>
                        <a href="/TermsConditions">
                          <p>Terms of Use</p>
                        </a>
                        <a href="/PrivacyPolicy">
                          <p>Privacy Notice</p>
                        </a>
                        <a href="/mission-statement">
                          <p>Mission statement </p>
                        </a>
                        <a href="/slavery-policy">
                          <p>Modern Day Slavery Policy</p>
                        </a>
                        {/* <a href="/cookie-policy">
                          <p>Cookie Policy</p>
                        </a> */}
                        <a href="/blogs">
                          <p>Blogs</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="footer_iner_content_right">
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <div className="footer-content">
                        <h3>Careers</h3>
                        <a href="/job-vacancies">
                          <p>Job Vacancies</p>
                        </a>
                        {/* <p> Terms &amp; Conditions</p> */}
                      </div>
                    </div>
                    <div className="col-md-6 col-6 ">
                      <div className="footer-content">
                        <h3>Account</h3>
                        <a href="/signin">
                          <p>Sign In</p>
                        </a>

                        <p>My Account</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="footer-content">
                  <h3>Newsletter</h3>
                  <div className="d-flex justify-content-center email-insert">
                    <input type="mail" name="mail" placeholder="Your email" />
                    <input type="submit" name="submit" value="Submit" />
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-f">
              Copyright © 2024. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- Footer End --> */}
    </>
  );
};

export default UserFooter;
