import { ToastContainer } from "react-toastify";
import RoutesPage from "./routes/Routes";
import Loader from "./components/loader/Loader";
import { onMessageListener, requestForToken } from "./notification/firebaseInit";

function App() {

  requestForToken();

  onMessageListener()
    .then((payload) => {
      console.log(payload,"ajajyaa")
      // setNotification({
      //   title: payload?.notification?.title,
      //   body: payload?.notification?.body,
      // });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <div className="App">
    <ToastContainer />
    <Loader />
      <RoutesPage />
    </div>
  );
}

export default App;
