import { useFormik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTradePersonProfile,
  updateTradeProfile,
} from "../../../redux/Action/tradeActions";
import MultiSelect from "../../MultiSelect/MultiSelect";
import { toast } from "react-toastify";
import RegularPopup from "../../regular_popup/RegularPopup";
import ImageUploading from "react-images-uploading";
const BusinessProfile = (props, ref) => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState();
  const [preview, setPreview] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [bio, setBio] = useState("");
  const [multiServices, setMultiServices] = useState([]);
  const [video, setVideo] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState();
  const [addService, setAddService] = useState(false);
  const [galleryImage, setGalleryImage] = useState([]);
  const [galleryPreview, setGalleryPreview] = useState([]);
  const [images, setImages] = useState([]);
  const [hover, setHover] = useState(false);
  const [badge, setBadge] = useState();
  const [insuranceNo, setInsuranceNo] = useState();
  const [insuranceExpire, setInsuranceExpire] = useState();
  const maxNumber = 6;
  const [showModal, setShowModal] = useState(false);
  const tradeProfileDetail = useSelector(
    (state) => state?.tradeAction?.tradePerson_detail
  );
  useEffect(() => {
    dispatch(getTradePersonProfile());
  }, []);
  useEffect(() => {
    if (tradeProfileDetail) {
      setProfileImage(tradeProfileDetail?.business_image);
      setCompanyName(tradeProfileDetail?.company_name);
      setBio(tradeProfileDetail?.bio);
      setVideo(tradeProfileDetail?.video);
      setBusinessEmail(tradeProfileDetail?.business_email);
      setPhoneNo(tradeProfileDetail?.business_phnNumber);
      setMultiServices(tradeProfileDetail?.multi_services);
      setGalleryImage(tradeProfileDetail?.gallery);
      setImages(tradeProfileDetail?.gallery);
      setBadge(tradeProfileDetail?.badge)
      setInsuranceNo(tradeProfileDetail?.insurance_no)
      setInsuranceExpire(tradeProfileDetail?.insurance_expire)
    }
  }, [tradeProfileDetail]);

  console.log(tradeProfileDetail, "insuranceExpire");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("insurance_no", insuranceNo);
    formData.append("insurance_expire", insuranceExpire);
    formData.append("business_image", profileImage);
    formData.append("company_name", companyName);
    formData.append("bio", bio);
    formData.append("video", video);
    formData.append("business_email", businessEmail);
    formData.append("business_phnNumber", phoneNo);
    multiServices &&
      formData.append("multi_services", JSON.stringify(multiServices));
    images?.length > 0 &&
      images.forEach((image) => {
        console.log(image, "image44");
        if (image?.file) {
          formData.append("gallery", image?.file);
        } else {
          formData.append("gallery", image);
        }
      });
    dispatch(updateTradeProfile(formData, setShowModal));
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleGalleryImageUpload = (e) => {
    if (e.target.files?.length <= 4) {
      const selectedFIles = [],
        gallery = [];
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
        selectedFIles.push(URL.createObjectURL(file));
        gallery.push(file);
      });
      setGalleryImage(gallery);
      setGalleryPreview(selectedFIles);
    } else {
      toast.error("Please upload only 4 images.");
    }
  };

  const handleRemoveList = (list) => {
    const arr = multiServices?.filter((item, i) => {
      if (item?.label != list) return item;
    });
    setMultiServices(arr);
  };
  // useImperativeHandle(ref, () => ({
  //   handleSubmit
  // }));
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  //tradeProfileDetail?.recentReviews?.filter((item)=> item.status == 1)?.length
  let badgeReview = (tradeProfileDetail?.overall_rating * 100) / 5;

  console.log(badgeReview, "badgeReview");
  return (
    <>
      {showModal == true && (
        <RegularPopup
          showModal={showModal}
          setShowModal={setShowModal}
          modalName={"tradeMyAccount"}
        />
      )}
      <div className="col-lg-8">
        <form onSubmit={handleSubmit} className="form_new">
          <div className="text-end d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
          <div className="edit_profile">
            <div className="d-flex mb-5 update_profile">
              {preview ? (
                <img src={preview} alt="" />
              ) : profileImage ? (
                <img
                  src={BACKEND_URL + "business_image/" + profileImage}
                  alt=""
                />
              ) : (
                <img
                  src={require("../../../assets/images/placeholder.png")}
                  alt=""
                />
              )}

              <div className="file form-group ms-5">
                <label for="input-file" className="box_shadow_new">
                  Update Profile Picture
                </label>
                <input
                  id="input-file"
                  type="file"
                  className="form-control box_shadow_new"
                  onChange={(e) => handleImageUpload(e)}
                  accept="image/*"
                />
              </div>

              <div className="badge-user">
                {
                  (badgeReview >= 87 && badgeReview <= 94) ? <img src={require("../../../assets/images/platinum_rated.png")} /> :
                    (badgeReview >= 95) ? <img src={require("../../../assets/images/diamond_rated.png")} /> :
                      (badgeReview >= 75 && badgeReview <= 86) && <img src={require("../../../assets/images/gold_rated.png")} />

                }

              </div>
            </div>
            <div className="form-group mb-3">
              <label for="">Business Display Name</label>
              <input
                type="text"
                className="form-control box_shadow_new"
                placeholder="Type Here"
                name="company_name"
                // value={formik.values.company_name}
                // onChange={formik.handleChange}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </div>
            <div className="form-group mb-3">
              <label for="">Services</label>
              <div className="box_shadow_new add_services_main services_main_input">
                <ul
                  className={
                    multiServices?.length > 0
                      ? "add_services add_services_select"
                      : "add_services"
                  }
                >
                  {multiServices?.map((item, i) => {
                    return (
                      <>
                        <li>
                          <h4>{item?.label}</h4>
                          <img
                            onClick={() => handleRemoveList(item?.label)}
                            src={
                              require("../../../assets/images/my-account/cancel.svg")
                                .default
                            }
                            alt=""
                          />
                        </li>
                      </>
                    );
                  })}
                </ul>
                {addService == false ? (
                  <div className="text-end add_service_btn">
                    <button
                      onClick={() => setAddService(true)}
                      className="btn white_btn"
                    >
                      Add a service
                    </button>
                  </div>
                ) : (
                  <MultiSelect
                    setMultiServices={setMultiServices}
                    multiServices={multiServices}
                    type="profile"
                  />
                )}
              </div>
            </div>
            <div className="form-group mb-3">
              <label for="">Bio</label>
              <textarea
                id=""
                cols="30"
                rows="10"
                className="form-control box_shadow_new bio_textarea"
                placeholder="Type Here"
                name="bio"
                onChange={(e) => setBio(e.target.value)}
                value={bio}
              ></textarea>
            </div>
            <div className="mb-3 position-relative">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg", "JPEG", "GIF", "PNG", "EPS", "AI"]}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    <label for="">Gallery</label>

                    {/* <input
      id="input-file"
      type="file"
      multiple
      className="form-control box_shadow_new add_gallery_btn"
      onChange={(e) => handleGalleryImageUpload(e)}
      accept="image/*"
    /> */}
                    <div
                      onClick={onImageUpload}
                      {...dragProps}
                      className="btn-custom-gallery"
                    >
                      {imageList?.length > 0 ||
                        tradeProfileDetail?.gallery?.length > 0
                        ? "Update Gallery"
                        : "Add Gallery"}
                    </div>

                    <div
                      className={
                        imageList.length > 6
                          ? "row gallery_row gallery_row_new"
                          : "row gallery_row"
                      }
                    >
                      {imageList?.map((image, i) => {
                        return (
                          <div className="col-md-4">
                            <div
                              className="gallery_images-grid"
                            // onMouseEnter={()=>setHover(true)}
                            // onMouseLeave={()=>setHover(false)}
                            >
                              <img
                                src={
                                  image.data_url
                                    ? image.data_url
                                    : BACKEND_URL + "business_image/" + image
                                }
                                alt=""
                              />
                            </div>
                            {
                              hover == false && <div
                                onClick={() => onImageRemove(i)}
                                className="close-icon-g"
                              // onMouseEnter={()=>setHover(true)}
                              // onMouseLeave={()=>setHover(false)}
                              >
                                <img
                                  src={
                                    require("../../../assets/images/gallery-close.svg")
                                      .default
                                  }
                                />
                              </div>
                            }

                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </ImageUploading>

              {/* <label for="">Gallery</label>

            <input
              id="input-file"
              type="file"
              multiple
              className="form-control box_shadow_new add_gallery_btn"
              onChange={(e) => handleGalleryImageUpload(e)}
              accept="image/*"
            />
            <button className="gallery_btn btn btn-custom">
              {galleryPreview?.length > 0 ||
                tradeProfileDetail?.gallery?.length > 0
                ? "Update Gallery"
                : "Add Gallery"}
            </button>


            <div className={galleryPreview.length > 6 ? "row gallery_row gallery_row_new" : "row gallery_row"}>
              {galleryPreview?.length > 0
                ? galleryPreview?.map((item, i) => {
                  return (
                    <div className="col-md-4">
                      <div className="gallery_images">
                        <img src={item} alt="" />
                      </div>
                    </div>
                  );
                })
                : galleryImage?.map((item, i) => {
                  return (
                    <div className="col-md-4">
                      <div className="gallery_images">
                        <img
                          src={BACKEND_URL + "business_image/" + item}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
            </div> */}
            </div>
            <div className="form-group mb-3">
              <label for="">Video</label>
              <input
                type="text"
                className="form-control box_shadow_new"
                placeholder="https://"
                name="video"
                onChange={(e) => setVideo(e.target.value)}
                value={video}
              />
            </div>
            <div className="form-group mb-3">
              <label for="">Contact email</label>
              <input
                type="email"
                className="form-control box_shadow_new"
                placeholder="Type Here"
                name="business_email"
                onChange={(e) => setBusinessEmail(e.target.value)}
                value={businessEmail}
              />
            </div>
            <div className="form-group mb-3">
              <label for="">Contact phone</label>
              <input
                type="text"
                className="form-control box_shadow_new"
                placeholder="Type Here"
                name="business_phnNumber"
                onChange={(e) => setPhoneNo(e.target.value)}
                value={phoneNo}
              />
            </div>
            <div className="form-group mb-3">
              <label for="">Insurance Number</label>
              <input
                type="text"
                className="form-control box_shadow_new"
                placeholder="Type Here"
                name="insurance_no"
                onChange={(e) => setInsuranceNo(e.target.value)}
                value={insuranceNo}
              />
            </div>
            <div className="form-group mb-3">
              <label for="">Insurance Expiry</label>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control box_shadow_new"
                name="insurance_expire"
                onChange={(e) => setInsuranceExpire(e.target.value)}
                value={insuranceExpire}
              />
            </div>
            <div className="text-end pt-35 pb-5 d-flex justify-content-end">
              {/* <button className="btn white_btn preview_btn">Preview</button> */}
              {/* <button className="btn btn-primary">Update</button> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default forwardRef(BusinessProfile);
