import React, { useState } from "react";
import lightLogo from "../../../assets/images/camera_placeholder.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { tradeReviewSubmit } from "../../../redux/Action/tradeActions";
import RegularPopup from "../../regular_popup/RegularPopup";

const TradeReviewDetail = ({setShowReview,aptId}) => {
    const dispatch=useDispatch()
  const [img, setImg] = useState({
    before_Img: "",
    after_Img: "",
  });
  const [preView, setPreview] = useState();
  const [starRate, setStarRate] = useState({
    quoteAccuracy: "",
    punctuality: "",
    quality_of_Work: "",
    friendliness: "",
    tidiness: "",
    over_all: "",
    comments:""
  });
  const [showModal, setShowModal] = useState(false);
  // const overValue = Object.keys(starRate).map((key) => starRate[key]);
  // const overAllStar = overValue.reduce((a, b) => a + b / 5, 0);
  // console.log(overValue, overAllStar);
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("appointmentId", aptId?.appointmentId);
    formData.append("senderId", sessionStorage.getItem("trade_id"));
    formData.append("receiverId", aptId?.userId);
    formData.append("quoteAccuracy", starRate?.quoteAccuracy);
    formData.append("punctuality", starRate?.punctuality);
    formData.append("quality_of_Work", starRate?.quality_of_Work);
    formData.append("friendliness", starRate?.friendliness);
    formData.append("tidiness", starRate?.tidiness);
    formData.append("overall_rating", starRate?.over_all);
    formData.append("before_Img",img?.before_Img);
    formData.append("after_Img", img?.after_Img);
    formData.append("trade_review_status",1);
    formData.append("comments",starRate?.comments);
    dispatch(tradeReviewSubmit(formData,setShowReview,setShowModal))
  };
  return (
    <>
       {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"traderReviewSubmit"} setShowReview={setShowReview}/>}
       <div className="col-lg-8 ">
    <div className="review_expanded_left box_shadow_new ratings_main_page">
      <div className="back_btn">
        <Button onClick={() => setShowReview(false)}>
          <ChevronLeftIcon />
        </Button>
      </div>
      <div>
      <div className="d-flex justify-content-between align-items-center mt-4"> 
       <h2 className="">Overall rating</h2>
        <p className=" mb-0">Click to rate</p>
       </div>
      <div className="review_sect mt-3 mb-3 overall_ratings_score">
        <div className="review_ratings_box">
          <ReactStars
            count={5}
            onChange={(e) =>
              setStarRate({
                ...starRate,
                over_all: e,
              })
            }
            isHalf={true}
            size={50}
            className={"starBig"}
            activeColor="#ffd700"
          />
        </div>
      </div>
      
      </div>
      <div className="comment mb-4 mt-5">
              <form className="form-group ">
                 <label className="">Add a Comment</label>
                 <textarea className="form-control box_shadow_new"
                    onChange={(e) =>
                    setStarRate({
                      ...starRate,
                      comments: e.target.value,
                    })
                  }
                 ></textarea>
              </form>
        </div>

      <div className="ratings_edit">
        <ul className="ratings_view">
          <li>
            <h6>Quote Accuracy</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
            <div className="review_ratings_box">
              <ReactStars
                count={5}
                onChange={(e) =>
                  setStarRate({
                    ...starRate,
                    quoteAccuracy: e,
                  })
                }
                isHalf={true}
                size={40}
                className={"starBig"} 
                activeColor="#ffd700"
              />
            </div>
          </li>
          <li>
            <h6>Punctuality</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
            <div className="review_ratings_box">
              <ReactStars
                count={5}
                onChange={(e) =>
                  setStarRate({
                    ...starRate,
                    punctuality: e,
                  })
                }
                size={40}
                  className={"starBig"}
                isHalf={true}
                activeColor="#ffd700"
              />
            </div>
          </li>
          <li>
            <h6>Quality of Work</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
            <div className="review_ratings_box">
              <ReactStars
                count={5}
                onChange={(e) =>
                  setStarRate({
                    ...starRate,
                    quality_of_Work: e,
                  })
                }
                size={40}
                  className={"starBig"}
                isHalf={true}
                activeColor="#ffd700"
              />
            </div>
          </li>
          <li>
            <h6>Tidiness</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
            <div className="review_ratings_box">
              <ReactStars
                count={5}
                onChange={(e) =>
                  setStarRate({
                    ...starRate,
                    tidiness: e,
                  })
                }
                size={40}
                  className={"starBig"}
                isHalf={true}
                activeColor="#ffd700"
              />
            </div>
          </li>
          <li>
            <h6>Friendliness</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</p>
            <div className="review_ratings_box">
              <ReactStars
                count={5}
                onChange={(e) =>
                  setStarRate({
                    ...starRate,
                    friendliness: e,
                  })
                }
                size={40}
                  className={"starBig"}
                isHalf={true}
                activeColor="#ffd700"
              />
            </div>
          </li>
        </ul>
        <div className="work_evidence">
          <h6>Evidence of work</h6>
          <p>Add some before and after photographs.</p>
          <div className="d-flex">
            <div className="">
              <input
                // onChange={(e) => handleImageUpload(e)}
                onChange={(e) => {
                  setImg({
                    ...img,
                    before_Img: e.target.files[0],
                  });
                  setPreview((prev) => {
                    return {
                      ...prev,
                      before_Img: URL.createObjectURL(e.target.files[0]),
                    };
                  });
                }}
                type="file"
                accept="image/*"
                className="reviews_upload dashed_box"
              />
              <div className="upload_preview_image ">
                <img
                  className="dashed_box"
                  src={preView?.before_Img ? preView?.before_Img : lightLogo}
                />
              </div>
              <span className="text-center">Before</span>
            </div>
            <div className="ms-3">
              <input
                onChange={(e) => {
                  setImg({
                    ...img,
                    after_Img: e.target.files[0],
                  });
                  setPreview((prev) => {
                    return {
                      ...prev,
                      after_Img: URL.createObjectURL(e.target.files[0]),
                    };
                  });
                }}
                type="file"
                accept="image/*"
                className="reviews_upload dashed_box"
              />
              <div className="upload_preview_image ">
                <img
                  className="dashed_box"
                  src={preView?.after_Img ? preView?.after_Img : lightLogo}
                />
              </div>
              <span className="text-center">After</span>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <button className="btn white_btn" onClick={()=>handleSubmit()}>Submit Review</button>
        </div>
      </div>
    </div>
  </div>
    </>
 
  )
}

export default TradeReviewDetail