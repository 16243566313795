import React, { useEffect, useState } from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import logoSearch from "../assets/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "../redux/Action/UserActions";
import { Accordion, Container } from "react-bootstrap";
const Services = () => {
  const [searchData, setSearchData] = useState("");
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const allServices = useSelector((state) => state?.useAction?.all_services);

  const getData = async () => {
    let data = await dispatch(getAllServices());
    setService(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = () => {
    let x = [...allServices];
    let searchArray = x.filter((obj) => {
      // return x?.trim()?.toLowerCase()?.includes(search?.trim()?.toLowerCase());
      return obj?.categoryName
        .trim()
        ?.toLowerCase()
        ?.includes(search?.trim()?.toLowerCase());
    });
    setService(searchArray);
  };

  console.log(service, "service");

  useEffect(() => {
    if (search == "") {
      setService(allServices);
    }
  }, [search]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);
  return (
    <UserLayout>
      {/* <!-- banner  start --> */}
      <section className="banner-img all-services">
        <div className="container-fluid container_inner_width">
          <div className="banner-content">
            <h1 className="mb-0">All Services</h1>
            <h2 className="mt-3">Find what you’re looking for</h2>
          </div>
        </div>
      </section>
      {/* <!-- banner / end --> */}

      {/* <!--Searchbar Section start --> */}
      <section className="searchbar-section-inner services_searchbar">
        <div className="container">
          <div className="searchbar-inner">
            <div className="input_width_80 ">
              <input
                type="search"
                name="search"
                value={search}
                placeholder="Search by Keyword"
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="search_icon">
                <img src={logoSearch} alt="search" className="" />
              </div>
            </div>
            <div className="input_width_20">
              {/* <a className="w-100 d-block"> */}
              <button
                onClick={() => handleSearch()}
                type="search"
                className="btn btn-primary h-100 w-100"
              >
                Search
              </button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--Searchbar Section / end --> */}

      {/* <!-- Services start --> */}
      {/* <section className="services-section common-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {allServices
                ?.filter(
                  (item, i) =>
                    item._id.toLowerCase().includes(searchData.toLowerCase()) ||
                    item.items.some((item) =>
                      item.toLowerCase().includes(searchData.toLowerCase())
                    )
                )
                .map((item, i) => {
                  return (
                    <div key={i} className="services_list">
                      <h2>{item?._id}</h2>
                      <div className="row">
                        {item?.items.map((item, i) => {
                          return (
                            <div className="col-lg-4">
                              <div className="service-name">
                                <p>{item}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section> */}
      <Container>
        {service?.length ? (
          <Accordion
            className="serviceslist_accord"
            // defaultActiveKey={["0"]}
            // alwaysOpen
          >
            {service?.map((res, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{res.categoryName}</Accordion.Header>
                {res.subCategories.map((subCategoriesRes) => (
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header className="sub-category">
                          {subCategoriesRes.subCategoryName}
                        </Accordion.Header>
                        <Accordion.Body className="side-spacing">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="service-name">
                                {subCategoriesRes.services.map(
                                  (servicesRes) => (
                                    <p>{servicesRes}</p>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                ))}
              </Accordion.Item>
            ))}
          </Accordion>
        ) : null}
      </Container>
      <section className="services_offer mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <h2 className="pb-4">SERVICES WE OFFER</h2>
            </div>
            <div className="col-lg-4">
              <div className="service_box">
                <h3>PLUMBING SERVICES</h3>
                <p>
                  You don’t have to live with a leaking tap, a dripping shower,
                  or a toilet flush that sounds like it’s ready to take off.
                  Instead, our 24-hour plumbers exist to make life simple,
                  stopping by at a time that works for you, keeping things
                  transparent with a quote in black and white, and refusing to
                  settle for sneaky add-ons. It’s who they are.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service_box">
                <h3>APPLIANCE REPAIR NEAR ME</h3>
                <p>
                  From a refrigerator that won’t get cold to a dryer that won’t
                  get hot, as well as microwaves, dishwashers and washing
                  machines, our service technicians exist to help you get more
                  out of your appliances. It’s all about being big on
                  convenience, kind to your wallet, and focused on repairing
                  over replacing. You’re welcome, planet Earth.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service_box">
                <h3>PAINTING AND DECORATING</h3>
                <p>
                  It could be a lick of paint in your hallway, a fresh coat over
                  a shabby wall, or a floor-to-ceiling transformation – but
                  whatever’s on the to-do list, you can expect the same quality
                  of service that our painter and wallpaper decorators have
                  become known for. Reliable residential painting services,
                  complete precision, and a tradesperson who knows their way
                  around a skirting board, every time.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="service_box">
                <h3>CONSTRUCTION LABOURER</h3>
                <p>
                  Let us do the background-checking, expert-finding, and
                  review-gathering. Offering a better way to find the right
                  construction consultant, we’re saying ‘no’ to time-consuming
                  Googling, endless quote gathering, and calls that never get
                  answered. Instead, think structures that last, renovations
                  that turn heads, and new builds that are made to be passed
                  down the generations.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="service_box">
                <h3>PLASTERING</h3>
                <p>
                  Inside or outside? Waterproof or not? Limewash, Venetian
                  plaster, or Roman clay finishes? Whatever you’re searching
                  for, you’ll only ever be a few clicks away from the right
                  local plasterer, with the right price point and turnaround
                  time to match. Less time crawling the web to find a plasterer.
                  More time spent getting things done. It’s the way it should
                  be.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="service_box">
                <h3>GARDENERS NEAR ME</h3>
                <p>
                  It’s an easier way to get your garden summer-ready – or
                  winter-ready, if that’s your kind of thing. Whether you’re
                  looking for your next dose of seasonal flowers, a creative eye
                  for landscape design, or a landscape gardener to simply swing
                  by to combat weeds and head off on their way, we’ve got a
                  member for that. Better yet – they’re only ever a click away.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="service_box">
                <h3>ELECTRICIAN</h3>
                <p>
                  We’ve brought together the area’s best domestic electricians,
                  invited their customers to review them, and made the process
                  run like a well-oiled machine – without charging a penny. From
                  fixing a light switch, issuing safety certificates, and fresh
                  wiring to emergency repairs as a 24-hour electrician, there’s
                  nothing our domestic and commercial electricians can’t do.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="service_box">
                <h3>LOCAL HANDYMAN</h3>
                <p>
                  It’s time to stop looking at it, promising that you’ll get
                  round to it. From hanging up photo frames, mounting TVs, and
                  building flat-pack furniture to the repairs that we all let
                  stack up, our handymen will handle it all, so you don’t have
                  to. It’s like having the handiest person you know on speed
                  dial, without having to pick up the phone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};

export default Services;
