import React, { useEffect, useRef, useState } from "react";
import TradeAccLayout from "../../components/trade_acc_home/layout/TradeAccLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getReviewTraderDetail,
  getReviewTraderDetailMsg,
  getTraderEnquiries,
  updateMsgCount,
} from "../../redux/Action/tradeActions";
import moment from "moment";
import TradeDetailEnquiryPage from "../../components/trade_acc_home/my_enquiries/TradeDetailEnquiryPage";
import ReviewModal from "../../components/user_acc_home/tradepersonRewiew/ReviewModal";
import ReactStars from "react-rating-stars-component";
import { io } from "socket.io-client";
const TradeEnquiries = () => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const BASE_URL = process.env.REACT_APP_SOCKET_URL;
  const socket = useRef();
  const [enquiryDetail, setEnquiryDetail] = useState();
  const [reviewDetail, setReviewDetail] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [reviewData, setReviewData] = useState();
  const [TradeEnquiry, setTradeEnquiry] = useState();
  const [totalMsgCount, seTotalMsgCount] = useState();
  const dispatch = useDispatch();
  const traderEnquiries = useSelector(
    (state) => state.tradeAction.traderEnquiries
  );
  const newMessages = useSelector((state) => state.tradeAction.newMessages);
  const reviewDetailMain = useSelector(
    (state) => state?.tradeAction?.review_detail_trader_new
  );

  useEffect(() => {
    setTradeEnquiry(traderEnquiries);
  }, [traderEnquiries]);

  useEffect(() => {
    socket.current = io.connect(BASE_URL);
    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (enquiryDetail) {
      (async () => {
        await socket.current.emit(
          "join_room",
          sessionStorage.getItem("trade_id")
        );
      })();
      handleMsgCount(enquiryDetail);
    }
  }, [enquiryDetail]);

  useEffect(() => {
    dispatch(getTraderEnquiries());
  }, []);

  useEffect(() => {
    // console.log(window.location.href.split("#"),'gds');
    if (window.location.href?.includes("#")) {
      if (traderEnquiries?.length > 0) {
        const data = traderEnquiries?.filter(
          (item) => item?._id == window.location.href?.split("#")[1]
        );
        setEnquiryDetail(data[0]);
        sessionStorage.setItem("traderEnquiryId", data[0]?._id);
        dispatch(getReviewTraderDetailMsg(data[0]?.tradeId?._id));
      }
    } else {
      if (traderEnquiries?.length > 0) {
        setEnquiryDetail(traderEnquiries[0]);
        sessionStorage.setItem("traderEnquiryId", traderEnquiries[0]?._id);
        dispatch(getReviewTraderDetailMsg(traderEnquiries[0]?.userId?._id));
      }
    }
  }, [traderEnquiries, window.location.href]);

  useEffect(() => {
    setReviewDetail(reviewDetailMain);
  }, [reviewDetailMain]);

  const reviewApi = (id) => {
    dispatch(getReviewTraderDetailMsg(id?.userId?._id)).then((data) => {
      setReviewDetail(data);
    });
  };
  const viewFullReviews = (val, type) => {
    if (val) {
      setType(type);
      setShow(true);
      setReviewData(val);
    }
  };

  useEffect(() => {
    socket.current.on("unread_count", (messageObject) => {
      setTradeEnquiry((prev) => {
        prev?.forEach((element) => {
          if (element?._id == messageObject?._id) {
            element.unread_count_trade = messageObject?.unread_count_trade;
          }
        });
        return [...prev];
      });
    });
  }, [socket]);
  useEffect(() => {
    if (TradeEnquiry) {
      const data = TradeEnquiry?.map((item) => item?.unread_count_trade).reduce(
        (a, b) => a + b,
        0
      );
      seTotalMsgCount(data);
    }
  }, [TradeEnquiry]);
  const handleMsgCount = (data) => {
    dispatch(
      updateMsgCount({
        unread_count_trade: 0,
        id: data?._id,
      })
    ).then((res) => {
      setTradeEnquiry((prev) => {
        prev?.forEach((element) => {
          if (element?._id == data?._id) {
            element.unread_count_trade = 0;
          }
        });
        return [...prev];
      });
    });
  };
  return (
    <TradeAccLayout>
      <ReviewModal
        show={show}
        setShow={setShow}
        reviewData={reviewData}
        type={type}
      />
      <section className="enquiry_sect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 p-0 col-md-4 col-sm-12 col-12">
              <div className="sidebar-enquiries">
                <div className="enquiries-title d-flex justify-content-between">
                  <h4 className="blck_color">
                    Showing {TradeEnquiry?.length} Enquiries
                  </h4>
                  <div className="d-flex">
                    <h4>{totalMsgCount} New Messages</h4>
                    <img
                      src={
                        require("../../assets/images/enquiries/filter.svg")
                          .default
                      }
                      alt="filter"
                      className="ms-2"
                    />
                  </div>
                </div>
                <ul
                  className={`sidebar_lisiting ${
                    TradeEnquiry?.length == 0 && " sidebar_listing_blank"
                  }`}
                >
                  {TradeEnquiry?.length > 0 ? (
                    TradeEnquiry?.map((enquiry) => {
                      console.log(enquiry,"enquiry")
                      var duration = moment.duration(
                        moment().diff(enquiry?.createdAt)
                      );
                      var days = duration.asDays();
                      var hours = duration.asHours();
                      var minutes = duration.asMinutes();
                      return (
                        <li
                          onClick={() => {
                            setEnquiryDetail(enquiry);
                            setEnquiryDetail(enquiry);
                            handleMsgCount(enquiry);
                            reviewApi(enquiry);
                            sessionStorage.setItem(
                              "traderEnquiryId",
                              enquiry?._id
                            );
                          }}
                          className={
                            enquiryDetail == enquiry
                              ? "box_shadow_border mb-4"
                              : "box_shadow_border_white mb-4"
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="dot"></span>
                            <span className="time">
                              {" "}
                              {hours > 24
                                ? `${Math.floor(days)} days ago`
                                : minutes > 60
                                ? `${Math.floor(hours)} hours ago`
                                : Math.floor(minutes) == 0
                                ? "Just now"
                                : `${Math.floor(minutes)} minutes ago`}
                            </span>
                          </div>
                          <div className="ps-4 enquiry_content">
                            <h3>
                              {enquiry?.userId?.firstName.concat(
                                " ",
                                enquiry?.userId?.lastName
                              )}
                            </h3>
                            <p>{enquiry?.service}</p>
                            <p className="mb-2 comment_text_enq mt-2">
                              {enquiry?.enquiry_address?.city}
                            </p>
                            <p className="comment_text_enq">
                              {enquiry?.chats?.message}
                            </p>
                            <h4 className="text-end">
                              <strong>
                                {enquiry?.unread_count_trade == 0
                                  ? ""
                                  : enquiry?.unread_count_trade}{" "}
                              </strong>
                              {
                                enquiry?.unread_count_trade == 0 ? "" : "Replied to you"
                              }
                              
                            </h4>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div className="no-enquiry-img">
                      <img
                        src={
                          require("../../assets/images/no-enquiry.svg").default
                        }
                      />
                      <p>No Enquiries yet</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 col-12">
              <div className="enquiry_detail">
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                    {TradeEnquiry?.length > 0 ? (
                      <TradeDetailEnquiryPage
                        socket={socket}
                        enquiryDetail={enquiryDetail}
                      />
                    ) : (
                      <p className="no-chats">No Chats yet</p>
                    )}
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4">
                    <div className="sidebar_reviews">
                      <h3 className="text-center">
                        What People Say about{" "}
                        {enquiryDetail?.userId?.firstName
                          ? enquiryDetail?.userId?.firstName +
                            " " +
                            enquiryDetail?.userId?.lastName
                          : ""}
                      </h3>
                      <ul className="review-list">
                        {reviewDetail?.reviews?.length > 0 ? (
                          reviewDetail?.reviews?.map((item, i) => {
                            return (
                              <li className="mb-4 list-unstyled">
                                <div className="recent_reviews box_shadow ">
                                  <div className="review_detail">
                                    <div className="review_detail_left">
                                      <img
                                        src={
                                          item?.senderId?.business_image
                                            ? BACKEND_URL +
                                              "business_image/" +
                                              item?.senderId?.business_image
                                            : require("../../assets/images/tradesperson/review-profile.png")
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="review_detail_right">
                                      <h3 className="revewier_name">
                                        {item?.senderId?.firstName +
                                          " " +
                                          item?.senderId?.lastName}
                                      </h3>
                                      <div className="review_sect mt-2 mb-2">
                                        <div className="review_ratings_box star_width">
                                          {item?.overall_rating ? (
                                            <ReactStars
                                              count={5}
                                              edit={false}
                                              isHalf={true}
                                              value={item?.overall_rating}
                                              size={40}
                                              activeColor="#ffd700"
                                            />
                                          ) : (
                                            <ReactStars
                                              count={5}
                                              edit={false}
                                              isHalf={true}
                                              size={40}
                                              activeColor="#ffd700"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <p className="comment_text">
                                        {item?.comments
                                          ? item?.comments
                                          : "TradePerson not gives any types of comments"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-4 justify-content-between">
                                    <p>
                                      {moment(item?.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <a
                                      className=""
                                      onClick={() => viewFullReviews(item, 1)}
                                    >
                                      More
                                      <img
                                        src={
                                          require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                            .default
                                        }
                                        alt=""
                                        className="ms-1"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <div className="no-enquiry-img review-img">
                            <img
                              src={
                                require("../../assets/images/no-reviews.svg")
                                  .default
                              }
                            />
                            <p>No Reviews yet</p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TradeAccLayout>
  );
};

export default TradeEnquiries;
