import { useFormik } from "formik";
import React, { useState,forwardRef, useImperativeHandle, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStripeAccount, getConnectOnBoard, getUserAccountDetails } from "../../../redux/Action/tradeActions";
import { STOP_LOADING } from "../../../redux/Constant/constant";

const Subscription = (props,ref) => {

  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const [submitClicked, setSubmitClicked] = useState(false);
  const paymentAccountDetails = useSelector(
    (state) => state.tradeAction.account_details
  );

  const handleSubmit=()=>{
      console.log(paymentAccountDetails,"subscription");
    }
    useImperativeHandle(ref, () => ({
      handleSubmit
    }));



    useEffect(() => {

      dispatch(createStripeAccount())
      .then((res) => {
        if(res.success){ 
          window.location.href = res?.data?.url;
          // dispatch({
          //   type: STOP_LOADING,
          //   payload: false
          // });
        }
      })
      .catch((error) => console.log(error));

    }, []);
  
    

}

export default  forwardRef(Subscription);


