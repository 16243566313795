import { toast } from "react-toastify";
import TradeAPI from "../../service/TradeApi";
import { TRADE_PERSON_REGISTER } from "../Constant/constant";
import Cookies from "universal-cookie";
export const tradeLogin =
  (userData, rememberMe, navigate) => async (dispatch) => {
    const cookies = new Cookies();
    // dispatch({ type: START_LOADING, payload: true });

    try{
       const Login= await TradeAPI.post("/auth/login",userData)
       console.log(Login.data.data);
       if(rememberMe && Login.status==200){
           cookies.set("tradeToken", Login.data.data.token, {
               path: "/",
               expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
             });
           sessionStorage.setItem("tradeToken",Login.data.data.token)
           sessionStorage.setItem("trade_id",Login?.data?.data?._id)
           window.location.href = "/trade-dashboard";
     
       }
        if (!rememberMe &&Login.status == 200) {
           sessionStorage.setItem("tradeToken", Login.data.data.token);
           sessionStorage.setItem("trade_id",Login.data.data?._id)
           toast.success(Login.data.message)
          window.location.href="/trade-dashboard"
    // dispatch({ type: STOP_LOADING, payload: false });

         }
       else {
           toast.error(Login.data.message)
         }
    }catch(error){
       toast.warning(error.message)
    }
}
export const tradeRegister=(tradeData,setShowModal)=>async(dispatch)=>{
try{
    const tradeRegiter= await TradeAPI.post("/auth/register",tradeData)
    if (tradeRegiter.status == 200) {
     dispatch({
         type: TRADE_PERSON_REGISTER,
         payload: tradeRegiter?.data,
     });
    //  toast.success(tradeRegiter.data.message)
     setShowModal(true)
   }
   
    else {
        toast.error(tradeRegiter.data.message)
      }
 }catch(error){
    toast.warning(error.message)
 }
}
export const forgotPassword=(data,navigate)=>async(dispatch)=>{
    try{
        const forgotPass= await TradeAPI.post("/auth/forgotPassword",data)
        if (forgotPass.status == 200) {
         
         toast.success(forgotPass.data.message)
         navigate("/tradepeople-login")
       }
       
        else {
            toast.error(forgotPass.data.message)
          }
     }catch(error){
        toast.warning(error.message)
     }
  }
  export const resetPassword=(data,navigate)=>async(dispatch)=>{
    try{
        const resetPass= await TradeAPI.post("/auth/resetPassword",data)
        if (resetPass.status == 200) {
         toast.success(resetPass.data.message)
         navigate("/tradepeople-login")
       }
        else {
            toast.error(resetPass.data.message)
          }
     }catch(error){
        toast.warning(error.message)
     }
  }
  
