import React from "react";
import {
  AVAILABLE_HOURS,
  ENVOICE_DETAIL,
  ESTIMATE_DETAIL,
  GET_TRADER_CHAT,
  GET_TRADE_LOCATIONS,
  QUOTE_DETAIL,
  REVIEW_DETAIL_NEW,
  REVIEW_DETAIL_TARDER,
  REVIEW_TARDER_DETAIL_NEW,
  STRIPE_USER_ACCOUNT_DETAILS,
  TRADER_APPOINTMENT,
  TRADER_ENQUIRIES,
  TRADER_ENQUIRY,
  TRADER_UPDATE_CHAT,
  TRADE_PERSON_PROFILE_DETAIL,
  TRADE_REVIEWS,
  TRADE_SERVICE_FETCH_SUCCESSFULLY,
  UPDATE_CHAT,
  WALLET_INFO,
  WALLET_TRANSATION,
} from "../Constant/constant";
const initialstate = {
  tradePerson_detail: null,
  trade_services: [],
  traderEnquiries: null,
  newMessages: 0,
  traderChat: null,
  traderChatObj: null,
  traderLoctions: null,
  trader_appointment: [],
  trader_reviews: null,
  review_detail_trader: null,
  review_detail_trader_new: null,
  account_details:null,
  estimat_detail:null,
  invoice_detail:null,
  walletDetail:null,
  availHours:null,
  walletInfo:null,
  quote_detail:null
};
const TradeReducer = (state = initialstate, action) => {
  switch (action.type) {
    case TRADE_PERSON_PROFILE_DETAIL:
      return {
        ...state,
        tradePerson_detail: action.payload,
      };
    case TRADE_SERVICE_FETCH_SUCCESSFULLY:
      return {
        ...state,
        trade_services: action.payload,
      };
    case TRADER_ENQUIRIES:
      return {
        ...state,
        traderEnquiries: action.payload.traderEnquiries,
        newMessages: action.payload.newMessages,
      };

    case GET_TRADER_CHAT:
      return {
        ...state,
        traderChatObj: action.payload,
        traderChat: action.payload?.chatsArray,
      };

    case TRADER_UPDATE_CHAT:
      return {
        ...state,
        traderChat: action.payload,
      };
    case GET_TRADE_LOCATIONS:
      return {
        ...state,
        traderLoctions: action.payload,
      };
    case TRADER_APPOINTMENT:
      return {
        ...state,
        trader_appointment: action.payload,
      };
    case TRADE_REVIEWS:
      return {
        ...state,
        trader_reviews: action.payload,
      };
    case REVIEW_DETAIL_TARDER:
      return {
        ...state,
        review_detail_trader: action.payload,
      };
    case REVIEW_TARDER_DETAIL_NEW:
      return {
        ...state,
        review_detail_trader_new: action.payload,
      };
      case ESTIMATE_DETAIL:
        return {
          ...state,
           estimat_detail: action.payload,
        };
        case QUOTE_DETAIL:
          return {
            ...state,
             quote_detail: action.payload,
          };
        case ENVOICE_DETAIL:
          return {
            ...state,
             invoice_detail: action.payload,
          };
      case STRIPE_USER_ACCOUNT_DETAILS:
        return {
          ...state,
          account_details: action.payload,
        };
      case WALLET_TRANSATION:
        return{
          ...state,
          walletDetail:action.payload
        }
        case AVAILABLE_HOURS:
          return {
            ...state,
            availHours:action.payload
          }
          case WALLET_INFO:
            return {
              ...state,
              walletInfo:action.payload
            }
    default:
      return state;
  }
};

export default TradeReducer;
