import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceCategory } from "../../redux/Action/tradeActions";
import {
  fetchServiceCategory1,
  getDataVal,
} from "../../redux/Action/UserActions";

const MultiSelect = ({ formik, type, setMultiServices, multiServices, detailVal,typeSelect }) => {
  const dispatch = useDispatch();
  const { trade_services } = useSelector((state) => ({
    trade_services: state.tradeAction.trade_services,
  }));
  const [selectedVal, setSelectedVal] = useState();
  const [isMenuSelect, setIsMenuSelect] = useState(false);

  const handleSelect = (e, type) => {
    setIsMenuSelect(false)
    if (type == "profile") {
      const arrayUniqueByKey = [
        ...new Map(
          [...multiServices, ...e].map((item) => [item["label"], item])
        ).values(),
      ];
      setMultiServices(arrayUniqueByKey);
    } else {
      if (formik) {
        formik.setFieldValue(
          "multi_services",
          e?.map((item) => {
            return {
              value: item?.value,
              label: item?.label,
            };
          })
        );
      }
      setSelectedVal(e);
    }
  };

  useEffect(() => {
    dispatch(fetchServiceCategory1());
    dispatch(fetchServiceCategory());
  }, []);
  useEffect(() => {
    dispatch(getDataVal(selectedVal));
  }, [selectedVal]);

  const sub_category_list = trade_services?.map((item) => {
    return {
      value: item?._id,
      exp: item?.service,
      category: item?.category_id?.category,
      label:
        item?.sub_category_id?.sub_category == "" ||
        item?.sub_category_id?.sub_category == undefined
          ? `${item?.category_id?.category} - ${item?.service}`
          : `${item?.category_id?.category} - ${item?.sub_category_id?.sub_category} - ${item?.service}`,
    };
  });


 const handleInputChange = (inputValue) => {
   console.log(inputValue, "trade_services")
   if (inputValue?.length > 1){
     setIsMenuSelect(true)
   }
   if (inputValue?.length == 0){
     setIsMenuSelect(false)
   }
  };

  const DropdownIndicator = (
    props
  ) => {
    return (
      <div className="dropdown_custom" onClick={() => setIsMenuSelect(true)}>
        <components.DropdownIndicator  {...props}>
          <img src={require("../../assets/images/arrow-bottom.svg").default}/>
        </components.DropdownIndicator>
      </div>
    );
  };
  return (
    <>
    {
        typeSelect == 'user-enquiry' ? <Select
          value={selectedVal}
          components={{ DropdownIndicator }}
          onChange={(e) => handleSelect(e, type)}
          options={detailVal?.multi_services?.length > 0 ? detailVal?.multi_services : sub_category_list}
          isMulti={type == "profile" || type == "register" ? true : false}
          className="custom_style"
        /> : <Select
          value={selectedVal}
            components={{ DropdownIndicator }}
          menuIsOpen={isMenuSelect}
          onInputChange={handleInputChange}
          onChange={(e) => handleSelect(e, type)}
          options={detailVal?.multi_services?.length > 0 ? detailVal?.multi_services : sub_category_list}
          isMulti={type == "profile" || type == "register" ? true : false}
          className="custom_style"
        />
    }
      
    </>
  );
};

export default MultiSelect;
