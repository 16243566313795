import React, { useEffect, useState } from "react";
import TradeAccLayout from "../layout/TradeAccLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import deleteIcon from "../../../assets/images/trash1.svg";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createEstimate,
  getEstimateDetail,
} from "../../../redux/Action/tradeActions";
import RegularPopup from "../../regular_popup/RegularPopup";

const Estimate = () => {
  const [ammountVal, setAmmountVal] = useState({
    total: "",
    salesTax: "",
    netAmount: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState();
  console.log(showModal);
  const navigate = useNavigate();
  const [itemAmmount, setTtemAmmount] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const esDetail = useSelector((state) => state?.tradeAction?.estimat_detail);
  useEffect(() => {
    dispatch(getEstimateDetail(id));
  }, []);
  const handleNewChange = (data, setFieldValue, index, val, endKey) => {
    var clone = structuredClone(val);

    clone.invoice_arr[index][endKey] = parseInt(data) || 0;
    // const totalPrice = clone?.invoice_arr[index].qty *
    //   clone?.invoice_arr[index].rate - clone?.invoice_arr[index].discount
    // const tax = clone?.invoice_arr[index].sales_tax
    // console.log(
    //   totalPrice * tax / 100 + totalPrice
    //   , "tttttt"
    // );
    const ammount =
      ((clone?.invoice_arr[index].qty * clone?.invoice_arr[index].rate -
        clone?.invoice_arr[index].discount) *
        clone?.invoice_arr[index].sales_tax) /
        100 +
      (clone?.invoice_arr[index].qty * clone?.invoice_arr[index].rate -
        clone?.invoice_arr[index].discount);
    clone.invoice_arr[index]["amount"] = parseFloat(ammount);

    const total = clone?.invoice_arr.reduce((acc, obj) => {
      return parseFloat(acc) + parseFloat(obj.amount);
    }, 0);
    const salesTax = clone?.invoice_arr.reduce((acc, obj) => {
      let priceForSales = obj.qty * obj.rate - obj.discount;

      return (
        parseFloat(acc) + parseFloat((priceForSales * obj?.sales_tax) / 100)
      );
    }, 0);
    setTtemAmmount(ammount);
    setFieldValue(
      `total`,
      parseFloat(parseFloat(total) - parseFloat(salesTax)).toFixed(2)
    );
    setFieldValue(`total_sales_tax`, parseFloat(salesTax).toFixed(2));
    setFieldValue(`net_amount`, parseFloat(total).toFixed(2));
    setFieldValue(
      `invoice_arr.${index}.amount`,
      parseFloat(ammount).toFixed(2)
    );
    setAmmountVal({
      ...ammountVal,
      total: parseFloat(parseFloat(total) - parseFloat(salesTax)).toFixed(2),
      netAmount: parseFloat(total).toFixed(2),
      salesTax: parseFloat(salesTax).toFixed(2),
    });
  };
  return (
    <TradeAccLayout>
      {showModal == true && (
        <RegularPopup
          showModal={showModal}
          setShowModal={setShowModal}
          modalName={"newEstimate"}
          enqId={id}
          msg={msg}
        />
      )}
      <div>
        <section className="new-estimate-title">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="new_enquiry_title">
                  <h2>Create a new estimate</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="view-estimate-sect">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-new">
                  <div className="card-body card-body-new">
                    <h3 className="mb-3">
                      {esDetail?.userId.firstName +
                        " " +
                        esDetail?.userId.lastName}
                    </h3>
                    <p>{esDetail?.serviceId?.service}</p>
                    {esDetail?.enquiry_address?.address
                      ?.split(",")
                      .filter((val) => {
                        return val !== " ";
                      })
                      ?.map((item, i) => {
                        return <p>{item}</p>;
                      })}
                    <p>{esDetail?.enquiry_address?.postcode}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="back_btn d-flex justify-content-end">
                  <Link
                    to="/trade-enquiries"
                    className="d-flex align-items-center"
                  >
                    Cancel / Exit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Formik
          // enableReinitialize: true
          initialValues={{
            enquiryId: id,
            total: "",
            total_sales_tax: "",
            net_amount: "",
            notes: "",
            invoice_arr: [
              {
                description: "",
                qty: "",
                rate: "",
                discount: "",
                sales_tax: "",
                amount: "",
              },
            ],
          }}
          onSubmit={(values) => {
            dispatch(createEstimate(values)).then((res) => {
              if (res) {
                setMsg(res);
                setShowModal(true);
              }
            });
          }}
          render={({ values, handleChange, setFieldValue }) => (
            <Form>
              <div>
                <div>
                  <section>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-estimate">
                            <FieldArray
                              name="invoice_arr"
                              render={(arrayHelpers) => (
                                <>
                                  <table className="table table-scroll small-first-col mb-0 upper-tble">
                                    <thead>
                                      <tr>
                                        <th>
                                          <p>Description</p>
                                        </th>
                                        <th>
                                          <p>Qty</p>
                                        </th>
                                        <th>
                                          <p>Rate</p>
                                        </th>
                                        <th>
                                          <p>Discount</p>
                                        </th>
                                        <th>
                                          <p>Sales Tax</p>
                                        </th>
                                        <th>
                                          <p>Amount</p>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="body-half-screen">
                                      <div>
                                        {values.invoice_arr?.map(
                                          (item, index) => (
                                            <tr key={index}>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="e.g Materials"
                                                  className="form-control"
                                                  value={
                                                    values.invoice_arr[index]
                                                      .description
                                                  }
                                                  onChange={handleChange}
                                                  name={`invoice_arr.${index}.description`}
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  placeholder="0"
                                                  className="form-control"
                                                  value={
                                                    values.invoice_arr[index]
                                                      .qty
                                                  }
                                                  onChange={(e) => {
                                                    handleNewChange(
                                                      e.target.value,
                                                      setFieldValue,
                                                      index,
                                                      values,
                                                      "qty"
                                                    );
                                                    handleChange(e);
                                                  }}
                                                  name={`invoice_arr.${index}.qty`}
                                                />
                                              </td>
                                              <td className="fixed-amt">
                                                <span>£</span>
                                                <input
                                                  type="number"
                                                  placeholder="0"
                                                  className="form-control"
                                                  value={
                                                    values.invoice_arr[index]
                                                      .rate
                                                  }
                                                  onChange={(e) => {
                                                    handleNewChange(
                                                      e.target.value,
                                                      setFieldValue,
                                                      index,
                                                      values,
                                                      "rate"
                                                    );
                                                    handleChange(e);
                                                  }}
                                                  name={`invoice_arr.${index}.rate`}
                                                />
                                              </td>
                                              <td className="fixed-amt">
                                                <span>£</span>
                                                <input
                                                  type="number"
                                                  placeholder="0"
                                                  className="form-control"
                                                  value={
                                                    values.invoice_arr[index]
                                                      .discount
                                                  }
                                                  onChange={(e) => {
                                                    handleNewChange(
                                                      e.target.value,
                                                      setFieldValue,
                                                      index,
                                                      values,
                                                      "discount"
                                                    );
                                                    handleChange(e);
                                                  }}
                                                  name={`invoice_arr.${index}.discount`}
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  placeholder="0%"
                                                  className="form-control"
                                                  value={
                                                    values.invoice_arr[index]
                                                      .sales_tax
                                                  }
                                                  onChange={(e) => {
                                                    handleNewChange(
                                                      e.target.value,
                                                      setFieldValue,
                                                      index,
                                                      values,
                                                      "sales_tax"
                                                    );
                                                    handleChange(e);
                                                  }}
                                                  name={`invoice_arr.${index}.sales_tax`}
                                                />
                                              </td>
                                              <td className="trash-amt">
                                                <div className="d-flex align-items-center">
                                                  <div className="fixed-amt">
                                                    <span>£</span>
                                                    <input
                                                      type="number"
                                                      placeholder="0"
                                                      className="form-control"
                                                      value={
                                                        (((values?.invoice_arr[
                                                          index
                                                        ].qty *
                                                          values?.invoice_arr[
                                                            index
                                                          ].rate -
                                                          values?.invoice_arr[
                                                            index
                                                          ].discount) *
                                                          values?.invoice_arr[
                                                            index
                                                          ].sales_tax) /
                                                          100 +
                                                        (values?.invoice_arr[
                                                          index
                                                        ].qty *
                                                          values?.invoice_arr[
                                                            index
                                                          ].rate -
                                                          values?.invoice_arr[
                                                            index
                                                          ].discount)).toFixed(2)
                                                      }
                                                      disabled
                                                    />
                                                  </div>
                                                  <div
                                                    className="ms-2"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      className="cursor-pointer"
                                                      src={deleteIcon}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </div>
                                    </tbody>
                                  </table>
                                  <section>
                                    <div className="add-more-row text-end">
                                      <a
                                        className="cursor-pointer"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            description: "",
                                            qty: "",
                                            rate: "",
                                            discount: "",
                                            sales_tax: "",
                                            amount: "",
                                          })
                                        }
                                      >
                                        Add More
                                      </a>
                                    </div>
                                  </section>
                                </>
                              )}
                            />
                            <table className="table fixed-table">
                              <tbody>
                                <tr>
                                  <td className="fixed-amt">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <p className="total-amt">Total</p>
                                      <p className="total-amt-width fixed-amt disabled-amt">
                                        <span>£</span>
                                        {ammountVal?.total
                                          ? ammountVal?.total
                                          : "0"}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fixed-amt border-none-tb">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <p className="total-amt">Sales Tax</p>
                                      <p className="total-amt-width fixed-amt disabled-amt">
                                        <span>£</span>
                                        {ammountVal?.salesTax
                                          ? ammountVal?.salesTax
                                          : "0"}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fixed-amt">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <p className="total-amt">NET Amount</p>
                                      <p className="total-amt-width fixed-amt disabled-amt ">
                                        <span>£</span>
                                        {ammountVal?.netAmount
                                          ? ammountVal?.netAmount
                                          : "0"}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <!-- View Estimate Form / End--> */}

                  <section className="notes-sect">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="notes-sect-inner">
                            <div className="row d-flex justify-content-between">
                              <div className="col-md-6">
                                <div>
                                  <h5>Notes</h5>
                                  <textarea
                                    name="notes"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    placeholder="Type here..."
                                    className="text-overflow-notes form-control"
                                    value={values.notes}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-md-5 d-flex justify-content-end align-items-end flex-column">
                                <p>
                                  By sending this estimate you agree toLorem
                                  ipsum dolor sit amet, consectetur adipiscing
                                  elit.
                                </p>
                                <div className="d-flex justify-content-end">
                                  <button className="btn btn-primary ">
                                    Send Estimate
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Form>
          )}
          enableReinitialize={true}
        />
      </div>
    </TradeAccLayout>
  );
};

export default Estimate;
