import React from "react";
import Layout from "../layout/Layout";
import { useFormik } from "formik";
import { forgotPassword } from "../../redux/Action/UserLoginAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required*";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      dispatch(forgotPassword(values, navigate));
    },
  });
  return (
    <Layout>
      <section class="wrapper_login login_page">
        <div class="container-fluid">
          <div class="container_data">
            <div class="row ">
              <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div class="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p class="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div class="sign_in ">
                  <form onSubmit={formik.handleSubmit} class="w-100">
                    <div class="card">
                      <div class="card-body">
                        <div class="title_signin">
                          <h3>Forgot your Password?</h3>
                          <div class="form-group mb-5 mt-4">
                            <label for="">Email</label>
                            <input
                              type="email"
                              class="form-control login_form_control"
                              placeholder="Email"
                              name="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                              {formik.errors.email && (
                            <p className="formik-error">
                              {formik.errors.email}
                            </p>
                          )}
                          </div>
                          <div class="btn_submit mt-4 d-flex">
                            <button
                              type="submit"
                              class="btn btn-primary btn-custom btn-lg w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ForgotPassword;
