import { useEffect, useState } from "react";
import backButton from "../../assets/images/tradesperson/back_btn.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import smallimag from "../../assets/images/tradesperson/small_img.png";
import stars from "../../assets/images/tradesperson/yellow_star.svg";
import ReviewModal from "../../components/user_acc_home/tradepersonRewiew/ReviewModal";
import { useDispatch, useSelector } from "react-redux";
import logo1 from "../../assets/images/tradesperson/services_icon.svg";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import {
  getReview_Detail,
  getprofileDetail,
} from "../../redux/Action/UserActions";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import RegularPopup from "../../components/regular_popup/RegularPopup";
const TradespersonDetails = () => {
  const [show, setShow] = useState(false);
  const [reviewData, setReviewData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [star, setStar] = useState(false)
  const userToken = window.sessionStorage.getItem("userToken");
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const detailVal = useSelector((state) => state?.useAction?.trade_detail);
  const reviewDetail = useSelector(
    (state) => state?.useAction?.review_detail_new
  );
  const rows = reviewDetail?.reviews?.reduce(function (rows, key, index) {
    return (
      (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows
    );
  }, []);
  console.log(rows, "rows");
  const navigate = useNavigate();

  const handleReviewClick = (val) => {
    if (val) {
      setReviewData(val);
      setShow(true);
    }
  };

  const handleButtonClick = () => {
    if (!userToken) {
      setShowModal(true)
      // toast.success("Please login to continue further");
    }
  };

  useEffect(() => {

    dispatch(getReview_Detail(id)).then((res) => {
      if (res) {
        setStar(true)
      }
    });
    dispatch(getprofileDetail(id));
  }, []);


  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    console.log(text.slice(0, 837).length, " text.slice(0, 900)")
    return (
      <>
        {isReadMore ? text.slice(0, 837) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? (text.slice(0, 837).length < 837 ? "" : "...read more") : " show less"}
        </span>
      </>
    );
  };


  return (
    <>
      {showModal == true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"userAccount"} />}
      <ReviewModal show={show} setShow={setShow} reviewData={reviewData} />
      <section className="pt-5 ">
        <div className="container-fluid p-75">
          <div className="row">
            <div className="col-12">
              <div className="back_btn mb-5">
                <a
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center"
                >
                  {" "}
                  <img
                    src={backButton}
                    alt="back-arrow"
                    className="me-2"
                  />{" "}
                  Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tardeperson_section pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-12">
              <div className="large-profile-img">
                {detailVal?.business_image && detailVal?.business_image != "undefined" ? (
                  <img
                    src={
                      BACKEND_URL +
                      "business_image/" +
                      detailVal?.business_image
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={require("../../assets/images/gallery-big.png")}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12">
              <div className="small_images_inner ">
                <div className="row">
                  {

                    detailVal?.gallery?.map((item) => {
                      return (
                        <div className="col-md-4">
                          <div className="small-profile-img">
                            <img
                              src={BACKEND_URL + "business_image/" + item}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                  {
                    [...Array(6 - detailVal?.gallery?.length || 0).keys()]?.map((item) => {
                      return (
                        <div className="col-md-4">
                          <div className="small-profile-img">
                            <img
                              src={require("../../assets/images/gallery-small.png")}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              <div className="small_images_inner_slider">
                <div
                  id="carouselExampleControls"
                  className="carousel"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="cards-wrapper">
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="cards-wrapper">
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="cards-wrapper">
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                        <div className=" card_img">
                          <div className="image-wrapper">
                            <img src={smallimag} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tradeperson_detailed_info">
        <div className="container">
          <div className="row">
            <div className=" col-lg-8">
              <div className="tradeperson_info_details">
                <div className="upper_info_sect">
                  <h1>{detailVal?.company_name}</h1>
                  <h5>{detailVal?.address}</h5>
                  <div className="review_sect justify-content-between">
                    <div className="review_ratings_box lineHeight d-flex align-items-center">
                      {star == true && (
                        <ReactStars
                          count={5}
                          edit={false}
                          isHalf={true}
                          value={reviewDetail?.overall_rating}
                          size={38}
                          activeColor="#ffd700"
                        />
                      )}
                      <p className="common_parah ms-3">
                      {reviewDetail?.overall_rating
                        ? "(" +
                        reviewDetail?.overall_rating +
                        " " +
                        "Reviews" +
                        ")"
                        : "(" + "0" + " " + "Reviews" + ")"}
                    </p>
                    </div>
                    
                    <div className="user-badges">
                    {
                                detailVal?.badge == 1 ? <img className="badge-img" src={require("../../assets/images/platinum_rated.png")} /> :
                                  detailVal?.badge == 2 ? <img className="badge-img" src={require("../../assets/images/diamond_rated.png")} /> :
                                    detailVal?.badge == 3 && <img className="badge-img" src={require("../../assets/images/gold_rated.png")} />

                              }
                              </div>
                  </div>
                  
                </div>
                <div className="bio_section ">
                  <h3 className="black_head">Bio</h3>
                  <div className="bio_text_fix box_shadow">
                    {/* <div className="container">
      <h2>
        <ReadMore>
          GeeksforGeeks: A Computer Science portal for geeks. 
          It contains well written, well thought and well explained
          computer science, programming articles and quizzes. 
          It provides a variety of services for you to learn, so thrive
          and also have fun! Free Tutorials, Millions of Articles, Live, 
          Online and Classroom Courses ,Frequent Coding Competitions,
          Webinars by Industry Experts, Internship opportunities, and Job
          Opportunities. Knowledge is power!
        </ReadMore>
      </h2>
    </div> */}
                    <p className="read-more-text">
                      {
                        detailVal?.bio ?
                          <ReadMore >
                            {detailVal?.bio}
                          </ReadMore> : <div className="placeholder-bio">No Content Available</div>
                      }
                    </p>

                  </div>
                </div>
                <div className="services-offered">
                  <h3 className="black_head">Services Offered</h3>
                  <ul className="services_offered_listing box_shadow">
                    {detailVal?.multi_services?.map((item) => {
                      return (
                        <li>
                          <img src={logo1} alt="" />
                          <h4 className="font13">{item?.label}</h4>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="tradesperson_sidebar">
                <div className="availability_sect">
                  <h3 className="light_head">Availability</h3>
                  <ul className="p-0 box_shadow">
                    {
                      detailVal?.availabilityId?.opening_hours?.map((item, i) => {
                        return (
                          <>
                            <li>
                              <p className="me-3 fix_width">{item?.day}</p>
                              <div>
                                {
                                  item?.arr?.map((data, i) => {
                                    return (
                                      <p>
                                        {data?.start || data?.end ? `${data?.start} - ${data?.end}` : data?.reason}
                                      </p>

                                    )
                                  })
                                }
                              </div>
                            </li>
                          </>
                        )
                      })
                    }

                  </ul>
                </div>
                <div className="cta_sect">
                  <h3 className="light_head">Get in Touch</h3>
                  <div className="box_shadow cta_sect_inner">
                    <Link
                      to={
                        userToken
                          ? `/user-enquiry/${detailVal?.company_name}/${id}/send`
                          : ""
                      }
                    >
                      <button
                        onClick={handleButtonClick}
                        className="btn white_btn"
                      >
                        Send a Message
                      </button>
                    </Link>
                    <Link
                      to={
                        userToken
                          ? `/user-enquiry/${detailVal?.company_name}/${id}/request`
                          : ""
                      }
                    >
                      <button
                        onClick={handleButtonClick}
                        className="btn btn-primary"
                      >
                        Request a Callback
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="cta_sect">
                  {detailVal?.video && detailVal?.video != "" ? (
                    <>
                      <h3 className="light_head">Watch our Video</h3>
                      <div className="video_img">
                        {detailVal?.video?.includes("embed") ||
                          detailVal?.video?.includes("youtube") ? (
                          <ReactPlayer url={detailVal?.video} />
                        ) : (
                          <video controls autoPlay>
                            {detailVal?.video?.includes("webm") && (
                              <source
                                src={detailVal?.video}
                                type="video/webm"
                              />
                            )}
                            {detailVal?.video?.includes("ogv") && (
                              <source src={detailVal?.video} type="video/ogg" />
                            )}
                            {detailVal?.video?.includes("mp4") && (
                              <source src={detailVal?.video} type="video/mp4" />
                            )}
                            {detailVal?.video?.includes("3gp") && (
                              <source src={detailVal?.video} type="video/3gp" />
                            )}
                          </video>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ratings_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="overall_ratings">
                <h3 className="black_head">Overall Ratings</h3>
                <ul className="ratings_pointers">
                  <li>
                    <div className="rating_pointers_content">
                      <p>Quote Accuracy</p>
                      <h5>
                        {reviewDetail?.quoteAccuracy
                          ? reviewDetail?.quoteAccuracy
                          : "0"}
                        %
                      </h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="rating_pointers_content">
                      <p>Punctuality</p>
                      <h5>
                        {reviewDetail?.punctuality
                          ? reviewDetail?.punctuality
                          : "0"}
                        %
                      </h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="rating_pointers_content">
                      <p>Quality of Work</p>
                      <h5>
                        {reviewDetail?.quality_of_Work
                          ? reviewDetail?.quality_of_Work
                          : "0"}
                        %
                      </h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="rating_pointers_content">
                      <p>Tidiness</p>
                      <h5>
                        {reviewDetail?.tidiness ? reviewDetail?.tidiness : "0"}%
                      </h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="rating_pointers_content">
                      <p>Friendliness</p>
                      <h5>
                        {reviewDetail?.friendliness
                          ? reviewDetail?.friendliness
                          : "0"}
                        %
                      </h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="rating_pointers_content">
                      <p>Consideration</p>
                      <h5>{reviewDetail?.quoteAccuracy}%</h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="rating_pointers_content">
                      <p>Reliability</p>
                      <h5>{reviewDetail?.quoteAccuracy}%</h5>
                      <div className="star_absolute">
                        <img src={stars} alt="" />
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ratings_section">
        <div className="container slider-column">
          <div className="row">
            {rows?.length > 0 ? (
              <div className="col-md-12">
                <h3 className="black_head">Services Offered</h3>

                <Swiper
                  className="mySwiper"
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                >
                  {rows?.map((data, index) => {
                    return (
                      <>
                        <SwiperSlide className="m-3" key={index}>
                          {data?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                onClick={() => handleReviewClick(item)}
                                className="recent_reviews  box_shadow m-3  recent-review-slider"
                              >
                                <p className="text-end">
                                  {moment(
                                    item?.appointmentId?.createdAt
                                  ).format("DD MMM YYYY")}
                                </p>
                                <div className="review_detail">
                                  <div className="review_detail_left">
                                    <img
                                      src={
                                        item?.senderId?.profile_image && item?.senderId?.profile_image != "undefined"
                                          ? BACKEND_URL +
                                          "profile_image/" +
                                          item?.senderId?.profile_image
                                          : require("../../assets/images/tradesperson/review-profile.png")
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="review_detail_right revewier-name-width">
                                    <h3 className="revewier_name ">
                                      {item?.senderId?.firstName +
                                        item?.senderId?.lastName}
                                    </h3>
                                    <div className="review_sect mt-3 mb-3">
                                      <div className="review_ratings_box lineHeight_stars">
                                        {star == true && (
                                          <ReactStars
                                            count={5}
                                            edit={false}
                                            isHalf={true}
                                            value={item?.overall_rating}
                                            size={50}
                                            activeColor="#ffd700"
                                          />
                                        )
                                        }
                                      </div>
                                    </div>
                                    <h4 className="mb-3 common_height_slider">
                                      {
                                        item?.appointmentId?.enquiryId
                                          ?.service
                                      }
                                    </h4>
                                    <p className="comment_text">
                                      {item?.comments
                                        ? item?.comments
                                        : "User not gives any types of comments"}
                                    </p>
                                  </div>
                                </div>
                                <h4 className="mt-4 text-end ">
                                  Read the full review{" "}
                                  <img
                                    src={
                                      require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                        .default
                                    }
                                    alt=""
                                    className="ms-1"
                                  />
                                </h4>
                              </div>
                            );
                          })}
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default TradespersonDetails;
