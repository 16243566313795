import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import logo from "../assets/images/landing-page/expand_right.svg";
const AboutUs = () => {
  return (
    <UserLayout>
      <div className="wrapper aboutUs toothaid-problem_page">
        {/* <!-- banner  start --> */}
        <section className="banner-img ">
          <div className="container-fluid container_inner_width">
            <div className="banner-content">
              <h1 className="mb-0">About Us New</h1>
            </div>
          </div>
        </section>
        {/* <!-- banner  end -->  */}

        {/* <!-- common banner bottom part start --> */}
        <section className="trust_wrapper position-relative home_btm_ques">
          <div className="banner_btm">
            <div className="btm_ques">
              <a href="services" className="book_dentist_link">
                IN A HURRY? Explore our services, instead.{" "}
                <img src={logo} alt="right-arrow" />
              </a>
            </div>
          </div>
        </section>
        {/* <!-- common banner bottom part end --> */}

        {/* <!--Expert Consultations Start--> */}
        <section className="section-divide common-padding expert_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className=" main_section_title">
                  <h2 className="text-center mb-5 mx-width-700">
                    TRADESPEOPLE, ON DEMAND
                  </h2>
                  <p className="text-Left large_parah">
                    Welcome to Smart Choice Traders, your go-to destination for
                    finding top notch tradespeople for all your home improvement
                    needs. We’re dedicated to making the process of hiring
                    skilled professionals easy, efficient, and stress-free.
                  </p>

                  <p className="text-Left large_parah">
                    Our directory boasts an extensive network of highly skilled
                    tradespeople spanning various specialities, including
                    plumbing, electrical work, carpentry, painting, and more. No
                    matter the project, we have the right expert for you.
                  </p>
                  <p className="text-Left large_parah">
                    Our intuitive platform allows you to effortlessly search for
                    tradespeople based on location, and expertise, you can also
                    view their customer ratings. With just a few clicks, you can
                    find the perfect professional to tackle your project.
                  </p>
                  <p className="text-Left large_parah">
                    We understand the importance of trust when it comes to
                    hiring tradespeople. That’s why we thoroughly vet all
                    professionals listed on our platform to ensure they meet our
                    stringent quality standards. You can hire with confidence
                    knowing that you’re getting a top-notch service every time.{" "}
                  </p>
                  <p className="text-Left large_parah">
                    We believe in transparency. That’s why we provide genuine
                    reviews and ratings from past customers, allowing you to
                    make informed decisions when choosing the best tradesperson
                    for your needs. If you are a tradesperson looking to sign
                    up, you will be pleased to know that we have a two-way
                    process, your fellow tradespeople are encouraged to review
                    customers likewise. We don’t just want high quality
                    tradespeople, but we equally want top-notch customers, who
                    pay on time, are courteous and respectful of the job you do.
                    A two-way street here!
                  </p>
                  <p>
                    Tradespeople and potential customers can easily communicate
                    through our platform. Discus project details, request
                    quotes, schedule appointments and make payments with ease,
                    all in one place.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className=" main_section_title">
                  <h2 className="text-center mb-5 mx-width-700">
                    GET STARTED TODAY
                  </h2>
                  <p className="text-Left large_parah">
                    Ready to find the perfect tradesperson for your project?
                    Start browsing our directory now and take the first step
                    towards transforming you home. Whether it’s a small repair
                    job or a major renovation project, we’ve got you covered.
                  </p>

                  <p className="text-Left large_parah">
                    Thank you for choosing Smart Choice Traders for all your
                    home improvement needs. We look forward to helping you bring
                    your vision to the life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </UserLayout>
  );
};

export default AboutUs;
