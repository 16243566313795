import React from 'react'
import UserLayout from '../components/layout/user_layout/UserLayout'

export default function BookingProcess() {
    return (
      <UserLayout>
    <div>
          <div className='container'>
              <div className='booking_process_main'>
                  <div className='b_top'>
                  <h2>BOOK A TRADESPERSON</h2>
                  <p>If you like convenience, you’ll love us.</p>
                  </div>
                  <div className='book_steps'>
                      <div className='step_one'>
                          <h3>STEP 01: BROWSE</h3>
                          <p>Meet your ‘find a tradesman’ app. Tap the service you need, scroll through our skilled tradesmen, and take a look at their proudest work. Our members must update their images each year, meaning everything you see will be up to date – just the way we like it.</p>
                      <img src={require('../assets/images/new-images/down-arrow.png')}/>
                      </div>
                       <div className='step_one'>
                          <h3>STEP 02: BOOK</h3>
                          <p>Found the right tradesperson? They’re now just a click away. Simply pick a timeslot that works for you, book an appointment directly, then put your feet up. You’ll get a knock at the door when it’s time, ready for them to inspect the job, and create a quote.</p>
                      <img src={require('../assets/images/new-images/down-arrow.png')}/>
                      </div>
                       <div className='step_one'>
                          <h3>STEP 03: ACCEPT</h3>
                          <p>They’ll then send a quote directly to you, all on our corner of the internet. It’s always best to shop around, so we’d nudge you to get 3-5 quotes, before accepting the one that works best. From there, you’ll pay a 15-20% deposit, allowing your tradesperson to stock up on the materials you need.</p>
                      <img src={require('../assets/images/new-images/down-arrow.png')}/>
                      </div>
                       <div className='step_one'>
                          <h3>STEP 04: OVERSEE</h3>
                          <p>It’s time. After discussing your ideal date/time, your tradesperson will arrive, armed with everything they need for a job well done. It’s just like the traditional way, only with better quality professionals, agreements in black and white, and the peace of mind that we’re in your corner, whenever, wherever.</p>
                      <img src={require('../assets/images/new-images/down-arrow.png')}/>
                      </div>
                       <div className='step_one'>
                          <h3>STEP 05: REVIEW</h3>
                          <p>We rely on customers like you to keep our quality high. So, whether they landed on your doorstep a few minutes late, or they passed your professionalism test with glowing colours, we want to hear all about it. Remember – they’ll also be reviewing you, so take this as an opportunity to stock up on those killer testimonials.</p>
                      
                      </div>
                      <div className='book_risks'>
                          <h3>WE DON’T LIKE RISKS.</h3>
                          <p>And when it comes to your home, neither should you. That’s why we’ll background check every tradesperson, so you can focus on what counts: Sitting back. There’s a reason we’re the best tradesman website.</p>
                       <a href="#" className="btn btn-primary white-btn">
                  Learn More
                </a>
                      </div>
                      </div>
                  </div>
      </div>
            </div>
            </UserLayout>
  )
}
