import React from 'react'
import { useSelector } from 'react-redux';
import './loader.css';
import { Container, Spinner } from 'react-bootstrap';

const Loader = () => {
    const isLoading = useSelector((state) => state.loaderReducer.loading);
  return (
    <>
       {isLoading &&
      (
        <Container className='loader_style' fluid>
          <Spinner animation='border' />
        </Container>
      )}
    </>
    
  )
}

export default Loader