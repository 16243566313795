import React, { useEffect, useState } from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import { useDispatch } from "react-redux";
import { contactUs } from "../redux/Action/UserActions";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [file, setFile] = useState(null)

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   let data = {
  //     full_name: e.target.full_name.value,
  //     email: e.target.email.value,
  //     message: e.target.message.value,
  //     image: e.target.image.files[0]
  //   }
  //   const formData = new FormData()

  //   Object.entries(data).forEach(([key, value]) =>
  //     formData.append(key, value)
  //   )
  //   try {
  //     dispatch(contactUs(formData))
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   debugger
  // }

  const validate = (values) => {
    const errors = {};
    if (!values.full_name) {
      errors.full_name = "Required*";
    }
    if (!values.email) {
      errors.email = "Required*";
    }
    if (!values.message) {
      errors.message = "Required*";
    }
    // if (!file) {
    //   errors.image = "Required*";
    // }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      message: ""
    },
    validate,
    onSubmit: async (values) => {
      const data = new FormData()
      data.append('full_name', values.full_name)
      data.append('email', values.email)
      data.append('message', values.message)
      data.append('image', file)

      try {
        debugger
        dispatch(contactUs(data));
        navigate('/')
      } catch (error) {
        console.log(error);
      }
    },
  });
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <UserLayout>
        <section class="contact_us_form symptom-checker m-120">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-md-12 d-flex justify-content-center">
                <div class="card card_shadow  card-height-box ">
                  <h2 class="text-center">Contact Us</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Full Name</label>
                      <input
                        type="text"
                        name="full_name"
                        placeholder="Full Name"
                        class="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.full_name}
                      />
                      {formik.errors.full_name && (
                        <p className="formik-error">
                          {formik.errors.full_name}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Email</label>
                      <input
                        name="email"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <p className="formik-error">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>

                    <div class="form-group mb-3 mt-4">
                      <label for="">Message</label>
                      <textarea
                        name="message"
                        placeholder="Message"
                        class="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      ></textarea>
                      {formik.errors.message && (
                        <p className="formik-error">
                          {formik.errors.message}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Attachment</label>
                      <input
                        type="file"
                        name="image"
                        placeholder="Subject"
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          // formik.handleChange
                        }}
                        value={formik.values.image}
                        class="form-control"
                      />
                      {formik.errors.image && (
                        <p className="formik-error">
                          {formik.errors.image}
                        </p>
                      )}
                    </div>
                    <div class="btn_submit mt-4 d-flex justify-content-center">
                      <button
                        htmlType="submit"
                        class="btn btn-primary btn-custom btn-lg w-100"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
    </div>
  );
}
