import { useFormik } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getUserProfile, updateContactPrefsUser } from "../../redux/Action/UserActions";
import { useDispatch, useSelector } from "react-redux";
import RegularPopup from "../regular_popup/RegularPopup";

const Contact = (props,ref) => {
  const [showModal, setShowModal] = useState(false);
    const userDetail = useSelector((state) => state?.useAction?.profile_detail);
    const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getUserProfile());
      }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      news_updates: userDetail?.news_updates || false,
      my_enquires: userDetail?.my_enquires || false,
      appointment_confirmation: userDetail?.appointment_confirmation || false,
      appointment_reminders: userDetail?.appointment_reminders || false,
    },

    onSubmit: async (values) => {
      const value = {
          news_updates: values.news_updates,
          my_enquires: values.my_enquires,
          appointment_confirmation: values.appointment_confirmation,
          appointment_reminders: values.appointment_reminders,
      };
      try {
          dispatch(updateContactPrefsUser(value,setShowModal));
      } catch (error) {
          console.log("error");
      }
    },
  });
  // const handleSubmit=()=>{
  //   formik.handleSubmit()
  //   }
  //   useImperativeHandle(ref, () => ({
  //     handleSubmit
  //   }));
  return (
    <>
     {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"userMyAccount"} />}
    <div className="col-lg-8">
      <form onSubmit={formik.handleSubmit}>
       <div className="text-end  mb-3">
              <button className="btn btn-primary">Save</button>
            </div>
        <div className="new_enquiry_content">
          <div className="form-group mb-3 contact-preference">
            <div className="d-flex justify-content-between align-content-center mb-3">
              <div className="d-flex w-100">
                <h4></h4>
                <div className="d-flex radio_btn radio_btn_label">
                  <p className="w-90">On </p>
                  <p className="w-10">Off</p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-center mb-3">
              <div className="d-flex w-100">
                <h4>News & Updates from Smart Choice Traders</h4>
                <div className="d-flex radio_btn">
                  <p className="w-90">
                    <input
                      type="radio"
                      id="test1"
                      checked={formik.values.news_updates ? true : false}
                      value={formik.values.news_updates}
                      onChange={() =>
                        formik.setFieldValue("news_updates", true)
                      }
                      name={`news_updates`}
                    />
                    <label for="test1"></label>
                  </p>
                  <p className="w-10">
                    <input
                      type="radio"
                      id="test2"
                      checked={
                        formik.values.news_updates == false ? true : false
                      }
                      value={formik.values.news_updates}
                      onChange={() =>
                        formik.setFieldValue("news_updates", false)
                      }
                      name={`news_updates`}
                    />
                    <label for="test2"></label>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-center mb-3">
              <div className="d-flex w-100">
                <h4>Responses to My Enquiries</h4>
                <div className="d-flex radio_btn">
                  <p className="w-90">
                    <input
                      type="radio"
                      id="test3"
                      checked={formik.values.my_enquires ? true : false}
                      value={formik.values.my_enquires}
                      onChange={() => formik.setFieldValue("my_enquires", true)}
                      name={`my_enquires`}
                    />
                    <label for="test3"></label>
                  </p>
                  <p className="w-10">
                    <input
                      type="radio"
                      id="test4"
                      checked={
                        formik.values.my_enquires == false ? true : false
                      }
                      value={formik.values.my_enquires}
                      onChange={() =>
                        formik.setFieldValue("my_enquires", false)
                      }
                      name={`my_enquires`}
                    />
                    <label for="test4"></label>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-center mb-3">
              <div className="d-flex w-100">
                <h4>Appointment Confirmations</h4>
                <div className="d-flex radio_btn">
                  <p className="w-90">
                    <input
                      type="radio"
                      id="test5"
                      checked={
                        formik.values.appointment_confirmation ? true : false
                      }
                      value={formik.values.appointment_confirmation}
                      onChange={() =>
                        formik.setFieldValue("appointment_confirmation", true)
                      }
                      name={`appointment_confirmation`}
                    />
                    <label for="test5"></label>
                  </p>
                  <p className="w-10">
                    <input
                      type="radio"
                      id="test6"
                      checked={
                        formik.values.appointment_confirmation == false
                          ? true
                          : false
                      }
                      value={formik.values.appointment_confirmation}
                      onChange={() =>
                        formik.setFieldValue("appointment_confirmation", false)
                      }
                      name={`appointment_confirmation`}
                    />
                    <label for="test6"></label>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-center">
              <div className="d-flex w-100">
                <h4>Appointment Reminders</h4>
                <div className="d-flex radio_btn">
                  <p className="w-90">
                    <input
                      type="radio"
                      id="test7"
                      checked={
                        formik.values.appointment_reminders ? true : false
                      }
                      value={formik.values.appointment_reminders}
                      onChange={() =>
                        formik.setFieldValue("appointment_reminders", true)
                      }
                      name={`appointment_reminders`}
                    />
                    <label for="test7"></label>
                  </p>
                  <p className="w-10">
                    <input
                      type="radio"
                      id="test8"
                      checked={
                        formik.values.appointment_reminders == false
                          ? true
                          : false
                      }
                      value={formik.values.appointment_reminders}
                      onChange={() =>
                        formik.setFieldValue("appointment_reminders", false)
                      }
                      name={`appointment_reminders`}
                    />
                    <label for="test8"></label>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="text-end pt-35">
              <button className="btn btn-primary">Update</button>
            </div> */}
          </div>
        </div>
      </form>
    </div>
    </>

  );
};

export default forwardRef(Contact);
