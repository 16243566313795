import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWalletInfo, getWalletTrasnsations } from "../../../redux/Action/tradeActions";
import moment from "moment";
import { useState } from "react";
import RegularPopup from "../../regular_popup/RegularPopup";

const Wallet = ({ tradeProfileDetail }) => {
  const [showModal, setShowModal] = useState(false)
  const [totalPending, setTotalPending] = useState()
  const dispatch = useDispatch();
  const walletDetail = useSelector((state) => state?.tradeAction?.walletDetail);
  const availableBalanace = useSelector((state) => state?.tradeAction?.walletInfo?.availableBalance);
  const pendingBalanace = useSelector((state) => state?.tradeAction?.walletInfo?.pendingAmount);
  useEffect(() => {
    dispatch(getWalletTrasnsations(tradeProfileDetail?._id));
    dispatch(getWalletInfo())
  }, []);
  useEffect(() => {
    if (walletDetail) {
      const total = walletDetail.reduce((acc, obj) => {
        return parseFloat(acc) + parseFloat(obj.amount);
      }, 0);
      walletDetail && setTotalPending(total)
    }
  }, [walletDetail])


  return (
    <>
      {showModal == true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={availableBalanace ? "witdrawFunds" : "disableFunds"} />}
      <div className="col-lg-8">
        <div className="row d-flex align-items-center">
          <div className="col-xl-8">
            <div className="availability-funds">
              <ul>
                <li className="balance-bg-blue">
                  <div>
                    <h1 className="blue-text-color">£{availableBalanace ? parseFloat(availableBalanace).toFixed(2) : "0.00"}</h1>
                    <p className="dasb_text_p">Available Balance</p>
                    <p className="blue-text-color">{availableBalanace ? "You can withdraw this now" : "Unavailable"}</p>
                  </div>
                </li>
                <li className="balance-bg-blue">
                  <div>
                    <h1 className="blue-text-color">£{pendingBalanace ? parseFloat(pendingBalanace).toFixed(2) : "0.00"}</h1>
                    <p className="dasb_text_p">Pending Balance</p>

                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="availability-funds-right">
              <div onClick={() => setShowModal(true)} className="withdraw-funds">
                <h4 className="blue-text-color">Withdraw Funds</h4>
                <img
                  src={require("../../../assets/images/download.svg").default}
                  alt=""
                  className="balance-icon-right"
                />
              </div>
            </div>
            {/* <div className="availability-funds-right">
            <div className="withdraw-funds">
              <h4>
                Export Selection
                <img
                  src={require("../../../assets/images/send.svg").default}
                  alt=""
                  className="balance-icon-right"
                />
              </h4>
            </div>
          </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="tbl_user_info transaction-table">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text">Transactions</h4>
                    <div className="date-picker-part">
                      {/* <div className="date-label-input">Date</div>
                    <div className="date-input">
                      <input type="date" />
                    </div> */}
                    </div>
                  </div>
                  <div className="table-responsive height-fix-table">
                    <table className="table  vehicles_table  w-100 ">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Date</th>
                          <th>Client</th>
                          <th>Service</th>
                          <th>Status</th>
                          <th>Withdraw</th>
                          <th>Amount</th>
                        </tr>
                      </thead>

                      <tbody className="td_color">
                        {
                          walletDetail?.length > 0 ?
                            walletDetail?.map((item, i) => {
                              return <tr key={i}>
                                <td>{i + 1}</td>
                                <td> {moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                <td>{item?.customer_id?.firstName + " " + item?.customer_id?.lastName}</td>
                                <td>{item?.enquiryId?.service ? item?.enquiryId?.service : "N/A"}</td>
                                <td>{item?.payment_status?.charAt(0).toUpperCase() + item?.payment_status?.slice(1)}</td>
                                <td>{item?.withdrawl_status == 0 ? "Pending" : "Withdrawn"}</td>
                                <td>{parseFloat(item?.amount).toFixed(2)}</td>
                              </tr>
                            }) : <td colSpan="6"><p className="no_content_table">No Transactions</p></td>
                        }

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
