import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./maping.css";
import ReactStars from "react-rating-stars-component";

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

const MyMarker = ({ text, tooltip, $hover, child, star }) => {
  const handleClick = () => {
    console.log(`You clicked on ${tooltip}`);
  };

  return (
    <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
      <div className="profile_img">
        <div className="profile_img_inner">
          {
            <img
              src={
                tooltip?.business_image ?
                  (process.env.REACT_APP_FILE_BASE_URL +
                    "business_image/" +
                    tooltip?.business_image) : require("../../../assets/images/placeholder(1).png")
              }
              className="loc_img"
            />
          }
        </div>
      </div>

      <span className="circleText">
        <div class="trade_modal_inner">
          {
            tooltip?.badge == 1 ? <img className="badge-img" src={require("../../../assets/images/platinum_rated.png")} /> :
              tooltip?.badge == 2 ? <img className="badge-img" src={require("../../../assets/images/diamond_rated.png")} /> :
                tooltip?.badge == 3 && <img className="badge-img" src={require("../../../assets/images/gold_rated.png")} />

          }
          {tooltip?.business_image ? (
            <div className="cent">
              <img className={tooltip?.badge ? "user-badge-img" : "without-badge center-class"}
                src={
                  process.env.REACT_APP_FILE_BASE_URL +
                  "business_image/" +
                  tooltip?.business_image
                }
                alt=""
              />
            </div>
          ) : (
            <div className="cent">
              <img className={tooltip?.badge ? "user-badge-img" : 'without-badge center-class'}
                src={require("../../../assets/images/placeholder(1).png")}
                alt=""
              />
            </div>
          )}
          {/* <img
            src={
                tooltip?.business_image?
             ( process.env.REACT_APP_FILE_BASE_URL +
              "business_image/" +
              tooltip?.business_image):require("../../../assets/images/placeholder(1).png")
            }
            alt=""
            class="img-fluid"
          /> */}
          <div class="tradeperson_verified-content">
            <div class="d-flex justify-content-between">
              <h3>{tooltip?.company_name}</h3>
              <img
                src={
                  require("../../../assets/images/tradesperson/verified_tick.svg")
                    .default
                }
                alt=""
              />
            </div>
            <p>{child?.address}</p>
            <div class="review_sect">
              <div class="review_ratings">
                {star == true && (
                  <ReactStars
                    count={5}
                    edit={false}
                    isHalf={true}
                    value={tooltip?.overall_rating}
                    size={20}
                    activeColor="#ffd700"
                  />
                )}
              </div>
              <p class="common_parah ms-3">
                {tooltip?.overall_rating
                  ? "(" + tooltip?.overall_rating + " " + "Reviews" + ")"
                  : "(" + "0" + " " + "Reviews" + ")"}
              </p>
            </div>
          </div>
          <div className="profile-btn-blue">
            <a href="#">
              <button type="button" class="btn btn-primary dark_btn">
                View Profile
              </button>
            </a>
          </div>
        </div>
      </span>
    </div>
  );
};

function Maping(props) {
  const { allTrade } = props;

  const [latestData, setLatestData] = useState();

  function displayMarkers() {
    return (
      latestData?.length > 0 &&
      latestData?.map((store, index) => {
        return store?.business_locations?.map((item, i) => {
          return (
            item?.latitude != null && (
              <MyMarker
                key={index}
                lat={item?.latitude}
                lng={item?.longitude}
                text={index}
                tooltip={store}
                star={props?.star}
                child={store?.business_locations}
              />
            )
          );
        });
      })
    );
  }
  useEffect(() => {
    setLatestData(allTrade);
  }, [allTrade]);

  console.log(latestData, "latestData-latestData");

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    const arrMarkers = [];

    latestData?.length > 0 &&
      latestData?.forEach((store, index) => {
        store?.business_locations?.forEach((item, i) => {
          arrMarkers?.push({ lat: item?.latitude, lng: item?.longitude });
        });
      });

    arrMarkers?.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <div className="parent_map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCHgfz8mcz1rEGaDS8meAs-Pu1ucGNEwRk",
          language: "en",
          region: "US",
        }}
        center={{
          lat: latestData?.length > 0 && latestData[0]?.latitude,
          lng: latestData?.length > 0 && latestData[0]?.longitude,
        }}
        defaultZoom={8}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        distanceToMouse={distanceToMouse}
      >
        {displayMarkers()}
      </GoogleMapReact>
    </div>
  );
}

export default Maping;
