import axios from "axios";
import { useFormik } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";
import { updateUserAddress } from "../../redux/Action/UserActions";
import { useDispatch } from "react-redux";
import RegularPopup from "../regular_popup/RegularPopup";

const Address = (props,ref) => {
  const [postCode, setPostCode] = useState("");
  const [adressValue, setAdressValue] = useState("");
  const [addressApi, setAddressApi] = useState();
  const [postAddress, setPostAddress] = useState();
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const postCodeClick = async (e) => {
    try {
      e.preventDefault();
      const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          setAddressApi(res.data);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (addressApi) {
      const data = addressApi?.addresses?.map((item) => {
        return item;
      });
      setPostAddress(data);
    }
  }, [addressApi]);
  useEffect(() => {
    if (props?.userDetail) {
      setPostCode(props?.userDetail?.postcode);
      setAdressValue(props?.userDetail?.address);
      setAddress(props?.userDetail?.address.split(",")[0]);
      setAddress2(props?.userDetail?.address.split(",")[1]);
      setCity(props?.userDetail?.address.split(",")[2]);
      setCountry(props?.userDetail?.address.split(",")[3]);
    }
  }, [props?.userDetail]);

  useEffect(() => {
    if (adressValue) {
      setAddress(adressValue?.split(",")[0]);
      setAddress2(adressValue?.split(",")[1]);
      setCity(adressValue?.split(",")[2]);
      setCountry(adressValue?.split(",")[3]);
    }
  }, [adressValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataVal = {
      postcode: postCode,
      address: adressValue,
      address_1: address,
      address_2: address2,
      city: city,
      country: country,
      latitude: addressApi?.latitude,
      longitude: addressApi?.longitude,
    };

    dispatch(updateUserAddress(dataVal,setShowModal));
  };
  // useImperativeHandle(ref, () => ({
  //   handleSubmit
  // }));
  return (
    <>
     {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"userMyAccount"} />}
    <div className="col-lg-8">
      <form onSubmit={handleSubmit} className="form_new">
      <div className="text-end">
            <button className="btn btn-primary">Save</button>
          </div>
        <div className="new_enquiry_content">
          <div className="form-group mb-3">
            <label for="">Postcode</label>
            <div className="row">
              <div className="col-lg-6 col-md-8 col-8">
                <input
                  type="search"
                  className="form-control box_shadow_new"
                  placeholder="Type Here"
                  name="postcode"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
              <div className=" col-lg-2 col-md-4 col-4">
                <input
                  type="submit"
                  value="Search"
                  className="form-control search_btn"
                  onClick={(e) => postCodeClick(e)}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label for="">Select Address</label>
            <select
              className="form-control box_shadow_new"
              onChange={(e) => setAdressValue(e.target.value)}
            >
              <option value="none" selected="selected" disabled hidden>
                {adressValue ? adressValue : " Please Select Address"}
              </option>
              {postAddress &&
                postAddress?.map((item) => {
                  const addressData = item.formatted_address.filter(
                    (str) => str !== ""
                  );
                  const arr = [
                    item?.line_1,
                    item?.line_2,
                    item?.town_or_city,
                    item?.country,
                  ];
                  const address_str = arr[0].concat(
                    arr[1] === undefined ? " " : ", ",
                    arr[1] === undefined ? "" : arr[1],
                    arr[2] === undefined ? "" : ", ",
                    arr[2] === undefined ? "" : arr[2],
                    arr[3] === undefined ? "" : ", ",
                    arr[3] === undefined ? "" : arr[3]
                  );
                  return <option value={address_str}>{address_str}</option>;
                })}
            </select>
            {/* <select name="" id="" className="form-control box_shadow_new">
              <option value="">Select From List</option>
              <option value="">Select From List</option>
            </select> */}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label for="">Address Line 1</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here.."
                  name="address_1"
                  value={address}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label for="">Address Line 2</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here.."
                  name="address_2"
                  value={address2}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label for="">City</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here.."
                  name="city"
                  value={city}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label for="">Country</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here.."
                  name="country"
                  value={country}
                />
              </div>
            </div>
          </div>

          {/* <div className="form-group mb-3">
            <label for="">First Name</label>
            <input
              type="text"
              className="form-control box_shadow_new"
              placeholder="Type Here"
            />
          </div>
          <div className="form-group mb-3">
            <label for="">Surname</label>
            <input
              type="text"
              className="form-control box_shadow_new"
              placeholder="Type Here"
            />
          </div> */}
          {/* <div className="text-end pt-35">
            <button className="btn btn-primary">Update</button>
          </div> */}
        </div>
      </form>
    </div>
    </>

  );
};

export default forwardRef(Address);
