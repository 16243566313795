import React, { useEffect, useState } from "react";
// import logo from "../../../assets/images/logo_two.svg";
import logo from "../../../assets/images/logo2.png";
import profileLogo from "../../../assets/images/profile.svg";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getUserProfile } from "../../../redux/Action/UserActions";
import { useDispatch, useSelector } from "react-redux";
const Header = () => {
  const [menuClick, setMenuClick] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state?.useAction?.profile_detail);
  const HandleLogout = () => {
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("device_token");
    sessionStorage.removeItem("enquiryId");
    sessionStorage.removeItem("userId");
    cookies.remove("userToken", { path: "/" });
    navigate("/");
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <header className="light_header">
      <div className="container-fluid p-100">
        {/* <!-- Navbar --> */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" className="img-fluid" />
          </Link>
          <button
            className={
              menuClick ? "navbar-toggler" : "navbar-toggler collapsed"
            }
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={menuClick ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setMenuClick((pre) => !pre)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              menuClick
                ? "collapse navbar-collapsing navbar-collapse flex-column show"
                : "collapse navbar-collapse flex-column"
            }
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/dashboard">
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <ul className="inner_header">
                  <li className="nav-item">
                    <Link className="nav-link" to="/find-a-tradeperson">
                      Find a Tradesperson
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/my-enquiries">
                      My Enquiries
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/appointments">
                      My Appointments
                    </Link>
                  </li>
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="profile_login nav-item"
                      >
                        {userDetail?.profile_image &&
                        userDetail?.profile_image != "undefined" ? (
                          <img
                            src={
                              BACKEND_URL +
                              "profile_image/" +
                              userDetail?.profile_image
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            alt=""
                          />
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate("/my-account")}>
                          View profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => HandleLogout()}>
                          Log out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <a className="profile_login nav-item" href="my-account.html">
                                        <img src={profileLogo} alt="profile" />
                                    </a> */}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Navbar / End --> */}
      </div>
    </header>
  );
};

export default Header;
