import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
const EnquiryModal = ({ show, setShow, company }) => {
  const handleClose = () => setShow(false);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="rating_modal_inner success_popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>O</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}

        <div className="row">
          <div className="col-lg-12">
            <div className="successModal_inner">
              <img
                src="images/tradesperson/check_img.svg"
                alt=""
                className="text-end"
              />
              <h2>Success!</h2>
              <h3>
                We’ve sent your enquiry to {company} You should here
                back from them within 24 hours
              </h3>
              <div className="d-flex justify-content-center successmodal_btn">
                <Link to="/find-a-tradeperson" className="btn white_btn">
                  View more Traders
                </Link>
                <Link to="/my-enquiries" className="btn white_btn ms-3">
                  Go to my Enquiries
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EnquiryModal;
