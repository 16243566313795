import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userRegister } from "../redux/Action/UserLoginAction";
import { toast } from "react-toastify";
import { InputGroup } from "react-bootstrap";
import { honorifics } from "../utils/honorifics";

const SignUp = () => {
  const [postCode, setPostCode] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [adressValue, setAdressValue] = useState("");
  const [addressApi, setAddressApi] = useState();
  const [postAddress, setPostAddress] = useState();
  const [termsConditions, setTermsConditions] = useState(false);
  const [acceptMailList, setAcceptMailList] = useState(false);
  const [validateValue, setValidateValue] = useState(false);
  console.log(validateValue, "validateValue");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postCodeClick = async (e) => {
    try {
      e.preventDefault();
      const url = `https://api.getaddress.io/autocomplete/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      // const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          if (!res.data.suggestions.length) {
            setPostAddress([]);
            return toast.warning('No address found')
          }
          setAddressApi(res.data.suggestions);
          // setAddressApi(res.data);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (addressApi) {
      const data = addressApi?.map((item) => {
        return item;
      });
      setPostAddress(data);
    }
  }, [addressApi]);
  
  const validate = (values) => {
    console.log(values.firstName, "values");
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Enter first name";
    }
    // else if (values.firstName.split(" ").length > 1) {
    //   errors.firstName = "Space not Allowed";
    // }
    // else if (!/^(?=.*[a-zA-Z])[A-Za-z0-9]+$/.test(values.firstName)) {
    //   errors.firstName = "Number not allowed";
    // }
    if (!values.lastName) {
      errors.lastName = "Enter surname ";
    }
    //  else if (values.lastName.split(" ").length > 1) {
    //   errors.lastName = "Space not allowed";
    // }
    //  else if (!/^(?=.*[a-zA-Z])[A-Za-z0-9]+$/.test(values.lastName)) {
    //   errors.lastName = "Number not allow";
    // }
    if (!values.email) {
      errors.email = "Enter email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Enter correct email";
    }
    if (!values.phone_no) {
      errors.phone_no = "Required";
    } else if (
      values.phone_no.toString().length > 16 ||
      values.phone_no.toString().length < 10
    ) {
      errors.phone_no = "Invalid phone number";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8 || values.password.length > 10) {
      errors.password = "Invalid Password";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone_no: "",
      password: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      const value = {
        honorific: values.honorific,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        postcode: postCode,
        address: adressValue,
        latitude: addressApi?.latitude,
        longitude: addressApi?.longitude,
        phone_no: values.phone_no,
        term_condition: termsConditions == true ? 1 : 0,
        mail_list: acceptMailList == true ? 1 : 0,
        password: values?.password,
      };
      if (!postCode) {
        toast.warning("Please provide postcode");
      }
      if (termsConditions == false) {
        toast.warning("Please accept terms and conditions");
      } else if (!adressValue) {
        toast.warning("Please provide address");
      } else {
        try {
          dispatch(userRegister(value, navigate));
        } catch (error) {
          console.log("error");
        }
      }
    },
  });
  return (
    <Layout>
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div className="login_desc  pb-5">
                  <h4>WE’LL PUT YOU IN FRONT OF MORE PEOPLE.</h4>
                  <p className="mt-3">
                    It’s simple – people come to us to search for traders like
                    you. It’s what we’re here for, putting your services within
                    clicking distance of the right people, taking your marketing
                    bill down to 0 as we go. Or in simple terms, we’re here to
                    help your business go further.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in signup">
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h3>Sign Up</h3>
                          <div className="form-group mb-3 mt-4">
                            <label for="">First Name</label>
                            <InputGroup className="mb-3 box-shadow-select">
                              {/* <select className="country-ui-c"
                                //placeholder="tilte"
                                  variant="outline-secondary"
                                  title="Dropdown"
                                  id="input-group-dropdown-1"
                                  name="honorific"
                                  onChange={formik.handleChange}
                                  value={formik.values.honorific}
                                >
                                  <option>Title</option>
                                 {honorifics?.map((item,i)=>{
                                  return (
                                    <><option value={item}>{item}</option></>
                                  )
                                 })}
                                  
                                </select> */}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type Here.."
                                name="firstName"
                                onChange={formik.handleChange}
                                value={formik.values.firstName.trimStart()}
                              />
                              {formik.errors.firstName && (
                                <p className="formik-error">
                                  {formik.errors.firstName}
                                </p>
                              )}
                            </InputGroup>
                          </div>
                          <div className="form-group mb-3 mt-4">
                            <label for="">Surname</label>
                            <input
                              type="text"
                              className="form-control login_form_control"
                              placeholder="Enter your surname"
                              name="lastName"
                              onChange={formik.handleChange}
                              value={formik.values.lastName.trimStart()}
                            />
                            {formik.errors.lastName && (
                              <p className="formik-error">
                                {formik.errors.lastName}
                              </p>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label for="">Postcode</label>
                            <div className="input-group d-flex justify-content-between">
                              <div className="w-70">
                                <input
                                  type="text"
                                  className="form-control br-none login_form_control "
                                  id=""
                                  placeholder="Enter here"
                                  name="postcode"
                                  onChange={(e) => setPostCode(e.target.value)}
                                />
                              </div>
                              <div className="w-30 submitBtn">
                                <button
                                  onClick={(e) => postCodeClick(e)}
                                  className="btn btn-primary"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 mt-4">
                            <select
                              className="form-control login_form_control"
                              onChange={(e) => setAdressValue(e.target.value)}
                            >
                              <option
                                value="none"
                                selected="selected"
                                disabled
                                hidden
                              >
                                Please Select Address
                              </option>
                              {postAddress &&
                                postAddress?.map((item) => {
                                  // const addressData =
                                  //   item.formatted_address.filter(
                                  //     (str) => str !== ""
                                  //   );
                                  // const arr = [
                                  //   item?.line_1,
                                  //   item?.line_2,
                                  //   item?.town_or_city,
                                  //   item?.country,
                                  // ];
                                  // const address_str = arr[0].concat(
                                  //   arr[1] === undefined ? "" : ", ",
                                  //   arr[1] === undefined ? "" : arr[1],
                                  //   arr[2] === undefined ? "" : ", ",
                                  //   arr[2] === undefined ? "" : arr[2],
                                  //   arr[3] === undefined ? "" : ", ",
                                  //   arr[3] === undefined ? "" : arr[3]
                                  // );
                                  return (
                                    <option value={item.address}>
                                      {item.address}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group mb-3 mt-4">
                            <label for="">Email Address</label>
                            <input
                              type="email"
                              className="form-control login_form_control"
                              placeholder="Enter your email address"
                              name="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.errors.email && (
                              <p className="formik-error">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>

                          <div className="form-group mb-3 mt-4">
                            <label for="">Phone Number</label>
                            <div class="d-flex align-items-center login_form_control  ">
                              <div class="country_code">
                                <p>+44</p>
                              </div>
                              <div class="phn-form-control_width">
                                <input
                                  type="number"
                                  className="form-control login_form_control"
                                  placeholder="Type Here.."
                                  name="phone_no"
                                  onChange={formik.handleChange}
                                  value={formik.values.phone_no}
                                />
                              </div>
                            </div>
                            {formik.errors.phone_no && (
                              <p className="formik-error">
                                {formik.errors.phone_no}
                              </p>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label for="">Password</label>
                            <div className="input-group">
                              <input
                                type={showPass ? "text" : "password"}
                                className="form-control br-none login_form_control"
                                id=""
                                placeholder="Type Here.."
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                              />
                              <div className="input-group-prepend">
                                <span
                                  onClick={() => setShowPass((pre) => !pre)}
                                  className="input-group-text bl-none "
                                  id="basic-addon1"
                                >
                                  <i
                                    className={
                                      showPass ? "fa fa-eye-slash" : "fa fa-eye"
                                    }
                                    id="eye"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            {formik.errors.password && (
                              <p className="formik-error">
                                {formik.errors.password}
                              </p>
                            )}
                          </div>
                          {/* <div className="form-group mb-3 mt-4">
                            <label for="">Password</label>
                            <input
                              type="number"
                              className="form-control login_form_control"
                              placeholder="Password"
                              name="password"
                              onChange={formik.handleChange}
                              value={formik.values.password} 
                            />
                            {formik.errors.password && (
                              <p className="formik-error">
                                {formik.errors.password}
                              </p>
                            )}
                          </div> */}
                          <div className="form-group mb-3 mt-4 checkbox_opt">
                            <div className="d-flex align-items-center mb-3">
                              <input
                                type="checkbox"
                                name="checkbox"
                                value=""
                                onChange={(e) =>
                                  setTermsConditions(e.target.checked)
                                }
                              />
                              <label for="">
                                I have read and accept the{" "}
                                <a
                                  href="/TermsConditionsCustomer"
                                  target="_blank"
                                >
                                  <b>Terms and Conditions</b>
                                </a>{" "}
                                and{" "}
                                <a href="/PrivacyPolicy" target="_blank">
                                  <b>Privacy Policy</b>
                                </a>
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setAcceptMailList(e.target.checked)
                                }
                              />
                              <label for="">
                                I would like to receive offers and updates from
                                Smart Choice Traders
                              </label>
                            </div>
                          </div>
                          <div className="btn_submit mt-4 d-flex">
                            <button
                              onClick={() => setValidateValue(true)}
                              className="btn btn-primary btn-custom btn-lg w-100"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>
                          <div className="regist_tody d-flex justify-content-center align-items-center register_today_link">
                            <Link to="/" className="text-center">
                              Already a member? Sign in
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SignUp;
