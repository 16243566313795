import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
const TradePrivateRoute = () => {
    const tradeToken=window.sessionStorage.getItem("tradeToken")
    if(tradeToken){
        return <Outlet />
    }else{
        return <Navigate to={"/"} />
    }
}

export default TradePrivateRoute