import React from "react";
import Header from "./Header";

const UserAccLayout = ({ children }) => {
  return (
    <div id="wrapper">
      <Header />
      {children}
    </div>
  );
};

export default UserAccLayout;
