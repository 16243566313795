import React from "react";

const EnquiryDetail = ({ setDetailShowHide, enquiryDetail }) => {
  return (
    <div className="mb-4 mt-4 enquiry_detail_card box_shadow_new">
      <h3>Enquiry Details</h3>
      <div className="enquiry_detail_card_inner">
        <ul className="service_content">
          <li>
            <p className="category_name">Service:</p>
            <p className="category_info">{enquiryDetail?.serviceId?.service}</p>
          </li>
          <li className="pb-4">
            <p className="category_name">Category:</p>
            <p className="category_info">
              {enquiryDetail?.serviceId?.category_id?.category}
            </p>
          </li>
          <li>
            <p className="small_border"></p>
          </li>
          <li className="pt-4">
            <p className="category_name">Message:</p>
            <p className="category_info text-message">{enquiryDetail?.description}</p> 
          </li>
        </ul>
        <div className="text-end">
          <a onClick={() => setDetailShowHide(false)} className="close_btn">
            Close
          </a>
        </div>
      </div>
    </div>
  );
};

export default EnquiryDetail;
