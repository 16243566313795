import { useRef } from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createSetAvailability, getAvailablityHours, updateSetAvailability } from "../../../redux/Action/tradeActions";
import RegularPopup from "../../regular_popup/RegularPopup";
import { FieldArray, Form, Formik } from "formik";
import { openingHoursData } from "../../../utils/rawData";
import useTimePicker from "../../../Hooks/timePicker";
import { toast } from "react-toastify";



let data = [
  { day: "Monday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 1 },
  { day: "Tuesday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 1 },
  { day: "Wednesday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 1 },
  { day: "Thursday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 1 },
  { day: "Friday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 1 },
  { day: "Saturday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 0 },
  { day: "Sunday", morning_start: "08:00 am", morning_end: "09:00 pm", evening_start: "08:00 am", evening_end: "08:00 pm", open_close: 0 },
];
const SetAvailbility = () => {
  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);
  var today = new Date().toISOString().split("T")[0];
  const [regularTime, setRegularTime] = useState();
  const { timeRanges } = useTimePicker();
  const dispatch = useDispatch();
  const openHours = useSelector((state) => state?.tradeAction?.availHours);
  console.log(openHours, "openHours");
  useEffect(() => {
    dispatch(getAvailablityHours());
  }, []);


console.log(openHours,"openHours")


  return (

    <div className="col-8">
      {showModal == true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"tradeSetAvail"} />}
      {/* Table row starts */}
      <div className="set-availibilty">
        <div className="container">
          <div className="row regular_hours">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body">



                    <Formik
                      // enableReinitialize: true
                      innerRef={formRef}
                      initialValues={{
                        opening_hours: openHours?.opening_hours || openingHoursData,
                      }}
                      onSubmit={(values) => {
                        console.log(values, "formik")
                        if (openHours?.opening_hours) {
                          dispatch(updateSetAvailability(values, setShowModal));
                        } else {
                          dispatch(createSetAvailability(values, setShowModal));
                        }

                      }}
                      render={({ values, handleChange, setFieldValue }) => (
                        <Form  >
                          <FieldArray
                            name="opening_hours"
                            render={(arrayHelpers) => (
                              <>
                                <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                                  <h4 className="title_text">Regular Hours</h4>{" "}
                                  <button
                                  type="submit"
                                    className="btn btn-primary"
                                  >
                                    Update
                                  </button>
                                  
                                </div>

                                <div className=" table-defaut-design">
                                  <h2>Business Hours</h2>
                                  <div className="row">
                                    {

                                      values?.opening_hours?.map((item, itemIndex) => {
                                        return (
                                          <>
                                            <FieldArray name={`opening_hours.${itemIndex}.arr`}>
                                              {subArrayHelpers => (

                                                <div index={itemIndex} className="col-lg-12">
                                                  <div className="buisness_form_fields">
                                                    <p>{item?.day}</p>
                                                    {
                                                      item?.day == "Saturday" || item?.day == "Sunday" ? <>
                                                        {
                                                          item?.arr?.map((data, i) => {
                                                            return (
                                                              <>
                                                                {i != 0 && <p></p>}
                                                                <input
                                                                  type="text"
                                                                  placeholder="Unavailable"
                                                                  name={`opening_hours.${itemIndex}.arr.${i}.reason`}
                                                                  value={
                                                                    values.opening_hours[itemIndex].arr[i].reason
                                                                  }
                                                                  onChange={handleChange}
                                                                />
                                                                <div className="edit_btns">
                                                                  {i != 0 && <button type="button"><img onClick={() =>
                                                                    subArrayHelpers.remove(i)
                                                                  } src={require('../../../assets/images/tradesperson/deletep.png')} alt='' /></button>}

                                                                  <button>
                                                                    <img onClick={() =>
                                                                      subArrayHelpers.push({
                                                                        reason: ""
                                                                      })
                                                                    } src={require('../../../assets/images/tradesperson/plusp.png')} alt='' />
                                                                  </button>  </div>
                                                              </>
                                                            )
                                                          })
                                                        }
                                                      </>
                                                        :
                                                        item?.arr?.map((data, i) => {

                                                          return (
                                                            <>
                                                              {i != 0 && <p></p>}
                                                              {
                                                                data?.reason ? <>
                                                                  <input
                                                                    type="text"
                                                                    placeholder="Unavailable"
                                                                    name={`opening_hours.${itemIndex}.arr.${i}.reason`}
                                                                    value={
                                                                      values.opening_hours[itemIndex].arr[i].reason
                                                                    }
                                                                    onChange={handleChange}
                                                                  />
                                                                  <div className="edit_btns">
                                                                    {i != 0 && <button type="button"><img onClick={() =>
                                                                      subArrayHelpers.remove(i)
                                                                    } src={require('../../../assets/images/tradesperson/deletep.png')} alt='' /></button>}


                                                                    <img onClick={() => {
                                                                      subArrayHelpers.remove(0)
                                                                      subArrayHelpers.push({
                                                                        start: '08:00 am', end: '09:00 pm'
                                                                      })
                                                                    }
                                                                    } src={require('../../../assets/images/tradesperson/plusp.png')} alt='' />
                                                                  </div>
                                                                </> : <>
                                                                  <select
                                                                    key={i}
                                                                    class="form-select add_width"
                                                                    aria-label="Default select example"
                                                                    name={`opening_hours.${itemIndex}.arr.${i}.start`}
                                                                    value={
                                                                      values.opening_hours[itemIndex].arr[i].start
                                                                    }
                                                                    onChange={handleChange}
                                                                  >
                                                                    {timeRanges?.map((time) => (
                                                                      <option value={time}>{time}</option>
                                                                    ))}
                                                                  </select>
                                                                  <select
                                                                    class="form-select add_width"
                                                                    aria-label="Default select example"
                                                                    name={`opening_hours.${itemIndex}.arr.${i}.end`}
                                                                    value={
                                                                      values.opening_hours[itemIndex].arr[i].end
                                                                    }
                                                                    onChange={handleChange}
                                                                  >
                                                                    {timeRanges?.map((time) => (
                                                                      <option value={time}>{time}</option>
                                                                    ))}
                                                                  </select>
                                                                  <div className="edit_btns">
                                                                    {<button type="button"><img onClick={() => {
                                                                      subArrayHelpers.remove(i);
                                                                      if (item?.arr?.length < 2) {
                                                                        subArrayHelpers.push({
                                                                          reason: "Unavailable"
                                                                        })
                                                                      }

                                                                    }
                                                                    } src={require('../../../assets/images/tradesperson/deletep.png')} alt='' /></button>}

                                                                    <button type="button"><img onClick={() =>
                                                                      subArrayHelpers.push({
                                                                        start: '08:00 am', end: '09:00 pm'
                                                                      })
                                                                    } src={require('../../../assets/images/tradesperson/plusp.png')} alt='' /></button>
                                                                  </div>
                                                                </>
                                                              }


                                                            </>
                                                          )
                                                        })
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                            </FieldArray>
                                          </>
                                        )
                                      })
                                    }

                                    {/* <div className="submit-btn">
                                      <button type="submit" class="btn mb-3">{"Create"}</button>
                                    </div> */}
                                  </div>
                                </div>


                              </>
                            )}
                          />
                        </Form>
                      )}
                      enableReinitialize={true}

                    />







                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Table row End */}
    </div>
  );
};
export default SetAvailbility;
