import UserLayout from "../components/layout/user_layout/UserLayout"

const BlogViewDetails = () => {
    return (
        <UserLayout>
            <section className="blog-detail-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="blog-left-page">
                                <h2 className="mb-3 blog-detail-head">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.     </h2>
                                <img
                                    src={require("../assets/images/landing-page/service-img-9.png")}
                                    className="img-fluid"
                                    alt="Electrical Problems"
                                />
                                <div className="">

                                    <h4 className="mt-2 mb-2">12th Oct,2023</h4>
                                </div>
                                <p className="mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                                    viverra mi et diam cursus consectetur. Etiam ac congue nunc.
                                    Aenean id dignissim nulla. Vivamus suscipit iaculis turpis, vel
                                    ornare diam. Mauris blandit dapibus arcu, in ultricies mauris
                                    molestie non. Integer tincidunt tellus quis dolor sagittis, in
                                    hendrerit ligula egestas.
                                </p>
                                <p className="mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                                    viverra mi et diam cursus consectetur. Etiam ac congue nunc.
                                    Aenean id dignissim nulla. Vivamus suscipit iaculis turpis, vel
                                    ornare diam. Mauris blandit dapibus arcu, in ultricies mauris
                                    molestie non. Integer tincidunt tellus quis dolor sagittis, in
                                    hendrerit ligula egestas.
                                </p>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                    <li>Lorem ipsum dolor sit amet,</li>
                                </ul>
                                <p className="mb-3">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                                    viverra mi et diam cursus consectetur. Etiam ac congue nunc.
                                    Aenean id dignissim nulla. Vivamus suscipit iaculis turpis, vel
                                    ornare diam. Mauris blandit dapibus arcu, in ultricies mauris
                                    molestie non. Integer tincidunt tellus quis dolor sagittis, in
                                    hendrerit ligula egestas.
                                </p>

                                <h2 className="mt-3 mb-3">Conclusion</h2>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                                    viverra mi et diam cursus consectetur. Etiam ac congue nunc.
                                    Aenean id dignissim nulla. Vivamus suscipit iaculis turpis, vel
                                    ornare diam. Mauris blandit dapibus arcu, in ultricies mauris
                                    molestie non. Integer tincidunt tellus quis dolor sagittis, in
                                    hendrerit ligula egestas.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </UserLayout>
    )
}
export default BlogViewDetails