import TradeReviewDetail from "./TradeReviewDetail";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAppointments,
  getTraderReviews,
} from "../../../redux/Action/tradeActions";
import moment from "moment";
import SeeReviewTrader from "./SeeReviewTrader";
const Reviews = (props, ref) => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showReview, setShowReview] = useState(false);
  const [seeDetail, setSeeDetail] = useState(false);
  const [reviewId,setReviewId]=useState()
  const [aptId, setaptId] = useState({
    userId: "",
    tradeId: "",
    appointmentId: "",
  });
  const dispatch = useDispatch();
  const traderAppointment = useSelector(
    (state) => state.tradeAction.trader_appointment
  );
  const traderReviews = useSelector(
    (state) => state?.tradeAction?.trader_reviews
  );

  useEffect(() => {
    dispatch(getAllAppointments());
    dispatch(getTraderReviews());
  }, [showReview]);
 
  const pendingApointment = traderAppointment?.filter((apt) => {
    return apt?.trade_review_status === 0;
  });
  const handleSubmit = () => {
    console.log("Reviews");
  };
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  return (
    <>
      {/* <div className="col-lg-8">
              <div className="enquiry_sect_main enquiry_main">
                    <p className='under_development_class'>
                      Under Development
                    </p>
            </div>
          </div> */}
      {showReview == false && seeDetail == false && (
        <div className="col-lg-8">
          <div className=" review-detail-sect">
            <div className="pending_reviews mb-5">
              <h4 className="mb-2">Pending Reviews</h4>
              <div className={pendingApointment?.length > 2 ? "overflow_scroll" : ""}>
                <div className="row">
                  {pendingApointment?.length > 0?
                    pendingApointment?.map((item, i) => {
                      return (
                        <div className="col-lg-6">
                          <a
                            onClick={() => {
                              setShowReview(true);
                              setaptId({
                                ...aptId,
                                userId: item?.userId?._id,
                                tradeId: item?.tradeId?._id,
                                appointmentId: item?._id,
                              });
                            }}
                          >
                            <div className="recent_reviews box_shadow mb-4">
                              <div className="review_detail">
                                <div className="review_detail_left">
                                  <img
                                    src={
                                      item?.userId?.profile_image && item?.userId?.profile_image != "undefined"
                                        ? BACKEND_URL +
                                          "profile_image/" +
                                          item?.userId?.profile_image
                                        : require("../../../assets/images/tradesperson/review-profile.png")
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="review_detail_right">
                                  <h3 className="revewier_name">{item?.userId?.firstName+item?.userId?.lastName}</h3>
                                  <div className="review_sect mt-2 mb-2">
                                    <div className="review_ratings_box">
                                      <ReactStars
                                        count={5}
                                        edit={false}
                                        isHalf={true}
                                        value={item?.reviewId?.overall_rating}
                                        size={38}
                                        activeColor="#ffd700"
                                      />
                                    </div>
                                  </div>

                                  <p>Your Review is Pending</p>
                                </div>
                              </div>
                              <div className="d-flex mt-4 justify-content-end cursor-pointer">
                                <h4>
                                  Review Now
                                  <img
                                    src={
                                      require("../../../assets/images/tradesperson/right-blue-arrow.svg")
                                        .default
                                    }
                                    alt=""
                                    className="ms-1"
                                  />
                                </h4>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    }):<div className="no_reviews">No Pending Reviews yet</div>
                  }
                </div>
              </div>
            </div>
            <div className="approved_review">
              <h4 className="mb-2">Your Reviews</h4>

              <div className={pendingApointment?.length > 2 ? "overflow_scroll" : ""}>
              <div className="row">
                {traderReviews?.length > 0 ? (
                  traderReviews?.map((item, i) => {
                    return (
                      <div className="col-lg-6">
                        <div className="recent_reviews box_shadow mb-4">
                          <div className="review_detail">
                            <div className="review_detail_left">
                              <img
                                src={
                                  item?.senderId?.profile_image && item?.senderId?.profile_image != "undefined"
                                    ? BACKEND_URL +
                                      "profile_image/" + 
                                      item?.senderId?.profile_image
                                    : require("../../../assets/images/tradesperson/review-profile.png")
                                }
                                alt=""
                              />
                            </div>
                            <div className="review_detail_right">
                              <h3 className="revewier_name">{item?.senderId?.firstName+" "+item?.senderId?.lastName}</h3>

                              <div className="review_sect mt-2 mb-2">
                                <div className="review_ratings_box">
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    isHalf={true}
                                    value={item?.overall_rating}
                                    size={38}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>

                              <p className="comment_text">
                              {
                                item?.comments?item?.comments:"User not gives any types of comments"
                              } 
                              </p>
                            </div>
                          </div>
                          <div className="d-flex mt-4 justify-content-between">
                            <p>
                              {moment(item?.createdAt).format("DD MMM YYYY")}
                            </p>
                            <div className="cursor-pointer" onClick={()=>{setSeeDetail(true);setReviewId(item?._id)}}>
                              Read More
                              <img
                                src={
                                  require("../../../assets/images/tradesperson/right-blue-arrow.svg")
                                    .default
                                }
                                alt=""
                                className="ms-1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no_reviews">No Reviews yet</div>
                )}
              </div>
                </div>   
             
            </div>
          </div>
        </div>
      )}
      {showReview == true && seeDetail == false && (
        <TradeReviewDetail setShowReview={setShowReview} aptId={aptId} />
      )}
      {seeDetail == true && showReview == false &&(
        <SeeReviewTrader setSeeDetail={setSeeDetail} reviewId={reviewId}/>
      )}
    </>
  );
};

export default forwardRef(Reviews);
