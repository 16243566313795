import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import UserAccLayout from "../../components/user_acc_home/layout/UserAccLayout";
import { getAllUserAppointments } from "../../redux/Action/UserActions";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const TradeAppointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const traderAppointment = useSelector(
    (state) => state?.useAction?.user_appointment
  );
  useEffect(() => {
    dispatch(getAllUserAppointments());
  }, []);
  const events = [];

  traderAppointment?.map((item, i) => {
    events?.push({
      extendedProps: { _id: item?.enquiryId?._id },
      title: `${item?.tradeId?.company_name}  ${item?.time_appointment}`,
      // date: `${item?.date_appointment}T${item?.time_appointment}`,
      date: `${item?.date_appointment}`,
    });
  });

  const handleEventClick = (arg) => {
    const enquiry_id = arg.event._def.extendedProps._id;
    navigate(`/my-enquiries#${enquiry_id}`);
  };

  return (
    <UserAccLayout>
      {/* <section className="enquiry_sect_main">
          
            
          <p className='under_development_class'>
            Under Development
          </p>
    
</section> */}

      <section className="enquiry_sect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 p-0 col-md-4 col-sm-12 col-12  ">
              <div className="sidebar-enquiries">
                <div className="enquiries-title d-flex justify-content-between">
                  <h4 className="blck_color">
                    Showing {traderAppointment?.length} Appointments
                  </h4>
                </div>
                <ul className="sidebar_lisiting">
                  {traderAppointment?.length > 0 ? (
                    traderAppointment?.map((enquiry) => {
                      var duration = moment.duration(
                        moment().diff(enquiry?.createdAt)
                      );
                      var days = duration.asDays();
                      var hours = duration.asHours();
                      var minutes = duration.asMinutes();
                      return (
                        <HashLink
                          to={`/my-enquiries#${enquiry?.enquiryId?._id}`}
                        >
                          <li className="box_shadow_border mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="dot"></span>
                              <span className="time">
                                {" "}
                                {hours > 24
                                  ? `${Math.floor(days)} days ago`
                                  : minutes > 60
                                  ? `${Math.floor(hours)} hours ago`
                                  : Math.floor(minutes) == 0
                                  ? "Just now"
                                  : `${Math.floor(minutes)} minutes ago`}
                              </span>
                            </div>
                            <div className="ps-4 enquiry_content">
                              <h3>{enquiry?.enquiryId?.company_name}</h3>
                              <p>{enquiry?.enquiryId?.service}</p>
                              <p className="mb-1">
                                {enquiry?.enquiryId?.enquiry_address?.address}
                              </p>
                              {/* <p>Hey I can sort this for you....</p> */}
                              <h4 className="text-end">
                                {moment(enquiry.date_appointment).format(
                                  "dddd Do MMMM"
                                )}
                              </h4>
                            </div>
                          </li>
                        </HashLink>
                      );
                    })
                  ) : (
                    <div className="no-enquiry-img ">
                      <img
                        src={require("../../assets/images/clock.svg").default}
                      />
                      <p>No Appointments yet</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="calendar-container">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    // right: "dayGridMonth,timeGridWeek,timeGridDay",
                    right: "",
                  }}
                  initialView="dayGridMonth"
                  events={events}
                  eventClick={handleEventClick}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserAccLayout>
  );
};

export default TradeAppointments;
