import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../redux/Action/tradeLoginAction";
import TradeHeader from "../layout/TradeHeader";
import UserLayout from "../../layout/user_layout/UserLayout";

const TradeForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validate = (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required*";
      }
      return errors;
    };
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validate,
      onSubmit: async (values) => {
        dispatch(forgotPassword(values, navigate));
      },
    });
  return (
    <>
      {/* <!-- header start --> */}
    
      <UserLayout>
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div className="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p className="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in ">
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h3>Forgot your Password?</h3>
                          <div className="form-group mb-5 mt-4">
                            <label for="">Email</label>
                            <input
                              type="email"
                              className="form-control login_form_control"
                              placeholder="Email"
                              name="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                             {formik.errors.email && (
                            <p className="formik-error">
                              {formik.errors.email}
                            </p>
                          )}
                          </div>
                          <div className="btn_submit mt-4 d-flex">
                            <button type="submit" className="btn btn-primary btn-custom btn-lg w-100">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </UserLayout>
      {/* <!-- Login Page End --> */}
    </>
  );
};

export default TradeForgotPassword;
