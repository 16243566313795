import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";

const SelectAddress = ({ type, setAddressApi, setAdressValue }) => {
  const [isMenuSelect, setIsMenuSelect] = useState(false);
  const [arrOpton, setArrOption] = useState([]);
  const handleSelect = (e, type) => {
    setIsMenuSelect(false);
    setAdressValue(e);
    console.log(e,"eeeeeeeeeeeee");
  };

  const handleInputChange = (inputValue) => {
    if (inputValue != "") {
      localStorage.setItem("postCode", inputValue);
    }
  };

  const postCodeClick = async (e) => {
    try {
      var arr = [];
      e.preventDefault();
      const url = `https://api.getaddress.io/find/${localStorage.getItem(
        "postCode"
      )}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          console.log(res, "resresresresresresres");
          setAddressApi(res.data);

          res &&
            res?.data?.addresses?.map((item) => {
              const arr1 = [
                item?.line_1,
                item?.line_2,
                item?.town_or_city,
                item?.country,
              ];
              const addressData = arr1.filter((str) => str !== "");
              const address_str = addressData[0].concat(
                addressData[1] === undefined ? "" : ", ",
                addressData[1] === undefined ? "" : addressData[1],
                addressData[2] === undefined ? "" : ", ",
                addressData[2] === undefined ? "" : addressData[2],
                addressData[3] === undefined ? "" : ", ",
                addressData[3] === undefined ? "" : addressData[3]
              );
              const address_str1 = arr1[0].concat(
                arr1[1] === undefined ? " " : ", ",
                arr1[1] === undefined ? "" : arr1[1],
                arr1[2] === undefined ? "" : ", ",
                arr1[2] === undefined ? "" : arr1[2],
                arr1[3] === undefined ? "" : ", ",
                arr1[3] === undefined ? "" : arr1[3]
              );
              return arr?.push({ value: address_str1, label: address_str });
            });
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            return toast.warning("Invalid Postcode");
          }
        });
      setArrOption(arr);
      setIsMenuSelect(true);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="d-flex">
        <div className="w-53">
          {/* <input
                                type="search"
                                className="form-control box_shadow_new"
                                placeholder="Type Here"
                                value={postCode}
                                onChange={(e) => setPostCode(e.target.value)}
                              /> */}
          <Select
            menuIsOpen={isMenuSelect}
            onInputChange={handleInputChange}
            onChange={(e) => handleSelect(e, type)}
            options={arrOpton}
            isMulti={false}
            className="custom_style"
          />
        </div>
        <div className=" ms-4">
          <button
            onClick={(e) => postCodeClick(e)}
            className="form-control search_btn"
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectAddress;
