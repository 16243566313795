import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_REGISTER,
} from "../Constant/constant";
let initialstate = {
  userInfo:null
};
const userSigninReducer = (state = initialstate, action) => {
  switch (action.type) {
    // case USER_LOGIN_REQUEST:
    //   return { loading: true };
    // case USER_LOGIN_SUCCESS:
    //   return { loading: false, userInfo: action.payload, error: action.error };
    // case USER_LOGIN_FAIL:
    //   return { loading: false, error: action.payload };
    case USER_REGISTER:
      return { loading: false, userInfo: action?.payload, error: action.error };
    default:
      return state;
  }
};

export default userSigninReducer;
