import axios from "axios";
import { useFormik } from "formik";
 import React, {useEffect, useState,forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getTradePersonProfile, updatePersonalInfo } from "../../../redux/Action/tradeActions";
import RegularPopup from "../../regular_popup/RegularPopup";

const PersonalDetail = (props,ref) => {
  const [postCode, setPostCode] = useState("");
  const [adressValue, setAdressValue] = useState("");
  const [addressApi, setAddressApi] = useState();
  const [postAddress, setPostAddress] = useState();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tradeProfileDetail = useSelector((state) => state?.tradeAction?.tradePerson_detail)
  const postCodeClick = async (e) => {
    try {
      e.preventDefault();
      const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          setAddressApi(res.data);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    dispatch(getTradePersonProfile())
  }, [])
  useEffect(() => {
    if (tradeProfileDetail) {
      setPostCode(tradeProfileDetail?.postcode);
      setAdressValue(tradeProfileDetail?.address)
    }
  }, [tradeProfileDetail])
  useEffect(() => {
    if (addressApi) {
      const data = addressApi?.addresses?.map((item) => {
        return item;
      });
      setPostAddress(data);
    }
  }, [addressApi]);
  const formik = useFormik({
    initialValues: {
      firstName: tradeProfileDetail?.firstName,
      lastName: tradeProfileDetail?.lastName,
      email: tradeProfileDetail?.email,
      mobile: tradeProfileDetail?.mobile,
    },

    onSubmit: async (values) => {
      const value = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        postcode: postCode,
        address: adressValue,
        latitude: tradeProfileDetail?.latitude,
        longitude: tradeProfileDetail?.longitude,
        mobile: values.mobile,
      };
      if (!postCode) {
        toast.warning("Please provide postcode");
      } else if (!adressValue) {
        toast.warning("Please provide address");
      } else {
        try {
          dispatch(updatePersonalInfo(value,setShowModal));
        } catch (error) {
          console.log("error");
        }
      }
    },
  });
  // const handleSubmit=()=>{
  //   formik.handleSubmit()
  // }
  // useImperativeHandle(ref, () => ({
  //   handleSubmit
  // }));
  return (
    <>
    {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"tradeMyAccount"} />}
    <div className="col-lg-8">
      <form onSubmit={formik.handleSubmit} className="form_new">
         <div className="text-end mb-3">
            <button type="submit" className="btn btn-primary">Save</button>
          </div>
        <div className="new_enquiry_content">
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-md-6">
                <label for="">First Name</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
              </div>
              <div className="col-md-6">
                <label for="">Surname</label>
                <input
                  type="text"
                  className="form-control box_shadow_new"
                  placeholder="Type Here"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label for="">Primary Location</label>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="search"
                  className="form-control box_shadow_new"
                  placeholder="Type Here"
                  name="postcode"
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <button
                  onClick={(e) => postCodeClick(e)}
                  className="form-control search_btn"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label for="">Select Address</label>
            <select
              className="form-control box_shadow_new"
              onChange={(e) => setAdressValue(e.target.value)}

            >
              <option value="none" selected="selected" disabled hidden>
                {adressValue ? adressValue : "Please Select Address"}
              </option>
              {postAddress &&
                postAddress?.map((item) => {
                  const addressData = item.formatted_address.filter(
                    (str) => str !== ""
                  );
                  const address_str = addressData[0].concat(
                    addressData[1] === undefined ? " " : ", ",
                    addressData[1] === undefined ? "" : addressData[1],
                    addressData[2] === undefined ? "" : ", ",
                    addressData[2] === undefined ? "" : addressData[2],
                    addressData[3] === undefined ? "" : ", ",
                    addressData[3] === undefined ? "" : addressData[3]
                  );
                  return <option value={address_str}>{address_str}</option>;
                })}
            </select>
          </div>
          <div className="form-group mb-3">
            <label for="">Email</label>
            <input
              type="email"
              className="form-control box_shadow_new"
              placeholder="Type Here"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>

          <div className="form-group mb-3">
          <label for="">Phone No.</label>
          <div class="d-flex align-items-center phn-form-control box_shadow_new">
            
            <div class="country_code">
              <p>+44</p>
            </div>
            <div class="phn-form-control_width">
              {" "}
              <input
                type="number"
                className="form-control "
                placeholder="Type Here.."
                name="mobile"
                onChange={formik.handleChange}
                value={formik.values.mobile}
              />
            </div>
          </div>
          </div>
          {/* <div className="text-end pt-35">
            <button className="btn btn-primary">Update</button>
          </div> */}
        </div>
      </form>
    </div>
    </>
  );
};

export default forwardRef(PersonalDetail);
