import { useFormik } from 'formik';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    var today = new Date().toISOString().split("T")[0];
    const [validateValue, setValidateValue] = useState(false);
    const validate = (values) => {
        const errors = {};
        if (!values.location) {
            errors.location = "Enter your location";
        }
        if (!values.date_appointment) {
            errors.date_appointment = "Select date";
        }

        if (!values.time_appointment) {
            errors.time_appointment = "Select time";
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            location: "",
            date_appointment: "",
            time_appointment: ""
        },
        validate,
        validateOnChange: validateValue,
        onSubmit: async (values) => {
            const value = {
                location: values.location,
                date_appointment: values.date_appointment,
                time_appointment: values.time_appointment,
            };

            try {
                props.handleNewAppClick(value);
                //props.socket.current.emit("new_appointment", appointmentObject);
            } catch (error) {
                console.log("error");
            }
            props.onHide()
        },
    });

    const startTime = 7; // Starting hour
    const endTime = 20; // Ending hour (6 PM)
    const startTime2 = 8; // Starting hour
    const endTime2 = 21;
    const timeSlots = [], timeSlots2 = [];

    // Generate time slots from 6:00 AM to 6:00 PM in 30-minute intervals
    for (let hour = startTime; hour <= endTime; hour++) {
        for (let minutes = 0; minutes < 60; minutes += 30) {
            const time = `${hour.toString().padStart(2, '0')}:${minutes === 0 ? '00' : '30'}`;
            timeSlots.push(time);
        }
    }

    let i = 0
    for (let hour = startTime2; hour <= endTime2; hour++) {
        for (let minutes = 0; minutes < 60; minutes += 30) {
            const time = `${hour.toString().padStart(2, '0')}:${minutes === 0 ? '00' : '30'}`;
            timeSlots2.push(`${timeSlots[i]} - ${time}`);
            i++
        }
    }

    const [selectedTime, setSelectedTime] = useState('');

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='confimation_details_modal'
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <h3 className='mb-4'>Please fill the details below.</h3>
                <form onSubmit={formik.handleSubmit}>

                    <div className='form-group-new mb-3'>
                        <label for="">Location</label>
                        <input
                            type="text"
                            name="location"
                            placeholder='Location'
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.location}
                        />
                        {formik.errors.location && (
                            <p className="formik-error">
                                {formik.errors.location}
                            </p>
                        )}
                    </div>
                    <div className='form-group-new mb-3'>
                        <label for="">Date</label>
                        <input
                            min={today}
                            type="date"
                            name="date_appointment"
                            placeholder="dd/mm/yyyy"
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.date_appointment}
                        />
                        {formik.errors.date_appointment && (
                            <p className="formik-error">
                                {formik.errors.date_appointment}
                            </p>
                        )}
                    </div>
                    <div className='form-group-new'>
                        <label for="">Time</label>

                        <select
                            value={formik.values.time_appointment}
                            name="time_appointment"
                            onChange={formik.handleChange}
                            className='form-control'
                        >
                            {timeSlots2?.map((time, index) => (
                                <option key={index} value={time}>
                                    {time}
                                </option>
                            ))}
                        </select>
                        {formik.errors.time_appointment && (
                            <p className="formik-error">
                                {formik.errors.time_appointment}
                            </p>
                        )}
                    </div>

                    <Modal.Footer>
                        <Button onClick={() => props.onHide()}>Cancel</Button>
                        <Button onClick={() => setValidateValue(true)} type="submit" >Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal.Body>

        </Modal>
    );
}

function Popup({ setModalShow, modalShow, enquiryDetail, socket, handleNewAppClick }) {


    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                enquiryDetail={enquiryDetail}
                socket={socket}
                handleNewAppClick={handleNewAppClick}
            />
        </>
    );
}

export default Popup;