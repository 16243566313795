import axios from "axios";
 
const TradeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_TRADE_API_BASE_URL}`,
  // timeout: 2000,
  headers: {
    //device_token: localStorage.getItem('device_token'),
    authorization: sessionStorage.getItem("tradeToken"),
  },
});
TradeAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        sessionStorage.clear();
        window.location.reload(false);
        window.location.href = '/tradepeople-login';
      }, 1000);
    }
    return error.response;
  }
);

export default TradeAPI;
