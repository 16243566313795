import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
export default function TermsConditionsCustomer() {
  return (
    <UserLayout>
      <div className="container">
        <div className="terms_conditions">
          <h2 className="text-center pb-4 ">CUSTOMER TERMS AND CONDITIONS</h2>

          <div className="t_content">
            <p>
              This agreement governs your use of Smart Choice Traders website,
              accessible at{" "}
              <a href="www.smartchoicetraders.com">
                www.smartchoicetraders.com
              </a>{" "}
              and any goods or services made available through the Website. By
              using the Website, you agree to be bound by this agreement, which
              forms a binding contractual agreement between you, the Customer,
              and us, Smart Choice Traders Ltd a company registered in England
              and Wales with Company Number 13329225 and registered office
              London Office Great Portland Street, 167-169 Great Portland Street
              (5th Floor), London, England, W1W 5PF (Smart Choice Traders, we or
              us).
            </p>
            <div className="cont">
              <p>In this agreement, when we refer to:</p>

              <ol>
                <li>
                  “the Customer”, we are referring to you, the individual
                  ordering Services via our Website, or if you are acting in the
                  capacity as a duly authorised representative of a company,
                  then that company;
                </li>
                <li>
                  “Services”, we are referring to the services from Service
                  Providers available through the Website;
                </li>
                <li>
                  “Service Providers”, we are referring to sellers of the
                  Services on the Website, being separate legal entities from
                  Smart Choice Traders (unless otherwise indicated);{" "}
                </li>
                <li>
                  “Users”, we are referring to all users of the Website,
                  including without limitation you (as a customer), other
                  customers, and Service Providers.{" "}
                </li>

                <p>YOUR KEY INFORMATION</p>
                <p>
                  The Consumer Contracts (Information, Cancellation and
                  Additional Charges) Regulations 2013 say that up to 14 days
                  after placing an Order for Services, in some cases, you can
                  change your mind and get a full refund. This right may apply
                  to the Orders you place via our Website.{" "}
                </p>
                <p>
                  The Consumer Rights Act 2015 (applicable in the United
                  Kingdom) requires that all Services available on the Website
                  are as described, fit for purpose and of satisfactory quality
                  and so nothing in these terms affects statutory rights.{" "}
                </p>
                <p>
                  During the expected lifespan of your Services, if this Act
                  applies to you, you’re entitled to the following:
                </p>

                <li>
                  up to 30 days: if your goods are faulty, you can get a refund;
                </li>
                <li>
                  up to six months: if it can’t be repaired or replaced, then
                  you’re entitled to a full refund in most cases;
                </li>
                <li>
                  up to six years: if the goods do not last a reasonable length
                  of time, you may be entitled to some money back.
                </li>

                <p>
                  This is a summary of some of your key rights in the United
                  Kingdom. For detailed information from Citizens Advice please
                  visit{" "}
                  <a href="www.citizensadvice.org.uk">
                    www.citizensadvice.org.uk
                  </a>
                  or call 0808 223 1133.
                </p>
                <p>
                  The information above summarises some of your key rights. It
                  is not intended to replace the contract below, which you
                  should read carefully.
                </p>
                <p>
                  If you have any questions about this contract or any orders
                  you have placed, please contact us by sending an email to:
                  contactus@smartchoicetraders.com
                </p>
                <li>
                  ACCOUNTS
                  <ul>
                    <li>
                      In order to use some functionalities of the Website, you
                      may be required to sign-up, register and receive an
                      account through the Website (an Account).
                    </li>
                    <li>
                      As part of the Account registration process and as part of
                      your continued use of the Website, you are required to
                      provide personal information and details, such as your
                      email address, first and last name, preferred username, a
                      secure password, billing, postal and physical addresses,
                      mobile phone number, payment information, and other
                      information as determined by Smart Choice Traders from
                      time to time.
                    </li>
                    <li>
                      You warrant that any information you give to Smart Choice
                      Traders in the course of completing the Account
                      registration process will always be accurate, honest,
                      correct and up-to-date.
                    </li>
                    <li>
                      Once you complete the Account registration process, Smart
                      Choice Traders may, in its absolute discretion, choose to
                      accept you as a registered user within the Website and
                      provide you with an Account.
                    </li>
                    <li>
                      Smart Choice Traders reserves the right to contact you
                      about any concerning behaviour by you, or to seek a
                      resolution with you.
                    </li>
                    <li>
                      Smart Choice Traders may, in its absolute discretion,
                      suspend or cancel your Account for any reason, including
                      for any failure to comply with this agreement.
                    </li>
                  </ul>
                </li>
                <li>QUOTES AND ORDERS</li>
                <li>
                  LISTING AND QUOTES
                  <ul>
                    <li>
                      Service Providers may list their services on our Website
                      (Listings).
                    </li>
                    <li>
                      Information contained in a Listing is provided by the
                      Service Provider and may not have been verified by Smart
                      Choice Traders. We make no warranties as to any Listings.
                    </li>
                    <li>Viewing our Website and perusing Listings is free.</li>
                    <li>
                      You can contact Service Providers, including responding to
                      their Listings and requesting quotes or estimates for
                      specific services.{" "}
                    </li>
                    <li>
                      Service Providers can supply formal quotes or estimates
                      including scope and inclusions to provide Services via our
                      Platform (Quotes).{" "}
                    </li>
                    <li>
                      We specify the information that a Service Provider should
                      supply with a Quote, however it is your responsibility to
                      ensure that the Quote is complete and meets your
                      requirements.
                    </li>
                  </ul>
                </li>
                <li>
                  ORDERS
                  <ul>
                    <li>You acknowledge and agree that:</li>
                    <li>
                      if you accept a Quote you will place an order to purchase
                      Services on the Website (Order), that will constitute your
                      entry into a contract with the relevant Service Provider
                      for those Services;
                    </li>
                    <li>
                      You must pay the price listed for the Services on the
                      Quote (Quoted Amount) plus the Customer Fee (which you
                      will be notified of at checkout) (Price).
                    </li>
                    <li>
                      Service Providers may accept payment of the Quoted Amount
                      in instalments (Instalments). Where the Quoted Amount is
                      to be paid in Instalments, you must pay each Instalment
                      plus the Customer Fee (which you will be notified of at
                      checkout) when requested by the Service Provider via the
                      Platform.
                    </li>
                    <li>
                      Service Providers may withhold delivery of Services if the
                      Price or Instalments (if applicable) (Payments) are not
                      paid within the payment terms specified by the Service
                      Provider.
                    </li>
                    <li>
                      The relevant Payment will be debited from your account,
                      and Smart Choice Traders will keep the Customer Fee for
                      each Payment. Smart Choice Traders may also charge Service
                      Providers a fee, which will be taken from the Quoted
                      Amount.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  INFORMATION WE GIVE YOU
                  <ul>
                    <li>
                      By law, the Consumer Contracts (Information, Cancellation
                      and Additional Charges) Regulations 2013 say that a seller
                      must give you certain key information before a legally
                      binding contract between you and the seller is made. This
                      information is set out at the start of these terms. If you
                      cannot access this information for any reason, you are
                      welcome to contact us using the functionality on the
                      website, or at the contact email address above, and we
                      will provide you with a copy of this information.
                    </li>
                    <li>
                      The key information we give you by law forms part of this
                      contract (as though it is set out in full here).
                    </li>
                    If we have to change any key information once a legally
                    binding contract between you and us or a Service Provider is
                    made, we can only do this if you agree to it.
                  </ul>
                </li>
                <li>
                  PAYMENT
                  <ul>
                    <li>
                      (Payment obligations) Unless otherwise agreed in writing
                      you must pay the Price for all Services specified in an
                      Order prior to the Service Provider providing those
                      Services.
                    </li>
                    <li>
                      (Third Party Payment Platform) Smart Choice Traders
                      processes payments through a Third Party Payment Platform
                      as set out in clause 10(i). In addition to this agreement,
                      your purchase of any Services via the Website will be
                      subject to the terms and the privacy policy of the Third
                      Party Payment Platform that you choose to pay with via the
                      Website.
                    </li>
                    <li>
                      (Release) You agree to release Smart Choice Traders and
                      its employees and agents in respect of all liability for
                      loss, damage or injury which may be suffered by any person
                      arising from any act or omission of the Third Party
                      Payment Platform, including any issue with security or
                      performance of the Third Party Payment Platform or any
                      error or mistake in processing your payment.
                    </li>
                  </ul>
                </li>
                <li>
                  Service Providers
                  <ul>
                    <li>
                      Our Website allows Customers to shop Services from trusted
                      Service Providers. We rely on the information supplied by
                      the Service Providers to:
                      <ul>
                        <li>
                          Determine whether they meet our requirements; and{" "}
                        </li>
                        <li>
                          When making any statements as to the Service
                          Providers’ qualifications or compliance on our
                          Website.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Whilst we use our best endeavours to verify the accuracy
                      of the Service Providers, and ensure they meet our
                      requirements and are suitably qualified to provide the
                      Services they offer in a Listing,including any information
                      and supporting certificates, documents about the Service
                      Providers and their Services, on our Website, we act
                      solely as a marketplace platform for Service Providers to
                      sell their Services and do not have control over the
                      Service Providers’ compliance practices, or the
                      information they supply or provide on the Website.
                    </li>
                  </ul>
                </li>
                <li>
                  SERVICES
                  <ul>
                    <li>
                      The Consumer Rights Act 2015 gives you certain legal
                      rights (also known as ‘statutory rights’). The Services
                      supplied to you must be as described, fit for purpose and
                      of satisfactory quality.
                    </li>
                    <li>
                      The Service Providers are required to endeavour to ensure
                      that the Services provided will be substantially the same
                      as the Services specified in a Listing or a Quote, or as
                      otherwise agreed with you in writing prior to you placing
                      your Order.
                    </li>
                    <li>
                      We try to make sure that Service Providers supply complete
                      and accurate Quotes, however prices for services may be
                      subject to the accuracy of information you supply to a
                      Service Provider and changes to the Quote including
                      without limitation the scope of work and any costs, may
                      change during the delivery of the Services;
                    </li>
                    <li>
                      We rely on information supplied by the Service Provider
                      about their business, qualifications and registrations. We
                      therefore make no warranties as to the accuracy of this
                      information. You may be entitled to a refund if a Service
                      is not as described or faulty, in accordance with clause
                      7.3.
                    </li>
                    <li>
                      Until the price of your Services is paid in full, title in
                      any goods supplied with those Services is retained by the
                      relevant Service Provider of the Services. Risk in the
                      Services will pass to you on delivery in accordance with
                      clause 12.{" "}
                    </li>
                    <li>
                      Service Providers are under a legal duty to supply you
                      with Services that are in conformity with this contract
                      (subject to your Order being accepted and confirmed).
                    </li>
                  </ul>
                </li>
                <li>
                  CANCELLATION BY THE SERVICE PROVIDER
                  <ul>
                    <li>
                      Smart Choice Traders will have no liability or obligation
                      to you if a Service Provider cancels an Order at any time
                      after you have placed your Order.
                    </li>
                    <li>
                      If a Service Provider cancels an Order in respect of their
                      Services, then you may be entitled to a refund of the
                      Quoted Amount subject to the terms and conditions of the
                      Service Provider and any statutory rights you may have.
                    </li>
                  </ul>
                </li>
                <li>
                  ORDERS THAT CAN BE CANCELLED FOR CHANGE OF MIND
                  <ul>
                    <li>
                      Your legal rights under the Consumer Rights Act 2015 (also
                      known as ‘statutory rights’) are set out at the top of
                      this agreement. They are a summary of some of your key
                      rights. For more detailed information on your rights and
                      what you should expect from us, please:
                      <ul>
                        <li>
                          contact us using the contact details on our site; or
                        </li>
                        <li>
                          visit the Citizens Advice website{" "}
                          <a href="www.citizensadvice.org.uk">
                            www.citizensadvice.org.uk
                          </a>{" "}
                          or call 0808 223 1133.
                        </li>
                      </ul>
                      <li>
                        Nothing in these terms affects your legal rights under
                        the Consumer Rights Act 2015 (also known as ‘statutory
                        rights’). You may also have other rights under law.
                      </li>
                      <li>
                        For many contracts for the sale of Services you have the
                        right to cancel your Order of those Services without
                        giving any reason.
                      </li>
                      <li>
                        The cancellation period will expire 14 days from the day
                        after which the Order is placed.
                      </li>
                      <li>
                        Where you request Services to be provided within the
                        cancellation period, the Service Provider may retain
                        fees paid for the Services to cover any costs in
                        providing the Services (including preparing to provide
                        the Services such as ordering materials).
                      </li>
                      <li>
                        To exercise the right to cancel, you must inform the
                        Service Provider of your decision to cancel your
                        contract by a clear statement to the Service Provider in
                        writing, for example by notifying the Service Provider
                        via the Website.
                      </li>
                      <li>
                        To meet the cancellation deadline, it is sufficient for
                        you to send your communication concerning your exercise
                        of the right to cancel before the cancellation period
                        has expired.
                      </li>
                      <li>
                        Service Providers will have their own policies in
                        respect of cancellation which will apply in addition to
                        your statutory rights. The Service Providers terms will
                        be as specified in their Listing or Quote and will apply
                        to your contract with the Service Provider in addition
                        to these terms.
                      </li>
                    </li>
                  </ul>
                </li>
                <li>
                  FAULTY SERVICES
                  <ul>
                    <li>
                      For all Services purchased, you have statutory rights if
                      the Services were not of satisfactory quality or as
                      described, which may entitle you to a replacement or
                      refund. Nothing in this clause 7.3 will require us to
                      provide a refund, repair or replacement in respect of loss
                      or damage caused by you.
                    </li>
                    <li>
                      If you do have the right to cancel this agreement due to
                      the relevant Services being faulty, you must submit a
                      claim to the relevant Service Provider who will consider
                      your claim.
                    </li>
                  </ul>
                </li>
                <li>
                  ELIGIBILITY
                  <ul>
                    <li>
                      This Website is not intended for unsupervised use by any
                      person under the age of 18 years old or any person who has
                      previously been suspended or prohibited from using the
                      Website. By using the Website, you represent and warrant
                      that you:
                    </li>
                    <li>
                      have not been suspended or prohibited from using the
                      Website;
                    </li>
                    <li>
                      are either:
                      <ul>
                        <li>
                          over the age of 18 years and accessing the Website for
                          personal use;{" "}
                        </li>
                        <li>
                          accessing the Website on behalf of someone under the
                          age of 18 years old and consent to that person’s use
                          of the Website.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Please do not access the Website if you are under the age
                      of 18 years old and do not have your parent or guardian’s
                      consent, or if you have previously been suspended or
                      prohibited from using the Website.
                    </li>
                    <li>
                      If you use the Website on behalf of a company or
                      organisation you warrant that you have the necessary
                      authority from that company or organisation to do so. If
                      you are signing up not as an individual but on behalf of
                      your company, your employer, an organisation, government
                      or other legal entity (Represented Entity), then “you” and
                      “Customer” means the Represented Entity and you are
                      binding the Represented Entity to this agreement. If you
                      are accepting this agreement and using our Website on
                      behalf of a Represented Entity, you represent and warrant
                      that you are authorised to do so.
                    </li>
                  </ul>
                </li>
                <li>
                  REVIEWS AND RATINGS
                  <ul>
                    <li>
                      Customers may rate a Listing, and Service Providers may
                      rate a Customer (each a ‘Rating’) and may provide feedback
                      to other Users regarding the relevant experience with that
                      User (Review).
                    </li>
                    <li>
                      Ratings and Reviews may be able to be viewed by other
                      Users and these may remain viewable until the relevant
                      Account and/or Listing is removed or terminated.
                    </li>
                    <li>
                      Users must provide true, fair and accurate information in
                      their Reviews.
                    </li>
                    <li>
                      If we consider that a Review is untrue, unfair,
                      inaccurate, offensive or inappropriate, we may delete the
                      Review or ban the relevant User from posting the Review,
                      or future Reviews. We do not undertake to review each
                      Review made by a User.
                    </li>
                    <li>
                      To the maximum extent permitted by law, we are not
                      responsible for the content of any Reviews.
                    </li>
                    <li>
                      You must not publish Reviews for Users to which you have
                      personal or professional relations.
                    </li>
                    <li>
                      Users can only write a Review about another User if they
                      have had an experience with that User on our Platform,
                      which means that:
                      <ul>
                        <li>
                          you have ordered and/or received Services from that
                          Service Provider;{" "}
                        </li>
                        <li>
                          you can otherwise document that you had a buying or
                          selling experience with that User, including via
                          correspondence or other interaction with the User,{" "}
                        </li>
                        <li>
                          (collectively referred to as a Service Experience).
                        </li>
                      </ul>
                    </li>
                    <li>
                      You must only write about your own Service Experience. You
                      are not permitted to write a Review about somebody else’s
                      Service Experience, such as that of a family member or
                      friend.
                    </li>
                    <li>
                      You must not write a Review about a direct competitor to
                      the business or company you own, are employed by or work
                      for.
                    </li>
                    <li>
                      Your Service Experience must have occurred within the last
                      12 months. This means within 12 months from the date on
                      which you write the Review.
                    </li>
                    <li>
                      You are encouraged to be specific and factual in your
                      Reviews. If you have been offered an incentive by a User
                      to write a Review, you should include information about
                      this in your Review. Incentives include the User offering
                      you a gift, reward, discount or advantage for writing a
                      Review about the User on the Platform.
                    </li>
                  </ul>
                </li>
                <li>
                  YOUR OBLIGATIONS
                  <ul>
                    <li>As a Customer, you agree:</li>
                    <li>
                      not to intimidate, harass, impersonate, stalk, threaten,
                      bully or endanger any other User or distribute unsolicited
                      commercial content, junk mail, spam, bulk content or
                      harassment;
                    </li>
                    <li>
                      to not share your Account with any other person and that
                      any use of your Account by any other person is strictly
                      prohibited. You must immediately notify Smart Choice
                      Traders of any unauthorised use of your Account, password
                      or email, or any other breach or potential breach of the
                      Website’s security;
                    </li>
                    <li>
                      to not use the Website for any purpose other than for the
                      purpose of making arrangements to receive Services,
                      including:
                      <ul>
                        <li>
                          you must not use the Website in a manner that is
                          illegal or fraudulent or facilitates illegal or
                          fraudulent activity (including requesting or accepting
                          a job or a sale which includes illegal goods,
                          activities or purposes);
                        </li>
                        <li>
                          you must not use the Website in connection with any
                          commercial or money making or other promotional or
                          marketing endeavours except those that are endorsed
                          herein, or as approved in writing by Smart Choice
                          Traders;{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      not to act in any way that may harm the reputation of
                      Smart Choice Traders or associated or interested parties
                      or do anything at all contrary to the interests of Smart
                      Choice Traders or the Website;
                    </li>
                    <li>
                      {" "}
                      you must not make any automated use of the Website and you
                      must not copy, reproduce, translate, adapt, vary or modify
                      the Website without the express written consent of Smart
                      Choice Traders;
                    </li>
                    <li>
                      that Smart Choice Traders may change any features of the
                      Website or Services offered through the Website at any
                      time without notice to you;
                    </li>
                    <li>
                      that information given to you through the Website, by
                      Smart Choice Traders or another User including a Service
                      Provider, is general in nature and we take no
                      responsibility for anything caused by any actions you take
                      in reliance on that information;
                    </li>
                    <li>
                      that Smart Choice Traders may cancel your account at any
                      time, including if it considers, in its absolute
                      discretion, that you are in breach or are likely to breach
                      this clause 0;{" "}
                    </li>
                    <li>
                      that you will be required to use a Third Party Payment
                      Platform in making or receiving any payments via the
                      Website (Third Party Payment Platform), you warrant that
                      you have read, understood and agree to be bound by the
                      relevant Third Party Payment Platform’s terms of use,
                      being:
                      <ul>
                        <li>
                          at present Stripe, and Stripe’s terms are available at{" "}
                          <a href="https://stripe.com/gb/legal">
                            https://stripe.com/gb/legal
                          </a>
                          ;
                        </li>
                        <li>
                          the terms of use of other third party payment portals
                          or other payment methods from time to time, that will
                          be available on other payment portal websites.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>POSTED MATERIALS</li>
                <li>
                  WARRANTIES
                  <ul>
                    <li>
                      By providing or posting any information, materials or
                      other content on the Website (Posted Material), you
                      represent and warrant that:
                    </li>
                    <li>
                      you are authorised to provide the Posted Material
                      (including by being authorised to provide any goods and
                      services that you represent you provide);
                    </li>
                    <li>
                      the Posted Material is accurate and true at the time it is
                      provided;
                    </li>
                    <li>
                      any Posted Material which is in the form of a review or
                      feedback is honest, accurate and presents a fair view of
                      the relevant person and/or your experience;
                    </li>
                    <li>
                      the Posted Material is free from any harmful,
                      discriminatory, defamatory or maliciously false
                      implications and does not contain any offensive or
                      explicit material;
                    </li>
                    <li>
                      the Posted Material does not infringe any Intellectual
                      Property Rights, including copyright, trademarks, business
                      names, patents, confidential information or any other
                      similar proprietary rights, whether registered or
                      unregistered, anywhere in the world;
                    </li>
                    <li>
                      {" "}
                      Posted Material does not contain any viruses or other
                      harmful code, or otherwise compromise the security or
                      integrity of the Website or any network or system;{" "}
                    </li>
                    <li>
                      the Posted Material does not breach or infringe any
                      applicable laws.
                    </li>
                  </ul>
                </li>
                <li>
                  LICENCE
                  <ul>
                    <li>
                      You grant to Smart Choice Traders a perpetual,
                      irrevocable, transferable, worldwide and royalty-free
                      licence (including the right to sublicense) to use, copy,
                      modify, reproduce and adapt any Intellectual Property
                      Rights in any Posted Material in order for Smart Choice
                      Traders to use, such Posted Material.
                    </li>
                    <li>
                      If it is determined that you retain moral rights
                      (including rights of attribution or integrity) in any
                      Posted Material, you forever release Smart Choice Traders
                      from any and all claims that you could assert against
                      Smart Choice Traders by virtue of any such moral rights.
                    </li>
                    <li>
                      You indemnify Smart Choice Traders against all damages,
                      losses, costs and expenses incurred by Smart Choice
                      Traders arising out of any third party claim that your
                      Posted Material infringes any third party’s Intellectual
                      Property Rights.
                    </li>
                  </ul>
                </li>
                <li>
                  REMOVAL
                  <ul>
                    <li>
                      Smart Choice Traders acts as a passive conduit for the
                      online distribution of Posted Material and has no
                      obligation to screen Posted Material in advance of it
                      being posted. However, Smart Choice Traders may, in its
                      absolute discretion, review and remove any Posted Material
                      (including links to you, your profile or listings you have
                      posted on the Website) at any time without giving any
                      explanation or justification for removing the Posted
                      Material.
                    </li>
                    <li>
                      {" "}
                      You agree that you are responsible for keeping and
                      maintaining records of Posted Material.
                    </li>
                  </ul>
                </li>
                <li>
                  SERVICE LIMITATIONS
                  <ul>
                    <li>
                      The Website is made available to you strictly on an ‘as
                      is’ basis. Without limitation, you acknowledge and agree
                      that Smart Choice Traders cannot and does not represent,
                      warrant or guarantee that:
                    </li>
                    <li>the Website will be free from errors or defects;</li>
                    <li>the Website will be accessible at all times;</li>
                    <li>
                      messages sent through the Website will be delivered
                      promptly, or delivered at all;
                    </li>
                    <li>
                      information you receive or supply through the Website will
                      be secure or confidential;{" "}
                    </li>
                    <li>
                      any information provided through the Website is accurate
                      or true.
                    </li>
                  </ul>
                </li>
                <li>
                  INTELLECTUAL PROPERTY
                  <ul>
                    <li>
                      Smart Choice Traders retains ownership of all materials
                      developed or provided (or both, as the case may be) in
                      connection with the Website (including text, graphics,
                      logos, design, icons, images, sound and video recordings,
                      pricing, downloads and software) (Website Content) and
                      reserves all rights in any Intellectual Property Rights
                      owned or licensed by it not expressly granted to you.
                    </li>
                    <li>
                      You may make a temporary electronic copy of all or part of
                      the Website Content for the sole purpose of viewing it.
                      You must not otherwise reproduce, transmit, adapt,
                      distribute, sell, modify or publish the Website Content
                      without prior written consent from Smart Choice Traders or
                      as permitted by law.
                    </li>
                    <li>
                      In this clause 13, “Intellectual Property Rights” means
                      all copyright, trade mark, design, patent, semiconductor
                      and circuit layout rights, trade, business, company and
                      domain names, confidential and other proprietary rights,
                      and any other rights to registration of such rights
                      whether created before or after the date of this agreement
                      worldwide.
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY CONTENT
                  <ul>
                    <li>
                      ⦁ The Website may contain text, images, data and other
                      content provided by a third party and displayed on the
                      Website (Third Party Content). Smart Choice Traders
                      accepts no responsibility for Third Party Content and
                      makes no representation, warranty or guarantee about the
                      quality, suitability, accuracy, reliability, currency or
                      completeness of Third Party Content.
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY TERMS
                  <ul>
                    <li>
                      Any service that requires Smart Choice Traders to acquire
                      goods and services supplied by a third party on behalf of
                      the Customer (including a third party payment service,
                      like the Third Party Payment Platform may be subject to
                      the terms and conditions of that third party (Third Party
                      Terms), including ‘no refund’ policies.
                    </li>
                    <li>
                      Service Providers will have their own terms in respect of
                      the Services they sell on our Website (Service Provider
                      Terms), which will apply in addition to the terms of this
                      agreement (to the extent they are not inconsistent with
                      this agreement) and you should read the Service Provider
                      Terms before you place an Order for Services from that
                      Service Provider.
                    </li>
                    <li>
                      Users agree to familiarise themselves with any Third Party
                      Terms applicable to any such goods and services and, by
                      instructing Smart Choice Traders to acquire the goods or
                      services on the User’s behalf, the User will be taken to
                      have agreed to such Third Party Terms.
                    </li>
                  </ul>
                </li>
                <li>
                  SECURITY
                  <ul>
                    <li>
                      Smart Choice Traders does not accept responsibility for
                      loss or damage to computer systems, mobile phones or other
                      electronic devices arising in connection with your use of
                      the Website. You should take your own precautions to
                      ensure that the process you employ to access the Website
                      does not expose you to the risk of viruses, malicious
                      computer code or other forms of interference.
                    </li>
                  </ul>
                </li>
                <li>
                  DISCLAIMER & LIABILITY
                  <ul>
                    <li>
                      (Introduction service) Smart Choice Traders is a medium
                      that facilitates the introduction of Customers and Service
                      Providers for the purposes of buying and selling Services.
                      Smart Choice Traders simply collects a service fee in
                      consideration for providing this introduction service and
                      does not have any obligations or liabilities to, and is
                      not a party to any contract between, Customers and Service
                      Providers in relation to such Services or otherwise
                      resulting from the introduction other than where Smart
                      Choice Traders is a Service Provider of the Services.
                    </li>
                    <li>
                      (Limitation of liability) To the maximum extent permitted
                      by applicable law, Smart Choice Traders excludes
                      completely all liability to any person for loss or damage
                      of any kind, however arising whether in contract, tort
                      (including negligence), statute, equity, indemnity or
                      otherwise, arising from or relating in any way to the
                      Website or its use or any services provided by any Service
                      Provider. This includes the transmission of any computer
                      virus.
                    </li>
                    <li>
                      (Indemnity) You agree to indemnify Smart Choice Traders
                      and its employees and agents in respect of all liability
                      for loss, damage or injury which may be suffered by any
                      person arising from you or your representatives’:
                      <ul>
                        <li>breach of any term of this agreement;</li>
                        <li>use of the Website; </li>
                        <li>
                          your provision or receipt of Services from another
                          User.
                        </li>
                      </ul>
                      <li>
                        (Consequential loss) To the maximum extent permitted by
                        law, under no circumstances will Smart Choice Traders be
                        liable for any incidental, special or consequential loss
                        or damages, or damages for loss of data, business or
                        business opportunity, goodwill, anticipated savings,
                        profits or revenue arising under or in connection with
                        the Website, this agreement or their subject matter, or
                        any services provided by any Service Provider (except to
                        the extent this liability cannot be excluded at law.
                      </li>
                      <li>
                        To the extent that the provisions of any applicable law
                        shall impose restrictions on the extent to which
                        liability can be excluded under agreement including, for
                        the avoidance of doubt, the provisions of sections 3, 6
                        and 11 of the Unfair Contract Terms Act 1977 in the UK
                        (and its equivalent in any other jurisdiction) relating
                        to the requirement of reasonableness, the exclusions set
                        out in this clause shall be limited in accordance with
                        such restrictions. However, any exclusions of liability
                        that are not affected by such restrictions shall remain
                        in full force and effect.
                      </li>
                    </li>
                  </ul>
                </li>
                <li>
                  CONFIDENTIALITY
                  <ul>
                    <li>You agree that:</li>
                    <li>
                      no information owned by Smart Choice Traders, including
                      system operations, documents, marketing strategies, staff
                      information and client information, may be disclosed or
                      made available to any third parties; and
                    </li>
                    <li>
                      all communications involving the details of other users on
                      this Website and of the Service Provider are confidential,
                      and must be kept as such by you and must not be
                      distributed nor disclosed to any third party.
                    </li>
                  </ul>
                </li>
                <li>
                  PRIVACY
                  <ul>
                    <li>
                      You agree to be bound by the clauses outlined in Smart
                      Choice Traders’s Privacy Policy, which can be accessed
                      here link.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  TERMINATION
                  <ul>
                    <li>
                      Smart Choice Traders reserves the right to terminate a
                      User’s access to any or all of the Website at any time
                      without notice, for any reason.
                    </li>
                    <li>
                      Users may terminate their Account on the Website at any
                      time by using the Website’s functionality where such
                      functionality is available. Where such functionality is
                      not available, Smart Choice Traders will effect such
                      termination within a reasonable time after receiving
                      written notice from the User.
                    </li>
                    <li>
                      Notwithstanding termination or expiry of your Account or
                      this agreement, the provisions of clause 17 and any other
                      provision which by its nature would reasonably be expected
                      to be complied with after termination or expiry, will
                      continue to apply.
                    </li>
                  </ul>
                </li>
                <li>
                  RECORD / AUDIT
                  <ul>
                    <li>
                      To the extent permitted by law, Smart Choice Traders
                      reserves the right to keep all records of any and all
                      transactions and communications made through this Website
                      between you and other Users (including conversations, user
                      posts, job request bids, comments, feedback, cookies, and
                      I.P. address information) for administration purposes and
                      also holds the right to produce these records in the event
                      of any legal dispute involving Smart Choice Traders.
                    </li>
                  </ul>
                </li>
                <li>
                  LINKED BUSINESSES
                  <ul>
                    <li>You acknowledge and agree that:</li>
                    <li>
                      the Website provides links and introductions to Service
                      Providers owned and operated by third parties that are not
                      under the control of Smart Choice Traders;
                    </li>
                    <li>
                      the provision by Smart Choice Traders of introductions to
                      Service Providers does not imply any endorsement or
                      recommendation by Smart Choice Traders of any Service
                      Provider;{" "}
                    </li>
                    <li>
                      Smart Choice Traders does not examine, determine or
                      warrant the certification and/or licensing, competence,
                      solvency or information of any Service Provider who uses
                      or is listed on the Website.
                    </li>
                  </ul>
                </li>
                <li>
                  COMMUNICATION OUTSIDE THE WEBSITE
                  <ul>
                    <li>
                      You must not communicate with a Service Provider, or
                      request or entice a Service Provider to communicate with
                      you, outside the Website (except in the course of
                      accepting the Service Provider’s Services under this
                      agreement).
                    </li>
                    <li>
                      Smart Choice Traders, in its absolute discretion, may
                      cancel your Account and suspend you from using the Website
                      if it finds or suspects that you have breached or are in
                      breach of this clause 23.
                    </li>
                  </ul>
                </li>
                <li>
                  NOTICES
                  <ul>
                    <li>
                      A notice or other communication to a party under this
                      agreement must be:
                    </li>
                    <li>in writing and in English;</li>
                    <li>
                      delivered via email to the other party, to the email
                      address specified in this agreement, or if no email
                      address is specified in this agreement, then the email
                      address most regularly used by the parties to correspond
                      for the purposes of the subject matter of this agreement
                      as at the date of this agreement (Email Address). The
                      parties may update their Email Address by notice to the
                      other party.
                    </li>
                    <li>
                      Unless the party sending the notice knows or reasonably
                      ought to suspect that an email was not delivered to the
                      other party’s Email Address, notice will be taken to be
                      given:
                      <ul>
                        <li>
                          24 hours after the email was sent, unless that falls
                          on a Saturday, Sunday or a public holiday in the state
                          or territory whose laws govern this agreement, in
                          which case the notice will be taken to be given on the
                          next occurring business day in that state or
                          territory;{" "}
                        </li>
                        <li>when replied to by the other party,</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  VAT
                  <ul>
                    <li>
                      Unless otherwise indicated, amounts stated in on the
                      Website do not include VAT. In relation to any VAT payable
                      for a taxable supply by a Service Provider or Smart Choice
                      Traders, you must pay the VAT subject to receiving a tax
                      invoice.
                    </li>
                  </ul>
                </li>
                <li>GENERAL</li>
                <li>
                  GOVERNING LAW AND JURISDICTION
                  <ul>
                    <li>
                      This agreement and any dispute or claim (including
                      non-contractual disputes or claims) arising out of or in
                      connection with it or its subject matter or formation
                      shall be governed by and construed in accordance with the
                      law of England and Wales. Each party irrevocably agrees
                      that the courts of England and Wales shall have exclusive
                      jurisdiction to settle any dispute or claim (including
                      non-contractual disputes or claims) arising out of or in
                      connection with this agreement or its subject matter or
                      formation.
                    </li>
                  </ul>
                </li>
                <li>
                  WAIVER
                  <ul>
                    <li>
                      No party to this agreement may rely on the words or
                      conduct of any other party as a waiver of any right unless
                      the waiver is in writing and signed by the party granting
                      the waiver.
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY RIGHTS
                  <ul>
                    <li>
                      This agreement does not give rise to any rights under the
                      Contracts (Rights of Third Parties) Act 1999 to enforce
                      any term of this agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  SEVERANCE
                  <ul>
                    <li>
                      Any term of this agreement which is wholly or partially
                      void or unenforceable is severed to the extent that it is
                      void or unenforceable. The validity and enforceability of
                      the remainder of this agreement is not limited or
                      otherwise affected.
                    </li>
                  </ul>
                </li>
                <li>
                  JOINT AND SEVERAL LIABILITY
                  <ul>
                    <li>
                      An obligation or a liability assumed by, or a right
                      conferred on, two or more persons binds or benefits them
                      jointly and severally.
                    </li>
                  </ul>
                </li>
                <li>
                  ASSIGNMENT
                  <ul>
                    <li>
                      A party cannot assign, novate or otherwise transfer any of
                      its rights or obligations under this agreement without the
                      prior written consent of the other party.
                    </li>
                  </ul>
                </li>
                <li>
                  COSTS
                  <ul>
                    <li>
                      Except as otherwise provided in this agreement, each party
                      must pay its own costs and expenses in connection with
                      negotiating, preparing, executing and performing this
                      agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  ENTIRE AGREEMENT
                  <ul>
                    <li>
                      This agreement embodies the entire agreement between the
                      parties and supersedes any prior negotiation, conduct,
                      arrangement, understanding or agreement, express or
                      implied, in relation to the subject matter of this
                      agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  INTERPRETATION
                  <ul>
                    <li>
                      (singular and plural) words in the singular includes the
                      plural (and vice versa);
                    </li>
                    <li>
                      (gender) words indicating a gender includes the
                      corresponding words of any other gender;
                    </li>
                    <li>
                      (defined terms) if a word or phrase is given a defined
                      meaning, any other part of speech or grammatical form of
                      that word or phrase has a corresponding meaning;
                    </li>
                    <li>
                      (person) a reference to “person” or “you” includes an
                      individual, the estate of an individual, a corporation, an
                      authority, an association, consortium or joint venture
                      (whether incorporated or unincorporated), a partnership, a
                      trust and any other entity;
                    </li>
                    <li>
                      (party) a reference to a party includes that party’s
                      executors, administrators, successors and permitted
                      assigns, including persons taking by way of novation and,
                      in the case of a trustee, includes any substituted or
                      additional trustee;
                    </li>
                    <li>
                      (this agreement) a reference to a party, clause,
                      paragraph, schedule, exhibit, attachment or annexure is a
                      reference to a party, clause, paragraph, schedule,
                      exhibit, attachment or annexure to or of this agreement,
                      and a reference to this agreement includes all schedules,
                      exhibits, attachments and annexures to it;
                    </li>
                    <li>
                      (document) a reference to a document (including this
                      agreement) is to that document as varied, novated,
                      ratified or replaced from time to time;
                    </li>
                    <li>
                      (headings) headings and words in bold type are for
                      convenience only and do not affect interpretation;
                    </li>
                    <li>
                      includes) the word “includes” and similar words in any
                      form is not a word of limitation;{" "}
                    </li>
                    <li>
                      (adverse interpretation) no provision of this agreement
                      will be interpreted adversely to a party because that
                      party was responsible for the preparation of this
                      agreement or that provision;{" "}
                    </li>
                    <li>
                      (currency) a reference to £ or GBP is to pound sterling
                      currency unless otherwise agreed in writing.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            <div className="cont">
              <p>
                By using the Website, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
