import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function HomeownerAdvice() {
  return (
    <UserLayout>
      <div>
        <div className="container">
          <div className="home_advice">
            <h2 className="text-center">ADVICE</h2>
            <p className="text-center ">For homeowners.</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="cont_sec">
                  <h2>SHOP AROUND.</h2>
                  <p>
                    It’s not just about finding the most wallet-friendly skilled
                    labourer – although it can be. We believe in the power of
                    3’s. Get at least three quotes, meet them to get a feel for
                    the way they work, and compare costs from there. Now, you
                    won’t get stung by that sky-high quote.
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>QUESTIONS TO ASK.</h2>
                  <p>
                    You’ve booked your slot and put the kettle on – now what? To
                    ensure they fit your needs like a glove, it’s always best to
                    go information hunting. Think asking about their
                    qualifications, checking how long they’ve been in the
                    business, ensuring they have insurance, querying timelines,
                    and asking to see examples of similar projects they’ve done.
                    Don’t worry about payments, that’s all on us.
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>WHAT TO LOOK OUT FOR.</h2>
                  <p>
                    Now, bad eggs don’t always show themselves in flashing
                    lights, but there are warning signs. At Smart Choice, our
                    reviews are designed to do the work for you – like asking a
                    friend for a recommendation, times 10. From
                    too-good-to-be-true prices and timekeeping issues to members
                    asking to be paid outside of the platform, trust your gut.
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>TRADESPERSON CHECKLIST.</h2>
                  <p>
                    Lucky you – you’re spoilt for choice. But when push comes to
                    shove, and you’ve got to decide, here’s how to cut through
                    the noise. Are they responsive, with professional replies?
                    What was their first impression – friendly, or in a hurry to
                    head to their next job? Were they on time? Were they
                    courteous, keeping things tidy? Do they use plain language
                    or confusing jargon? Are they a general labourer, or a
                    specialist? What do their reviews say?
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>ISSUES? WE’RE HERE.</h2>
                  <p>
                    We’ll admit it – we’ll never compromise when it comes to
                    you. So, whether it’s an issue with a tradesperson or
                    difficulties with our website, our friendly team will be at
                    your fingertips. They’re the kind of people who will stop at
                    nothing to keep you happy, whatever the weather.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
