import { combineReducers } from 'redux';
import userSigninReducer from './Reducer/AuthReducer';
import loaderReducer from './Reducer/loaderReducer';
import UserReducer from './Reducer/UserReducer';
import TradeReducer from './Reducer/TradeReducer';
const reducer = combineReducers({
  userSigninReducer: userSigninReducer,
  loaderReducer:loaderReducer,
  useAction:UserReducer,
  tradeAction:TradeReducer
  
});

export default reducer;
