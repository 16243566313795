import React from "react";
import { Link } from "react-router-dom";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function Blogs() {
  return (
    <UserLayout>
      <section className="blogs-sect discover common-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blogs-title">
                <h2 className="text-center mb-5">Blogs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 table-d">
              <Link
                to="/outsmarting-rogue-traders"
                className="blogs-sect-inner"
              >
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>
                    Outsmarting Rogue Traders: Your Guide to Dodging Disaster in
                    UK Construction
                  </h4>
                </div>
                <p>
                  Picture this: You're excited about your upcoming construction
                  project, envisioning your dream space taking shape. But wait!
                  Cue the ominous music because lurking in the shadows are rogue
                  traders, ready to turn your dreams into nightmares. Fear not,
                  brave homeowner or investor! In this blog post, we'll arm you
                  with knowledge and a dash of British wit to help you spot and
                  avoid rogue traders in the UK construction scene.
                </p>
              </Link>
            </div>
            <div className="col-lg-4 table-d">
              <Link to="/budget-friendly-planning" className="blogs-sect-inner">
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>Budget-Friendly Planning </h4>
                </div>
                <p>
                  Dreaming of a home makeover without breaking the bank? You're
                  in the right place! In this guide, we'll show you how to plan
                  your budget-friendly renovation while optimising your content
                  for search engines. Let's dive into the world of savvy
                  budgeting and SEO success.
                </p>
              </Link>
            </div>
            <div className="col-lg-4 table-d">
              <Link
                to="/becoming-eco-friendly-builder"
                className="blogs-sect-inner"
              >
                <img
                  src={require("../assets/images/landing-page/service-img-9.png")}
                  className="img-fluid"
                  alt="Electrical Problems"
                />
                <div className="blog-head">
                  {/* <span>12th Oct</span> */}
                  <h4>
                    Becoming an Eco-Friendly Builder: A Path to Sustainable
                    Construction
                  </h4>
                </div>
                <p>
                  The construction industry plays a significant role in shaping
                  our world, and as an aspiring builder, you have the
                  opportunity to make a positive impact on the environment. In
                  this blog post, we’ll explore the steps to becoming an
                  eco-friendly builder, from embracing sustainable practices and
                  materials to navigating certifications. Let’s embark on the
                  journey to becoming an eco-friendly builder!
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
}
