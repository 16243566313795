import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userVerify } from '../../redux/Action/UserLoginAction';
import Loader from '../loader/Loader';

const VerifyUser = () => {
    const {id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const verified = useSelector((state) => state);
    useEffect(() => {
      if (id) {
        dispatch(userVerify(id));
      }
    }, [id]);
    useEffect(() => {
      if (verified) {
        navigate("/");
      }
    }, [verified]);
  return (
    <div>
        <Loader />
    </div>
  )
}

export default VerifyUser