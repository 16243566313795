import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function Tradeperson() {
  return (
    <UserLayout>
      <div>
        <div className="container">
          <div className="home_advice">
            <h2 className="text-center">ADVICE</h2>
            <p className="text-center ">For tradespeople.</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="cont_sec">
                  <h2>ON OUR PLATFORM.</h2>
                  <p>
                    It’s the big question – how can you stand out in a sea of
                    skilled tradesmen? Well, we think it all comes down to
                    knowing your target customers, and what they’re looking for
                    in a tradesperson. It could be cost, pushing you to stay
                    competitive. It could be craftsmanship, using photos to show
                    how great you are. It could be excellent service, with a
                    desire to feel not just heard, but listened to. Beneath all
                    good trade businesses lie even better insights.
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>WHEN YOU GET THERE.</h2>
                  <p>
                    The truth is, first impressions count. But it’s about more
                    than a friendly face. Arrive on time, every time – or let
                    your customer know in advance if you’re running late. Dress
                    professionally, free of holes or stains. And prove that
                    you’ll look after the customer’s home, whether that’s
                    offering to take your shoes off at the door or bringing shoe
                    covers with you. It’s all in the details.
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>WHILST YOU’RE THERE.</h2>
                  <p>
                    Now is the time to sell yourself, without the sales pitch.
                    It’s best to get on their level, from using language they
                    understand to carefully going through costs, timelines, and
                    potential issues. Honesty is always appreciated, so don’t
                    shy away from talking about noise, possible setbacks, and
                    limitations.{" "}
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>WHEN YOU’RE LEAVING.</h2>
                  <p>
                    Working with a trader can be daunting – but it doesn’t have
                    to be. By letting them know exactly what happens next,
                    whether you need to get in touch with a supplier, come back
                    for another inspection, or you’re ready to send a quote
                    their way, you’ll help them feel in control. Better yet, let
                    the homeowner know how they can stay in touch.
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>AFTER YOU’VE LEFT.</h2>
                  <p>
                    Once you’re ready to send over the quote, make it count.
                    Think moving forward without delay, summarising everything
                    you discussed, and inviting them to ask the questions that
                    they didn’t get a chance to. It’s not in the bag until
                    they’ve signed the dotted line, so don’t fall at the last
                    hurdle.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="home_advice">
            <h2 className="text-center">WE DO THINGS DIFFERENTLY</h2>
            <p className="text-center ">But what’s in it for you?</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="cont_sec">
                  <h2>But what’s in it for you? MORE TIME MAKING MONEY.</h2>
                  <p>
                    Less time getting lost in email chains. We’re here to give
                    you more hours in the day, taking on the booking, pitching,
                    and scheduling for you, while you focus on the moneymaking.
                    Easy peasy.
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>MARKETING? THAT’S OLD SCHOOL.</h2>
                  <p>
                    We’ll leave the projects to you, while you leave the
                    marketing to us. It’s all about putting your business in the
                    eye line of local people, making you look good, without
                    breaking the bank with ads.
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>TRUST, MADE EASY.</h2>
                  <p>
                    It’s true – by being one of our tried-and-tested traders,
                    you’ll enjoy instant customer trust. The result? Higher
                    quotes get accepted, higher conversion rates, and higher
                    client satisfaction. A win-win-win.
                  </p>
                </div>
                <div className="cont_sec text-end">
                  <h2>WHEN YOU’RE LEAVING.</h2>
                  <p>
                    Working with a trader can be daunting – but it doesn’t have
                    to be. By letting them know exactly what happens next,
                    whether you need to get in touch with a supplier, come back
                    for another inspection, or you’re ready to send a quote
                    their way, you’ll help them feel in control. Better yet, let
                    the homeowner know how they can stay in touch.
                  </p>
                </div>
                <div className="cont_sec">
                  <h2>LOVE CONVENIENCE? </h2>
                  <p>
                    You’ll love us. We’re all about making your life easier,
                    from our simple online payments, the ability to showcase
                    your best work, and our instant scheduling tool to an
                    opportunity to learn more about your customers before giving
                    them the thumbs up. It’s the type of directory of services
                    that takes away the cons and leaves you with all the pros.
                    Your business, but better.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
