import { toast } from "react-toastify";
import API from "../../service/Api";
import {
  START_LOADING,
  STOP_LOADING,
  USER_REGISTER,
} from "../Constant/constant";
import Cookies from "universal-cookie";
export const userLogin =
  (userData, rememberMe, navigate) => async (dispatch) => {
    const cookies = new Cookies();
    // dispatch({ type: START_LOADING, payload: true });

    try {
      const Login = await API.post("/auth/login", userData);
      if (rememberMe && Login.status == 200) {
        cookies.set("userToken", Login.data.data.token, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
        sessionStorage.setItem("userToken", Login.data.data.token);
        sessionStorage.setItem("userId", Login.data.data._id);
        window.location.href = "/dashboard";
      }
      if (!rememberMe && Login.status == 200) {
        sessionStorage.setItem("userToken", Login.data.data.token);
        sessionStorage.setItem("userId", Login.data.data._id);
        toast.success(Login.data.message);
        window.location.href = "/dashboard";

        // dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(Login.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };
export const userRegister = (userData, navigate) => async (dispatch) => {
  try {
    const userLogin = await API.post("/auth/register", userData);
    if (userLogin.status == 200) {
      dispatch({
        type: USER_REGISTER,
        payload: userData?.data,
      });
      // toast.success(userLogin.data.message)
      navigate("/sign-up-success");
    } else {
      toast.error(userLogin.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const userVerify = (id) => async (dispatch) => {
  try {
    const verify = await API.get(`/auth/verifyUser/${id}`);
    if (verify.status == 200) {
      toast.success(verify.data.message);
    } else {
      toast.error(verify.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const forgotPassword = (data, navigate) => async (dispatch) => {
  try {
    const forgotPass = await API.post("/auth/forgotPassword", data);
    if (forgotPass.status == 200) {
      toast.success(forgotPass.data.message);
      navigate("/");
    } else {
      toast.error(forgotPass.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const resetPassword = (data, navigate) => async (dispatch) => {
  try {
    const resetPass = await API.post("/auth/resetPassword", data);
    if (resetPass.status == 200) {
      toast.success(resetPass.data.message);
      navigate("/");
    } else {
      toast.error(resetPass.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
