import React, { useEffect, useRef, useState } from "react";
import UserAccLayout from "../../components/user_acc_home/layout/UserAccLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEnquiry,
  getReview_Detail,
  updateMsgCount,
} from "../../redux/Action/UserActions";
import moment from "moment";
import Details from "../../components/myEnquires/Details";
import ReactStars from "react-rating-stars-component";
import ReviewModal from "../../components/user_acc_home/tradepersonRewiew/ReviewModal";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
const MyEnquiries = () => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const BASE_URL = process.env.REACT_APP_SOCKET_URL;
  const socket = useRef();
  const [show, setShow] = useState(false);
  const [reviewData, setReviewData] = useState();
  const [totalMsgCount, seTotalMsgCount] = useState();
  const dispatch = useDispatch();

  const enquiry = useSelector((state) => state?.useAction?.enquiry);
  const newMessages = useSelector((state) => state?.useAction?.userNewMessage);
  const reviewDetailMain = useSelector(
    (state) => state?.useAction?.review_detail_new
  );
  const [enquiryDetail, setEnquiryDetail] = useState();
  const [reviewDetail, setReviewDetail] = useState();
  const [Enquiry, setEnquiry] = useState();
  useEffect(() => {
    socket.current = io.connect(BASE_URL);
    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(getAllEnquiry());
  }, []);

  useEffect(() => {
    if (window.location.href?.includes("#")) {
      if (enquiry?.length > 0) {
        console.log(enquiry);
        const data = enquiry?.filter(
          (item) => item?._id == window.location.href?.split("#")[1]
        );
        setEnquiryDetail(data[0]);
        sessionStorage.setItem("enquiryId", data[0]?._id);
        dispatch(getReview_Detail(data[0]?.tradeId?._id));
      }
    } else {
      if (enquiry?.length > 0) {
        setEnquiryDetail(enquiry[0]);
        sessionStorage.setItem("enquiryId", enquiry[0]?._id);
        dispatch(getReview_Detail(enquiry[0]?.tradeId?._id));
      }
    }
  }, [enquiry, window.location.href]);

  // console.log(enquiry[0].userId?._id ,'enquiry[0]?.tradeId?._id');
  useEffect(() => {
    setReviewDetail(reviewDetailMain);
  }, [reviewDetailMain]);

  const reviewApi = (id) => {
    dispatch(getReview_Detail(id?.tradeId?._id)).then((data) => {
      setReviewDetail(data);
    });
  };
  const viewFullReviews = (val) => {
    if (val) {
      setShow(true);
      setReviewData(val);
    }
  };

  useEffect(() => {
    if (enquiryDetail) {
      (async () => {
        await socket.current.emit(
          "join_room",
          sessionStorage.getItem("userId")
        );
      })();
      handleMsgCount(enquiryDetail);
    }
  }, [enquiryDetail]);

  useEffect(() => {
    socket.current.on("unread_count", (messageObject) => {
      setEnquiry((prev) => {
        prev?.forEach((element) => {
          if (element?._id == messageObject?._id) {
            element.unread_count_user = messageObject?.unread_count_user;
          }
        });
        return [...prev];
      });
    });
  }, [socket]);

  useEffect(() => {
    setEnquiry(enquiry);
  }, [enquiry]);
  useEffect(() => {
    if (Enquiry) {
      const data = Enquiry?.map((item) => item?.unread_count_user).reduce(
        (a, b) => a + b,
        0
      );
      seTotalMsgCount(data);
    }
  }, [Enquiry]);
  const handleMsgCount = (data) => {
    dispatch(
      updateMsgCount({
        unread_count_user: 0,
        id: data?._id,
      })
    ).then((res) => {
      setEnquiry((prev) => {
        prev?.forEach((element) => {
          if (element?._id == data?._id) {
            element.unread_count_user = 0;
          }
        });
        return [...prev];
      });
    });
  };
  return (
    <UserAccLayout>
      <ReviewModal show={show} setShow={setShow} reviewData={reviewData} />
      <section className="enquiry_sect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 p-0 col-md-4 col-sm-12 col-12">
              <div className="sidebar-enquiries">
                <div className="enquiries-title d-flex justify-content-between">
                  <h4 className="blck_color">
                    Showing {Enquiry?.length} Enquiries
                  </h4>
                  <div className="d-flex">
                    <h4>{totalMsgCount} New Messages</h4>
                    <img
                      src={
                        require("../../assets/images/enquiries/filter.svg")
                          .default
                      }
                      alt="filter" 
                      className="ms-2"
                    />
                  </div>
                </div>
                <ul
                  className={`sidebar_lisiting ${
                    Enquiry?.length == 0 && " sidebar_listing_blank"
                  }`}
                >
                  {Enquiry?.length > 0 ? (
                    Enquiry?.map((item, i) => {
                      var duration = moment.duration(
                        moment().diff(item?.createdAt)
                      );

                      var days = duration.asDays();
                      var hours = duration.asHours();
                      var minutes = duration.asMinutes();
                      return (
                        <li
                          onClick={() => {
                            setEnquiryDetail(item);
                            reviewApi(item);
                            handleMsgCount(item);
                            sessionStorage.setItem("enquiryId", item._id);
                          }}
                          className={
                            enquiryDetail == item
                              ? "box_shadow_border mb-4"
                              : "box_shadow_border_white mb-4"
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="dot"></span>
                            <span className="time">
                              {hours > 24
                                ? `${Math.floor(days)} hours ago`
                                : minutes > 60
                                ? `${Math.floor(hours)} hours ago`
                                : Math.floor(minutes) == 0
                                ? "Just now"
                                : `${Math.floor(minutes)} minutes ago`}{" "}
                            </span>
                          </div>
                          <div className="ps-4 enquiry_content">
                            <h3>{item?.company_name}</h3>
                            <p>{item?.serviceId?.service}</p>
                            <p className="mb-2 comment_text_enq mt-2">
                              {item?.enquiry_address?.city}
                            </p>
                            <p className="comment_text_enq">
                              {item?.chats?.message}
                            </p>
                            <h4 className="text-end">
                              <strong>
                                {item?.unread_count_user == 0
                                  ? ""
                                  : item?.unread_count_user}{" "}
                              </strong>
                              {
                                item?.unread_count_user == 0 ? "" : "Replied to you"
                              }
                            </h4>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div className="no-enquiry-img">
                      <img
                        src={
                          require("../../assets/images/no-enquiry.svg").default
                        }
                      />
                      <p>No Enquiries yet</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 col-12">
              <div className="enquiry_detail">
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8">
                    {Enquiry?.length > 0 ? (
                      <Details socket={socket} enquiryDetail={enquiryDetail} />
                    ) : (
                      <p className="no-chats">No Chats yet</p>
                    )}
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4">
                    <div className="sidebar_reviews">
                      <h3 className="text-center">
                        What People Say about {enquiryDetail?.company_name}
                      </h3>
                      <ul className="review-list">
                        {reviewDetail?.reviews.length > 0 ? (
                          reviewDetail?.reviews?.map((item, i) => {
                            return (
                              <li className="mb-4 list-unstyled">
                                <div className="recent_reviews box_shadow ">
                                  <div className="review_detail">
                                    <div className="review_detail_left">
                                      <img
                                        src={
                                          item?.senderId?.profile_image &&
                                          item?.senderId?.profile_image !=
                                            "undefined"
                                            ? BACKEND_URL +
                                              "profile_image/" +
                                              item?.senderId?.profile_image
                                            : require("../../assets/images/tradesperson/review-profile.png")
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="review_detail_right">
                                      <h3 className="revewier_name">
                                        {item?.senderId?.firstName +
                                          " " +
                                          item?.senderId?.lastName}
                                      </h3>
                                      <div className="review_sect mt-2 mb-2">
                                        <div className="review_ratings_box star_width lineHeight_stars">
                                          {item?.overall_rating ? (
                                            <ReactStars
                                              count={5}
                                              edit={false}
                                              isHalf={true}
                                              value={item?.overall_rating}
                                              size={40}
                                              activeColor="#ffd700"
                                            />
                                          ) : (
                                            <ReactStars
                                              count={5}
                                              edit={false}
                                              isHalf={true}
                                              size={40}
                                              activeColor="#ffd700"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <p className="comment_text">
                                        {item?.comments
                                          ? item?.comments
                                          : "TradePerson not gives any types of comments"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-4 justify-content-between">
                                    <p>
                                      {moment(item?.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <a
                                      className=""
                                      onClick={() => viewFullReviews(item)}
                                    >
                                      More
                                      <img
                                        src={
                                          require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                            .default
                                        }
                                        alt=""
                                        className="ms-1"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <div className="no-enquiry-img review-img">
                            <img
                              src={
                                require("../../assets/images/no-reviews.svg")
                                  .default
                              }
                            />
                            <p>No Reviews yet</p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserAccLayout>
  );
};

export default MyEnquiries;
