import { useFormik } from "formik";
import React, { useState,forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { updateTradePassword } from "../../../redux/Action/tradeActions";
import RegularPopup from "../../regular_popup/RegularPopup";
const UpdatePassword = (props,ref) => {
  const [showPass, setShowPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmsPass] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Required";
    }
    if (!values.new_password) {
      errors.new_password = "Required";
    }
    if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,
    onSubmit: (values) => {
      const obData = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      if (values.new_password == values.confirm_password) {
        dispatch(updateTradePassword(obData,setShowModal));
      }
    },
  });
  // const handleSubmit=()=>{
  // formik.handleSubmit()
  // }
  // useImperativeHandle(ref, () => ({
  //   handleSubmit
  // }));
  return (
    <>
    {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"tradeMyAccount"} />}
    <div className="col-lg-8">
      <form onSubmit={formik.handleSubmit} className="form_new">
         <div className="text-end mb-4">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        <div className="new_enquiry_content">
          <div className="form-group mb-3">
            <label for="">Old Password</label>
            <div className="input-group box_shadow_new input_default_grp">
              <input
                type={showPass ? "text" : "password"}
                className="form-control "
                placeholder="****"
                name="old_password"
                onChange={formik.handleChange}
                value={formik.values.old_password}
              />
              <div className="input-group-prepend">
                <span
                  onClick={() => setShowPass((pre) => !pre)}
                  className="input-group-text bl-none "
                  id="basic-addon1"
                >
                  <i
                    className={showPass ? "fa fa-eye-slash" : "fa fa-eye"}
                    id="eye"
                  ></i>
                </span>
              </div>
            </div>
            {formik.errors.old_password && (
              <p className="formik-error">{formik.errors.old_password}</p>
            )}
          </div>
          <div className="form-group mb-3">
            <label for="">New Password</label>
            <div className="input-group box_shadow_new input_default_grp">
              <input
                type={newPass ? "text" : "password"}
                className="form-control"
                placeholder="****"
                name="new_password"
                onChange={formik.handleChange}
                value={formik.values.new_password}
              />
              <div className="input-group-prepend">
                <span
                  onClick={() => setNewPass((pre) => !pre)}
                  className="input-group-text bl-none "
                  id="basic-addon1"
                >
                  <i
                    className={newPass ? "fa fa-eye-slash" : "fa fa-eye"}
                    id="eye"
                  ></i>
                </span>
              </div>
            </div>
            {formik.errors.new_password && (
              <p className="formik-error">{formik.errors.new_password}</p>
            )}
          </div>
          <div className="form-group mb-3">
            <label for="">Re-Enter New Password</label>
            <div className="input-group box_shadow_new input_default_grp">
              <input
                type={confirmPass ? "text" : "password"}
                className="form-control "
                placeholder="****"
                name="confirm_password"
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
              />
              <div className="input-group-prepend">
                <span
                  onClick={() => setConfirmsPass((pre) => !pre)}
                  className="input-group-text bl-none "
                  id="basic-addon1"
                >
                  <i
                    className={confirmPass ? "fa fa-eye-slash" : "fa fa-eye"}
                    id="eye"
                  ></i>
                </span>
              </div>
            </div>
            {formik.errors.confirm_password && (
              <p className="formik-error">{formik.errors.confirm_password}</p>
            )}
          </div>
          {/* <div className="text-end pt-35">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div> */}
        </div>
      </form>
    </div>
    </>
  );
};

export default forwardRef(UpdatePassword);
