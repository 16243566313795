import React, { createContext, useContext, useEffect, useState } from "react";
import UserAccLayout from "../../components/user_acc_home/layout/UserAccLayout";
import searchLogo from "../../assets/images/tradesperson/search.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServiceCategory1,
  getDataVal,
  getprofileDetail,
  searchTrade,
} from "../../redux/Action/UserActions";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import axios from "axios";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";

const AccFindTradePerson = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const val = useSelector((state) => state?.useAction?.selected);
  const dispatch = useDispatch();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    dispatch(fetchServiceCategory1());
    dispatch(getDataVal());

    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position,"position")
          setStatus(true);
          setLat(position.coords.latitude);
          setLong(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      );
    }
  }, []);

  const searchAction = () => {
    if (val && location != "location") {
      navigate(
        `/tradespersonList/${val?.category}/${val.label}/${location}/${googleLat}/${googleLong}/${lat}/${long}`
      );

    } else if (val) {
      navigate(
        `/tradespersonList/${val?.category}/${val.label}/${location}/lat/long/${lat}/${long}`
      );
    }
  };



  return (
    <UserAccLayout>
      {/* <!-- Find-a-tradesperson --> */}
      <section className="tardeperson_section common_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main_head">
                <h1 className="mb-3 text-center">Find a Tradesperson</h1>
              </div>
              <div className="search_sect_inner">
                <form action="">
                  <div className="d-flex search_content">
                    <div className="w-53 position-relative search-input">
                      {/* <input
                          type="search"
                          name="search"
                          placeholder="Search by Trade"
                          className="form-control"
                        /> */}
                      <MultiSelect />
                      {/* <div className="search_img">
                          <img src={searchLogo} alt="search" />
                        </div> */}
                    </div>
                    <div className="w-47 search_area search_area_new">
                      <div className="w-60">
                        <Autocomplete
                         
                          apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                          onPlaceSelected={(place) => {
                            setLocation(place?.formatted_address);
                            setGoogleLat(place?.geometry?.location?.lat());
                            setGoogleLong(place?.geometry?.location?.lng());
                          }}
                          types={["address"]}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "uk" },
                          }}
                        />
                        {/* <input
                          type="text"
                          name="location"
                          placeholder="Near"
                          className="form-control"
                          onChange={(e) => setLocation(e.target.value)}
                        /> */}
                      </div>
                      <div className="w-35">
                      {
                        location? <button
                          className="form-control search_btn"
                          onClick={(e) => searchAction(e.preventDefault())}
                        >
                          Search
                        </button>: <button
                          className="form-control search_btn"
                          onClick={(e) => {
                            e.preventDefault();
                            toast.error("Please input a location to continue")
                          }}
                        >
                          Search
                        </button>
                      }
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-center content_center">
                  <p>
                    Get a free quotation in minutes from reputable Tradespeople
                    in your local area. All Tradespeople listed on our site are
                    vetted by our 20 point quality assurance process. We
                    guarantee quality and affordability to everyone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Find-a-tradesperson / End--> */}

      {/* <!-- Popular Categories --> */}
      {/* <section className="recent_requests common_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub_head">
                <h4>Popular Categories</h4>
              </div>
              <div className="d-flex justify-content-between service_boxes">
                <div className="service">
                  <p className="box"></p>
                  <h4>Service One</h4>
                </div>
                <div className="service">
                  <p className="box"></p>
                  <h4>Service Two</h4>
                </div>
                <div className="service">
                  <p className="box"></p>
                  <h4>Service Three</h4>
                </div>
                <div className="service">
                  <p className="box"></p>
                  <h4>Service Four</h4>
                </div>
                <div className="service">
                  <p className="box"></p>
                  <h4>Service Five</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Popular Categories / End--> */}
    </UserAccLayout>
  );
};

export default AccFindTradePerson;
