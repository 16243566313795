import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import UserLayout from "../components/layout/user_layout/UserLayout";
export default function Faqs() {
    return (
      <UserLayout>
      <div>
          
          <div className='container'>
              <h2 className='text-center main_hdng pb-4 pt-4'>FAQS</h2>
              <div className='main_accord mt-4 mb-4'>
                  <h2 className='pb-4'>I’M A TRADESPERSON</h2>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>How do I get more bookings?</Accordion.Header>
        <Accordion.Body>
          A picture speaks a thousand words – or just six, “I’m good at what I do”. If you put the spotlight on your best work (don’t forget videos!), keep things professional, and keep your prices competitive, you’ll be given the platform to thrive. No daunting marketing. No expensive websites. Just you, doing what you do best.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How do payments work?</Accordion.Header>
        <Accordion.Body>
          Well, like clockwork. Simply arrive at the customer’s home during the booked timeslot, inspect the job as usual, and send over a quote on our website. If they accept, they’ll need to pay a 15-20% deposit, ensuring you’re never left out-of-pocket. It’s a chasing-up-free zone.
Friendly reminder: The quote is not a contract, and we’re not legally responsible should the customer decide against going ahead with the project.

        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
        <Accordion.Header>How do I achieve a badge?</Accordion.Header>
        <Accordion.Body>
          They’re in your customer’s hands. It all comes down to keeping them happy, with a 75% satisfaction rate giving you Smart Gold Tradesperson status, an 87% rate landing you Smart Platinum Tradesperson status, and a 95% rate putting you in the top 5% of traders, with Smart Diamond status. If this is you, give yourself a pat on the back.

        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
        <Accordion.Header>Since customers can review us, can we review them?</Accordion.Header>
        <Accordion.Body>
          Yes! It’s a two-way street, giving you the customer insights you need before you take on their project. We think of it as a win-win.

        </Accordion.Body>
                      </Accordion.Item>
                    
    </Accordion>
              </div>
              
               <div className='main_accord mt-4 mb-4'>
                  <h2 className='pb-4'>I’M A CUSTOMER.</h2>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Do you guarantee the quality of your tradespeople?</Accordion.Header>
        <Accordion.Body>
         Even better, our customers do. It’s all about inviting you to have your say – not a member of the team in an office ten miles away. Simply put, each review impacts the member’s satisfaction rate; they need to keep 75% of customers satisfied to keep their Smart Gold Tradesperson status, 87% to remain a Smart Platinum Tradesperson, and 95% to keep their Smart Diamond status in the bag.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>I’m concerned about bait-and-switch tactics. Can you help?</Accordion.Header>
        <Accordion.Body>
          You’re in luck. Our members aren’t the type to think up a quote once they get to your doorstep. Instead, it’s all done online, from the initial quote to the payment of the deposit. It’s as easy as ordering a takeaway – but a whole lot more reliable.

        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
        <Accordion.Header>Vetting. How does it work?</Accordion.Header>
        <Accordion.Body>
          We believe that we’re only as good as our least-popular member. So much so, we’re here to make sure every trader is as trusted as the next. Think business checks, background checks, and those all-important references, putting you in great hands.

        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
        <Accordion.Header>Can I trust that the profile images are authentic?</Accordion.Header>
        <Accordion.Body>
          They’re not only authentic – they’re recent, too. We ask our members to change their photos each year, so their profile represents their current standard of work. No catfishing, here.

        </Accordion.Body>
                      </Accordion.Item>
                       <Accordion.Item eventKey="4">
        <Accordion.Header>I’m not sure what I need. Who do I turn to?</Accordion.Header>
        <Accordion.Body>
          You don’t need to have all the answers. We do. If you’re looking for help around the house – or office, garden, or unit – our handymen are happy to work on an hourly basis. Simply decide how many hours you’d like, pay upfront, and you’re all set.

        </Accordion.Body>
                      </Accordion.Item>
                    
    </Accordion>
          </div>
          </div>
            </div>
            </UserLayout>
  )
}
