import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import rightArrow from "../../assets/images/right_arrow.svg";
import TradeLayout from "../../components/tradepeople/layout/TradeLayout";
import { tradeRegister } from "../../redux/Action/tradeLoginAction";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import RegularPopup from "../../components/regular_popup/RegularPopup";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { honorifics } from "../../utils/honorifics";
import { Select } from "@mui/material";
import UserLayout from "../../components/layout/user_layout/UserLayout";

const TradeRegister = () => {
  var today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];
  const [postCode, setPostCode] = useState("");
  const [adressValue, setAdressValue] = useState("");
  const [addressApi, setAddressApi] = useState();
  const [postAddress, setPostAddress] = useState();
  const [searchedPostCode, setSearchedPostCode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [validateValue, setValidateValue] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleKeyEnter = (e) => {
    const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
    axios
      .get(url)
      .then((res) => {
        setAddressApi(res.data);
        setSearchedPostCode(true);
      })
      .catch((err) => {
        if (err?.response?.data?.Message == "Bad Request: Invalid postcode.") {
          toast.warning("Invalid Postcode");
          setSearchedPostCode(false);
        }
      });
  };

  useEffect(() => {
    if (addressApi) {
      const data = addressApi?.addresses?.map((item) => {
        return item;
      });
      setPostAddress(data);
    }
  }, [addressApi]);
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Enter first name";
    }
    //  else if (values.firstName.split(" ").length > 1) {
    //   errors.firstName = "Space not Allowed";
    // } else if (!/^(?=.*[a-zA-Z])[A-Za-z0-9]+$/.test(values.firstName)) {
    //   errors.firstName = "Number not allowed";
    // }
    if (!values.lastName) {
      errors.lastName = "Enter surname ";
    }
    // else if (values.lastName.split(" ").length > 1) {
    //   errors.lastName = "Space not allowed";
    // } else if (!/^(?=.*[a-zA-Z])[A-Za-z0-9]+$/.test(values.lastName)) {
    //   errors.lastName = "Number not allow";
    // }
    if (!values.company_name) {
      errors.company_name = "Enter company name";
    }
    if (!values.email) {
      errors.email = "Enter email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Enter correct email";
    }
    if (!values.mobile) {
      errors.mobile = "Required";
    } else if (
      values.mobile.toString().length > 16 ||
      values.mobile.toString().length < 10
    ) {
      errors.mobile = "Invalid phone number";
    }
    if (!values.date) {
      errors.date = "Select date";
    }

    if (!values.time) {
      errors.time = "Select time";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      // honorific: "",
      firstName: "",
      lastName: "",
      company_name: "",
      your_trade: "",
      telephone: "",
      mobile: "",
      email: "",
      date: "",
      time: "",
      multi_services: [],
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      console.log(values, "values");
      const value = {
        // honorific: values.honorific,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        postcode: postCode,
        address: adressValue,
        latitude: addressApi?.latitude,
        longitude: addressApi?.longitude,
        company_name: values.company_name,
        your_trade: values.your_trade,
        multi_services: values.multi_services,
        address_1: adressValue.split(",")[0],
        address_2: adressValue.split(",")[1],
        city: adressValue.split(",")[2],
        country: adressValue.split(",")[3],
        telephone: values.telephone,
        mobile: values.mobile,
        date: values.date,
        time: values.time,
        business_locations: [
          {
            address: adressValue,
            address_1: adressValue.split(",")[0],
            address_2: adressValue.split(",")[1],
            city: adressValue.split(",")[2],
            country: adressValue.split(",")[3],
            latitude: addressApi?.latitude,
            longitude: addressApi?.longitude,
            postcode: postCode,
            radius: "",
            location: {
              coordinates: [addressApi?.longitude, addressApi?.latitude],
            },
          },
        ],
        term_condition: termsConditions == true ? 1 : 0,
      };
      if (!value?.multi_services.length > 0) {
        return toast.warning("Please provide services");
      }
      if (termsConditions == false) {
        return toast.warning("Please accept terms and conditions");
      }
      if (!postCode) {
        return toast.warning("Please provide postcode");
      } else if (!adressValue) {
        return toast.warning("Please provide address");
      } else {
        try {
          dispatch(tradeRegister(value, setShowModal));
        } catch (error) {
          console.log("error");
        }
      }
    },
  });

  return (
    <UserLayout>
      {showModal == true && (
        <RegularPopup
          showModal={showModal}
          setShowModal={setShowModal}
          modalName={"tradeRegister"}
        />
      )}
      {/* <!-- banner  start --> */}
      <section className="banner-img">
        <div className="container-fluid container_inner_width">
          <div className="banner-content">
            <h1 className="mb-0">
              Join as a Smart Choice
              <br />
              Member Today{" "}
            </h1>
            <h2 className="mt-3">
              Get genuine enquiries from local clients,
              <br />
              for less than the competition.
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- banner  end -->  */}

      {/* <!-- common banner bottom part start --> */}
      <section className="trust_wrapper position-relative">
        <div className="banner_btm">
          <div className="btm_ques">
            <a href="#">
              Complete Our Online Registration Form
              <img src={rightArrow} alt="right-arrow" />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- common banner bottom part end --> */}

      {/* <!-- Registration Section start --> */}
      <section className="registration_sect">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <form onSubmit={formik.handleSubmit}>
                <div className="card card-box">
                  <div className="card-body">
                    <h2 className="text-center">Register Today</h2>

                    {/* <!-- Your Details --> */}
                    <div className="personal_details_sect">
                      <h4>Your Details</h4>
                      <div className="new_enquiry_content">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">First Name</label>
                              <InputGroup className="mb-3 box-shadow-select back_white">
                                {/* <select className="country-ui-c"
                                //placeholder="tilte"
                                  variant="outline-secondary"
                                  title="Dropdown"
                                  id="input-group-dropdown-1"
                                  name="honorific"
                                  onChange={formik.handleChange}
                                  value={formik.values.honorific}
                                >
                                  <option>Title</option>
                                 {honorifics?.map((item,i)=>{
                                  return (
                                    <><option value={item}>{item}</option></>
                                  )
                                 })}
                                  
                                </select> */}
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type Here.."
                                  name="firstName"
                                  onChange={formik.handleChange}
                                  value={formik.values.firstName.trimStart()}
                                />
                                {formik.errors.firstName && (
                                  <p className="formik-error">
                                    {formik.errors.firstName}
                                  </p>
                                )}
                              </InputGroup>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Surname</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Type Here.."
                                name="lastName"
                                onChange={formik.handleChange}
                                value={formik.values.lastName.trimStart()}
                              />
                              {formik.errors.lastName && (
                                <p className="formik-error">
                                  {formik.errors.lastName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-0">
                              <label for="">Company Name</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Type Here.."
                                name="company_name"
                                onChange={formik.handleChange}
                                value={formik.values.company_name}
                              />
                              {formik.errors.company_name && (
                                <p className="formik-error">
                                  {formik.errors.company_name}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="border-seperate"></p>
                    </div>

                    {/* <!-- Your Working Trades --> */}
                    <div className="personal_details_sect">
                      <h4>Your Working Trades</h4>
                      <div className="new_enquiry_content working_trades">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <label for="">Your Trade(s)</label>
                              <MultiSelect formik={formik} type={"register"} />
                              {/* <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Start Typing"
                                name="your_trade"
                                onChange={formik.handleChange}
                                value={formik.values.your_trade}
                              />
                              {formik.errors.your_trade && (
                                <p className="formik-error">
                                  {formik.errors.your_trade}
                                </p>
                              )} */}
                            </div>
                            <p className="mb-0">
                              Select the services you or your business offer.
                              You may be asked to provide evidence of these
                              services during your application. You can always
                              edit these later in your settings, once approved.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="border-seperate"></p>
                    </div>

                    {/* <!-- Your Contact Details --> */}
                    <div className="personal_details_sect">
                      <h4>Your Contact Details</h4>
                      <div className="new_enquiry_content">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group mb-3">
                              <label for="">Postcode</label>

                              <div className="d-flex">
                                {searchedPostCode ? (
                                  <select
                                    className="form-control box_shadow_new"
                                    onChange={(e) =>
                                      setAdressValue(e.target.value)
                                    }
                                  >
                                    <option
                                      value="none"
                                      selected="selected"
                                      disabled
                                      hidden
                                    >
                                      Please Select Address
                                    </option>
                                    {postAddress &&
                                      postAddress?.map((item) => {
                                        const arr = [
                                          item?.line_1,
                                          item?.line_2,
                                          item?.town_or_city,
                                          item?.country,
                                        ];

                                        const address_str = arr[0].concat(
                                          arr[1] === undefined ? " " : ", ",
                                          arr[1] === undefined ? "" : arr[1],
                                          arr[2] === undefined ? "" : ", ",
                                          arr[2] === undefined ? "" : arr[2],
                                          arr[3] === undefined ? "" : ", ",
                                          arr[3] === undefined ? "" : arr[3]
                                        );
                                        return (
                                          <option value={address_str}>
                                            {address_str}
                                          </option>
                                        );
                                      })}
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control box_shadow_new"
                                    placeholder="Type Here.."
                                    name="postcode"
                                    onChange={(e) =>
                                      setPostCode(e.target.value)
                                    }
                                    // onKeyDown={(e) => handleKeyEnter(e)}
                                  />
                                )}
                                <button
                                  onClick={() => handleKeyEnter()}
                                  className="btn btn-primary ms-3"
                                  type="button"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Address Line 1</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Address Line 1"
                                name="address_1"
                                value={adressValue.split(",")[0]}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Address Line 2</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Address Line 2"
                                name="address_2"
                                value={adressValue.split(",")[1]}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">City</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="City"
                                name="city"
                                value={adressValue.split(",")[2]}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Country</label>
                              <input
                                type="text"
                                className="form-control box_shadow_new"
                                placeholder="Country"
                                name="country"
                                value={adressValue.split(",")[3]}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Telephone (Optional)</label>

                              <div class="d-flex align-items-center phn-form-control box_shadow_new">
                                <div class="country_code">
                                  <p>+44</p>
                                </div>
                                <div class="phn-form-control_width">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control "
                                    placeholder="Type Here.."
                                    name="telephone"
                                    onChange={formik.handleChange}
                                    value={formik.values.telephone}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Mobile</label>
                              <div class="d-flex align-items-center phn-form-control box_shadow_new">
                                <div class="country_code">
                                  <p>+44</p>
                                </div>
                                <div class="phn-form-control_width">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control "
                                    placeholder="Type Here.."
                                    name="mobile"
                                    onChange={formik.handleChange}
                                    value={formik.values.mobile}
                                  />
                                </div>
                              </div>

                              {formik.errors.mobile && (
                                <p className="formik-error">
                                  {formik.errors.mobile}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-0">
                              <label for="">Email</label>
                              <input
                                type="email"
                                className="form-control box_shadow_new"
                                placeholder="Type Here.."
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                              />
                              {formik.errors.email && (
                                <p className="formik-error">
                                  {formik.errors.email}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="border-seperate"></p>
                    </div>

                    {/* <!-- Your Pre-Registration Call Back --> */}
                    <div className="personal_details_sect">
                      <h4>Your Pre-Registration Call Back</h4>
                      <div className="new_enquiry_content">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Date</label>
                              <input
                                type="date"
                                min={today}
                                className="form-control box_shadow_new"
                                placeholder="dd/mm/yyyy"
                                name="date"
                                onChange={formik.handleChange}
                                value={formik.values.date}
                              />
                              {formik.errors.date && (
                                <p className="formik-error">
                                  {formik.errors.date}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label for="">Time</label>
                              <input
                                type="time"
                                className="form-control box_shadow_new"
                                placeholder="--:--"
                                name="time"
                                onChange={formik.handleChange}
                                value={formik.values.time}
                              />
                              {formik.errors.time && (
                                <p className="formik-error">
                                  {formik.errors.time}
                                </p>
                              )}
                            </div>
                          </div>
                          <p>
                            Select a date and time for one of our team members
                            to call you and confirm your account. Calls
                            typically last between 5 - 10 minutes. You'll just
                            be asked a handful of questions to confirm your
                            identity and our team to give you some instructions
                            to help set up your account.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 mt-4 checkbox_opt">
                      <div className="d-flex align-items-center mb-3">
                        <input
                          type="checkbox"
                          name="checkbox"
                          value=""
                          onChange={(e) => setTermsConditions(e.target.checked)}
                        />
                        <label for="">
                          I have read and accept the{" "}
                          <a href="/TermsConditionsTraders" target="_blank">
                            <b>Terms and Conditions</b>
                          </a>{" "}
                          and{" "}
                          <a href="/PrivacyPolicy" target="_blank">
                            <b>Privacy Policy</b>
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="mt-5 d-flex justify-content-center">
                      <button
                        onClick={() => setValidateValue(true)}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-lg-4">
              <div className="service-terms">
                <h4>Terms of Service</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  viverra mi et diam cursus consectetur. Etiam ac congue nunc.
                  Aenean id dignissim nulla. Vivamus suscipit iaculis turpis,
                  vel ornare diam. Mauris blandit dapibus arcu, in ultricies
                  mauris molestie non. Integer tincidunt tellus quis dolor
                  sagittis, in hendrerit ligula egestas. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit. Duis viverra mi et diam
                  cursus consectetur. Etiam ac congue nunc. Aenean id dignissim
                  nulla. Vivamus suscipit iaculis turpis, vel ornare diam.
                  Mauris blandit dapibus arcu, in ultricies mauris molestie non.
                  Integer tincidunt tellus quis dolor sagittis, in hendrerit
                  ligula egestas. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Duis viverra mi et diam cursus consectetur.
                  Etiam ac congue nunc. Aenean id dignissim nulla. Vivamus
                  suscipit iaculis turpis, vel ornare diam. Mauris blandit
                  dapibus arcu, in ultricies mauris molestie non. Integer
                  tincidunt tellus quis dolor sagittis, in hendrerit ligula
                  egestas.{" "}
                </p>
                <div className="d-flex justify-content-center mt-5">
                  <a href="#" className="btn white-btn white_lg_btn">
                    Read More
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <!-- Registration Section End --> */}
    </UserLayout>
  );
};

export default TradeRegister;
