import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function TermsConditions() {
  return (
    <UserLayout>
      <div>
        <div className="container">
          <div className="terms_conditions">
            <h2 className="text-center pb-4 ">Terms and Conditions</h2>

            <div className="t_content">
              <p>
                Please read these Terms and Conditions ("Terms") carefully
                before using smartchoicetraders.com (the "Website") operated by
                Smart Choice Traders Ltd ("us," "we," or "our").
              </p>
              <div className="cont">
                <ol>
                  <li>
                    {" "}
                    Acceptance of Terms: By accessing or using the Website, you
                    agree to be bound by these Terms. If you do not agree with
                    any part of these Terms, you may not use the Website.
                  </li>
                  <li>
                    Intellectual Property: The Website and its original content,
                    features, and functionality are owned by Smart Choice
                    Traders Ltd and are protected by international copyright,
                    trademark, patent, trade secret, and other intellectual
                    property or proprietary rights laws.
                  </li>
                  <li>
                    User Conduct:
                    <ul>
                      <li>
                        You agree to use the Website for lawful purposes and in
                        a manner that does not infringe the rights of, restrict,
                        or inhibit the use and enjoyment of the Website by any
                        third party.
                      </li>
                      <li>
                        You shall not engage in any activity that could damage,
                        disable, overburden, or impair the Website or interfere
                        with any other party's use of the Website.
                      </li>
                      <li>
                        You shall not attempt to gain unauthorized access to any
                        part of the Website, accounts, computer systems, or
                        networks connected to the Website, through hacking,
                        password mining, or any other means.
                      </li>
                    </ul>
                  </li>
                  <li>
                    User Contributions:
                    <ul>
                      <li>
                        The Website may allow users to post, submit, or display
                        content, including comments, reviews, or other materials
                        ("User Contributions").
                      </li>
                      <li>
                        By submitting User Contributions, you grant us a
                        non-exclusive, royalty-free, perpetual, irrevocable, and
                        fully sub-licensable right to use, reproduce, modify,
                        adapt, publish, translate, distribute, and display such
                        User Contributions throughout the world in any media.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Links to Third-Party Websites: The Website may contain links
                    to third-party websites or services that are not owned or
                    controlled by us. We have no control over and assume no
                    responsibility for the content, privacy policies, or
                    practices of any third-party websites or services. You
                    acknowledge and agree that we shall not be responsible or
                    liable for any damage or loss caused or alleged to be caused
                    by or in connection with the use of or reliance on any such
                    content, goods, or services available on or through any
                    third-party websites or services.
                  </li>
                  <li>
                    Limitation of Liability:
                    <ul>
                      <li>
                        In no event shall we, our directors, employees,
                        partners, agents, suppliers, or affiliates be liable for
                        any indirect, incidental, special, consequential, or
                        punitive damages arising out of or relating to your use
                        of the Website.
                      </li>
                      <li>
                        We do not warrant that the Website will be error-free,
                        uninterrupted, secure, or free of viruses or other
                        harmful components.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Indemnification: You agree to indemnify and hold harmless
                    Smart Choice Traders LTD and its directors, employees,
                    partners, agents, suppliers, and affiliates from any claim
                    or demand, including reasonable attorneys' fees, made by any
                    third party due to or arising out of your breach of these
                    Terms or your violation of any law or the rights of a third
                    party.
                  </li>
                  <li>
                    Governing Law: These Terms shall be governed and construed
                    in accordance with the laws of United Kingdom without regard
                    to its conflict of law provisions.
                  </li>
                  <li>
                    Changes to Terms: We reserve the right, at our sole
                    discretion, to modify or replace these Terms at any time.
                    The updated version will be posted on the Website, and the
                    date of the latest revision will be indicated.
                  </li>
                  <li>
                    Contact Us: If you have any questions, concerns, or requests
                    regarding these Terms, please contact us at enquiries
                    <a href="mailto:@smartchoicetraders.com.">
                      @smartchoicetraders.com.
                    </a>
                  </li>
                </ol>
              </div>

              <div className="cont">
                <p>
                  By using the Website, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms and
                  Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
