import { useEffect } from "react";
import UserLayout from "../components/layout/user_layout/UserLayout"

const BecomingEcoFriendlyBuilder = () => {
    useEffect(() => {
        window?.scrollTo(0, 0);
    }, [])
    return (
        <UserLayout>
            <section className="blog-detail-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="blog-left-page">
                                <h2 className="mb-3 blog-detail-head">Becoming an Eco-Friendly Builder: A Path to Sustainable Construction</h2>
                                <div className="blog-detailMain-img">
                                <img
                                    src={require("../assets/images/landing-page/service-img-9.png")}
                                    className="img-fluid"
                                    alt="Electrical Problems"
                                />
                                </div>
                                {/* <div className="">

                                    <h4 className="mt-2 mb-2">12th Oct,2023</h4>
                                </div> */}                            
                                <p className="mb-3">
                                The construction industry plays a significant role in shaping our world, and as an aspiring builder, you have the opportunity to make a positive impact on the environment. In this blog post, we’ll explore the steps to becoming an eco-friendly builder, from embracing sustainable practices and materials to navigating certifications. Let’s embark on the journey to becoming an eco-friendly builder!
                                </p>
                                <div className="blog-subheading-title-black">Why Choose Eco-Friendly Building?</div>
                                <p className="mb-3">Eco-friendly builders help reduce the construction industry’s carbon footprint and contribute to a more sustainable future.</p>
                                
                                <div className="blog-subheading-title">Cost Savings </div>
                                <p className="mb-3">Sustainable building practices often result in lower energy and resource consumption, translating into cost savings for clients and builders alike.</p>
                                    
                                <div className="blog-subheading-title">Competitive Edge</div>
                                <p className="mb-3">As sustainability becomes a priority, eco-friendly builders gain a competitive advantage in the market.</p>
                                
                                <div className="blog-subheading-title">Healthier Living Spaces</div>
                                <p className="mb-3">Eco-friendly construction can result in improved indoor air quality and healthier living environments for occupants.</p>
                                       
                               

                                <div className="blog-subheading-title-black">Steps to Become an Eco-Friendly Builder</div>                              
                                    
                                    <div className="blog-subheading-title">Education and Training</div>
                                    <p className="mb-3">Start by educating yourself on sustainable building practices. Consider enrolling in courses, workshops, or certifications related to green building.</p>
                                    
                                    <div className="blog-subheading-title">Embrace Sustainable Materials</div>
                                    <p className="mb-3">Choose eco-friendly materials like reclaimed wood, recycled metal, and low-impact insulation that are sourced responsibly.</p>
                                    
                                    <div className="blog-subheading-title">Energy Efficiency</div>
                                    <p className="mb-3">Implement energy-efficient design principles, such as optimising building orientation, using-efficient windows, and adding insulation.</p>
                                      
                                    <div className="blog-subheading-title">Water Conservation</div>
                                    <p className="mb-3">Install water saving fixtures, rainwater harvesting systems, and drought-resistant landscaping to reduce water consumption.</p>
                                    
                                    <div className="blog-subheading-title">Waster Reduction</div>
                                    <p className="mb-3">Develop a waste management plan to reduce construction waste recycle materials and minimise landfill contributions.</p>
                                    
                                    <div className="blog-subheading-title">Renewable Energy</div>
                                    <p className="mb-3">Explore renewable energy options like solar panels, geothermal heating and cooling systems for your projects. </p>
                                    
                                    <div className="blog-subheading-title">Certifications</div>
                                    <p className="mb-3">Consider pursuing green building certifications like LEED (Leadership in Energy and Environmental Design), BREEAM (Building Research Establishment Assessment Method) to showcase your commitment to sustainability.</p>
                                    
                                    <div className="blog-subheading-title">Collaborate with Experts</div>
                                    <p className="mb-3">Partner with architects, contractors who share your passion for eco-friendly construction to maximise your impact.</p>
                                    
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </UserLayout>
    )
}
export default BecomingEcoFriendlyBuilder