import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import backButton from "../../assets/images/tradesperson/back_btn.svg";
import { useDispatch, useSelector } from "react-redux";
import { createSearches, searchTrade } from "../../redux/Action/UserActions";
import Maping from "./Map/Maping";
import Autocomplete from "react-google-autocomplete";
import ReactStars from "react-rating-stars-component";
const TradespersonList = () => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const { val, location, category, lat, long, search_lat, search_long } =
    useParams();
  const [locationAddress, setLocationAddress] = useState(
    location != "location" ? location : undefined
  );
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const [star, setStar] = useState(false)
  const dispatch = useDispatch();
  const allTrade = useSelector((state) => state?.useAction?.all_trades);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(searchTrade(val, location, lat, long, search_lat, search_long)).then((res) => {
      if (res) {
        setStar(true)
      }
    });
  }, []);

  useEffect(() => {
    dispatch(createSearches(sessionStorage.getItem("userId"), val, location, allTrade?.length))
  }, [allTrade])

  const handleInnerSearch = (e) => {
    e.preventDefault();

    if (locationAddress != "undefined") {
      // dispatch(searchTrade(val.exp, location, lat, long));
      // const url = `https://api.getaddress.io/find/${locationAddress}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      // axios
      //   .get(url)
      //   .then((res) => {
      //     console.log(res.data, "fffffffffffffffffffffffffff")

      //     dispatch(searchTrade(val, res?.data?.postcode, lat, long, res?.data?.latitude, res?.data?.longitude,"location"));
      //   })
      //   .catch((err) => {
      //     if (
      //       err?.response?.data?.Message == "Bad Request: Invalid postcode."
      //     ) {
      //       toast.warning("Invalid Postcode");
      //     }
      //   });
      dispatch(
        searchTrade(
          val,
          locationAddress || location,
          lat,
          long,
          googleLat,
          googleLong,
          "location"
        )
      );
    }
  };

  return (
    <>
      <section className="pt-5 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="back_btn mb-5">
                <a
                  onClick={() => navigate(-1)}
                  className="d-flex align-items-center"
                >
                  {" "}
                  <img
                    src={backButton}
                    alt="back-arrow"
                    className="me-2"
                  />{" "}
                  Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tardeperson_section pb-5">
        <div className="container-fluid p-75 ">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="search_sect_inner">
                <h2 className="mb-4">
                  {val}
                  <span>Near</span>
                </h2>
                <form action="">
                  <div className="d-flex search_content search_content_main">
                    <div className="w-78 search-input-location">
                      <Autocomplete
                        defaultValue={location == "location" ? "" : location}
                        style={{ width: "500px" }}
                        apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                        onPlaceSelected={(place) => {
                          console.log(place?.geometry?.location);
                          setLocationAddress(place?.formatted_address);
                          setGoogleLat(place?.geometry?.location?.lat());
                          setGoogleLong(place?.geometry?.location?.lng());
                        }}
                        types={["address"]}
                        options={{
                          types: ["(regions)"],
                          componentRestrictions: { country: "uk" },
                        }}
                      />
                      {/* <input
                        type="search"
                        name="search"
                        placeholder="PLACE, POST CODE"
                        className="form-control"
                        value={locationAddress}
                        onChange={(e) => setLocationAddress(e.target.value)}
                      /> */}
                    </div>
                    <div className="w-15">
                      <input
                        type="submit"
                        name="search"
                        placeholder="Search"
                        value="Search"
                        className="form-control search_btn"
                        onClick={(e) => handleInnerSearch(e)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tardeperson_section tradeperson_logged_in">
        <div className="container-fluid p-75">
          <h4 className="mb-4">{allTrade?.length} Local Results</h4>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div
                className={
                  allTrade?.length > 0
                    ? "search-results"
                    : "search-results search-results_hide"
                }
              >
                <div className="fix_height_search side_scroll_hide">
                  <div className="trade-details-main-info row">
                    {allTrade?.length > 0 ? (
                      allTrade?.map((item) => {
                        return (
                          <div className="col-lg-6 col-md-6">
                            <Link
                              className="trade_info content_fit_img"
                              to={`/tradespersonDetail/${item?._id}`}
                            >
                              <div className="cent">
                              {
                                item?.badge == 1 ? <img  className="badge-img" src={require("../../assets/images/platinum_rated.png")} /> :
                                  item?.badge == 2 ? <img className="badge-img" src={require("../../assets/images/diamond_rated.png")} /> :
                                    item?.badge == 3 && <img className="badge-img" src={require("../../assets/images/gold_rated.png")} />

                              }
                              </div>
                            
                              {item?.business_image ? (
                               <div className="cent">
                                 <img className={item?.badge ? "user-badge-img" : "without-badge center-class"}
                                  src={
                                    BACKEND_URL +
                                    "business_image/" +
                                    item?.business_image
                                  }
                                  alt=""
                                />
                               </div>
                              ) : (
                                <div className="cent">
                                  <img className={item?.badge ? "user-badge-img" : 'without-badge center-class'}
                                    src={require("../../assets/images/placeholder(1).png")}
                                    alt=""
                                  />
                                </div>
                              )}

                              <h3>{item?.company_name}</h3>
                              <p className="common_parah">{item?.address}</p>
                              <div className="review_sect d-flex">
                                <div className="review_ratings review_stars_width">
                                  {star == true && (
                                    <ReactStars
                                      count={5}
                                      edit={false}
                                      isHalf={true}
                                      value={item?.overall_rating}
                                      size={25}
                                      activeColor="#ffd700"
                                    />
                                  )
                                  }
                                </div>
                                <p className="common_parah ms-3">
                                  {item?.overall_rating
                                    ? "(" +
                                    item?.overall_rating +
                                    " " +
                                    "Reviews" +
                                    ")"
                                    : "(" +
                                    "0" +
                                    " " +
                                    "Reviews" +
                                    ")"}
                                </p>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <p className="no-results text-center d-flex flex-column">
                        <img
                          src={require("../../assets/images/no-results.png")}
                          alt=""
                        />
                        <span>No results found</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 ">
              <div className="map">
                <div>
                  <Maping allTrade={allTrade} star={star} />
                </div>
                {/* <div className="profile_img">
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#tradePerson"
                    className="profile_img_inner"
                  >
                    <img src="images/tradesperson/trade_img.png" alt="" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade tradeperson-modal"
        id="tradePerson"
        tabindex="-1"
        aria-labelledby="tradePersonLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="trade_modal_inner">
                <img
                  src="images/tradesperson/trade_img.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="modal-footer">
              <a href="#">
                <button type="button" className="btn btn-primary dark_btn">
                  View Profile
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradespersonList;
