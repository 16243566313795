import React from 'react'
import UserHeader from './UserHeader'
import UserFooter from './UserFooter'
import Header from '../Header'

const UserLayout = ({children}) => {

  const pages = [
    "/tradepeople-login",
    "/tradepeople-forgot-password"
    //"/tradepeople-register",
  ];

  return (
    <div id="wrapper">
        {/* <UserHeader /> */}
        <Header />
        {children}
        {!pages.includes(window.location.pathname) && 
        <UserFooter />
      }
        
    </div>
  )
}

export default UserLayout