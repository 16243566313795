import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
// import logo from "../../assets/images/logo_two.svg"
import { tradeLogin } from "../../redux/Action/tradeLoginAction";
import TradeHeader from "../../components/tradepeople/layout/TradeHeader";
import UserLayout from "../../components/layout/user_layout/UserLayout";

const TradeLogin = () => {
  const [showPass, setShowPass] = useState(false);
  const [rememberMe, setRemember] = useState(false);
  const [validateValue, setValidateValue] = useState(false);
  const dispatch = useDispatch();
  const cookie = new Cookies();
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("tradeToken") || cookie.get("tradeToken")) {
      if (cookie.get("tradeToken") && !sessionStorage.getItem("tradeToken")) {
        sessionStorage.setItem("tradeToken", cookie.get("tradeToken"));
      }
      window.location.href = "/trade-dashboard";
    }
  }, []);
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8 || values.password.length > 10) {
      errors.password = "Invalid Password";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: (values) => {
      values.device_token = sessionStorage.getItem("device_token");
      dispatch(tradeLogin(values, rememberMe, navigate));
    },
  });
  return (
    <>
      {/* <TradeHeader /> */}
      <UserLayout>
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                {/* <div className="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p className="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div> */}
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in ">
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h3>Nice to see you again!</h3>
                          <div className="form-group mb-3 mt-4">
                            <label for="">Login</label>
                            <input
                              type="email"
                              className="form-control login_form_control"
                              placeholder="Email"
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.email && (
                              <p className="formik-error">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label for="">Password</label>
                            <div className="input-group">
                              <input
                                type={showPass ? "text" : "password"}
                                className="form-control br-none login_form_control"
                                id=""
                                placeholder="Login Password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                              />
                              <div className="input-group-prepend">
                                <span
                                  onClick={() => setShowPass((pre) => !pre)}
                                  className="input-group-text bl-none "
                                  id="basic-addon1"
                                >
                                  <i
                                    className={
                                      showPass ? "fa fa-eye-slash" : "fa fa-eye"
                                    }
                                    id="eye"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            {formik.errors.password && (
                              <p className="formik-error">
                                {formik.errors.password}
                              </p>
                            )}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="custom-control custom-switch d-flex">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  onChange={(e) =>
                                    setRemember(e.target.checked)
                                  }
                                />
                                <label
                                  className="custom-control-label ms-2"
                                  for="customSwitch1"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="frgt-pw">
                              <Link to="/tradepeople-forgot-password">
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                          <div className="btn_submit mt-4 d-flex">
                            <button
                              onClick={() => setValidateValue(true)}
                              type="submit"
                              className="btn btn-primary btn-custom btn-lg w-100"
                            >
                              Sign In
                            </button>
                          </div>
                          <div className="regist_tody d-flex justify-content-center align-items-center register_today_link">
                            <Link
                              to="/tradepeople-register"
                              className="text-center"
                            >
                              Not a member yet? Register Today
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </UserLayout>
     
    </>
  );
};

export default TradeLogin;
