export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_REQUEST = "USER_REGISTER_SUCCESS";
export const USER_LOGIN_SUCCESS = "USER_REGISTER_FAIL";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const USER_REGISTER = "USER_REGISTER";
export const USER_PROFILE_DETAIL = "USER_PROFILE_DETAIL";
export const UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL";
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const TRADE_PERSON_REGISTER = "TRADE_PERSON_REGISTER";
export const UPDATE_TRADE_PASSWORD = "UPDATE_TRADE_PASSWORD";
export const TRADE_PERSON_PROFILE_DETAIL = "TRADE_PERSON_PROFILE_DETAIL";
export const UPDATE_TRADE_PROFILE_DETAIL = "UPDATE_TRADE_PROFILE_DETAIL";
export const TRADE_SERVICE_FETCH_SUCCESSFULLY =
  "TRADE_SERVICE_FETCH_SUCCESSFULLY";
export const SERVICE_FETCH_SUCCESSFULLY = "SERVICE_FETCH_SUCCESSFULLY";
export const VALUE_SELECTED = "VALUE_SELECTED";
export const ALL_TRADES = "ALL_TRADES";
export const TRADE_DETAIL = "TRADE_DETAIL";
export const GET_ALL_ENQUIRY = "GET_ALL_ENQUIRY";
export const USER_CONTACT_PREFERENCES = "USER_CONTACT_PREFERENCES";
export const TRADER_ENQUIRIES = "TRADER_ENQUIRIES";
export const GET_USER_CHAT = "GET_USER_CHAT";
export const GET_TRADER_CHAT = "GET_TRADER_CHAT";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const TRADER_UPDATE_CHAT = "TRADER_UPDATE_CHAT";
export const GET_TRADE_LOCATIONS = "GET_TRADE_LOCATIONS";

export const TRADER_APPOINTMENT = "TRADER_APPOINTMENT";
export const USER_APPOINTMENT = "USER_APPOINTMENT";
export const USER_REVIEWS="USER_REVIEWS";
export const TRADE_REVIEWS="TRADE_REVIEWS";
export const REVIEW_DETAIL="REVIEW_DETAIL";
export const REVIEW_DETAIL_TARDER="REVIEW_DETAIL_TARDER";
export const REVIEW_DETAIL_NEW="REVIEW_DETAIL_NEW";
export const REVIEW_TARDER_DETAIL_NEW="REVIEW_TARDER_DETAIL_NEW"

export const STRIPE_USER_ACCOUNT_DETAILS = 'STRIPE_USER_ACCOUNT_DETAILS';
export const ESTIMATE_DETAIL ="ESTIMATE_DETAIL";
export const ENVOICE_DETAIL="ENVOICE_DETAIL";
export const WALLET_TRANSATION="WALLET_TRANSATION";
export const AVAILABLE_HOURS="AVAILABLE_HOURS";
export const WALLET_INFO="WALLET_INFO";
export const ALL_SERVICES="ALL_SERVICES";
export const QUOTE_DETAIL ="QUOTE_DETAIL";
export const ENVOICE_DETAIL1="ENVOICE_DETAIL1"