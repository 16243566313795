import React, { useEffect, useRef, useState } from "react";
import BusinessProfile from "../../components/tradepeople/my_account/BusinessProfile";
import UpdatePassword from "../../components/tradepeople/my_account/UpdatePassword";
import Subscription from "../../components/tradepeople/my_account/Subscription";
import ContactPreferences from "../../components/tradepeople/my_account/ContactPreferences";
import Reviews from "../../components/tradepeople/my_account/Reviews";
import PersonalDetail from "../../components/tradepeople/my_account/PersonalDetail";
import BusinessLoaction from "../../components/tradepeople/my_account/BusinessLoaction";
import { useDispatch, useSelector } from "react-redux";

import TradeAccLayout from "../../components/trade_acc_home/layout/TradeAccLayout";
import { getTradePersonProfile } from "../../redux/Action/tradeActions";
import RegularPopup from "../../components/regular_popup/RegularPopup";
import { useLocation } from "react-router-dom";
import Wallet from "../../components/tradepeople/my_account/Wallet";
import SetAvailbility from "../../components/tradepeople/my_account/SetAvailibility";
var dataArr = [
  {
    name: "Edit Business Profile",
    key: 0,
  },
  {
    name: "My Business Locations",
    key: 1,
  },
  {
    name: "Personal Details",
    key: 2,
  },
  {
    name: "My Reviews",
    key: 3,
  },
  {
    name: "Contact Preferences",
    key: 4,
  },
  {
    name: "Set Availability",
    key: 5,
  },
  {
    name: "Bank Account",
    key: 6,
  },
  {
    name: "Wallet",
    key: 7,
  },
  {
    name: "Update Password",
    key: 8,
  },
 
];

const TradeMyAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [tabClick, setTab] = useState("Edit Business Profile");
  const tradeProfileDetail = useSelector(
    (state) => state?.tradeAction?.tradePerson_detail
  );
  useEffect(() => {
    dispatch(getTradePersonProfile());
    if(window.location.href.split("/")[3]=="tradepeople-my-account?success=true"){
      setShowModal(true)
    }
  }, []);
  let prevRef = useRef();

  return (
    <TradeAccLayout>
     {showModal == true && (
        <RegularPopup
          showModal={showModal}
          setShowModal={setShowModal}
          modalName={"paymentSuccess"} 
        />
      )}
      <section className="account_settings common_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main_head">
                <h3 className="mb-4">My Account & Settings</h3>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="setting_tabs">
                    <ul>
                      {dataArr.map((item) => {
                        return (
                          <li
                            className={
                              tabClick == item?.name
                                ? "blue_active_tab mb-2"
                                : "white_tab mb-2"
                            }
                          >
                            <a
                              onClick={() => {
                                setTab(item?.name);
                              }}
                            >
                              {item?.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {tabClick == "Edit Business Profile" && (
                  <BusinessProfile
                    tradeProfileDetail={tradeProfileDetail}
                    ref={prevRef}
                  />
                )}
                {tabClick == "My Business Locations" && (
                  <BusinessLoaction ref={prevRef} />
                )}
                {tabClick == "Personal Details" && (
                  <PersonalDetail
                    tradeProfileDetail={tradeProfileDetail}
                    ref={prevRef}
                  />
                )}
                {tabClick == "My Reviews" && <Reviews ref={prevRef} />}
                {tabClick == "Contact Preferences" && (
                  <ContactPreferences ref={prevRef} />
                )}
                {tabClick == "Bank Account" && (
                  <Subscription ref={prevRef} />
                )}
                {tabClick == "Update Password" && (
                  <UpdatePassword ref={prevRef} />
                )}
                {
                  tabClick =="Wallet" &&(
                    <Wallet tradeProfileDetail={tradeProfileDetail} />
                  )
                }
                {
                   tabClick =="Set Availability"&&( <SetAvailbility />)
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Account & Settings / End--> */}
    </TradeAccLayout>
  );
};

export default TradeMyAccount;
