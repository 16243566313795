import { useFormik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../redux/Action/UserActions";
import { getUserProfile } from "../../redux/Action/UserActions";
import RegularPopup from "../regular_popup/RegularPopup";

const EdditNamePicture = (props, ref) => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.useAction?.profile_detail);
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showModal, setShowModal] = useState(false);
  console.log(profileImage, "profileImage");
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);
  useEffect(() => {
    if (userDetail) {
      setProfileImage(userDetail?.profile_image);
      setFirstName(userDetail?.firstName);
      setLastName(userDetail?.lastName);
    }
  }, [userDetail]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile_image", profileImage);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    dispatch(updateUserProfile(formData,setShowModal));
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };
  // useImperativeHandle(ref, () => ({
  //   handleSubmit,
  // }));
  return (
    <>
        {showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"userMyAccount"} />}
        <div className="col-lg-8">
      <form onSubmit={handleSubmit} className="form_new">
      <div className="text-end"> 
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        <div className="edit_profile">
          <div className="d-flex mb-5 update_profile">
            {/* //   src={require("../../assets/images/my-account/avatar.png")} */}
            {userDetail?.profile_image && userDetail?.profile_image != "undefined" ? (
              <img
                src={BACKEND_URL + "profile_image/" + userDetail?.profile_image}
                alt=""
              />
            ) : preview ? (
              <img src={preview} alt="" />
            ) : (
              <img
                src={require("../../assets/images/placeholder.png")}
                alt=""
              />
            )}
      
            <div className="file form-group ms-5 ">
              <label for="input-file" className="box_shadow_new">
                Update Profile Picture
              </label>
              <input
                id="input-file"
                type="file"
                className="form-control box_shadow_new"
                onChange={(e) => handleImageUpload(e)}
                accept="image/*"
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label for="">First Name</label>
            <input
              type="text"
              className="form-control box_shadow_new"
              placeholder="Type Here"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label for="">Surname</label>
            <input
              type="text"
              className="form-control box_shadow_new"
              placeholder="Type Here"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {/* <div className="text-end pt-35">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div> */}
        </div>
      </form>
    </div>
    </>
  
  );
};

export default forwardRef(EdditNamePicture);
