import EnquiryDetail from "../../components/user_acc_home/my_enquiries/EnquiryDetail";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentSuccess, saveImages, userGetChat } from "../../redux/Action/UserActions";
import moment from "moment/moment";
import NewAppointment from "./NewAppointment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Details = ({ enquiryDetail, socket }) => {
  const IMG_URL = process.env.REACT_APP_IMG_URL;
  const dispatch = useDispatch();
  const params = useParams()

  const [chat, setChat] = useState([]);
  const [detailShowHide, setDetailShowHide] = useState(false);
  const [stateForScroll, setStateForScroll] = useState();
  const ref = useChatScroll(stateForScroll);
  const [appointmentStatus, setAppointmentStatus] = useState();
  const [message, setMessage] = useState("");
  const [imagesArr, setImagesArr] = useState([]);

  const [chatId, setChatId] = useState();
  const chatObject = useSelector((state) => state?.useAction?.chatObj);
  const userChat = useSelector((state) => state?.useAction?.chat);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionParam = urlParams.get('session_id');
  const invoiceId = urlParams.get('invoiceId');
  const [arrFiles, setArrFiles] = useState([]);

  useEffect(() => {
    if (chatObject?.enquiryId == enquiryDetail?._id && userChat?.length > 0) {
      setChat(userChat);
    } else {
      setChat([]);
    }
  }, [userChat]);

  //CHECKOUT SESSION
  useEffect(() => {
    if (sessionParam) {
      dispatch(paymentSuccess(sessionParam, invoiceId))
    }

  }, [sessionParam])

  useEffect(() => {
    socket.current.on("recieve_message", (messageObject) => {
      if (messageObject?.enquiryId == sessionStorage.getItem("enquiryId"))
        setChat((prev) => [...prev, messageObject]);
      setStateForScroll(messageObject.message);
    });

    socket.current.on("appointment_created", (messageObject) => {
      setAppointmentStatus(5);
    });
    socket.current.on("appointment_declined", (messageObject) => {
      setAppointmentStatus(2);
    });
    socket.current.on("appointment_cancelled", (messageObject) => {
      setAppointmentStatus(0);
    });

    socket.current.on("estimate_delete", (messageObject) => {
      console.log(messageObject, "estimate_delete");
      if (messageObject?.enquiryId == sessionStorage.getItem("enquiryId")) {
        setChat((prev) => {
          //prev.pop();
          return prev?.map((item) => {
            if (item?._id != messageObject?.chatId?._id) {
              return item;
            }
          });
        });
      }
      // setAppointmentStatus(0);
    });

    socket.current.on("quote_delete", (messageObject) => {
      console.log(messageObject, "estimate_delete");
      if (messageObject?.enquiryId == sessionStorage.getItem("enquiryId")) {
        setChat((prev) => {
          //prev.pop();
          return prev?.map((item) => {
            if (item?._id != messageObject?.chatId?._id) {
              return item;
            }
          });
        });
      }
      // setAppointmentStatus(0);
    });

    socket.current.on("invoice_delete", (messageObject) => {
      console.log(messageObject, "invoive_delete");
      if (messageObject?.enquiryId == sessionStorage.getItem("enquiryId")) {
        setChat((prev) => {
          //prev.pop();
          return prev?.map((item) => {
            if (item?._id != messageObject?.chatId?._id) {
              return item;
            }
          });
        });
      }
      // setAppointmentStatus(0);
    });

    socket.current.on("chat_id", (messageObject) => {
      console.log(messageObject, "global");
      setChatId(messageObject);
      if (messageObject?.enquiryId == sessionStorage.getItem("enquiryId")) {
        setChat((prev) => {
          prev.pop();
          return [...prev, messageObject];
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    if (enquiryDetail) {
      dispatch(userGetChat(enquiryDetail?._id));
      (async () => {
        await socket.current.emit("join_room", enquiryDetail?._id);
      })();
    }
  }, [enquiryDetail, appointmentStatus]);

  function useChatScroll(dep) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [dep]);
    return ref;
  }

  const handleEnter = (e) => {
    e.preventDefault();
    if (message) {
      let blockWords = ['checkatrade', 'trustatrader', 'check a trade'];
      let blockedWordFound = blockWords?.some(word => message?.toLowerCase().includes(word.toLowerCase()));

      if(blockedWordFound) return toast.error("Your message contains words that are not allowed. Please remove the blocked words and try again.")
    }

    if (message || imagesArr?.length) {
      const messageObject = {
        type: 0,
        enquiryId: enquiryDetail?._id,
        message: message,
        sender: "user",
        time: new Date().toISOString(),
        images: imagesArr
      };
      socket.current.emit("send_message", messageObject);
      setChat((prev) => [...prev, messageObject]);
      setStateForScroll(message);
      setMessage("");
      setArrFiles([])
    }

  };

  const handleAppointmentResponse = (response, chatObj) => {
    switch (response) {
      case "accept":
        const appointmentObject = {
          enquiryId: enquiryDetail?._id,
          tradeId: enquiryDetail?.tradeId?._id,
          userId: enquiryDetail?.userId?._id,
          chatId: chatObj,
          type: "accept",
          notify_status: 11,
          sender: 'user'
        };
        setAppointmentStatus(1);
        socket.current.emit("new_appointment", appointmentObject);
        break;

      case "decline":
        const appointmentOb = {
          enquiryId: enquiryDetail?._id,
          chatId: chatObj,
          notify_status: 12,
          sender: 'user'
        };
        socket.current.emit("decline_appointment", appointmentOb);
        break;

      case "cancel":
        const appointmentObj = {
          enquiryId: enquiryDetail?._id,
          chatId: chatObj,
          type: "cancel",
          notify_status: 13,
          sender: 'user'
        };
        dispatch(userGetChat(enquiryDetail?._id));
        setAppointmentStatus(0);
        socket.current.emit("cancel_appointment", appointmentObj);
        console.log(appointmentObj, "appointmentObj-jjj");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setStateForScroll(chat);
  }, [chat]);

  const handleFileChange = async (e) => {
    var file = e.target.files;
    if (file.length > 5) {
      toast.error("Please select less than 6");
    } else {

      var arr_files = []
      for (var i = 0; i < file.length; i++) {
        arr_files.push(file[i])

      }

      const formData = new FormData();

      arr_files?.forEach((image, index) => {
        formData.append(`images`, image);
      });

      dispatch(saveImages(formData)).then((data => {
        console.log(data, "saveImages")
        setImagesArr(data?.data)
      }))

      // console.log(arr_files);
      setArrFiles(arr_files)


    }
  };

  console.log(chat, "chat");
  return (
    <div className="enquiry_main_page">
      <div className="enquiry_main_head box_shadow_new">
        <div className="row">
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7">
            <div className="enquiry_main_head_left">
              <h3>{enquiryDetail?.company_name}</h3>
              <p>{enquiryDetail?.serviceId?.service}</p>
              <p>{enquiryDetail?.enquiry_address?.address}</p>
              <p className="mb-1">Verified, Upcoming Talent</p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5">
            <div className="enquiry_main_head_right">
              {enquiryDetail?.tradeId?.business_image && (
                <img
                  src={`${IMG_URL}/business_image/${enquiryDetail?.tradeId?.business_image}`}
                  alt=""
                  className="img-fluid"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {detailShowHide == true ? (
        <EnquiryDetail
          enquiryDetail={enquiryDetail}
          setDetailShowHide={setDetailShowHide}
        />
      ) : (
        <div className="mb-4 mt-4 enquiry_btn">
          <a onClick={() => setDetailShowHide(true)} className="white_btn">
            View Enquiry Details
          </a>
        </div>
      )}
      <div className="chat box_shadow_new">
        <div className="chat-title">
          <h3>
            Conversation
            <span>
              Started{" "}
              {chat?.length > 0 && moment(chat[0]?.time).format("DD/MM/YYYY")}{" "}
              {chat?.length > 0 && moment(chat[0]?.time).format("hh:mm a")}
            </span>
          </h3>
        </div>
        <div ref={ref} className="chat_inner">
          {chat?.length > 0 &&
            chat?.map((chatMessage) => {
              // console.log(chatMessage?.status, 'chatMessage')
              return (
                <>
                  {chatMessage?.type == 0 ? (
                    chatMessage?.sender == "trader" ? (
                      <div className="chat_left">
                        <h4>{enquiryDetail?.company_name}</h4>
                        <p className="text-message">{chatMessage?.message}</p>
                        {
                          chatMessage?.enq_image && <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/profile_image/${chatMessage?.enq_image}`} />
                          </div>
                        }
                         {
                          chatMessage?.images?.length>0 && chatMessage?.images?.map((item)=>{
                            return (
                              <>
                              <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/${item}`} />
                          </div>
                              </>
                            )
                          })
                        }
                        {/* <p>Can you send some photos of the issue?</p>
                    <p>I have availability this afternoon</p> */}
                        <div className="text-end chat-time">
                          <h5>
                            {moment(chatMessage?.time).format("DD/MM/YYYY")}
                          </h5>
                          <h5>{moment(chatMessage?.time).format("hh:mm a")}</h5>
                        </div>
                      </div>
                    ) : (
                      <div className="chat_right">
                        <p className="text-message">{chatMessage?.message}</p>
                        {
                          chatMessage?.enq_image && <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/profile_image/${chatMessage?.enq_image}`} />
                          </div>
                        }
                         {
                          chatMessage?.images?.length>0 && chatMessage?.images?.map((item)=>{
                            return (
                              <>
                              <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/${item}`} />
                          </div>
                              </>
                            )
                          })
                        }

                        {/* <p>Sure, I’ll get some over to you!</p>
                      <p>Cheers!</p> */}
                        <div className="text-end chat-time">
                          <h5>
                            {moment(chatMessage?.time).format("DD/MM/YYYY")}
                          </h5>
                          <h5>{moment(chatMessage?.time).format("hh:mm a")}</h5>
                        </div>
                      </div>
                    )
                  ) : (
                    chatMessage?.type == 1 &&
                    chatMessage?.status != undefined && (
                      <NewAppointment
                        messageObject={chatMessage}
                        setAppointmentStatus={setAppointmentStatus}
                        appointmentStatus={chatMessage?.status}
                        handleAppointmentResponse={handleAppointmentResponse}
                        enquiryDetail={enquiryDetail}
                        socket={socket}
                      />
                    )
                  )}
                </>
              );
            })}
        </div>
        <div className="chat_bottom chat_textarea">
          <form className="form_new">
            <div className="d-flex">
              <div className="file form-group ">
                <label for="input-file" className="">
                  <img src="images/enquiries/upload.svg" alt="" />
                </label>
                {/* <input
                  id="input-file"
                  type="file"
                  className="form-control box_shadow_new"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message"
                   
                /> */}
              </div>
              {arrFiles?.length > 0 && (<div className="message-reply-bar">
                <div className="message-reply-bar">
                  <div className="message-reply-inner row">
                    <div className="d-flex media-view-send col-md-8">
                      <div className="pdf-div"><div className="d-flex">
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="message-reply-inner row">
                  {arrFiles?.map(function (file, iDx) {
                    return (
                      <div className="d-flex media-view-send col-md-12 justify-content-center">
                        <div className="pdf-div">
                          <div className="d-flex">
                            <img
                              className="img-fluid pdf-set"
                              src={
                                require("../../assets/images/other-file.svg")
                                  .default
                              }
                            />
                            <div className="pdf-info">
                                <h3 className="h-13">{file.name}</h3>
                                <p className="h-12 pt-1">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                              </div>
                          </div>

                        </div>

                      </div>)
                  })}

                </div>
              </div>)}
              <div className="w-100 border-outer ">
                <div className="d-flex align-items-center mb-2">
                  <div className="doc-attach">
                    <img
                      src={require("../../assets/images/attach.svg").default}
                    />
                    <input
                      type="file"
                      class="form-control"
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  // onKeyDown={(e) => handleKeyEnter(e)}
                  cols="30"
                  rows="10"
                  className="form-control send-msg-textarea"
                  placeholder="Type message here.."
                ></textarea>
                <button onClick={(e) => handleEnter(e)} className="btn btn-primary msg-send-btn">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Details;
