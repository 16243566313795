import { useEffect, useState } from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import logoSearch from "../assets/images/search.svg";
import logo from "../assets/images/landing-page/expand_right.svg";
import MultiSelect from "../components/MultiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchServiceCategory1,
  getDataVal,
  searchTrade,
} from "../redux/Action/UserActions";
import { toast } from "react-toastify";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";

const FindTradePerson = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const val = useSelector((state) => state?.useAction?.selected);
  const dispatch = useDispatch();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    dispatch(fetchServiceCategory1());
    dispatch(getDataVal());

    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      return navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude, "position.coords.latitude");
          setStatus(true);
          setLat(position.coords.latitude);
          setLong(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);
  const searchAction = () => {
    if (val && location != "location") {
      navigate(
        `/tradespersonList/${val?.category}/${val.label}/${location}/${googleLat}/${googleLong}/${lat}/${long}`
      );
    } else if (val) {
      navigate(
        `/tradespersonList/${val?.category}/${val.label}/${location}/lat/long/${lat}/${long}`
      );
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["uk"] },
    types: ["city"],
  };

  return (
    <UserLayout>
      {/* <!-- banner  start --> */}
      <section className="banner-img registration_banner_img ">
        <div className="container-fluid ">
          <div className="banner-content">
            <div className="searchBar">
              <form action="" method="">
                <h1 className="mb-0">Find a Local Tradesperson</h1>
                <div className="searchbar-inner">
                  <div className="input_width_60">
                    {/* <input
                      type="search"
                      name="search"
                      value=""
                      id=""
                      placeholder="Search by Trade"
                      className="form-control "
                    />  */}
                    <MultiSelect />
                    {/* <div className="search_icon">
                      <img src={logoSearch} alt="search" className="" />
                    </div> */}
                  </div>
                  <div className="input_width_40">
                    <Autocomplete
                      style={{ width: "500px" }}
                      apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                      onPlaceSelected={(place) => {
                        console.log(place?.geometry?.location);
                        setLocation(place?.formatted_address);
                        setGoogleLat(place?.geometry?.location?.lat());
                        setGoogleLong(place?.geometry?.location?.lng());
                      }}
                      types={["address"]}
                      options={{
                        types: ["(regions)"],
                        componentRestrictions: { country: "uk" },
                      }}
                    />
                    {/* <input
                      type="text"
                      name="location"
                      placeholder="Near"
                      className="form-control search_option_btn_inner"
                      onChange={(e) => setLocation(e.target.value)}
                    /> */}
                    <div className="w-35">
                      {location ? (
                        <button
                          className="form-control search_btn"
                          onClick={(e) => searchAction(e.preventDefault())}
                        >
                          Search
                        </button>
                      ) : (
                        <button
                          onClick={(e) => e.preventDefault()}
                          className="form-control search_btn"
                        >
                          Search
                        </button>
                      )}
                    </div>
                    {/* <button
                      type="search"
                      className="btn btn-primary h-100 w-100"
                    >
                      Search
                    </button> */}
                  </div>
                </div>
                <p>
                  Get a free quotation in minutes from reputable Tradespeople in
                  your local area. All Tradespeople listed on our site are
                  vetted by our 20 point quality assurance process. We guarantee
                  quality and affordability to everyone.
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner  end --> */}

      {/* <!-- common banner bottom part start --> */}
      <section className="trust_wrapper position-relative home_btm_ques search_main_page">
        <div className="banner_btm">
          <div className="btm_ques">
            <a href="#" className="book_dentist_link">
              Not sure what you need? Take a look at all of our services
              <img src={logo} alt="right-arrow" />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- common banner bottom part end --> */}

      {/* <!-- Testimonial start --> */}
      <section className="testimonial ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4  d-none d-lg-block">
              <ol className="carousel-indicators tabs">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  className="active"
                >
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/1.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1">
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/2.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2">
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/3.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
              </ol>
            </div>
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <div
                id="carouselExampleIndicators"
                data-interval="false"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/1-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/2-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/3-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/4-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/5-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="quote-wrapper">
                      <img
                        src={require("../assets/images/testimonial/6-lg.png")}
                        className="img-fluid"
                        alt="testimonial-img"
                      />
                      <p>
                        “ Smart Choice came to the rescue when I couldn’t get an
                        appointment with my local Tradesperson. Great service. “
                      </p>
                      <h3>Tim, Suffolk</h3>
                    </div>
                  </div>
                </div>
                <ol className="carousel-indicators indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                </ol>
              </div>
            </div>
            <div className="col-lg-4  d-none d-lg-block">
              <ol className="carousel-indicators tabs">
                <li data-target="#carouselExampleIndicators" data-slide-to="3">
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/4.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4">
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/5.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
                <li data-target="#carouselExampleIndicators" data-slide-to="5">
                  <figure>
                    <img
                      src={require("../assets/images/testimonial/6.png")}
                      className="img-fluid"
                      alt="testimonial-img"
                    />
                  </figure>
                </li>
              </ol>
              {/* <!-- <ol className="carousel-indicators indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" className=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2" className=""></li>
                    </ol> --> */}
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};

export default FindTradePerson;
