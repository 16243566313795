import React, { useState } from "react";
import logo from "../../assets/images/logo3.svg";
import { Link, useParams } from "react-router-dom";

const Header = () => {
  const params = useParams();
  const [menuClick, setMenuClick] = useState(false);
  console.log(window.location.pathname, "params")

  const pages = [
    "/tradepeople-login",
    "/tradepeople-register",
  ];

  const menuItems = [
    { route: "/services", info: "Our Services" },
    { route: "/BookingProcess", info: "Booking Process" },
    { route: "/HomeownerAdvice", info: "Homeowner Advice Area" },
    { route: "/TradepersonAdvice", info: "Tradesperson Advice Area" },
    { route: "/tradepeople-register", info: "Join Us" },
    { route: "/Faqs", info: "FAQs" },
    { route: "/about-us", info: "About Us" },
  ]


  return (
    <header className="light_header white_header outside_header">
      <div className="container-fluid p-100">
        {/* <!-- Navbar --> */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="img-fluid" />
          </a>
          <button
            className={
              menuClick ? "navbar-toggler" : "navbar-toggler collapsed"
            }
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={menuClick ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setMenuClick((pre) => !pre)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              menuClick
                ? "collapse navbar-collapsing navbar-collapse flex-column show"
                : "collapse navbar-collapse flex-column"
            }
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <ul className="inner_header">
                  {
                    menuItems?.map((data, i) => {
                      return (
                        <>
                          <li className={data?.route == window.location.pathname ? "nav-item active" :  "nav-item"}>
                            <Link className="nav-link" to={data.route}>
                              {data.info}
                            </Link>
                          </li>
                        </>
                      )
                    })
                  }


                </ul>

              </li>
              <li className="nav-item">
                <ul className="inner_header">
                  <li className={"/tradepeople-login" == window.location.pathname ? "nav-item active" :  "nav-item"}>
                    <Link className="nav-item" to="/tradepeople-login">
                      Trader Log In
                    </Link>

                  </li>
                  <li className={"/signin" == window.location.pathname ? "nav-item active" :  "nav-item"}>
                    <Link className="nav-item" to="/signin">
                      Customer Log In
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Navbar / End --> */}
      </div>
    </header>
  );
};

export default Header;
