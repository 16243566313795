import React from "react";
import {
  ALL_SERVICES,
  ALL_TRADES,
  ENVOICE_DETAIL,
  ENVOICE_DETAIL1,
  ESTIMATE_DETAIL,
  GET_ALL_ENQUIRY,
  GET_USER_CHAT,
  QUOTE_DETAIL,
  REVIEW_DETAIL,
  REVIEW_DETAIL_NEW,
  SERVICE_FETCH_SUCCESSFULLY,
  TRADE_DETAIL,
  UPDATE_CHAT,
  USER_APPOINTMENT,
  USER_PROFILE_DETAIL,
  USER_REVIEWS,
  VALUE_SELECTED,
} from "../Constant/constant";
const initialstate = {
  profile_detail: null,
  trade_services: null,
  selected: null,
  all_trades: null,
  enquiry: [],
  chat: null,
  chatObj: null,
  user_appointment: [],
  review_detail: null,
  review_detail_new: null,
  userNewMessage: 0,
  invoice_detail:null,
  estimat_detail:null,
  all_services:null,
  quote_detail:null,
  invoice_detail1:null
};
const UserReducer = (state = initialstate, action) => {
  switch (action.type) {
    case USER_PROFILE_DETAIL:
      return {
        ...state,
        profile_detail: action.payload,
      };
    case SERVICE_FETCH_SUCCESSFULLY:
      return {
        ...state,
        trade_services: action.payload,
      };
    case VALUE_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case ALL_TRADES:
      return {
        ...state,
        all_trades: action.payload,
      };
    case TRADE_DETAIL:
      return {
        ...state,
        trade_detail: action.payload,
      };
    case GET_ALL_ENQUIRY:
      return {
        ...state,
        enquiry: action.payload.user,
        userNewMessage: action.payload.newMessages,
      };
    case GET_USER_CHAT:
      return {
        ...state,
        chatObj: action.payload,
        chat: action.payload?.chatsArray,
      };

    case UPDATE_CHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case USER_APPOINTMENT:
      return {
        ...state,
        user_appointment: action.payload,
      };
    case USER_REVIEWS:
      return {
        ...state,
        user_reviews: action.payload,
      };
    case REVIEW_DETAIL:
      return {
        ...state,
        review_detail: action.payload,
      };
      case ESTIMATE_DETAIL:
        return {
          ...state,
           estimat_detail: action.payload,
        };
        case QUOTE_DETAIL:
          return {
            ...state,
             quote_detail: action.payload,
          };
        case ENVOICE_DETAIL:
          return {
            ...state,
            invoice_detail: action.payload,
          };
          case ENVOICE_DETAIL1:
          return {
            ...state,
            invoice_detail1: action.payload,
          };
    case REVIEW_DETAIL_NEW:
      return {
        ...state,
        review_detail_new: action.payload,
      };
      case ALL_SERVICES :
        return {
          ...state,
          all_services:action.payload
        }
    default:
      return state;
  }
};

export default UserReducer;
