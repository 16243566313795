import React, { useEffect, useState } from 'react'
import logo from "../../../assets/images/logo3.svg";
// import logo from "../../../assets/images/logo2.png";
import profileLogo from "../../../assets/images/profile.svg";
import { Dropdown } from "react-bootstrap";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getTradePersonProfile } from '../../../redux/Action/tradeActions';
const Header = () => {
  const [menuClick,setMenuClick]=useState(false)
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
    const navigate = useNavigate();
  const tradeProfileDetail = useSelector(
    (state) => state?.tradeAction?.tradePerson_detail
  );
    const cookies = new Cookies();
    const HandleLogout = () => {   
        sessionStorage.removeItem("tradeToken");
        sessionStorage.removeItem("device_token");
        sessionStorage.removeItem("trade_id");
        sessionStorage.removeItem("traderEnquiryId");
        cookies.remove("tradeToken", { path: "/" });
        if(!sessionStorage.getItem("tradeToken")){
            navigate("/");
        }
      };
  useEffect(() => {
    dispatch(getTradePersonProfile());
  }, []);
  return (
    <header className="light_header">
      <div className="container-fluid p-100">
        {/* <!-- Navbar --> */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" className="img-fluid" />
          </Link>
          <button
             className={menuClick ? "navbar-toggler" : "navbar-toggler collapsed"}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={menuClick ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={()=>setMenuClick((pre)=>!pre)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div   className={
              menuClick
                ? "collapse navbar-collapsing navbar-collapse flex-column show"
                : "collapse navbar-collapse flex-column"
            } id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link" to="/trade-dashboard">
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <ul className="inner_header">
                  <li className="nav-item">
                    <Link className="nav-link" to="/trade-enquiries">
                      My Enquiries
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/trade-appointments">
                      My Appointments
                    </Link>
                  </li>
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="profile_login nav-item"
                      >
                        {tradeProfileDetail?.business_image ? (
                          <img
                            src={
                               BACKEND_URL +
                                "business_image/" +
                                tradeProfileDetail?.business_image
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            alt=""
                          />
                        )}
                        {/* <img src={profileLogo} alt="profile" /> */}
                        {/* {verified == 0 ? "Unverified" : "Verified"}   */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => navigate("/tradepeople-my-account")}
                        >
                          View profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => HandleLogout()}>
                          Log out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Navbar / End --> */}
      </div>
    </header>
  )
}

export default Header