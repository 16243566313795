import React, { useEffect, useRef, useState } from "react";
import TradeEquiriesDetail from "./TradeEquiriesDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceDetail,
  getQuoteDetail,
  getTraderChat,
  saveTradeImages,
} from "../../../redux/Action/tradeActions";
import moment from "moment";
import NewAppointment from "../../myEnquires/NewAppointment";
import Popup from "../../myEnquires/Popup";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { saveImages } from "../../../redux/Action/UserActions";
import { toast } from "react-toastify";

const TradeDetailEnquiryPage = ({ enquiryDetail, socket }) => {
  console.log(enquiryDetail);
  const [chatId, setChatId] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const IMG_URL = process.env.REACT_APP_IMG_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showHideDetail, setShowHideDetail] = useState(false);
  const [stateForScroll, setStateForScroll] = useState();
  const [chat, setChat] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState();
  const [appointmentStatus2, setAppointmentStatus2] = useState();
  const [appointmentStatus3, setAppointmentStatus3] = useState();
  const [active, setActive] = useState("");
  const ref = useChatScroll(stateForScroll);
  const [message, setMessage] = useState("");
  const [imagesArr, setImagesArr] = useState([]);
  const [arrFiles, setArrFiles] = useState([]);

  console.log(message, "message");
  const traderChatObj = useSelector(
    (state) => state?.tradeAction?.traderChatObj
  );
  const traderChat = useSelector((state) => state?.tradeAction?.traderChat);
  const InvoiceDetail = useSelector(
    (state) => state?.tradeAction?.invoice_detail
  );
  const quoteDetail = useSelector(
    (state) => state?.tradeAction?.quote_detail
  );
  console.log(quoteDetail, "InvoiceDetail");
  useEffect(() => {
    if (
      traderChatObj?.enquiryId == enquiryDetail?._id &&
      traderChat?.length > 0
    ) {
      setChat(traderChat);
    } else {
      setChat([]);
    }
  }, [traderChat]);

  useEffect(() => {
    if (enquiryDetail) {
      dispatch(getTraderChat(enquiryDetail?._id)).then((data) => {
        setChat(data?.chatsArray);
      });
      (async () => {
        await socket.current.emit("join_room", enquiryDetail?._id);
      })();
    }
  }, [enquiryDetail, appointmentStatus]);
  useEffect(() => {
    socket.current.on("recieve_message", (messageObject) => {
      if (
        messageObject?.enquiryId == sessionStorage.getItem("traderEnquiryId")
      ) {
        setChat((prev) => [...prev, messageObject]);
        setStateForScroll(messageObject.message);
      }
    });

    socket.current.on("appointment_cancelled", (messageObject) => {
      setAppointmentStatus(0);
    });

    socket.current.on("appointment_declined", (messageObject) => {
      setAppointmentStatus(2);
    });
    socket.current.on("appointment_created", (messageObject) => {
      setAppointmentStatus(1);
    });
    socket.current.on("chat_id", (messageObject) => {
      setChatId(messageObject);
    });
  }, [socket]);

  function useChatScroll(dep) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [dep]);
    return ref;
  }

  const handleEnter = (e) => {
    e.preventDefault();

    if (message) {
      let blockWords = ['checkatrade', 'trustatrader', 'check a trade'];
      let blockedWordFound = blockWords?.some(word => message?.toLowerCase().includes(word.toLowerCase()));

      if(blockedWordFound) return toast.error("Your message contains words that are not allowed. Please remove the blocked words and try again.")
    }

    if (message || imagesArr?.length) {
      e.preventDefault();
      const messageObject = {
        type: 0,
        enquiryId: enquiryDetail?._id,
        message: message,
        sender: "trader",
        time: new Date().toISOString(),
        images: imagesArr
      };
      socket.current.emit("send_message", messageObject);
      setChat((prev) => {
        return [...prev, messageObject];
      });
      setImagesArr([])
      setArrFiles([])
      setStateForScroll(message);
      setMessage("");
    }
    //}
  };

  const handleNewAppClick = (value) => {
    const appointmentObj = {
      type: 1,
      enquiryId: enquiryDetail?._id,
      message: enquiryDetail?.company_name,
      sender: "trader",
      time: new Date().toISOString(),
      status: 3,
      location: value.location,
      date_appointment: value.date_appointment,
      time_appointment: value.time_appointment,
      notify_status: 10,
    };
    const appointmentObject = {
      enquiryId: enquiryDetail?._id,
      tradeId: enquiryDetail?.tradeId,
      userId: enquiryDetail?.userId?._id,
      location: value.location,
      date_appointment: value.date_appointment,
      time_appointment: value.time_appointment,
      status: 3,
    };

    // status == 0 -> cancelled 1 -> accepted 2 -> declined
    let obj = chat?.find((o) => o?.type == 1 && o.status == 3);

    if (obj) {
      return console.log("appointment already exsist");
    }
    socket.current.emit("send_message", appointmentObj);
    socket.current.emit("new_appointment", appointmentObject);

    setChat((prev) => {
      return [...prev, appointmentObj];
    });
    setStateForScroll(enquiryDetail?.company_name);
    // socket.current.emit("new_appointment", appointmentObj);
  };

  useEffect(() => {
    setStateForScroll(chat);
  }, [chat]);

  useEffect(() => {
    dispatch(getInvoiceDetail(enquiryDetail?._id));
    dispatch(getQuoteDetail(enquiryDetail?._id))
  }, [enquiryDetail]);
  const handleAppointmentResponse = (response, chatObj) => {
    switch (response) {
      case "estimateCancel":
        const appointmentOb = {
          enquiryId: enquiryDetail?._id,
          chatId: chatObj,
        };
        setChat((prev) => {
          return prev?.map((item) => {
            if (item?._id != chatObj?._id) {
              return item;
            }
          });
        });
        socket.current.emit("estimateCancel", appointmentOb);
        break;

      case "invoiceCancel":
        const appointmentObj = {
          enquiryId: enquiryDetail?._id,
          chatId: chatObj,
        };
        setChat((prev) => {
          //prev.pop();
          return prev?.map((item) => {
            if (item?._id != chatObj?._id) {
              return item;
            }
          });
        });
        socket.current.emit("invoiceCancel", appointmentObj);
        break;
      case "cancelQuote":
        const quoteObj = {
          enquiryId: enquiryDetail?._id,
          chatId: chatObj,
        };
        setChat((prev) => {
          //prev.pop();
          return prev?.map((item) => {
            if (item?._id != chatObj?._id) {
              return item;
            }
          });
        });
        socket.current.emit("cancelQuote", quoteObj);
        break;

      default:
        break;
    }
  };

  const handleDropSelect = (name, type) => {
    name == "active" && setActive(type);
    if (type == 1) {
      navigate(`/estimate/${enquiryDetail?._id}`);
    }
    if (type == 2) {
      navigate(`/quote/${enquiryDetail?._id}`);
    }
    if (type == 3) {
      navigate(`/invoice/${enquiryDetail?._id}`);
    }
  };
  useEffect(() => {
    if (InvoiceDetail) {
      setAppointmentStatus2(false);
    } else {
      setAppointmentStatus2(true);
    }
    if (quoteDetail && InvoiceDetail) {
      setAppointmentStatus3(false)
    } else {
      setAppointmentStatus3(true)
    }
  }, [InvoiceDetail, quoteDetail]);

  const handleFileChange = async (e) => {
    var file = e.target.files;
    if (file.length > 5) {
      toast.error("Please select less than 6");
    } else {

      var arr_files = []
      for (var i = 0; i < file.length; i++) {
        arr_files.push(file[i])

      }

      const formData = new FormData();

      arr_files?.forEach((image, index) => {
        formData.append(`images`, image);
      });

      dispatch(saveTradeImages(formData)).then((data => {
        console.log(data, "saveImages")
        setImagesArr(data?.data)
      }))

      // console.log(arr_files);
      setArrFiles(arr_files)


    }
  };

  console.log(chat, "chat")

  return (
    <div className="enquiry_main_page">
      <div className="enquiry_main_head box_shadow_new">
        <div className="row">
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7">
            <div className="enquiry_main_head_left">
              <h3>
                {enquiryDetail?.userId?.firstName +
                  " " +
                  enquiryDetail?.userId?.lastName}
              </h3>
              <p>{enquiryDetail?.serviceId?.service}</p>
              <p>{enquiryDetail?.enquiry_address?.address}</p>
              <p className="mb-1">Verified, Upcoming Talent</p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5">
            <div className="enquiry_main_head_right mt-0">
              {enquiryDetail?.tradeId?.business_image && (
                <img
                  src={`${IMG_URL}/profile_image/${enquiryDetail?.userId?.profile_image}`}
                  alt=""
                  className="img-fluid"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showHideDetail == true ? (
        <TradeEquiriesDetail
          enquiryDetail={enquiryDetail}
          setShowHideDetail={setShowHideDetail}
        />
      ) : (
        <div className="mb-4 mt-4 enquiry_btn">
          <a onClick={() => setShowHideDetail(true)} className="white_btn">
            View Enquiry Details
          </a>
        </div>
      )}
      <div className="option-smart-list">
        <a href="#">
          {" "}
          <button
            onClick={() => setModalShow(true)}
            className="btn white_btn appointment_btn mb-4"
          >
            New Appointment
          </button>
        </a>
        <a>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className="white_btn appointment_btn"
            >
              {active == 1
                ? "New Estimate"
                : active == 2
                  ? "New Invoice"
                  : active == 3
                    ? "New Quote"
                    : "Select here.."}
              <i className="mdi mdi-menu-down "></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              <Dropdown.Item onClick={() => handleDropSelect("active", 1)}>
                New Estimate
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDropSelect("active", 2)}
              //disabled={appointmentStatus2}
              >
                New Quote
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDropSelect("active", 3)}
                disabled={appointmentStatus3}
              >
                New Invoice
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </a>

        {/* <Link to={`/estimate/${enquiryDetail?._id}`}>  
            {" "}
            <button
              className="btn white_btn appointment_btn mb-4"
            >
              New Estimate 
            </button>
          </Link>
          {
            InvoiceDetail?<Link to={`/invoice/${enquiryDetail?._id}`}>
            {" "} 
            <button
              className="btn white_btn appointment_btn mb-4"
            >
              New Invoice
            </button>
          </Link>: <button
              className="btn white_btn appointment_btn mb-4 disabled-amt"
              disabled
            >
              New Invoice
            </button>
          } */}
      </div>
      <div className="chat box_shadow_new">
        <div className="chat-title d-flex justify-content-between">
          <h3>Conversation</h3>
          <h3>
            <span>
              Started{" "}
              {chat?.length > 0 && moment(chat[0]?.time).format("DD/MM/YYYY")}{" "}
              {chat?.length > 0 && moment(chat[0]?.time).format("hh:mm a")}
            </span>
          </h3>

          {/* <a href="#">
            {" "}
            <button
              onClick={() => setModalShow(true)}
              className="btn white_btn appointment_btn"
            >
              New Appointment
            </button>
          </a> */}
        </div>
        <div ref={ref} className="chat_inner">
          {chat?.length > 0 &&
            chat?.map((chatMessage) => {
              return (
                <>
                  {chatMessage?.type == 0 ? (
                    chatMessage?.sender == "user" ? (
                      <div className="chat_left">
                        <h4>
                          {enquiryDetail?.userId?.firstName.concat(
                            " ",
                            enquiryDetail?.userId?.lastName
                          )}
                        </h4>
                        <p className="text-message">{chatMessage?.message}</p>
                        {
                          chatMessage?.enq_image && <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/profile_image/${chatMessage?.enq_image}`} />
                          </div>
                        }
                        {
                          chatMessage?.images?.length > 0 && chatMessage?.images?.map((item) => {
                            return (
                              <>
                                <div className="chat-img-smt">
                                  <img src={process.env.REACT_APP_IMG_URL + `/${item}`} />
                                </div>
                              </>
                            )
                          })
                        }
                        <div className="text-end chat-time">
                          <h5>
                            {moment(chatMessage?.time).format("DD/MM/YYYY")}
                          </h5>
                          <h5>{moment(chatMessage?.time).format("hh:mm a")}</h5>
                        </div>
                      </div>
                    ) : (
                      <div className="chat_right">
                        <p className="text-message">{chatMessage?.message}</p>
                        {
                          chatMessage?.enq_image && <div className="chat-img-smt">
                            <img src={process.env.REACT_APP_IMG_URL + `/profile_image/${chatMessage?.enq_image}`} />
                          </div>
                        }
                        {
                          chatMessage?.images?.length > 0 && chatMessage?.images?.map((item) => {
                            return (
                              <>
                                <div className="chat-img-smt">
                                  <img src={process.env.REACT_APP_IMG_URL + `/${item}`} />
                                </div>
                              </>
                            )
                          })
                        }
                        {/* <p>Sure, I’ll get some over to you!</p>
                      <p>Cheers!</p> */}
                        <div className="text-end chat-time">
                          <h5>
                            {moment(chatMessage?.time).format("DD/MM/YYYY")}
                          </h5>
                          <h5>{moment(chatMessage?.time).format("hh:mm a")}</h5>
                        </div>
                      </div>
                    )
                  ) : (
                    chatMessage?.type == 1 &&
                    chatMessage?.status != undefined && (
                      <NewAppointment
                        messageObject={chatMessage}
                        setAppointmentStatus={setAppointmentStatus}
                        appointmentStatus={chatMessage?.status}
                        enquiryDetail={enquiryDetail}
                        handleAppointmentResponse={handleAppointmentResponse}
                      />
                    )
                  )}
                </>
              );
            })}
        </div>
        <div className="chat_bottom chat_textarea">
          <form action="" className="form_new">
            <div className="d-flex">
              <div className="file form-group ">
                <label for="input-file" className="">
                  <img src="images/enquiries/upload.svg" alt="" />
                </label>
                <input
                  id="input-file"
                  type="file"
                  className="form-control box_shadow_new"
                />
              </div>
              {arrFiles?.length > 0 && (<div className="message-reply-bar">
                <div className="message-reply-bar">
                  <div className="message-reply-inner row">
                    <div className="d-flex media-view-send col-md-8">
                      <div className="pdf-div"><div className="d-flex">
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="message-reply-inner row">
                  {arrFiles?.map(function (file, iDx) {
                    return (
                      <div className="d-flex media-view-send col-md-12 justify-content-center">
                        <div className="pdf-div">
                          <div className="d-flex">
                            <img
                              className="img-fluid pdf-set"
                              src={
                                require("../../../assets/images/other-file.svg")
                                  .default
                              }
                            />
                            <div className="pdf-info">
                              <h3 className="h-13">{file.name}</h3>
                              <p className="h-12 pt-1">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                            </div>
                          </div>

                        </div>

                      </div>)
                  })}

                </div>
              </div>)}
              <div className="w-100 border-outer">
                <div className="d-flex align-items-center mb-2">
                  <div className="doc-attach">
                    <img
                      src={require("../../../assets/images/attach.svg").default}
                    />
                    <input
                      type="file"
                      class="form-control"
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  // onKeyDown={(e) => handleKeyEnter(e)}
                  cols="30"
                  rows="10"
                  className="form-control send-msg-textarea"
                  placeholder="Type message here.."
                ></textarea>
                <button
                  onClick={(e) => handleEnter(e)}
                  className="btn btn-primary msg-send-btn"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Popup
        socket={socket}
        setModalShow={setModalShow}
        modalShow={modalShow}
        enquiryDetail={enquiryDetail}
        handleNewAppClick={handleNewAppClick}
      />
    </div>
  );
};

export default TradeDetailEnquiryPage;
