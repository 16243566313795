export const modalTitle = {
    userAccount: "This is User Account",
  };
  export const modalBody = {
    userAccount:"Please login to continue further",
    tradeRegister: "Thank you for registering to Smart Choice Traders. You'll soon receive an email confirming your pre-registration call back and to set up your account.",
    userMyAccount:"Saved successfully",
    tradeMyAccount:"Saved successfully",
    userReviewSubmit:"Thank you for your review",
    traderReviewSubmit:"Thank you for your review",
    cancelAppointment:"Are you sure?",
    needCancelAppointment:"Are you sure?",
    newEstimate:"Estimate",
    cancelEstimate:"Are you sure?",
    cancelQuote:"Are you sure?",
    newInvoice:"newInvoice",
    invoiceCancel:"Are you sure?",
    paymentSuccess:"Account registered successfully",
    witdrawFunds:"Are you sure you want to withdraw your funds?",
    disableFunds:"Your funds are not yet available to withdraw. Please try again later",
    newQuote:"Quote",
    tradeSetAvail:"Updated Successfully"
  };
  