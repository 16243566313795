import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import { Container } from "react-bootstrap";
export default function JobVacancies() {
  return (
    <UserLayout>
      <Container>
        <div className="no-vacancies">
          <h2>No openings at present, keep checking.</h2>
        </div>
      </Container>
    </UserLayout>
  );
}
