import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RegularPopup from "../regular_popup/RegularPopup";

const NewAppointment = ({
  messageObject,
  handleAppointmentResponse,
  appointmentStatus,
  setAppointmentStatus, 
  enquiryDetail,
}) => {
  const { message, time, time_appointment, date_appointment } = messageObject;
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [needCancel, setNeedCancel] = useState(false);
  const [cancelEstimate, setCancelEstimate] = useState(false);
  const [cancelQuote, setCancelQuote] = useState(false);
  const [cancelIvoice, setCancelIvoice] = useState(false);
console.log(appointmentStatus,"appointmentStatus");
  const navigate = useNavigate();
  return (
    <>
      {showModal == true && (
        <RegularPopup
          showModal={showModal}
          setShowModal={setShowModal}
          modalName={"cancelAppointment"}
          handleAppointmentResponse={handleAppointmentResponse}
          messageObject={messageObject}
        />
      )}
      {needCancel == true && (
        <RegularPopup
          showModal={needCancel}
          setShowModal={setNeedCancel}
          modalName={"needCancelAppointment"}
          handleAppointmentResponse={handleAppointmentResponse}
          messageObject={messageObject}
        />
      )}
      {cancelEstimate == true && (
        <RegularPopup
          showModal={cancelEstimate}
          setShowModal={setCancelEstimate}
          modalName={"cancelEstimate"}
          handleAppointmentResponse={handleAppointmentResponse}
          messageObject={messageObject}
        />
      )}
      {cancelQuote == true && (
        <RegularPopup
          showModal={cancelQuote}
          setShowModal={setCancelQuote}
          modalName={"cancelQuote"}
          handleAppointmentResponse={handleAppointmentResponse}
          messageObject={messageObject}
        />
      )}
      {cancelIvoice == true && (
        <RegularPopup
          showModal={cancelIvoice}
          setShowModal={setCancelIvoice}
          modalName={"invoiceCancel"}
          handleAppointmentResponse={handleAppointmentResponse}
          messageObject={messageObject}
        />
      )}
      <div className="appointment_confirmation_box">
        <div className="appointment_confirmation_content">
          <h3>
            {appointmentStatus != 6 && appointmentStatus != 7 && appointmentStatus != 10 &&
              (appointmentStatus == 1
                ? "Appointment Confirmed"
                : appointmentStatus == 0
                ? "Appointment Cancelled"
                : appointmentStatus == 2
                ? "Appointment declined"
                : "Appointment Confirmation")}
          </h3>
          <p className="mb-3 mt-3">
            {appointmentStatus == 7 ?  `${enquiryDetail?.company_name} has sent a new invoice.`
             :  appointmentStatus == 6
              ? `${enquiryDetail?.company_name} has sent a new estimate.`
              : appointmentStatus == 10
              ? `${enquiryDetail?.company_name} has sent a new quote.`: `${message} has offered you a home appointment`}
          </p>
          {appointmentStatus != 6 && appointmentStatus != 7 && appointmentStatus != 10 &&(
            <>
              <p>{moment(date_appointment).format("dddd Do MMMM YYYY")}</p>
              <p>{time_appointment}</p>
            </>
          )}
        </div>

        <div
          className={
            appointmentStatus == 1
              ? "d-flex justify-content-center appointment_btns"
              : "d-flex justify-content-between appointment_btns"
          }
        >
          {pathname == "/my-enquiries" ? (
            <>
              {appointmentStatus == 1 ? (
                <button
                  type="button"
                  className="btn white_btn"
                  onClick={
                    () => setNeedCancel(true)
                    // handleAppointmentResponse("cancel", messageObject)
                  }
                >
                  I Need to Cancel
                </button>
              ) : appointmentStatus == 0 ? null : appointmentStatus ==
                2 ? null : appointmentStatus == 6 ? (
                <>
                  <button
                    type="button"
                    className="btn white_btn estimate-btn mx-auto"
                    onClick={() => navigate(`/invoice-user/${enquiryDetail?._id}`)}
                  >
                    View
                  </button>
                </>
              ) : appointmentStatus == 7 ? (
                <>
                  <button
                    type="button"
                    className="btn white_btn estimate-btn mx-auto"
                    onClick={() => navigate(`/invoice-pay/${enquiryDetail?._id}`)}
                  >
                    View
                  </button>
                </>
              ) :  appointmentStatus == 10 ? (
                <>
                  <button
                    type="button"
                    className="btn white_btn estimate-btn mx-auto"
                    onClick={() => navigate(`/quote-user/${enquiryDetail?._id}`)}
                  >
                    View
                  </button>
                </>
              ) :(
                <>
                  <button
                    onClick={
                      () => setShowModal(true)
                      // handleAppointmentResponse("decline", messageObject)
                    }
                    type="button"
                    className="btn white_btn"
                  >
                    Decline
                  </button>
                  <button
                    onClick={() =>
                      handleAppointmentResponse("accept", messageObject)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Confirm
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              {appointmentStatus == 1 ? null : appointmentStatus ==
                0 ? null : appointmentStatus == 2 ? null : appointmentStatus ==
                6 ? (
                <>
                  <button
                    type="button"
                    onClick={
                      () => setCancelEstimate(true)
                      //  handleAppointmentResponse("estimateCancel", messageObject)
                      // handleAppointmentResponse("cancel", messageObject)
                    }
                    className="btn white_btn estimate-btn"
                  >
                    Delete
                  </button>
                  <button type="button" className="btn white_btn estimate-btn" onClick={() => navigate(`/estimate-view/${enquiryDetail?._id}`)}>
                    View
                  </button>
                </>
              ) : appointmentStatus ==
                7 ? (
                <>
                  <button
                    type="button"
                    onClick={
                      () => 
                      setCancelIvoice(true)
                        // handleAppointmentResponse("invoiceCancel", messageObject)
                      // handleAppointmentResponse("cancel", messageObject)
                    }
                    className="btn white_btn estimate-btn"
                  >
                    Delete
                  </button>
                  <button type="button" className="btn white_btn estimate-btn" onClick={() => navigate(`/Inovice-view/${enquiryDetail?._id}`)}>
                    View
                  </button>
                </>
              ) :appointmentStatus ==
                10 ? (
                <>
                  <button
                    type="button"
                    onClick={
                      () => 
                      setCancelQuote(true)
                        // handleAppointmentResponse("invoiceCancel", messageObject)
                      // handleAppointmentResponse("cancel", messageObject)
                    }
                    className="btn white_btn estimate-btn"
                  >
                    Delete
                  </button>
                  <button type="button" className="btn white_btn estimate-btn" onClick={() => navigate(`/quote-view/${enquiryDetail?._id}`)}>
                    View
                  </button>
                </>
              ): (
                <>
                  <button type="button" className="btn white_btn" disabled>
                    Decline
                  </button>
                  <button type="button" className="btn btn-primary" disabled>
                    Confirm
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NewAppointment;
