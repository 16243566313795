import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpSuccess from "../components/sign_up/SignUpSuccess";
import ForgotPassword from "../components/sign_up/ForgotPassword";
import VerifyUser from "../components/verify_user/VerifyUser";
import ResetPassword from "../components/sign_up/ResetPassword";
import MyAccount from "../pages/user_acc_home/MyAccount";
import TradeRegister from "../pages/tradePeople/TradeRegister";
import TradeLogin from "../pages/tradePeople/TradeLogin";
import TradeForgotPassword from "../components/tradepeople/passwords/TradeForgotPassword";
import TradeResetPassword from "../components/tradepeople/passwords/TradeResetPassword";
import FindTradePerson from "../pages/FindTradePerson";
import Services from "../pages/Services";
// import AboutUs from "../pages/AboutUs";
import Home from "../pages/Home";
import Dashboard from "../pages/user_acc_home/Dashboard";
import AccFindTradePerson from "../pages/user_acc_home/AccFindTradePerson";
import MyEnquiries from "../pages/user_acc_home/MyEnquiries";
import Appointments from "../pages/user_acc_home/Appointments";
import TradeMyAccount from "../pages/trade_acc_home/TradeMyAccount";
import TradeDashboard from "../pages/trade_acc_home/TradeDashboard";
import TradeEnquiries from "../pages/trade_acc_home/TradeEnquiries";
import TradeAppointments from "../pages/trade_acc_home/TradeAppointments";
import TradespersonList from "../pages/user_acc_home/TradespersonList";
import TradespersonDetails from "../pages/user_acc_home/TradespersonDetails";
import UserEnquiry from "../pages/UserEnquiry";
import PrivateRoute from "./PrivateRoute";
import TradePrivateRoute from "./TradePrivateRoute";
import ReviewDetail from "../components/my_account/ReviewDetail";
import Estimate from "../components/trade_acc_home/my_enquiries/Estimate";
import InVoice from "../components/trade_acc_home/my_enquiries/InVoice";
import InVoiceUser from "../components/myEnquires/InVoiceUser";
import InVoicePay from "../components/myEnquires/InVoicePay";
import ViewEstimate from "../components/trade_acc_home/my_enquiries/ViewEstimate";
import ViewInvoice from "../components/trade_acc_home/my_enquiries/ViewInvoice";
import BlogViewDetails from "../pages/BlogViewDetails";
import CreateQuate from "../components/trade_acc_home/my_enquiries/CreateQuate";
import ViewQuote from "../components/trade_acc_home/my_enquiries/ViewQuote";
import QuoteViewUser from "../components/myEnquires/QuoteViewUser";
import OutsmartingRogueTraders from "../pages/OutsmartingRogueTraders";
import BecomingEcoFriendlyBuilder from "../pages/BecomingEcoFriendlyBuilder";
import BudgetFriendlyPlanning from "../pages/BudgetFriendlyPlanning ";
import Faqs from "../pages/Faqs";
import TermsConditions from "../pages/TermsConditions";
import BookingProcess from "../pages/BookingProcess";
import HomeownerAdvice from "../pages/HomeownerAdvice";
import Tradeperson from "../pages/TradepersonAdvice";
import ContactUs from "../pages/ContactUs";
import CustomerServices from "../pages/CustomerServices";
import AboutUs from "../pages/AboutUsNew";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditionsTraders from "../pages/TermsConditionsTraders";
import TermsConditionsCustomer from "../pages/TermsConditionsCustomer";
import JobVacancies from "../pages/JobVacancies";
import SlaveryPolicy from "../pages/SlaveryPolicy";
import MissionStatement from "../pages/MissionStatement";
import Blogs from "../pages/Blogs";
const RoutesPage = () => {
  return (
    <>
      <BrowserRouter>
        {/* USER PANEL ROUTES */}
        <Routes>
          <Route path="/verify_user/:id" element={<VerifyUser />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/" element={<Home />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/sign-up-success" element={<SignUpSuccess />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/user/passwordReset/:token"
            element={<ResetPassword />}
          />
          <Route path="/blog_view" element={<BlogViewDetails />} />
          <Route
            path="/outsmarting-rogue-traders"
            element={<OutsmartingRogueTraders />}
          />
          <Route
            path="/becoming-eco-friendly-builder"
            element={<BecomingEcoFriendlyBuilder />}
          />
          <Route
            path="/budget-friendly-planning"
            element={<BudgetFriendlyPlanning />}
          />
          <Route path="/BookingProcess" element={<BookingProcess />} />
          <Route path="/HomeownerAdvice" element={<HomeownerAdvice />} />
          <Route path="/TradepersonAdvice" element={<Tradeperson />} />
          <Route
            exact
            path="/find-trade-person"
            element={<FindTradePerson />}
          />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="*" element={<Navigate to="/" />} />

          {/* user_acc_home */}
          <Route
            exact
            path="/tradespersonList/:category/:val/:location/:search_lat/:search_long/:lat/:long"
            element={<TradespersonList />}
          />
          <Route
            exact
            path="/tradespersonDetail/:id"
            element={<TradespersonDetails />}
          />
          <Route
            exact
            path="/user-enquiry/:company/:trade_id/:from"
            element={<UserEnquiry />}
          />
          <Route element={<PrivateRoute />}>
            <Route exact path="/invoice-user/:id" element={<InVoiceUser />} />
            <Route exact path="/quote-user/:id" element={<QuoteViewUser />} />
            <Route exact path="/invoice-pay/:id" element={<InVoicePay />} />
            <Route exact path="my-account" element={<MyAccount />} />
            <Route
              exact
              path="my-account/:tab/:id"
              element={<ReviewDetail />}
            />

            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route
              exact
              path="/find-a-tradeperson"
              element={<AccFindTradePerson />}
            />
            <Route exact path="/my-enquiries" element={<MyEnquiries />} />
            <Route exact path="/appointments" element={<Appointments />} />
          </Route>

          {/* trade_acc_home */}
          <Route element={<TradePrivateRoute />}>
            <Route
              exact
              path="/tradepeople-my-account"
              element={<TradeMyAccount />}
            />
            <Route exact path="/trade-dashboard" element={<TradeDashboard />} />
            <Route exact path="/trade-enquiries" element={<TradeEnquiries />} />
            <Route exact path="/invoice/:id" element={<InVoice />} />
            <Route exact path="/estimate/:id" element={<Estimate />} />
            <Route exact path="/quote/:id" element={<CreateQuate />} />
            <Route exact path="/estimate-view/:id" element={<ViewEstimate />} />
            <Route exact path="/quote-view/:id" element={<ViewQuote />} />
            <Route exact path="/Inovice-view/:id" element={<ViewInvoice />} />
            <Route
              exact
              path="/trade-appointments"
              element={<TradeAppointments />}
            />
          </Route>
          <Route
            exact
            path="/tradepeople-forgot-password"
            element={<TradeForgotPassword />}
          />
          <Route
            path="/trade/passwordReset/:token"
            element={<TradeResetPassword />}
          />
          <Route
            exact
            path="/tradepeople-register"
            element={<TradeRegister />}
          />
          <Route exact path="/tradepeople-login" element={<TradeLogin />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route
            exact
            path="/CustomerServices"
            element={<CustomerServices />}
          />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/TermsConditionsTraders"
            element={<TermsConditionsTraders />}
          />
          <Route
            exact
            path="/TermsConditionsCustomer"
            element={<TermsConditionsCustomer />}
          />
          <Route exact path="/job-vacancies" element={<JobVacancies />} />
          <Route exact path="/slavery-policy" element={<SlaveryPolicy />} />
          <Route
            exact
            path="/mission-statement"
            element={<MissionStatement />}
          />
          <Route exact path="/blogs" element={<Blogs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesPage;
