import MultiSelect from "../components/MultiSelect/MultiSelect";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import backLogo from "../assets/images/tradesperson/back_btn.svg";
import {
  enquirySubmit,
  getDataVal,
  getReview_Detail,
  getprofileDetail,
} from "../redux/Action/UserActions";
import EnquiryModal from "../components/user_acc_home/enquiry/EnquiryModal";
import ReactStars from "react-rating-stars-component";
// "images/tradesperson/yellow_star.svg"
import star from "../assets/images/tradesperson/yellow_star.svg";
import SelectAddress from "../components/MultiSelect/SelectAddress";
import moment from "moment";
import ReviewModal from "../components/user_acc_home/tradepersonRewiew/ReviewModal";
import lightLogo from "../assets/images/camera_placeholder.png";
const UserEnquiry = () => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showReview, setShowReview] = useState(false);
  const [reviewData, setReviewData] = useState();
  const [img, setImg] = useState({
    after_Img: "",
  });
  const [preView, setPreview] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company, trade_id, from } = useParams();
  console.log(from, 'from');
  const service = useSelector((state) => state?.useAction?.selected);
  const detailVal = useSelector((state) => state?.useAction?.trade_detail);
  const reviewDetail = useSelector(
    (state) => state?.useAction?.review_detail_new
  );
  const [postCode, setPostCode] = useState("");
  const [adressValue, setAdressValue] = useState("");

  const [addressApi, setAddressApi] = useState();
  const [postAddress, setPostAddress] = useState();
  const [enqAddress, setEnqAddress] = useState();
  const [show, setShow] = useState(false);

  const postCodeClick = async (e) => {
    try {
      e.preventDefault();
      const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          setAddressApi(res.data);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    dispatch(getDataVal());
    dispatch(getReview_Detail(trade_id));
  }, []);

  useEffect(() => {
    if (addressApi) {
      const data = addressApi?.addresses?.map((item) => {
        return item;
      });
      setPostAddress(data);
    }
  }, [addressApi]);
  console.log(enqAddress, "enqAddress")
  useEffect(() => {
    const addressFormat = adressValue && adressValue?.value.split(",");
    const enquiryAddress = {
      address: adressValue?.label,
      address_1: addressFormat[0],
      address_2: addressFormat[1],
      city: addressFormat[2],
      country: addressFormat[3],
      latitude: addressApi?.latitude,
      longitude: addressApi?.longitude,
      postcode: addressApi?.postcode,
    };
    setEnqAddress(enquiryAddress);
  }, [adressValue]);

  useEffect(() => {
    dispatch(getprofileDetail(trade_id));
  }, []);

  const formik = useFormik({
    initialValues: {
      description: "",
      contact_no: "",
      best_time_to_contact: "",
    },

    onSubmit: async (values) => {
      console.log(enqAddress,"enqAddress")
      const formData = new FormData();
      formData.append("service", service?.label);
      formData.append("serviceId", service?.value);
      formData.append("description", values.description);
      formData.append("enquiry_address", JSON.stringify(enqAddress));
      formData.append("contact_no", values.contact_no);
      formData.append("best_time_to_contact", values.best_time_to_contact);
      formData.append("userId", sessionStorage.getItem("userId"));
      formData.append("company_name", company);
      formData.append("tradeId", trade_id);
      formData.append("enquiry_img", img?.after_Img);
      // const value = {
      //   service: service?.label,
      //   serviceId: service?.value,
      //   description: values.description,
      //   enquiry_address: enqAddress,
      //   contact_no: values.contact_no,
      //   best_time_to_contact: values.best_time_to_contact,
      //   userId: sessionStorage.getItem("userId"),
      //   company_name: company,
      //   tradeId: trade_id,
      // };
      if (!service) {
        toast.warning("Please select service");
      } else if (!addressApi?.postcode) {
        toast.warning("Please provide postcode");
      } else if (!adressValue) {
        toast.warning("Please provide address");
      } else if (from == "request" && !values.contact_no) {
        toast.warning("Please provide valid Contact number");
      }
      // if(from == "request" && !values.contact_no){
      //   if(!values.contact_no || values.contact_no.toString().length > 16 ||
      //   values.contact_no.toString().length < 10){
      //     toast.warning("Provide valid contact number");
      //   }
      // }
      else if (from == "request" && !values.best_time_to_contact) {
        toast.warning("Please provide best time to contact");
      } else {
        try {
          dispatch(enquirySubmit(formData, setShow));
        } catch (error) {
          console.log("error");
        }
      }
    },
  });
  return (
    <div id="wrapper">
      {/* <!-- Back Button --> */}
      <EnquiryModal show={show} setShow={setShow} company={company} />
      <ReviewModal
        show={showReview}
        setShow={setShowReview}
        reviewData={reviewData}
      />
      <section className="pt-5 ">
        <div className="container-fluid p-75">
          <div className="row">
            <div className="col-12">
              <div className="back_btn mb-5">
                <a
                  onClick={() => navigate(-1)}
                  //   href="portal-home-request-visible.html"
                  className="d-flex align-items-center"
                >
                  {" "}
                  <img src={backLogo} alt="back-arrow" className="me-2" /> Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Back Button / End--> */}

      {/* <!-- New Enquiry--> */}
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="new_enquiry_title">
                <h2>
                  New Enquiry<span>to</span>
                </h2>
                <h2>{company}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- New Enquiry / End--> */}

      {/* <!-- New Enquiry Form--> */}
      <section className="new_enquiry_sect enquiry_input">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="card pt-5">
                <div className="card-body box_shadow_new">
                  <form onSubmit={formik.handleSubmit} className="form_new">
                    <div className="new_enquiry_content pb-35 enquiry_input">
                      <h3>Service Required</h3>
                      <div className="form-group">
                        <label for="">Select a Service</label>
                        <MultiSelect
                          typeSelect="user-enquiry"
                          detailVal={detailVal}
                        />
                      </div>
                    </div>
                    <div className="p-35">
                      <div className="new_enquiry_content enquiry_input_postcode">
                        <h3>Confirm Job Address</h3>
                        <div className="form-group mb-3">
                          <label for="">Postcode</label>
                          <SelectAddress
                            setAddressApi={setAddressApi}
                            setAdressValue={setAdressValue}
                          />
                        </div>

                        {/* <div className="text-center mt-4">
                          <h4 className="text-decoration-underline">
                            Address Not Listed
                          </h4>
                        </div> */}
                      </div>
                    </div>
                    <div className="new_enquiry_content pt-35 enquiry_input">
                      <h3>Your Enquiry</h3>
                      {from == "send" && (<div className="form-group mb-3">
                        <label for="">
                          Tell us more about your requirements
                        </label>
                        <textarea
                          name="description"
                          id=""
                          cols="30"
                          rows="5"
                          placeholder="Type Here..."
                          className="form-control box_shadow_new"
                          onChange={formik.handleChange}
                          value={formik.values.description}
                        ></textarea>
                      </div>)}
                      <div className="d-flex justify-content-between formWrapper">
                        {from == "request" && (
                          <div className="w-49 form-group">
                            <label for="">
                              Tell us more about your requirements
                            </label>
                            <textarea
                              name="description"
                              id=""
                              cols="30"
                              rows="5"
                              placeholder="Type Here..."
                              className="form-control box_shadow_new"
                              onChange={formik.handleChange}
                              value={formik.values.description}
                            ></textarea>

                          </div>
                        )}
                        {(
                          <div className="w-49 form-group">
                            <label for="">Add image</label>
                            <div className=" ">
                              <input
                                onChange={(e) => {
                                  setImg({
                                    ...img,
                                    after_Img: e.target.files[0],
                                  });
                                  setPreview((prev) => {
                                    return {
                                      ...prev,
                                      after_Img: URL.createObjectURL(e.target.files[0]),
                                    };
                                  });
                                }}
                                type="file"
                                accept="image/*"
                                className="reviews_upload dashed_box new_enq_img_hide"
                              />
                              <div className="upload_preview_image ">
                                <img
                                  className="dashed_box new_enq_img"
                                  src={preView?.after_Img ? preView?.after_Img : lightLogo}
                                />
                              </div>

                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-between formWrapper">
                        {from == "request" && (
                          <div className="w-49 form-group">
                            <label for="">Confirm Contact Number</label>
                            <div class="d-flex align-items-center phn-form-control box_shadow_new">
                              <div class="country_code">
                                <p>+44</p>
                              </div>
                              <input
                                name="contact_no"
                                type="number"
                                className="form-control  "
                                placeholder="Type Here.."
                                onChange={formik.handleChange}
                                value={formik.values.contact_no}
                              />
                            </div>
                          </div>
                        )}
                        {from == "request" && (
                          <div className="w-49 form-group">
                            <label for="">Best time to contact</label>
                            <input
                              name="best_time_to_contact"
                              type="time"
                              className="form-control box_shadow_new"
                              onChange={formik.handleChange}
                              value={formik.values.best_time_to_contact}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center pt-35">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        data-bs-toggle="modal"
                        data-bs-target="#successModal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="sidebar_reviews">
                <h3 className="text-center">What People Say about {company}</h3>
                <ul className="review-list">
                  {reviewDetail?.reviews?.length > 0 ? (
                    reviewDetail?.reviews?.map((item, i) => {
                      return (
                        <li className="mb-4 list-unstyled">
                          <div className="recent_reviews box_shadow ">
                            <div className="review_detail">
                              <div className="review_detail_left">
                                <img
                                  src={
                                    item?.senderId?.profile_image &&
                                      item?.senderId?.profile_image != "undefined"
                                      ? BACKEND_URL +
                                      "profile_image/" +
                                      item?.senderId?.profile_image
                                      : require("../assets/images/tradesperson/review-profile.png")
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="review_detail_right">
                                <h3 className="revewier_name">
                                  {item?.senderId?.firstName +
                                    " " +
                                    item?.senderId?.lastName}
                                </h3>
                                <div className="review_sect mt-2 mb-2">
                                  <div className="review_ratings_box">
                                    {item?.overall_rating ? (
                                      <ReactStars
                                        count={5}
                                        edit={false}
                                        isHalf={true}
                                        value={item?.overall_rating}
                                        size={40}
                                        activeColor="#ffd700"
                                      />
                                    ) : (
                                      <ReactStars
                                        count={5}
                                        edit={false}
                                        isHalf={true}
                                        size={40}
                                        activeColor="#ffd700"
                                      />
                                    )}
                                  </div>
                                </div>

                                <p className="comment_text">
                                  {item?.comments
                                    ? item?.comments
                                    : "TradePerson not gives any types of comments"}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex mt-4 justify-content-between">
                              <p>
                                {" "}
                                {moment(item?.createdAt).format("DD MMM YYYY")}
                              </p>
                              <a
                                className=""
                                onClick={() => {
                                  setShowReview(true);
                                  setReviewData(item);
                                }}
                              >
                                More
                                <img
                                  src={
                                    require("../assets/images/tradesperson/right-blue-arrow.svg")
                                      .default
                                  }
                                  alt=""
                                  className="ms-1"
                                />
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div className="no-enquiry-img review-img">
                      <img
                        src={require("../assets/images/no-reviews.svg").default}
                      />
                      <p>No Reviews yet</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- New Enquiry Form / End--> */}

      {/* <!-- Success Modal --> */}
      <div
        className="modal fade success_popup"
        id="successModal"
        tabindex="-1"
        aria-labelledby="successModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> --> */}
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="successModal_inner">
                    <img
                      src="images/tradesperson/check_img.svg"
                      alt=""
                      className="text-end"
                    />
                    <h2>Success!</h2>
                    <h3>
                      We’ve sent your enquiry to Drain Stoppers LTD You should
                      here back from them within 24 hours
                    </h3>
                    <div className="d-flex justify-content-center successmodal_btn">
                      <a
                        href="find-a-tradeperson-login.html"
                        className="btn white_btn"
                      >
                        View more Traders
                      </a>
                      <a
                        href="my-enquiries.html"
                        className="btn white_btn ms-3"
                      >
                        Go to my Enquiries
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Success Modal / End--> */}
    </div>
  );
};

export default UserEnquiry;
