import React, { useEffect } from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { add_complaint } from "../redux/Action/UserActions";

export default function CustomerServices() {


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validate = (values) => {
    const errors = {};
    if (!values.full_name) {
      errors.full_name = "Required*";
    }
    if (!values.email) {
      errors.email = "Required*";
    }
    if (!values.message) {
      errors.message = "Required*";
    }
    if (!values.subject) {
      errors.subject = "Required*";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      subject: "",
      message: ""
    },
    validate,
    onSubmit: async (values) => {
      try {
        dispatch(add_complaint(values, navigate));
      } catch (error) {
        console.log(error);
      }
    },
  });
  
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <UserLayout>
        <section class="contact_us_form symptom-checker m-120">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-md-12 d-flex justify-content-center">
                <div class="card card_shadow  card-height-box ">
                  <h2 class="text-center">Customer Services</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Full Name</label>
                      <input
                        type="text"
                        name="full_name"
                        placeholder="Full Name"
                        class="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.full_name}
                      />
                      {formik.errors.full_name && (
                        <p className="formik-error">
                          {formik.errors.full_name}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Email</label>
                      <input
                        name="email"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && (
                        <p className="formik-error">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        class="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.subject}
                      />
                      {formik.errors.subject && (
                        <p className="formik-error">
                          {formik.errors.subject}
                        </p>
                      )}
                    </div>
                    <div class="form-group mb-3 mt-4">
                      <label for="">Message</label>
                      <textarea
                        name="message"
                        placeholder="Message"
                        class="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      ></textarea>
                      {formik.errors.message && (
                        <p className="formik-error">
                          {formik.errors.message}
                        </p>
                      )}
                    </div>
                    <div class="btn_submit mt-4 d-flex justify-content-center">
                      <button
                        htmlType="submit"
                        class="btn btn-primary btn-custom btn-lg w-100"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </UserLayout>
    </div>
  );
}
