import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function MissionStatement() {
  return (
    <UserLayout>
      <div>
        <div className="container">
          <div className="terms_conditions">
            <h2 className="text-center pb-4 ">MISSION STATEMENT</h2>

            <div className="t_content">
              <h3>1. INTRODUCTION </h3>
              <p>
                At Smart Choice Traders, we are committed to promoting ethical
                standards in our business and supply chain. We recognize that
                modern slavery, which includes forced labor, human trafficking,
                debt bondage, child labor, and exploitative practices, is a
                significant violation of human rights. This policy outlines our
                commitment to combatting modern slavery and the expectations we
                hold for our tradespeople, suppliers, and staff
              </p>
            </div>
            <div className="t_content">
              <p>We are committed to:</p>
              <h3>1. Empowering Connections</h3>
              <p>
                By providing a user-friendly platform, we connect vetted and
                experienced tradespeople with customers who seek reliable
                services. Our aim is to simplify the booking process, making it
                as seamless as possible for customers to find the right expert
                for their needs.
              </p>
            </div>
            <div className="t_content">
              <h3>2. Enhancing Transparency</h3>
              <p>
                We champion open and transparent communication between
                tradespeople and customers. Our platform enables straightforward
                scheduling and quotation processes, ensuring that our users are
                equipped with all the necessary information to make informed
                decisions.
              </p>
            </div>
            <div className="t_content">
              <h3>3. Fostering Accountability</h3>
              <p>
                Our dual review system not only allows customers to assess
                tradespeople based on their experience but also empowers
                tradespeople to rate customers. This mutual accountability
                builds trust and encourages a culture of respect and high
                standards within the community.
              </p>
            </div>
            <div className="t_content">
              <h3>4. Promoting Fair Practices</h3>
              <p>
                By implementing a transaction-based fee structure rather than
                traditional subscription models, we ensure that both
                tradespeople and customers can engage in our platform without
                the burdens of ongoing costs. This democratises access to
                quality services and encourages more interactions within the
                trades community.
              </p>
            </div>
            <div className="t_content">
              <h3>5. Creating a Secure Environment</h3>
              <p>
                We prioritise the safety and security of our users. Our rigorous
                vetting process for tradespeople and commitment to quality
                assurance help foster a safe space where customers can
                confidently seek services and tradespeople can showcase their
                skills.
              </p>
            </div>
            <p>
              Our ultimate goal is to enhance the experience of hiring and
              providing tradespeople, turning every interaction into a positive
              and rewarding experience. Together, we build a community anchored
              in trust, quality and mutual respect ensuring that every choice
              made through Smart Choice Traders is indeed a smart choice.
            </p>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
