import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReviewDetail from "./ReviewDetail";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReviews,
  getAllUserAppointments,
} from "../../redux/Action/UserActions";
import moment from "moment/moment";
import SeeReview from "./SeeReview";
const Reviews = (props, ref) => {
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [showReview, setShowReview] = useState(false);
  const [seeDetail, setSeeDetail] = useState(false);
  const [aptId, setaptId] = useState({
    userId: "",
    tradeId: "",
    appointmentId: "",
  });
  const [reviewId,setReviewId]=useState()
  const dispatch = useDispatch();
  const traderAppointment = useSelector(
    (state) => state?.useAction?.user_appointment
  );
  const userReviews = useSelector((state) => state?.useAction?.user_reviews);
  console.log(userReviews);
  const pendingApointment = traderAppointment?.filter((apt) => {
    return apt?.customer_review_status === 0;
  });
  useEffect(() => {
    dispatch(getAllUserAppointments());
    dispatch(getAllReviews());
  }, [showReview]);
  // const handleSubmit = () => {
  //   console.log("Reviews");
  // };
  // useImperativeHandle(ref, () => ({
  //   handleSubmit,
  // }));
  return (
    <>
      {showReview == false && seeDetail==false &&(
        <div className="col-lg-8">
          <div className="review-detail-sect">
            <div className="pending_reviews mb-5">
              <h4 className="mb-2">Pending Reviews</h4>
              <div className="row">
                {pendingApointment?.length > 0?
                  pendingApointment?.map((item, i) => {
                    return (
                      <div className="col-lg-6">
                        <div
                          onClick={() => {
                            setShowReview(true);
                            setaptId({
                              ...aptId,
                              userId: item?.userId?._id,
                              tradeId: item?.tradeId?._id,
                              appointmentId: item?._id,
                            });
                          }}
                          className="pending_reviews_height"
                        >
                          <div className="recent_reviews box_shadow mb-4">
                            <div className="review_detail">
                              <div className="review_detail_left">
                                <img
                                  src={
                                    item?.tradeId?.business_image && item?.tradeId?.business_image !="undefined"
                                      ? BACKEND_URL +
                                        "business_image/" +
                                        item?.tradeId?.business_image
                                      : require("../../assets/images/tradesperson/review-profile.png")
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="review_detail_right">
                                <h3 className="revewier_name">{item?.tradeId?.company_name}</h3>
                                <div className="review_sect mt-2 mb-2">
                                  <div className="review_ratings_box">
                                    <ReactStars
                                      count={5}
                                      edit={false}
                                      isHalf={true}
                                      value={item?.reviewId?.overall_rating}
                                      size={38}
                                      activeColor="#ffd700"
                                    />
                                  </div>
                                </div>

                                <p>Your Review is Pending</p>
                              </div>
                            </div>
                            <div className="d-flex mt-4 justify-content-end">
                              <h4>
                                Review Now
                                <img
                                  src={
                                    require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                      .default
                                  }
                                  alt=""
                                  className="ms-1"
                                />
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }):<div className="no_reviews">No Pending Reviews yet</div>}
              </div>
            </div>
            <div className="approved_review">
              <h4 className="mb-2">Your Reviews</h4>
              <div className="overflow_scroll_table">
              <div className="row">
                {userReviews?.length > 0 ? (
                  userReviews?.map((item, i) => {
                    return (
                      <div className="col-lg-6">
                        <div className="recent_reviews box_shadow mb-4">
                          <div className="review_detail">
                            <div className="review_detail_left">
                              <img
                                src={
                                  item?.senderId?.business_image && item?.senderId?.business_image != "undefined"
                                    ? BACKEND_URL +
                                      "business_image/" +
                                      item?.senderId?.business_image
                                    : require("../../assets/images/tradesperson/review-profile.png")
                                }
                                alt=""
                              />
                            </div>
                            <div className="review_detail_right">
                              <h3 className="revewier_name">{item?.senderId?.company_name}</h3>

                              <div className="review_sect mt-2 mb-2">
                                <div className="review_ratings_box">
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    isHalf={true}
                                    value={item?.overall_rating}
                                    size={38}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>

                              <p className="comment_text">
                                {item?.comments
                                  ? item?.comments
                                  : "TradePerson not gives any types of comments"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex mt-4 justify-content-between">
                            <p>
                              {moment(item?.createdAt).format("DD MMM YYYY")}
                            </p>
                            <div className="cursor-pointer" onClick={()=>{setSeeDetail(true);setReviewId(item?._id)}}>
                              Read More
                              <img
                                src={
                                  require("../../assets/images/tradesperson/right-blue-arrow.svg")
                                    .default
                                }
                                alt=""
                                className="ms-1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div  className="no_reviews">No Reviews yet</div>
                )}
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showReview == true && seeDetail==false &&(
        <ReviewDetail setShowReview={setShowReview} aptId={aptId} />
      )}
      {seeDetail == true && showReview == false &&(
        <SeeReview setSeeDetail={setSeeDetail} reviewId={reviewId}/>
      )}
    </>
  );
};

export default forwardRef(Reviews);
