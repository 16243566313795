import React from "react";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";

const SignUpSuccess = () => {
  return (
    <Layout>
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div className="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p className="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in signup-success">
                  <form action="" className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h3 className="mb-4">
                            Thank you for joining <br /> Smart Choice Traders!
                          </h3>
                          <p className="mb-4">
                            We’ve sent you an email to confirm your account.{" "}
                          </p>
                          <p className="marginBottom30">
                            You’ll need to confirm your account and setup your
                            password before submitting any requests.
                          </p>

                          <div className="btn_submit mt-4 d-flex">
                            <Link
                              to="/signin"
                              className="btn btn-primary btn-custom btn-lg w-100"
                            >
                              Return to Sign in
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SignUpSuccess;
