import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEstimateDetailUser, getInvoiceDetailUser } from "../../redux/Action/UserActions";
import UserAccLayout from "../user_acc_home/layout/UserAccLayout";
import { useReactToPrint } from "react-to-print";
const InVoiceUser = () => {
  const componentRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const esDetail = useSelector((state) => state?.useAction?.estimat_detail);
  const InvoiceDetail = useSelector(
    (state) => state?.useAction?.invoice_detail1
  );
  useEffect(() => {
    dispatch(getInvoiceDetailUser(id));
    dispatch(getEstimateDetailUser(id));
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  return (
    <UserAccLayout>
    <div ref={componentRef}>
  {/* <!-- New Estimate--> */}
  <section className="new-estimate-title">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="new_enquiry_title">
                <h2>New Estimate</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- New Estimate / End--> */}

      {/* <!-- View Estimate Form--> */}
      <section className="view-estimate-sect">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-new">
                <div className="card-body card-body-new">
                  <h3 className="mb-3">
                    {esDetail?.tradeId.company_name}
                  </h3>
                  <p>{esDetail?.serviceId?.service}</p>
                  {esDetail?.enquiry_address?.address
                      ?.split(",")
                      .filter((val) => {
                        return val !== " ";
                      })
                      ?.map((item, i) => {
                        return <p>{item}</p>;
                      })}
                    <p>{esDetail?.enquiry_address?.postcode}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 estimate_print_hide">
              <div className="back_btn d-flex justify-content-end">
                <Link to="/my-enquiries" className="d-flex align-items-center">
                   Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- View Estimate Form / End--> */}

      {/* <!-- View Estimate Form--> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-estimate">
                <table className="table table-scroll small-first-col mb-0">
                  <thead>
                    <tr>
                      <th>
                        <p>Description</p>
                      </th>
                      <th>
                        <p>Qty</p>
                      </th>
                      <th>
                        <p>Rate</p>
                      </th>
                      <th>
                        <p>Discount</p>
                      </th>
                      <th>
                        <p>Sales Tax</p>
                      </th>
                      <th>
                        <p>Amount</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="body-half-screen">
                    {InvoiceDetail?.invoice_arr.map((item, i) => {
                      return (
                        <tr>
                          <td>
                            <p>{item?.description?item?.description:"N/A"}</p>
                          </td>
                          <td>
                            <p>{item?.qty?item?.qty:"0"}</p>
                          </td>
                          <td className="fixed-amt">
                        <div className="d-flex align-items-center justify-content-end">
                          {/* <p className="total-amt">Total</p> */}
                          <p className="total-amt-width fixed-amt">
                            <span>£</span>
                            {item?.rate? parseFloat(item?.rate).toFixed(2) :"0.00"}
                          </p>
                        </div>
                      </td>
                          <td>
                          <p>{item?.discount? parseFloat(item?.discount).toFixed(2):"0.00"}</p>
                          </td>
                          <td>
                            <p>{item?.sales_tax?item?.sales_tax:"0"}%</p>
                          </td>
                           
                          <td className="fixed-amt">
                        <div className="d-flex align-items-center justify-content-end">
                          {/* <p className="total-amt">Total</p> */}
                          <p className="total-amt-width fixed-amt disabled-amt">
                            <span>£</span>
                            {item?.amount ? parseFloat(item?.amount).toFixed(2) : "0"}
                          </p>
                        </div>
                      </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <section>
                  <div className="add-more-row text-end">
                    <a href="">Add More</a>
                  </div>
                </section> */}
                <table className="table fixed-table">
                  <tbody>
                    <tr>
                      <td className="fixed-amt">
                        <div className="d-flex align-items-center justify-content-end">
                          <p className="total-amt">Total</p>
                          <p className="total-amt-width fixed-amt disabled-amt">
                            <span>£</span>
                            {InvoiceDetail?.total ? parseFloat(InvoiceDetail?.total).toFixed(2)  : "0"}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="fixed-amt border-none-tb">
                        <div className="d-flex align-items-center justify-content-end">
                          <p className="total-amt">Sales Tax</p>
                          <p className="total-amt-width fixed-amt disabled-amt">
                            <span>£</span>
                            {InvoiceDetail?.total_sales_tax
                              ? parseFloat(InvoiceDetail?.total_sales_tax).toFixed(2) 
                              : "0"}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                        <td className="fixed-amt">
                          <div className="d-flex align-items-center justify-content-end">
                            <p className="total-amt">
                            SCT service fee
                              {/* Smart Choice Protection Fee */}
                            </p>
                            <p className="total-amt-width fixed-amt disabled-amt">
                              <span>£</span>
                              {parseFloat((InvoiceDetail?.total * 10) / 100).toFixed(2) }
                              {/* { 
                              parseFloat((InvoiceDetail?.total * 10) / 100) < 11.80 
                              ? "11.80" : 
                              parseFloat((InvoiceDetail?.total * 10) / 100) > 35.00 
                              ? "35.00" : 
                              parseFloat((InvoiceDetail?.total * 10) / 100).toFixed(2) 
                              } */}
                            </p>
                          </div>
                        </td>
                      </tr>
                    <tr>
                      <td className="fixed-amt">
                        <div className="d-flex align-items-center justify-content-end">
                          <p className="total-amt">NET Amount</p>
                          <p className="total-amt-width fixed-amt disabled-amt ">
                            <span>£</span>
                            {InvoiceDetail?.net_amount
                                ? parseFloat(InvoiceDetail?.net_amount +
                                  parseFloat((InvoiceDetail?.total * 10) / 100)
                                  ).toFixed(2)
                                : "0"}
                            {/* {InvoiceDetail?.net_amount
                                ? parseFloat(InvoiceDetail?.net_amount +
                                  (parseFloat((InvoiceDetail?.total * 10) / 100) < 11.80
                                  ? 11.80 :
                                  parseFloat((InvoiceDetail?.total * 10) / 100) > 35.00
                                    ? 35.00 :
                                    parseFloat((InvoiceDetail?.total * 10) / 100))).toFixed(2)
                                : "0"} */}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- View Estimate Form / End--> */}

      <section className="notes-sect">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="notes-sect-inner">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-6">
                    <div>
                      <h5>Notes</h5>
                      <p  className="text-overflow-notes form-control">
                      {InvoiceDetail?.notes}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 estimate_print_hide">
                 
                    <div className="d-flex justify-content-end align-items-end flex-column h-100">
                      <button className="btn btn-primary " onClick={handlePrint}>Print</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </UserAccLayout>
  );
};

export default InVoiceUser;
