import axios from "axios";
import { FieldArray, Form, Formik, useFormik } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteBusinessLocation,
  getTradeLocations,
  getTradePersonProfile,
  updateLocationsProfile,
  updateTradeProfile,
} from "../../../redux/Action/tradeActions";
import { useDispatch, useSelector } from "react-redux";
import RegularPopup from "../../regular_popup/RegularPopup";

const BusinessLoaction =(props, ref) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [addressApi, setAddressApi] = useState([]);
  const [latitude, setLatitude] = useState([]);
  const [longitude, setLongitude] = useState([]);
  const [businessLocations, setBusinessLocations] = useState();
  const [showModal, setShowModal] = useState(false);
  const tradeProfileDetail = useSelector(
    (state) => state?.tradeAction?.tradePerson_detail
  );
  const businessLocation = useSelector(
    (state) => state?.tradeAction?.traderLoctions
  );
  const [radius, setRadius] = useState();

  const postCodeClick = async (e, postCode) => {
    try {
      e.preventDefault();
      const url = `https://api.getaddress.io/find/${postCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      await axios
        .get(url)
        .then((res) => {
          setAddressApi(res.data.addresses);
          setLatitude(res.data.latitude);
          setLongitude(res.data.longitude);
        })
        .catch((err) => {
          if (
            err?.response?.data?.Message == "Bad Request: Invalid postcode."
          ) {
            toast.warning("Invalid Postcode");
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFormikArr = (dataParse, setFieldValue, index, postcode) => {
    const data = JSON.parse(dataParse);
    console.log(postcode,"dataParse");

    setFieldValue(
      `business_locations.${index}.tradeId`,
      sessionStorage.getItem("trade_id")
    );
    setFieldValue(
      `business_locations.${index}.address`,
      `${data?.line_1}, ${data?.line_2}, ${data?.town_or_city}, ${data?.country}`
    );
    setFieldValue(`business_locations.${index}.postcode`, postcode);
    setFieldValue(`business_locations.${index}.address_1`, data?.line_1);
    setFieldValue(`business_locations.${index}.address_2`, data?.line_2);
    setFieldValue(`business_locations.${index}.city`, data?.town_or_city);
    setFieldValue(`business_locations.${index}.country`, data?.country);
    setFieldValue(`business_locations.${index}.latitude`, latitude);
    setFieldValue(`business_locations.${index}.longitude`, longitude);
    setFieldValue(`business_locations.${index}.location`, {
      coordinates: [longitude, latitude],
    });
  };
  useEffect(() => {
    dispatch(getTradePersonProfile());
    sessionStorage.setItem("tradeId", tradeProfileDetail?._id);
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("trade_id")) {
      dispatch(getTradeLocations(sessionStorage.getItem("trade_id"))); 
    }
  }, []);

  useEffect(() => {
    if (businessLocation) {
      setBusinessLocations(businessLocation);
    }
  }, [businessLocation]);

  // const handleSubmit=()=>{
  //   formRef.current.handleSubmit()
  // }
  // useImperativeHandle(ref, () => ({
  //   handleSubmit
  // }));
  return (
    <>
{showModal==true && <RegularPopup showModal={showModal} setShowModal={setShowModal} modalName={"tradeMyAccount"} />}
  <div className="col-lg-8"> 
      <Formik
        // enableReinitialize: true
        innerRef={formRef}
        initialValues={{
          tradeId: tradeProfileDetail?._id,
          business_locations: businessLocations || [
            {
              tradeId: "",
              address: "",
              address_1: "",
              address_2: "",
              city: "",
              country: "",
              latitude: null,
              longitude: null,
              postcode: "",
              radius: "",
              location: {
                coordinates: [longitude, latitude],
              },
            },
          ],
        }}
        onSubmit={(values) => {
          dispatch(
            updateLocationsProfile(values, sessionStorage.getItem("trade_id"),setShowModal)
          );
        }}
        render={({ values, handleChange, setFieldValue }) => (
          <Form >
            <FieldArray
              name="business_locations"
              render={(arrayHelpers) => (
                <div>
                   <div className="text-end ms-3">
                    <button type="submit" className="btn btn-primary" >
                      Save
                    </button>
                  </div>
                  {values.business_locations &&
                    values.business_locations.length > 0 &&
                    values.business_locations?.map((item, index) => (
                      <div key={index}>
                        <form className="form_new">
                          <div className="row">
                            <div className="col-2">
                              <div className="add_another_row">
                                <p>{index + 1}</p>
                              </div>
                            </div>
                            <div className="col-10">
                              <div className="new_enquiry_content add_business_loc">
                                <div className="form-group mb-3">
                                  <label for="">{index==0?"Primary Location":"Additional Location"}</label>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <input
                                        type="text"
                                        className="form-control box_shadow_new"
                                        placeholder="Type Here"
                                        value={
                                          values.business_locations[index]
                                            .postcode
                                        }
                                        onChange={handleChange}
                                        name={`business_locations.${index}.postcode`}
                                      />
                                    </div>
                                    <div className=" col-md-3">
                                      <input
                                        onClick={(e) =>
                                          postCodeClick(
                                            e,
                                            values.business_locations[index]
                                              .postcode
                                          )
                                        }
                                        type="submit"
                                        value="Search"
                                        className="form-control search_btn"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group mb-3">
                                  <label for="">Select Address</label>
                                  <select
                                    className="form-control  box_shadow_new"
                                    //   value={values.business_locations[index].address}
                                    onChange={(e) =>
                                      handleFormikArr(
                                        e.target.value,
                                        setFieldValue,
                                        index,
                                        values.business_locations[index]
                                          .postcode
                                      )
                                    }
                                    name={`business_locations.${index}.address`}
                                  >
                                    <option>
                                      {values.business_locations[index].address
                                        ? values.business_locations[index]
                                          .address
                                        : "Select Address"}
                                    </option>
                                    {addressApi?.map((item, i) => {
                                      return (
                                        <option value={JSON.stringify(item)}>
                                          {`${item?.line_1}, ${item?.line_2}, ${item?.town_or_city}, ${item?.country}`}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="form-group mb-5">
                                  <label for="">Radius Covered</label>
                                  {/* <input
                                    type="number"
                                    className="form-control box_shadow_new"
                                    placeholder="Add Miles"
                                    value={
                                      values.business_locations[index].radius
                                    }
                                    onChange={handleChange}
                                    name={`business_locations.${index}.radius`}
                                  /> */}
                                  <select
                                    className="form-control box_shadow_new"
                                    onChange={handleChange}
                                    name={`business_locations.${index}.radius`}
                                    value={
                                      values.business_locations[index].radius
                                    }
                                  >
                                    <option selected disabled hidden>
                                      Select Radius
                                    </option>
                                    <option value="5">5 miles</option>
                                    <option value="10">10 miles</option>
                                    <option value="20">20 miles</option>
                                    <option value="30">30 miles</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex  justify-content-center">
                                <div className="remove_btn mb-3 ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (item?._id) {
                                        dispatch(
                                          deleteBusinessLocation(item?._id)
                                        );
                                      }
                                      arrayHelpers.remove(index);
                                    }}
                                    className="btn white_btn"
                                  >
                                    Remove
                                  </button>
                                </div>

                                {/* {values.business_locations?.length - 1 ==
                                  index && (
                                  <div className="add_another_btn mb-3 ms-3">
                                    {index == 2 ? (
                                      <button
                                        type="button"
                                        className="btn white_btn"
                                        disabled
                                      >
                                        Add another
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            tradeId:"",
                                            address: "",
                                            address_1: "",
                                            address_2: "",
                                            city: "",
                                            country: "",
                                            latitude: null,
                                            longitude: null,
                                            postcode: "",
                                            radius: "",
                                          })
                                        }
                                        className="btn white_btn"
                                      >
                                        Add another
                                      </button>
                                    )}
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                 <div className="d-flex justify-content-center pt-35">
                 <div className="add_another_btn add-btn-row">
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          tradeId: "",
                          address: "",
                          address_1: "",
                          address_2: "",
                          city: "",
                          country: "",
                          latitude: null,
                          longitude: null,
                          postcode: "",
                          radius: "",
                          location: {
                            coordinates: [0, 0],
                          },
                        })
                      }
                      className="btn white_btn"
                    >
                      Add
                    </button>
                  </div>
                  {/* <div className="text-end ms-3">
                    <button type="submit" className="btn btn-primary" >
                      Update
                    </button>
                  </div> */}
                 </div>
                </div>
              )}
            />
          </Form>
        )}
        enableReinitialize={true}

      />
    
    </div>
    </>
  
  );
};

export default forwardRef(BusinessLoaction);
