import Modal from "react-bootstrap/Modal";
import ReactStars from "react-rating-stars-component";
import lightLogo from "../../../assets/images/white_logo_main.png";
import moment from "moment";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

const ReviewModal = ({ show, setShow, reviewData, type }) => {
  const handleClose = () => setShow(false);
  const BACKEND_URL = process.env.REACT_APP_FILE_BASE_URL;
  return (
    <>
      <Modal show={show} onHide={handleClose} className="rating_modal_inner">
        <Modal.Header closeButton>
          <Modal.Title>Overall Ratings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-lg-6">
              <div class="review_expanded_left">
                <div class="review_sect mt-3 mb-3 overall_ratings_score">
                  <div class="review_ratings_box">
                    {reviewData && (
                      <ReactStars
                        count={5}
                        value={reviewData?.overall_rating}
                        edit={false}
                        isHalf={true}
                        size={60}
                        activeColor="#ffd700"
                      />
                    )}
                  </div>
                </div>
                <div className="comment mb-4">
                  <form className="form-group ">
                    <label className="">Comment</label>
                    <textarea
                      className="form-control box_shadow_new"
                      value={reviewData?.comments}
                      disabled
                    ></textarea>
                  </form>
                </div>
                <div class="ratings_fix_height">
                  <ul class="ratings_view">
                    <li>
                      <h6>Quote Accuracy</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      </p>
                      <div class="review_ratings_box lineHeight_stars">
                        {reviewData && (
                          <ReactStars
                            count={5}
                            value={reviewData?.quoteAccuracy}
                            edit={false}
                            isHalf={true}
                            size={40}
                            activeColor="#ffd700"
                          />
                        )}
                      </div>
                    </li>
                    <li>
                      <h6>Punctuality</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      </p>
                      <div class="review_ratings_box lineHeight_stars">
                        {reviewData && (
                          <ReactStars
                            count={5}
                            value={reviewData?.punctuality}
                            edit={false}
                            isHalf={true}
                            size={40}
                            activeColor="#ffd700"
                          />
                        )}
                      </div>
                    </li>
                    <li>
                      <h6>Quality of Work</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      </p>
                      <div class="review_ratings_box lineHeight_stars">
                        {reviewData && (
                          <ReactStars
                            count={5}
                            value={reviewData?.quality_of_Work}
                            edit={false}
                            isHalf={true}
                            size={40}
                            activeColor="#ffd700"
                          />
                        )}
                      </div>
                    </li>
                    <li>
                      <h6>Tidiness</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      </p>
                      <div class="review_ratings_box lineHeight_stars">
                        {reviewData && (
                          <ReactStars
                            count={5}
                            value={reviewData?.tidiness}
                            edit={false}
                            isHalf={true}
                            size={40}
                            activeColor="#ffd700"
                          />
                        )}
                      </div>
                    </li>
                    <li>
                      <h6>Friendliness</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      </p>
                      <div class="review_ratings_box lineHeight_stars">
                        {reviewData && (
                          <ReactStars
                            count={5}
                            value={reviewData?.friendliness}
                            edit={false}
                            isHalf={true}
                            size={40}
                            activeColor="#ffd700"
                          />
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="review_expanded_right">
                <div class="review-title_sect text-end">
                  <img src="images/tradesperson/review-profile.png" alt="" />
                  <h3>{reviewData?.senderId?.firstName + " " + reviewData?.senderId?.lastName}</h3>
                  <h3>{reviewData?.appointmentId?.enquiryId?.service}</h3>
                  <p>Reviewed in  {moment(reviewData?.appointmentId?.createdAt).format(
                    "MMM YYYY"
                  )}</p>
                </div>
                <div class="image_transform">
                  <LightgalleryProvider
                    galleryClassName="my_custom_classname"
                  >
                    <LightgalleryItem src={type == 1 ? (reviewData?.after_Img
                      ? BACKEND_URL +
                      reviewData?.after_Img
                      : lightLogo) : (reviewData?.after_Img
                        ? BACKEND_URL +
                        reviewData?.after_Img
                        : lightLogo)}>
                      <div class="mb-3">
                        <img
                          className="dashed_box"
                          src={
                            type == 1 ? (reviewData?.after_Img
                              ? BACKEND_URL +
                              reviewData?.after_Img
                              : lightLogo) : (reviewData?.after_Img
                                ? BACKEND_URL +
                                reviewData?.after_Img
                                : lightLogo)

                          }
                        />
                        <h6 class="mt-2">Before</h6>
                      </div>
                    </LightgalleryItem>
                    <LightgalleryItem src={type == 1 ? (reviewData?.before_Img
                      ? BACKEND_URL +
                      reviewData?.before_Img
                      : lightLogo) : (reviewData?.before_Img
                        ? BACKEND_URL +
                        reviewData?.before_Img
                        : lightLogo)}>
                      <div>
                        <img
                          className="dashed_box"
                          src={type == 1 ? (reviewData?.before_Img
                            ? BACKEND_URL +
                            reviewData?.before_Img
                            : lightLogo) : (reviewData?.before_Img
                              ? BACKEND_URL +
                              reviewData?.before_Img
                              : lightLogo)

                          }
                        />
                        <h6 class="mt-2">After</h6>
                      </div>
                    </LightgalleryItem>


                  </LightgalleryProvider>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewModal;
