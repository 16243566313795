import React from "react";
import UserLayout from "../components/layout/user_layout/UserLayout";

export default function TermsConditionsTraders() {
  return (
    <UserLayout>
      <div className="container">
        <div className="terms_conditions">
          <h2 className="text-center pb-4 ">Traders Terms and Conditions</h2>

          <div className="t_content">
            <p>
              The Service Provider terms and conditions below (Terms) form the
              agreement which governs your use of the Smart Choice Traders
              platform, accessible at www.smartchoicetraders.com and any goods
              or services you sell through the Platform. By clicking accept, or
              using the Platform and applying for a Service Provider account
              with us, you agree to be bound by these Terms which forms a
              binding contractual agreement between you, the Service Provider,
              and us, Smart Choice Traders Ltd a company registered in England
              and Wales with Company Number 13329225 and registered office
              London Office Great Portland Street, 167-169 Great Portland Street
              (5th Floor), London, England, W1W 5PF (Smart Choice Traders, we or
              us).
            </p>
            <div className="cont">
              <ol>
                <li>
                  DEFINITIONS
                  <ul>
                    In these Terms, the following words have the corresponding
                    meaning:
                    <li>
                      Account means your Service Provider account on the Service
                      Provider Portal of the Platform;
                    </li>
                    <li>
                      Service Provider Portal means the Service Provider portal
                      for Service Providers to create and manage Listings on the
                      Platform;
                    </li>
                    <li>
                      Commencement Date means the date this agreement is signed;
                    </li>
                    <li>
                      Commission means the commission payable to Smart Choice
                      Traders for each Payment made via the Platform, as set out
                      in the Service Provider Portal or as otherwise agreed
                      between the parties in writing;{" "}
                    </li>
                    <li>
                      Customer means a customer of the Platform, being a person
                      or entity who places an order for Services (whether sold
                      by you or another User) on the Platform;
                    </li>
                    <li>
                      Customer Fee means the fee payable by Customers to Smart
                      Choice Traders for each Payment made, as set out in the
                      Platform.
                    </li>
                    <li>
                      Customer Terms means the terms applicable to the Customers
                      (and which will apply to your sale of Services to
                      Customers via the Platform), found here [link];{" "}
                    </li>
                    <li>
                      Services means the services you offer to Customers on the
                      Platform including but not limited to the services
                      available in your Listings;
                    </li>
                    <li>Listing means a listing for the sale of Services;</li>
                    <li>
                      Order means an order for Services placed by a Customer via
                      the Platform;
                    </li>
                    <li>
                      Price means the price payable for the Services in a
                      listing, as set by the Brand;
                    </li>
                    <li>
                      Remaining Amount means the Price less the Commission;
                    </li>
                    <li>
                      Third Party Payment Platform means any third party payment
                      platform available to make or receive payments on the
                      Platform, as set out in clause 13(i);
                    </li>
                    <li>
                      User means any user of the Platform including without
                      limitation you, other Service Providers and Customers.{" "}
                    </li>
                    In addition to the above words, throughout these Terms we
                    may define additional words and phrases.
                  </ul>
                </li>
                <li>
                  DURATION OF THIS AGREEMENT These Terms commence on the
                  Commencement Date and continue until terminated in accordance
                  with clause 24 (Term).
                </li>
                <li>
                  REPRESENTING A COMPANY If you use the Platform on behalf of a
                  company or organisation you warrant that you have the
                  necessary authority from that company or organisation to do
                  so. If you are signing up not as an individual but on behalf
                  of your company, your employer, an organisation, government or
                  other legal entity (Represented Entity), then “you” and
                  “Brand” means the Represented Entity and you are binding the
                  Represented Entity to this agreement. If you are accepting
                  this agreement and using our Platform on behalf of a
                  Represented Entity, you represent and warrant that you are
                  authorised to do so.
                </li>
                <li>
                  ACCOUNTS
                  <ul>
                    All Service Providers are required to sign-up to and create
                    an Account on our Service Provider Portal <br /> As part of
                    the Account registration process and as part of your
                    continued use of the Platform, you are required to provide:
                    <li>
                      Contact information and details, such as your company
                      details, email address, first and last name of the
                      relevant contact person, mobile phone number, and other
                      information as determined by Smart Choice Traders from
                      time to time;
                    </li>
                    <li> Service Provider logos;</li>
                    <li>A description of your business and services;</li>
                    <li>Your qualifications and compliance documents; and</li>
                    <li>
                      You are also required to provide information, including
                      supporting documentation and evidence as we so require, to
                      substantiate any claims of registrations, qualifications
                      or certifications of the Service Provider.
                    </li>
                    <li>
                      You warrant that any information you give to Smart Choice
                      Traders in the course of completing the Account
                      registration will always be accurate, honest, correct and
                      up-to-date.
                    </li>
                    <li>
                      Smart Choice Traders reserves the right to contact you
                      about any concerning behaviour by you, or to seek a
                      resolution with you.
                    </li>
                    <li>
                      Smart Choice Traders may, in its absolute discretion,
                      suspend or cancel your Account for any reason, including
                      for any failure to comply with this agreement
                    </li>
                  </ul>
                </li>
                <li>
                  LISTINGS & QUOTES <br />
                  You acknowledge and agree that:
                  <ul>
                    you must use your best endeavours to provide as much
                    information as possible in any listing you upload
                    <li>
                      a complete description of the Services you offer; and
                    </li>
                    <li> Service areas.</li>
                    <li>
                      you warrant that all information provided in a Listing or
                      in respect of the Services is accurate and up to date, and
                      you are solely responsible for updating the Listing
                      details on the Platform;
                    </li>
                    <li>
                      you can update or amend a Listing via the Service Provider
                      Portal;
                    </li>
                    <li>
                      Smart Choice Traders will not be liable to you for any
                      errors in the Listings (including any pricing errors);
                    </li>
                    <li>
                      Smart Choice Traders may choose not to accept any Listing
                      you submit to the Platform, and Smart Choice Traders may
                      limit the number of Listings you can submit on the
                      Platform;
                    </li>
                  </ul>
                </li>
                <li>
                  QUOTES <br />
                  You acknowledge and agree that:
                  <ul>
                    Customers will be able to respond to your Listings and
                    request a quote for the provision of the Services in a
                    Listing specific to their requirements (Customer Request).
                    <br />
                    You must respond to Customer Requests by preparing and
                    submitting a quote via the Platform in the format specified
                    on the Platform, including specifying:
                    <li> The scope of the Services to be provided;</li>
                    <li> Any pre-conditions to providing the Services;</li>
                    <li>
                      Any goods or materials which are supplied with the
                      Services;
                    </li>
                    <li> The date for delivery of the Services;</li>
                    <li> VAT status;</li>
                    <li>
                      The price for the Services (including any goods or
                      materials supplied with the Services)(Quoted Amount);
                    </li>
                    <li>
                      Your terms and conditions including any warranties, late
                      payment policy and cancellation policies; and
                    </li>
                    <li>
                      If the Quoted Amount is due in Instalments, the frequency
                      and due dates of the Instalments,
                    </li>
                    <li>
                      When a customer accepts a Quote, they will place an Order
                      on the Platform and be prompted to pay the Quoted Amount
                      plus the Customer Fee (Price).{" "}
                    </li>
                    <li>
                      Smart Choice Traders will not be liable to you for any
                      errors in the Quote (including any pricing errors); and{" "}
                    </li>
                    <li>
                      You will indemnify Smart Choice Traders against any loss,
                      claim, liability, expense or damages arising out of or in
                      connection with a Listing, Quote or your Services
                    </li>
                  </ul>
                </li>
                <li>
                  INSTALMENTS
                  <ul>
                    <li>
                      Where the Quoted Amount is payable over multiple
                      instalment payments (Instalments), the Customer will be
                      prompted to pay the first Instalment plus the Customer
                      Fee. The Customer Fee shall be payable for each Instalment
                      payment made via the Platform. ;
                    </li>
                    <li>
                      You may prompt the Customer to pay any subsequent
                      Instalments via the Service Provider Portal.
                    </li>
                    <li>
                      You must not accept payment for Services forming part of
                      an Order, including but not limited to any part of the
                      Quoted Amount, outside of the Platform.
                    </li>
                  </ul>
                </li>
                <li>
                  BINDING CONTRACT
                  <br />
                  You agree that when a Customer places an Order for Services in
                  one of your Listings, this constitutes a binding contract
                  between you and that Customer, where you will provide the
                  Customer with the Services in the Listing they accepted in
                  exchange for your receipt of the Remaining Balance. A contract
                  is formed in this respect when the Customer places the Order
                  on the Platform.
                </li>
                <li>
                  DELIVERY OF SERVICES
                  <ul>
                    <li>
                      Delivery of Services to Customers is the responsibility of
                      the Service Provider.
                    </li>
                    <li>
                      All Orders will be forwarded automatically to the Service
                      Provider via email.
                    </li>
                    <li>
                      It is your responsibility to regularly check the Service
                      Provider Portal and your nominated email for Orders.
                    </li>
                    <li>
                      You must take all reasonable steps to provide the Services
                      as described in every Listing or Quote that is accepted by
                      a Customer, including by not cancelling any part of an
                      accepted Quote or Order and otherwise in accordance with
                      the fulfilment timeframes as agreed in writing between you
                      and the Customer.
                    </li>
                  </ul>
                </li>
                <li>
                  FEES
                  <br />
                  COMMISSION
                  <ul>
                    <li>
                      Once a Customer accepts a Quote on the Platform the
                      Customer will be prompted to pay:
                      <ul>
                        <li>the Price, or </li>
                        <li>
                          in the case of Instalment payments, the relevant
                          Instalment, via the Third Party Payment Platform
                          (Payment).
                        </li>
                      </ul>
                    </li>
                    <li>
                      You agree that we shall be entitled to retain both the
                      Customer Fee and the Commission (Our Fees) for each
                      Payment, and may direct the Third Party Payment Platform
                      to pay to us Our Fees from any Payment paid by a Customer.
                    </li>
                    <li>
                      You are entitled to receive the Quoted Amount paid by a
                      Customer for an Order less our Commission (Remaining
                      Balance).
                    </li>
                    <li>
                      After the Third Party Payment Platform receives payment
                      from the Customer, we will ensure if you have agreed to be
                      paid via the Third Party Payment Platform, that it
                      transfers the Remaining Balance to you within a reasonable
                      time; or
                    </li>
                    <li>
                      The Third Party Payment Platform may hold the Remaining
                      Balance for a reasonable period after receiving it from a
                      Customer. For the purposes of this clause, you understand
                      and agree that 30 days is a reasonable period.{" "}
                    </li>
                    <li>
                      Smart Choice Traders reserves the right to have the Third
                      Party Payment Platform hold the Remaining Balance and
                      disperse this amount, along with other amounts, to the
                      Service Provider in a bulk monthly payment.
                    </li>
                    <li>
                      If you issue us an invoice for payment, you acknowledge
                      and agree that the payment terms of that invoice will be
                      no less than 30 days.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  REFUNDS & CANCELLATIONS
                  <ul>
                    SERVICE FEE IS NON-REFUNDABLE <br />
                    Our Commission is not refundable for change of mind. In the
                    event that either you or a Customer cancels an Order, after
                    the Price has been paid, we will not be required to refund
                    any part of the Commission to you.
                    <li>
                      CANCELLATION BY YOU
                      <ul>
                        <li>
                          Without limiting or otherwise affecting the terms of
                          this agreement, if you wish to cancel an Order before
                          you have fulfilled the requirements of the Order that
                          a Customer has agreed to, you must contact us using
                          the Service Provider Portal’s functionality or
                          emailing us at tradesperson@smartchoicetraders.co.uk,
                          including providing details as to why you are
                          cancelling. If Smart Choice Traders decides to
                          investigate your request, you must provide assistance
                          and information to Smart Choice Traders as reasonably
                          requested.{" "}
                        </li>
                        <li>
                          If we accept your request to cancel an Order already
                          accepted by a Customer, we may take one or more of the
                          following actions and you authorise us to do so:
                        </li>
                        <li>cancel your Account;</li>
                        <li>
                          refund the Price or any Instalment paid by a Customer,
                          including any Customer Fee and Commission, to the
                          relevant Customer;{" "}
                        </li>
                        <li>
                          require that you pay all or part of the Price or
                          Instalment (including Customer Fee and Commission)
                          refunded to the Customer and issue you an invoice for
                          that amount.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  CANCELLATION BY A CUSTOMER
                  <ul>
                    You acknowledge and agree that the Customer has the right to
                    cancel their Order in accordance with the Customer Terms and
                    any other right that the Customer may have at law, and where
                    a Customer is entitled to, or otherwise granted by us in our
                    sole discretion, a refund, you:
                    <li>
                      <ul>
                        <li>
                          Authorise us to issue a refund to a customer on your
                          behalf;
                        </li>
                        <li>
                          Agree to indemnify Smart Choice Traders in respect of
                          any refunds we pay to a Customer in respect of
                          Services supplied by you on the Platform.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  BYPASSING
                  <ul>
                    <li>
                      <ul>
                        <li>
                          You agree that while you are a Service Provider on the
                          Platform, regardless of the reason that your Account
                          was suspended or cancelled, you will not, either
                          directly or indirectly, solicit or attempt to solicit
                          any business, work, income or other benefit, from any
                          Customer whom you came to know about, or with whom you
                          provided goods or services to directly or indirectly,
                          by using the Platform. This provision will apply
                          whether or not the Customer or their representative is
                          still active on the Platform.
                        </li>
                        <li>
                          Smart Choice Traders may, in its absolute discretion,
                          cancel your Account and suspend you from using the
                          Platform if it finds or suspects that you have
                          breached or are in breach of this clause 11.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  WARRANTIES
                  <ul>
                    <li>
                      <ul>
                        <li>
                          By listing yourself as a Service Provider on the
                          Platform and posting a Listing, you represent and
                          warrant that as at the date of this agreement and
                          throughout the Term
                        </li>
                        <li>
                          You and all employees, staff, contractors and
                          subcontractors who provide Services on behalf of you
                          or your business (Personnel) are qualified, able and
                          have the right to provide the Services specified in
                          the Listing within England and Wales, including having
                          the relevant qualifications, certifications and
                          registrations required to supply those Services in
                          accordance with industry best-practice and all
                          relevant regulations; and
                        </li>
                        <li>
                          You, and your Personnel, will comply at all times with
                          all applicable laws, including without limitation
                          providing the relevant Services to Customers in
                          compliance with all applicable laws.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  USER OBLIGATIONS
                  <ul>
                    <li>As a Service Provider you agree:</li>
                    <li>
                      not to intimidate, harass, impersonate, stalk, threaten,
                      bully or endanger any other User or distribute unsolicited
                      commercial content, junk mail, spam, bulk content or
                      harassment;
                    </li>
                    <li>
                      to not share your Account with any other person and that
                      any use of your Account by any other person is strictly
                      prohibited. You must immediately notify Smart Choice
                      Traders of any unauthorised use of your Account, password
                      or email, or any other breach or potential breach of the
                      Platform’s security;
                    </li>
                    <li>
                      to not use the Platform for any purpose other than for the
                      purpose of making arrangements to provide Services,
                      including:
                      <ul>
                        <li>
                          you must not use the Platform in a manner that is
                          illegal or fraudulent or facilitates illegal or
                          fraudulent activity (including requesting or accepting
                          a job or a sale which includes illegal goods,
                          activities or purposes);{" "}
                        </li>
                        <li>
                          you must not use the Platform in connection with any
                          commercial or money making or other promotional or
                          marketing endeavours except those that are endorsed
                          herein, or as approved in writing by Smart Choice
                          Traders;
                        </li>
                      </ul>
                    </li>
                    <li>
                      not to act in any way that may harm the reputation of
                      Smart Choice Traders or associated or interested parties
                      or do anything at all contrary to the interests of Smart
                      Choice Traders or the Platform;{" "}
                    </li>
                    <li>
                      you must not make any automated use of the Platform and
                      you must not copy, reproduce, translate, adapt, vary or
                      modify the Platform without the express written consent of
                      Smart Choice Traders;
                    </li>
                    <li>
                      that Smart Choice Traders may change any features of the
                      Platform or Services offered through the Platform at any
                      time without notice to you;
                    </li>
                    <li>
                      that information given to you through the Platform, by
                      Smart Choice Traders or another User including a Service
                      Provider, is general in nature and we take no
                      responsibility for anything caused by any actions you take
                      in reliance on that information;
                    </li>
                    <li>
                      that Smart Choice Traders may cancel your account at any
                      time, including if it considers, in its absolute
                      discretion, that you are in breach or are likely to breach
                      this clause 5;{" "}
                    </li>
                    <li>
                      that you may be required to use a Third Party Payment
                      Platform (currently Stripe) in receiving any payments via
                      the Platform, and you warrant that you have read,
                      understood and agree to be bound by the relevant Third
                      Party Payment Platform’s terms of use, available here{" "}
                      <a href="https://stripe.com/gb/legal">
                        https://stripe.com/gb/legal
                      </a>
                      ;
                    </li>
                    <li>
                      the terms of use of other portals or other payment methods
                      may apply to your Account and use of the Platform from
                      time to time, and provided we have notified you of these
                      terms you agree to be bound by those terms. If you do not
                      agree to any third party terms, you must notify us and
                      immediately cease using the Platform.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  REVIEWS AND RATINGS
                  <ul>
                    <li>
                      Customers may rate a Listing, and Service Providers may
                      rate a Customer (each a ‘Rating’) and may provide feedback
                      to other Users regarding the relevant experience with that
                      User (Review).
                    </li>
                    <li>
                      Ratings and Reviews may be able to be viewed by other
                      Users and these may remain viewable until the relevant
                      Account and/or Listing is removed or terminated.
                    </li>
                    <li>
                      (c) Users must provide true, fair and accurate information
                      in their Reviews
                    </li>
                    <li>
                      If we consider that a Review is untrue, unfair,
                      inaccurate, offensive or inappropriate, we may delete the
                      Review or ban the relevant User from posting the Review,
                      or future Reviews. We do not undertake to review each
                      Review made by a User.
                    </li>
                    <li>
                      To the maximum extent permitted by law, we are not
                      responsible for the content of any Reviews
                    </li>
                    <li>
                      You must not publish Reviews for Users to which you have
                      personal or professional relations.
                    </li>
                    <li>
                      Users can only write a Review about another User if they
                      have had an experience with that User on our Platform,
                      which means that:
                      <ul>
                        <li>
                          you have sold a product or service to that Customer;{" "}
                        </li>
                        <li>
                          you can otherwise document that you had a buying or
                          selling experience with that User, including via
                          correspondence or other interaction with the User,
                        </li>
                        <li>
                          (collectively referred to as a Service Experience).
                        </li>
                      </ul>
                    </li>
                    <li>
                      You must only write about your own Service Experience. You
                      are not permitted to write a Review about somebody else’s
                      Service Experience, such as that of a family member or
                      friend.
                    </li>
                    <li>
                      You must not write a Review about a direct competitor to
                      the Service Provider you own, are employed by or work for.
                    </li>
                    <li>
                      Your Service Experience must have occurred within the last
                      12 months. This means within 12 months from the date on
                      which you write the Review.
                    </li>
                    <li>
                      You are encouraged to be specific and factual in your
                      Reviews. If you have been offered an incentive by a User
                      to write a Review, you should include information about
                      this in your Review. Incentives include the User offering
                      you a gift, reward, discount or advantage for writing a
                      Review about the User on the Platform.
                    </li>
                  </ul>
                </li>
                <li>POSTED MATERIALS</li>
                <li>
                  WARRANTIES
                  <ul>
                    <li>
                      By providing or posting any information, materials or
                      other content on the Platform (Posted Material), you
                      represent and warrant that:
                    </li>
                    <li>
                      you are authorised to provide the Posted Material
                      (including by being authorised to provide any goods and
                      services that you represent you provide);
                    </li>
                    <li>
                      the Posted Material is accurate and true at the time it is
                      provided;
                    </li>
                    <li>
                      any Posted Material which is in the form of a review or
                      feedback is honest, accurate and presents a fair view of
                      the relevant person and/or your experience;
                    </li>
                    <li>
                      the Posted Material is free from any harmful,
                      discriminatory, defamatory or maliciously false
                      implications and does not contain any offensive or
                      explicit material;
                    </li>
                    <li>
                      the Posted Material is not “passing off” of any product or
                      service and does not constitute unfair competition;
                    </li>
                    <li>
                      the Posted Material does not infringe any Intellectual
                      Property Rights, including copyright, trademarks, business
                      names, patents, confidential information or any other
                      similar proprietary rights, whether registered or
                      unregistered, anywhere in the world;
                    </li>
                    <li>
                      the Posted Material does not contain any viruses or other
                      harmful code, or otherwise compromise the security or
                      integrity of the Platform or any network or system;
                    </li>
                    <li>
                      the Posted Material does not breach or infringe any
                      applicable laws.
                    </li>
                  </ul>
                </li>
                <li>
                  LICENCE
                  <ul>
                    <li>
                      You grant to Smart Choice Traders a perpetual,
                      irrevocable, transferable, worldwide and royalty-free
                      licence (including the right to sublicense) to use, copy,
                      modify, reproduce and adapt any Intellectual Property
                      Rights in any Posted Material in order for Smart Choice
                      Traders to use, exploit or otherwise enjoy the benefit of
                      such Posted Material.
                    </li>
                    <li>
                      If it is determined that you retain moral rights
                      (including rights of attribution or integrity) in any
                      Posted Material, you forever release Smart Choice Traders
                      from any and all claims that you could assert against
                      Smart Choice Traders by virtue of any such moral rights.
                    </li>
                    <li>
                      You indemnify Smart Choice Traders against all damages,
                      losses, costs and expenses incurred by Smart Choice
                      Traders arising out of any third party claim that your
                      Posted Material infringes any third party’s Intellectual
                      Property Rights.
                    </li>
                  </ul>
                </li>
                <li>
                  REMOVAL
                  <ul>
                    <li>
                      Smart Choice Traders acts as a passive conduit for the
                      online distribution of Posted Material and has no
                      obligation to screen Posted Material in advance of it
                      being posted. However, Smart Choice Traders may, in its
                      absolute discretion, review and remove any Posted Material
                      (including links to you, your profile or listings you have
                      posted on the Platform) at any time without giving any
                      explanation or justification for removing the Posted
                      Material.
                    </li>
                    <li>
                      You agree that you are responsible for keeping and
                      maintaining records of Posted Material.
                    </li>
                  </ul>
                </li>
                <li>
                  SERVICE LIMITATIONS
                  <ul>
                    <li>
                      The Platform is made available to you strictly on an ‘as
                      is’ basis. Without limitation, you acknowledge and agree
                      that Smart Choice Traders cannot and does not represent,
                      warrant or guarantee that:
                    </li>
                    <li>the Platform will be free from errors or defects;</li>
                    <li>the Platform will be accessible at all times;</li>
                    <li>
                      messages sent through the Platform will be delivered
                      promptly, or delivered at all;
                    </li>
                    <li>
                      information you receive or supply through the Platform
                      will be secure or confidential; or
                    </li>
                    <li>
                      any information provided through the Platform is accurate
                      or true.
                    </li>
                  </ul>
                </li>

                <li>
                  INTELLECTUAL PROPERTY
                  <ul>
                    <li>
                      Smart Choice Traders retains ownership of all materials
                      developed or provided (or both, as the case may be) in
                      connection with the Platform (including text, graphics,
                      logos, design, icons, images, sound and video recordings,
                      pricing, downloads and software) (Platform Content) and
                      reserves all rights in any Intellectual Property Rights
                      owned or licensed by it not expressly granted to you.
                    </li>
                    <li>
                      You retain ownership of the Intellectual Property Rights
                      in all Service Provider Materials, and you grant to Smart
                      Choice Traders a non-exclusive, royalty-free,
                      non-transferrable, revocable licence to use the
                      Intellectual Property Rights in the Service Provider
                      Materials for the purpose of providing the Platform to
                      you, including hosting Listings and facilitating Orders,
                      as contemplated by these Terms.
                    </li>
                    <li>
                      You may make a temporary electronic copy of all or part of
                      the Platform Content for the sole purpose of viewing it.
                      You must not otherwise reproduce, transmit, adapt,
                      distribute, sell, modify or publish the Platform Content
                      without prior written consent from Smart Choice Traders or
                      as permitted by law.
                    </li>
                    <li>
                      In this clause 16:
                      <ul>
                        <li>
                          “Service Provider Content” means any documents or
                          materials supplied by the Service Provider to Smart
                          Choice Traders under or in connection with these Terms
                          including any Intellectual Property Rights attaching
                          to those materials;{" "}
                        </li>
                        <li>
                          “Intellectual Property Rights” means all copyright,
                          trade mark, design, patent, semiconductor and circuit
                          layout rights, trade, business, company and domain
                          names, confidential and other proprietary rights, and
                          any other rights to registration of such rights
                          whether created before or after the date of this
                          agreement worldwide.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY CONTENT
                  <ul>
                    <li>
                      The Platform may contain text, images, data and other
                      content provided by a third party and displayed on the
                      Platform (Third Party Content). Smart Choice Traders
                      accepts no responsibility for Third Party Content and
                      makes no representation, warranty or guarantee about the
                      quality, suitability, accuracy, reliability, currency or
                      completeness of Third Party Content
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY TERMS
                  <ul>
                    <li>
                      Any service that requires Smart Choice Traders to acquire
                      goods and services supplied by a third party on behalf of
                      the Customer (including a third party payment service,
                      like the Third Party Payment Platform may be subject to
                      the terms and conditions of that third party (Third Party
                      Terms), including ‘no refund’ policies.
                    </li>
                    <li>
                      You agree to familiarise yourself with any Third Party
                      Terms applicable to any such goods and services and, by
                      instructing Smart Choice Traders to acquire the goods or
                      services on your behalf, the Service Provider will be
                      taken to have agreed to such Third Party Terms.
                    </li>
                  </ul>
                </li>
                <li>
                  SECURITY
                  <ul>
                    <li>
                      Smart Choice Traders does not accept responsibility for
                      loss or damage to computer systems, mobile phones or other
                      electronic devices arising in connection with your use of
                      the Platform. You should take your own precautions to
                      ensure that the process you employ to access the Platform
                      does not expose you to the risk of viruses, malicious
                      computer code or other forms of interference.
                    </li>
                  </ul>
                </li>
                <li>
                  DISCLAIMER
                  <ul>
                    <li>
                      (Introduction service) Smart Choice Traders is a medium
                      that facilitates the introduction of Customers and Service
                      Providers for the purposes of buying and selling Services.
                      Smart Choice Traders simply collects a service fee in
                      consideration for providing this introduction service and
                      does not have any obligations or liabilities to, and is
                      not a party to any contract between, Customers and Service
                      Providers in relation to such Services or otherwise
                      resulting from the introduction.
                    </li>
                    <li>
                      (Limitation of liability) To the maximum extent permitted
                      by applicable law, Smart Choice Traders excludes
                      completely all liability to any person for loss or damage
                      of any kind, however arising whether in contract, tort
                      (including negligence), statute, equity, indemnity or
                      otherwise, arising from or relating in any way to the
                      Platform or its use or any services provided by any
                      Service Provider. This includes the transmission of any
                      computer virus.
                    </li>
                    <li>
                      (Indemnity) You agree to indemnify Smart Choice Traders
                      and its employees and agents in respect of all liability
                      for loss, damage or injury which may be suffered by any
                      person arising from you or your representatives’:
                      <ul>
                        <li>breach of any term of this agreement; </li>
                        <li>use of the Platform; </li>
                        <li>
                          your provision or receipt of Services from another
                          User.
                        </li>
                      </ul>
                      <li>
                        (Consequential loss) To the maximum extent permitted by
                        law, under no circumstances will Smart Choice Traders be
                        liable for any incidental, special or consequential loss
                        or damages, or damages for loss of data, business or
                        business opportunity, goodwill, anticipated savings,
                        profits or revenue arising under or in connection with
                        the Platform, this agreement or their subject matter, or
                        any services provided by any Service Provider (except to
                        the extent this liability cannot be excluded at law.
                      </li>
                    </li>
                  </ul>
                </li>
                <li>
                  SUBCONTRACTING
                  <ul>
                    <li>
                      The Service Provider must not, without the prior written
                      consent of Smart Choice Traders and subject to clause 23,
                      subcontract any part of this agreement including without
                      limitation the performance or delivery of any Services to
                      any Customers pursuant to an Order.
                    </li>
                  </ul>
                </li>
                <li>
                  CONFIDENTIALITY
                  <ul>
                    <li>You agree that:</li>
                    <li>
                      no information owned by Smart Choice Traders, including
                      these Terms, our Commission rate, the number of sales
                      completed via our Platform, our system operations,
                      documents, marketing strategies, staff information, and
                      Customer information, may be disclosed or made available
                      to any third parties;{" "}
                    </li>
                    <li>
                      all communications involving the details of other users on
                      this Platform (including but not limited the Customer) and
                      of the Service Provider are confidential, and must be kept
                      as such by you and must not be distributed nor disclosed
                      to any third party.
                    </li>
                  </ul>
                </li>
                <li>
                  PRIVACY
                  <ul>
                    <li>
                      Words and phrases in this clause shall have the meaning
                      given to them by applicable data protection and privacy
                      laws, including the General Data Protection Regulation
                      2016/679 (GDPR) and applicable national legislation that
                      implements or supplements the GDPR or otherwise applies to
                      data protection and privacy, and any statutory instrument,
                      order, rule or regulation made thereunder, as from time to
                      time amended, extended, re-enacted or consolidated (Data
                      Protection Legislation) and the terms “controller”,
                      “processor”, “process” and “personal data” shall have the
                      meanings given to those terms in such Data Protection
                      Legislation. 
                    </li>
                    <li>
                      Smart Choice Traders will collect and process information
                      relating to the Service Provider in accordance with Smart
                      Choice Traders’ privacy notice, available here:
                    </li>
                    <li>
                      The Service Provider and Smart Choice Traders acknowledge
                      that for the purposes of the Data Protection Legislation,
                      Smart Choice Traders is the controller and the Service
                      Provider is the processor.
                    </li>
                    <li>
                      The Service Provider and Smart Choice Traders will comply
                      with the Data Protection Legislation.
                    </li>
                    <li>
                      The Service Provider shall, in relation to any Personal
                      Data processed in connection with this agreement:
                    </li>
                    <li>
                      process that Personal Data only on written instructions of
                      Smart Choice Traders as detailed in Schedule 1, as updated
                      from time to time;
                    </li>
                    <li>keep the Personal Data confidential;</li>
                    <li>
                      comply with Smart Choice Traders’ data protection policy
                      and other relevant policies made available to the Brand;
                    </li>
                    <li>
                      comply with Smart Choice Traders’ reasonable instructions
                      with respect to processing Personal Data;
                    </li>
                    <li>
                      not transfer any Personal Data outside of the UK unless,
                      in accordance with the Data Protection Legislation, the
                      Service Provider ensures that (i) the transfer is to a
                      country approved as providing an adequate level of
                      protection for Personal Data; or (ii) there are
                      appropriate safeguards in place for the transfer of
                      Personal Data; or (iii) binding corporate rules are in
                      place; or (iv) one of the derogations for specific
                      situations applies to the transfer;
                    </li>
                    <li>
                      assist Smart Choice Traders in responding to any data
                      subject access request and to ensure compliance with its
                      obligations under the Data Protection Legislation with
                      respect to security, breach notifications, privacy impact
                      assessments and consultations with supervisory authorities
                      or regulators;
                    </li>
                    <li>
                      notify Smart Choice Traders without undue delay on
                      becoming aware of a Personal Data breach or communication
                      which relates to Smart Choice Traders’ or Service
                      Provider’s compliance with the Data Protection
                      Legislation;
                    </li>
                    <li>
                      at the written request of Smart Choice Traders, delete or
                      return Personal Data (and any copies of the same) to Smart
                      Choice Traders on termination of this agreement unless
                      required by the Data Protection Legislation to store the
                      Personal Data;
                    </li>
                    <li>
                      maintain complete and accurate records and information to
                      demonstrate compliance with this clause 23 and allow for
                      audits by Smart Choice Traders or Smart Choice Traders’
                      designated auditor.
                    </li>
                    <li>
                      The Service Provider shall ensure that they have in place
                      appropriate technical or organisational measures, reviewed
                      and approved by Smart Choice Traders, to protect against
                      unauthorised or unlawful processing of Personal Data and
                      against accidental loss or destruction of, or damage to,
                      Personal Data, appropriate to the harm that might result
                      from the unauthorised or unlawful processing or accidental
                      loss, destruction or damage and the nature of the data to
                      be protected, having regard to the state of technological
                      development and the cost of implementing any measures.
                      Such measures may include, where appropriate:
                      <ul>
                        <li>pseudonymising and encrypting Personal Data;</li>
                        <li>
                          ensuring confidentiality, integrity, availability and
                          resilience of its systems and services;
                        </li>
                        <li>
                          ensuring that availability of and access to Personal
                          Data can be restored in a timely manner after an
                          incident;
                        </li>
                        <li>
                          regularly assessing and evaluating the effectiveness
                          of the technical and organisational measures adopted
                          by it.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Smart Choice Traders agrees that any sub-contractor
                      appointed under clause 21, or any other third party who
                      the Service Provider shares Personal Data with for the
                      purposes of fulfilling an Order, is a third-party
                      processor of Personal Data under this agreement. The
                      Service Provider confirms that it will enter into a
                      written agreement, which incorporates terms which are
                      substantially similar to those set out in this clause 23
                      with the sub-contractor or third-party processor. The
                      Service Provider shall remain fully liable for all acts or
                      omissions of any third-party processor appointed by it
                      pursuant to this clause 23(g).
                    </li>
                    <li>
                      The Service Provider shall have personal liability for and
                      shall indemnify Smart Choice Traders for any loss,
                      liability, costs (including legal costs), damages, or
                      expenses resulting from any breach by the Service Provider
                      of the Data Protection Legislation, and shall maintain in
                      force full and comprehensive Insurance Policies.
                    </li>
                  </ul>
                </li>
                <li>
                  TERMINATION
                  <ul>
                    <li>
                      Smart Choice Traders reserves the right to terminate your
                      access to any or all of the Platform (including any
                      Account, Listings and other memberships), for any reason,
                      by providing you with one months’ written notice.
                    </li>
                    <li>
                      You may cancel your Account on the Platform by providing
                      Smart Choice Traders one months’ written notice to
                      tradespeople@smartchoicetraders.com.
                    </li>
                    <li>
                      Either party may immediately terminate these terms by
                      written notice to the other party if:
                      <ul>
                        <li>
                          the other party is in default or breach of these
                          terms;
                        </li>
                        <li>
                          the other party is convicted, or any of the other
                          party’s personnel are convicted, of a criminal offence
                          involving fraud or dishonesty or an offence which, in
                          the opinion of the other party, affects the other
                          party’s obligations under these terms;
                        </li>
                        <li>
                          the other party or any of the other party’s personnel
                          conducts themselves in a way tending to bring them or
                          the other party into disrepute;
                        </li>
                        <li>
                          the other party or any of the other party’s personnel
                          has a conflict of interest that cannot be resolved to
                          the satisfaction of the other party.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Notwithstanding termination or expiry of your Account or
                      membership or this agreement, the provisions of clause 20
                      and any other provision which by its nature would
                      reasonably be expected to be complied with after
                      termination or expiry, will continue to apply.
                    </li>
                  </ul>
                </li>
                <li>
                  RECORD / AUDIT
                  <ul>
                    <li>
                      To the extent permitted by law, Smart Choice Traders
                      reserves the right to keep all records of any and all
                      transactions and communications made through this Platform
                      between you and other Users (including conversations, user
                      posts, job request bids, comments, feedback, cookies, and
                      I.P. address information) for administration purposes and
                      also holds the right to produce these records in the event
                      of any legal dispute involving Smart Choice Traders.
                    </li>
                  </ul>
                </li>
                <li>
                  NOTICES
                  <ul>
                    <li>
                      A notice or other communication to a party under this
                      agreement must be:
                    </li>
                    <li>in writing and in English; </li>
                    <li>
                      delivered via email to the other party, to:
                      <ul>
                        <li>
                          in respect of the Service Provider, to the email
                          address supplied with the Service Provider’s Account;{" "}
                        </li>
                        <li>
                          in respect of Smart Choice Traders, to
                          tradespeople@smartchoicetraders.com,
                        </li>
                      </ul>
                    </li>
                    <li>
                      (Email Address). The parties may update their Email
                      Address by notice to the other party.
                    </li>
                    <li>
                      Unless the party sending the notice knows or reasonably
                      ought to suspect that an email was not delivered to the
                      other party’s Email Address, notice will be taken to be
                      given:
                      <ul>
                        <li>
                          24 hours after the email was sent, unless that falls
                          on a Saturday, Sunday or a public holiday in the state
                          or territory whose laws govern this agreement, in
                          which case the notice will be taken to be given on the
                          next occurring business day in that state or
                          territory;{" "}
                        </li>
                        <li>
                          when replied to by the other party,whichever is
                          earlier.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>GENERAL</li>
                <li>
                  GOVERNING LAW AND JURISDICTION
                  <ul>
                    <li>
                      This agreement and any dispute or claim (including
                      non-contractual disputes or claims) arising out of or in
                      connection with it or its subject matter or formation
                      shall be governed by and construed in accordance with the
                      law of England and Wales. Each party irrevocably agrees
                      that the courts of England and Wales shall have exclusive
                      jurisdiction to settle any dispute or claim (including
                      non-contractual disputes or claims) arising out of or in
                      connection with this agreement or its subject matter or
                      formation.
                    </li>
                  </ul>
                </li>
                <li>
                  THIRD PARTY RIGHTS
                  <ul>
                    <li>
                      This agreement does not give rise to any rights under the
                      Contracts (Rights of Third Parties) Act 1999 to enforce
                      any term of this agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  UNFAIR CONTRACT TERMS
                  <ul>
                    <li>
                      To the extent that the provisions of any applicable law
                      shall impose restrictions on the extent to which liability
                      can be excluded under this agreement including, for the
                      avoidance of doubt, the provisions of sections 3, 6 and 11
                      of the Unfair Contract Terms Act 1977 in the UK (and its
                      equivalent in any other jurisdiction) relating to the
                      requirement of reasonableness, the exclusions set out in
                      this clause shall be limited in accordance with such
                      restrictions. However, any exclusions of liability that
                      are not affected by such restrictions shall remain in full
                      force and effect.
                    </li>
                  </ul>
                </li>
                <li>
                  WAIVER
                  <ul>
                    <li>
                      No party to this agreement may rely on the words or
                      conduct of any other party as a waiver of any right unless
                      the waiver is in writing and signed by the party granting
                      the waiver.
                    </li>
                  </ul>
                </li>
                <li>
                  SEVERANCE
                  <ul>
                    <li>
                      Any term of this agreement which is wholly or partially
                      void or unenforceable is severed to the extent that it is
                      void or unenforceable. The validity and enforceability of
                      the remainder of this agreement is not limited or
                      otherwise affected.
                    </li>
                  </ul>
                </li>
                <li>
                  JOINT AND SEVERAL LIABILITY
                  <ul>
                    <li>
                      An obligation or a liability assumed by, or a right
                      conferred on, two or more persons binds or benefits them
                      jointly and severally.
                    </li>
                  </ul>
                </li>
                <li>
                  ASSIGNMENT
                  <ul>
                    <li>
                      A party cannot assign, novate or otherwise transfer any of
                      its rights or obligations under this agreement without the
                      prior written consent of the other party.
                    </li>
                  </ul>
                </li>
                <li>
                  COSTS
                  <ul>
                    <li>
                      Except as otherwise provided in this agreement, each party
                      must pay its own costs and expenses in connection with
                      negotiating, preparing, executing and performing this
                      agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  ENTIRE AGREEMENT
                  <ul>
                    <li>
                      This agreement embodies the entire agreement between the
                      parties and supersedes any prior negotiation, conduct,
                      arrangement, understanding or agreement, express or
                      implied, in relation to the subject matter of this
                      agreement.
                    </li>
                  </ul>
                </li>
                <li>
                  INTERPRETATION
                  <ul>
                    <li>
                      (singular and plural) words in the singular includes the
                      plural (and vice versa);
                    </li>
                    <li>
                      (gender) words indicating a gender includes the
                      corresponding words of any other gender;
                    </li>
                    <li>
                      (defined terms) if a word or phrase is given a defined
                      meaning, any other part of speech or grammatical form of
                      that word or phrase has a corresponding meaning;
                    </li>
                    <li>
                      (person) a reference to “person” or “you” includes an
                      individual, the estate of an individual, a corporation, an
                      authority, an association, consortium or joint venture
                      (whether incorporated or unincorporated), a partnership, a
                      trust and any other entity;
                    </li>
                    <li>
                      (party) a reference to a party includes that party’s
                      executors, administrators, successors and permitted
                      assigns, including persons taking by way of novation and,
                      in the case of a trustee, includes any substituted or
                      additional trustee;
                    </li>
                    <li>
                      (this agreement) a reference to a party, clause,
                      paragraph, schedule, exhibit, attachment or annexure is a
                      reference to a party, clause, paragraph, schedule,
                      exhibit, attachment or annexure to or of this agreement,
                      and a reference to this agreement includes all schedules,
                      exhibits, attachments and annexures to it;
                    </li>
                    <li>
                      (document) a reference to a document (including this
                      agreement) is to that document as varied, novated,
                      ratified or replaced from time to time;
                    </li>
                    <li>
                      (headings) headings and words in bold type are for
                      convenience only and do not affect interpretation;
                    </li>
                    <li>
                      (includes) the word “includes” and similar words in any
                      form is not a word of limitation;{" "}
                    </li>
                    <li>
                      (adverse interpretation) no provision of this agreement
                      will be interpreted adversely to a party because that
                      party was responsible for the preparation of this
                      agreement or that provision;{" "}
                    </li>
                    <li>
                      (currency) a reference to £ or GBP is to pound sterling
                      currency unless otherwise agreed in writing.
                    </li>
                    <li>
                      Processing of Personal Data by the Smart Choice Traders
                    </li>
                  </ul>
                </li>
                <li>
                  SCOPE
                  <ul>
                    <li>
                      Personal data is collected, stored and analysed on Smart
                      Choice Traders’ systems only.
                    </li>
                    <li>
                      Processing of Smart Choice Traders’ customer data shall be
                      undertaken within the Platform only.
                    </li>
                    <li>
                      Processing of Smart Choice Traders’ customer data is
                      restricted to use cases detailed in the Terms.
                    </li>
                    <li>
                      Processing of Smart Choice Traders’ customer data includes
                      fulfilment and delivery of the Orders in accordance with
                      the Terms only.
                    </li>
                  </ul>
                </li>
                <li>
                  NATURE
                  <ul>
                    <li>
                      Electronically, automatically through a website or other
                      digital service. Specifically Smart Choice Traders’
                      Platform.
                    </li>
                  </ul>
                </li>
                <li>
                  PURPOSE OF PROCESSING
                  <ul>
                    <li>
                      To provide the services as contracted between Smart Choice
                      Traders and the Customer and in accordance with this
                      agreement. Specifically, to fulfil Orders of Services
                      requested by the Customer via the Platform.
                    </li>
                  </ul>
                </li>
                <li>
                  DURATION OF PROCESSING
                  <ul>
                    <li>The duration of this agreement only.</li>
                  </ul>
                </li>
                <li>
                  CATEGORIES OF PERSONAL DATA
                  <ul>
                    <li>Name, email address, postal address, phone number.</li>
                  </ul>
                </li>
                <li>
                  CATEGORIES OF DATA SUBJECT
                  <ul>
                    <li>Customers of Smart Choice Traders.</li>
                  </ul>
                </li>
              </ol>
            </div>

            <div className="cont">
              <p>
                By using the Website, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
