import { toast } from "react-toastify";
import {
  AVAILABLE_HOURS,
  ENVOICE_DETAIL,
  ESTIMATE_DETAIL,
  GET_TRADER_CHAT,
  GET_TRADE_LOCATIONS,
  QUOTE_DETAIL,
  REVIEW_DETAIL_NEW,
  REVIEW_DETAIL_TARDER,
  REVIEW_TARDER_DETAIL_NEW,
  START_LOADING,
  STOP_LOADING,
  STRIPE_USER_ACCOUNT_DETAILS,
  TRADER_APPOINTMENT,
  TRADER_ENQUIRIES,
  TRADER_ENQUIRY,
  TRADER_UPDATE_CHAT,
  TRADE_PERSON_PROFILE_DETAIL,
  TRADE_REVIEWS,
  TRADE_SERVICE_FETCH_SUCCESSFULLY,
  UPDATE_TRADE_PASSWORD,
  UPDATE_TRADE_PROFILE_DETAIL,
  WALLET_INFO,
  WALLET_TRANSATION,
} from "../Constant/constant";
import TradeAPI from "../../service/TradeApi";
export const getTradePersonProfile = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.get("/getTradesPersonDetails", data);
    if (res?.data.success) {
      dispatch({
        type: TRADE_PERSON_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      return res?.data?.data
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateTradePassword = (data,setShowModal) => async (dispatch) => {
  try {
    dispatch({
      type: START_LOADING,
      payload: true
    });
    const res = await TradeAPI.put("/updatePassword", data);
    if (res?.data.success) {
      dispatch({
        type: STOP_LOADING,
        payload: false
      });
      dispatch({
        type: UPDATE_TRADE_PASSWORD,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
    } else {
      toast.error(res?.data.message);
      dispatch({
        type: STOP_LOADING,
        payload: false
      });
    }
  } catch (error) {
    toast.error(error);
    dispatch({
      type: STOP_LOADING,
      payload: false
    });
  }
};
export const updateTradeProfile = (data,setShowModal) => async (dispatch) => {
  try {
    dispatch({
      type: START_LOADING,
      payload: true
    });
    const res = await TradeAPI.put("/updateBusinessProfile", data);
    console.log(res,'resres');
    if (res?.data.success) {
      dispatch({
        type: TRADE_PERSON_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
      dispatch({
        type: STOP_LOADING,
        payload: false
      });
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateLocationsProfile = (data, id,setShowModal) => async (dispatch) => {
  try {
    dispatch({
      type: START_LOADING,
      payload: true
    });
    const res = await TradeAPI.put("/createBusinessLocations", data);
    if (res?.status == 200) {
      dispatch(getTradeLocations(id));
      dispatch({
        type: TRADE_PERSON_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      setShowModal(true)
      // toast.success(res?.data.message);
      dispatch({
        type: START_LOADING,
        payload: false
      });
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updatePersonalInfo = (data,setShowModal) => async (dispatch) => {
  try {

    dispatch({
      type: START_LOADING,
      payload: true
    });
    const res = await TradeAPI.put("/updatePersonalProfile", data);
  
    if (res.status == 200) {
     
      dispatch({
        type: STOP_LOADING,
        payload: false
      });
      dispatch({
        type: TRADE_PERSON_PROFILE_DETAIL,
        payload: res?.data,
      });
      dispatch(getTradePersonProfile());
      setShowModal(true)
      // toast.success(res.data.message);
   
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const fetchServiceCategory = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.get("/fetchServiceCategory", data);
    if (res?.data.success) {
      dispatch({
        type: TRADE_SERVICE_FETCH_SUCCESSFULLY,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getTraderEnquiries = () => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get("/getTraderEnquiries");
    if (data?.success) {
      dispatch({
        type: TRADER_ENQUIRIES,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAllAppointments = () => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get("/getAllAppointments");
    if (data?.success) {
      dispatch({
        type: TRADER_APPOINTMENT,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};



export const getTraderChat = (enquiry_id) => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get(
      `/traderGetChat?enquiry_id=${enquiry_id}`
    );
    if (data?.success) {
      dispatch({
        type: GET_TRADER_CHAT,
        payload: data?.data,
      });
      return data?.data
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const traderUpdateChat = (payload) => async (dispatch) => {
  dispatch({
    type: TRADER_UPDATE_CHAT,
    payload: payload,
  });
};

export const getTradeLocations = (id) => async (dispatch) => {
  try {
    const response = await TradeAPI.get(`/fetchBusinessLocById?tradeId=${id}`);
    if (response?.data?.success) {
      // dispatch(getTradePersonProfile());
      dispatch({
        type: GET_TRADE_LOCATIONS,
        payload: response?.data?.data,
      });
    } else {
      toast.error(response?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const deleteBusinessLocation = (id) => async (dispatch) => {
  try {
    const response = await TradeAPI.delete(`/deleteBusinessLocation?id=${id}`);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
// reviews
export const tradeReviewSubmit = (data, setShowReview,setShowModal) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/addReviews", data);
    if (res.status == 200) {
      // dispatch({
      //   type: USER_APPOINTMENT,
      //   payload: data?.data,
      // });
      setShowModal(true);
      // toast.success(res.data.message)
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getTraderReviews = () => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get("/customerReviews");
    if (data?.success) {
      dispatch({
        type: TRADE_REVIEWS,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const getReviewTraderDetail = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/reviewsById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: REVIEW_DETAIL_TARDER,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getReviewTraderDetailMsg = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/userReviewsById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: REVIEW_TARDER_DETAIL_NEW,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const updateMsgCount = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.put("/updateEnquiryUnreadMsg", data);
    if (res.status == 200) {
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};



export const createStripeAccount = (data) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING, payload: true })
    const res = await TradeAPI.post("/createAccount", data);
    if (res.status == 200) {

      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getUserAccountDetails = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getStripeUserAccountDetails`);
    console.log(res.data.data,"bbbbbbbbbbbbbbbbbbb")
    if (res?.data?.success) {
      dispatch({
        type: STRIPE_USER_ACCOUNT_DETAILS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};


export const getConnectOnBoard = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getConnectOnBoard`);
    if (res?.data?.success) {
      return  res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getEstimateDetail = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getEnquiryDetailById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: ESTIMATE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getQuoteDetail = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getQuoteById/?id=${id}`);
    if (res?.data?.success) {
      dispatch({
        type: QUOTE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getInvoiceDetail = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getInvoicesById/?id=${id}`);
    console.log(res,"res");
    if (res?.data?.success) {
      dispatch({
        type: ENVOICE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getInvoiceDetailView = (id) => async (dispatch) => {
  try {
    const res = await TradeAPI.get(`/getEstimateById/?id=${id}`);
    console.log(res,"res");
    if (res?.data?.success) {
      dispatch({
        type: ENVOICE_DETAIL,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const createEstimate = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/createInvoices", data);
    if (res?.data) {
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const createIvoice = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/createEstimate", data);
    console.log(res,"invoice");
    if (res?.data) {
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getWalletTrasnsations = (id) => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get(`/getAllTransactions?id=${id}`);
    if (data?.success) {
      dispatch({
        type: WALLET_TRANSATION,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const getAvailablityHours = (id) => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get(`/fetchAvailability`);
    console.log(data,"AVAILABLE_HOURS");
    if (data?.success) {
      dispatch({
        type: AVAILABLE_HOURS,
        payload: data?.data,
      });
      // return data?.data
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getWithdraw = (id) => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get(`/transferMoney`);
    console.log(data,"resMoney");
    if (data?.data?.success) {
      dispatch({
        type: AVAILABLE_HOURS,
        payload: data?.data,
      });
      return data?.data
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getWalletInfo = (id) => async (dispatch) => {
  try {
    const { data } = await TradeAPI.get(`/walletInfo`);
    console.log(data,"ress");
    if (data?.success) {
      dispatch({
        type: WALLET_INFO,
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const createQuote = (data) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/createQuote", data);
    console.log(res,"res");
    if (res?.data) {
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const createSetAvailability = (data,setShowModal) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/createSetAvailability", data);
    console.log(res,"res");
    if (res?.data) {
      setShowModal(true)
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};


export const updateSetAvailability = (data,setShowModal) => async (dispatch) => {
  try {
    const res = await TradeAPI.post("/updateSetAvailability", data);
    console.log(res,"res");
    if (res?.data) {
      setShowModal(true)
      return res?.data
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const saveTradeImages = (data) => async (dispatch) => {
  try {
   
    const res = await TradeAPI.post("/saveImages", data);
    if (res.status == 200) {
     
      return res?.data
    } else {
      toast.error(res.data.message);
     
    }
  } catch (error) {
    toast.warning(error.message);
  }
};