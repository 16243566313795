import React from "react";
import { modalBody, modalTitle } from "./PopupData";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getWithdraw } from "../../redux/Action/tradeActions";
const RegularPopup = ({
  showModal,
  setShowModal,
  modalName,
  setShowReview,
  handleAppointmentResponse,
  messageObject,
  enqId,
  msg,
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setShowModal(false);
  };
  const dispatch = useDispatch();
  const handleClick = () => {
    switch (modalName) {
      case "userAccount":
        setShowModal(false);
        navigate("/signin");
        break;
      case "tradeRegister":
        setShowModal(false);
        navigate("/tradepeople-login");
        break;
      case "userMyAccount":
        setShowModal(false);
        break;
      case "tradeMyAccount":
        setShowModal(false);
        break;
      case "userReviewSubmit":
        setShowReview(false);
        break;
      case "traderReviewSubmit":
        setShowReview(false);
        break;
      case "cancelAppointment":
        handleAppointmentResponse("decline", messageObject);
        setShowModal(false);
        break;
      case "needCancelAppointment":
        handleAppointmentResponse("cancel", messageObject);
        setShowModal(false);
        break;
      case "cancelEstimate":
        handleAppointmentResponse("estimateCancel", messageObject);
        setShowModal(false);
        break;
      case "cancelQuote":
        handleAppointmentResponse("cancelQuote", messageObject);
        setShowModal(false);
        break;
      case "invoiceCancel":
        handleAppointmentResponse("invoiceCancel", messageObject);
        setShowModal(false);
        break;
      case "newEstimate":
        setShowModal(false);
        break;
      case "newQuote":
        setShowModal(false);
        break;
      case "newInvoice":
        setShowModal(false);
        break;
      case "paymentSuccess":
        setShowModal(false);
        break;
      case "witdrawFunds":
        dispatch(getWithdraw()).then((res) => {
          console.log(res, "yyy");
        });
        setShowModal(false);
        break;
      case "disableFunds":
        setShowModal(false);
        break;


      case "tradeSetAvail":
        setShowModal(false);
        break;

    }
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={
        modalName == "userAccount" || modalName == "tradeRegister"
          ? "regular_popup regular-popup-big"
          : "regular_popup"
      }
    >
      <Modal.Header
        closeButton
        onClick={() => {
          modalName == "cancelAppointment" ||
            modalName == "needCancelAppointment" ||
            modalName == "cancelEstimate" ||
            modalName == "invoiceCancel"
            ? setShowModal(false)
            : handleClick();
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {/* {modalTitle[modalName]} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={
            modalName == "tradeRegister"
              ? "regular-popup-inner register_popup"
              : "regular-popup-inner"
          }
        >
          <img
            src={require("../../assets/images/success.svg").default}
            className="img-fluid succes-popup-img"
            alt="testimonial-img"
          />
          <h2 className="text-center mb-0 delete_content">
            {modalName == "newEstimate" || modalName == "newInvoice" || modalName == "newQuote"
              ? msg?.message
              : modalBody[modalName]}
          </h2>
        </div>
        {modalName == "tradeRegister" ||
          modalName == "userAccount" ||
          modalName == "newEstimate" ||
          modalName == "newQuote" ||
          modalName == "newInvoice" ? (
          <div className="d-flex justify-content-center delete_acc_btns">
            {modalName == "newEstimate" || modalName == "newInvoice" || modalName == "newQuote" ? (
              <button
                onClick={() => {
                  navigate(`/trade-enquiries#${enqId}`);
                  handleClick();
                }}
                type="submit"
                className={
                  modalName == "userAccount"
                    ? "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3 msg_callback_popup"
                    : "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
                }
              >
                OK
              </button>
            ) : (
              <button
                onClick={handleClick}
                type="submit"
                className={
                  modalName == "userAccount"
                    ? "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3 msg_callback_popup"
                    : "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
                }
              >
                OK
              </button>
            )}
          </div>
        ) : modalName == "witdrawFunds" ? (
          <div className="d-flex justify-content-center delete_acc_btns">
            <button
              onClick={handleClick}
              type="submit"
              className={
                "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
              }
            >
              Yes
            </button>
            <button
              onClick={handleClose}
              // type="submit"
              className={
                "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
              }
            >
              No
            </button>
          </div>
        ): modalName == "disableFunds" ? (
          <div className="d-flex justify-content-center delete_acc_btns">
            <button
              onClick={handleClose}
              // type="submit"
              className={
                "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
              }
            >
              Okay
            </button>
          </div>
        )  : modalName == "cancelAppointment" ||
          modalName == "needCancelAppointment" ||
          modalName == "cancelEstimate" ||
          modalName == "cancelQuote" ||
          modalName == "invoiceCancel" ? (
          <div className="d-flex justify-content-center delete_acc_btns">
            <button
              onClick={handleClick}
              type="submit"
              className={
                modalName == "cancelAppointment" ||
                  modalName == "cancelEstimate" ||
                  modalName == "cancelQuote" ||
                  modalName == "invoiceCancel"
                  ? "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3 msg_callback_popup"
                  : "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
              }
            >
              Yes
            </button>
            <button
              onClick={handleClose}
              // type="submit"
              className={
                modalName == "cancelAppointment" ||
                  modalName == "cancelEstimate" ||
                  modalName == "cancelQuote" ||
                  modalName == "invoiceCancel"
                  ? "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3 msg_callback_popup"
                  : "btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn mt-3"
              }
            >
              No
            </button>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RegularPopup;
