// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import msgIcon from "../assets/images/message_icon.svg";
const firebaseConfig = {
  apiKey: "AIzaSyDX3zGeaESpDdokFU23S0U6oqYCMzp6ivo",
  authDomain: "flamehold-bba96.firebaseapp.com",
  projectId: "flamehold-bba96",
  storageBucket: "flamehold-bba96.appspot.com",
  messagingSenderId: "383161256264",
  appId: "1:383161256264:web:01eb83b83178b840f68b7d",
  measurementId: "G-V0HPGXN61M",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BMEhtaqnOSqqq5ITkH4aehiWlY03jKAbDssymt1QW9ELQiH3DqO1UMyssR49bhmnEjbeCTUzk9CshGeP2-a6X-c`,
  })
    .then((currentToken) => {
      if (currentToken) {
        sessionStorage.setItem('device_token', currentToken);
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
     console.log(payload,"payloadpayload");
      const search = window.location.href;
      let isExistHash = search?.split("/")[search?.split("/")?.length -1];

      if(isExistHash?.includes("#")){
        isExistHash=isExistHash?.split("#")[0]
      }

      if(!['my-enquiries','trade-enquiries']?.includes(isExistHash)){
        toast(payload?.notification?.title, {
          icon: <img src={msgIcon} width="20" height="20" />,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
          style: { color: "black" },
        });
    }     
      resolve(payload);
    });
  });
